import { neutral10, spacing5 } from '@leaftrade/leaftrade-component-library';
import styled from 'styled-components';

export const CheckoutActionsLeafPayWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${spacing5};
  border: 1px solid ${neutral10.hex};
  padding: ${spacing5};
`;
