import React from 'react';
import useTilesMap from '../hooks/useTilesMap';

const Tile = ({ position, page, type, attributes }) => {
  const tilesMap = useTilesMap();
  const _attributes = { ...attributes, page, position };
  const Component = tilesMap[type];
  return <Component className='brand-page__tile' {..._attributes} />;
};

export default Tile;
