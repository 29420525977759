import {
  Typography,
  bodySmallTypography,
  boldBodySmallTypography,
} from '@leaftrade/leaftrade-component-library';
import React, { useEffect } from 'react';
import { useStorefrontState } from '../../../../hooks/useReduxState';
import ProductDetailsAccordion from '../ProductDetailsAccordion';
import { IndividualCannabinoidWrapper } from './ProductDetailsCannabinoidsAccordion.styles';

const ProductDetailsCannabinoidsAccordion = () => {
  const { productDetails } = useStorefrontState();
  const { isLoadingProductDetails, selectedVariant } = productDetails;
  const { lab_results_unit: labResultsUnit } = selectedVariant;

  const cannabinoidsData = selectedVariant?.stock_record?.batch?.cannabinoids;
  const unit = labResultsUnit === 'percent' ? '%' : ` ${labResultsUnit}`;

  useEffect(() => {
    const { hash } = window.location;
    if (!isLoadingProductDetails && hash === '#cannabinoids') {
      const element = document.getElementById('cannabinoids');
      const headerOffset = 75;
      const elementPosition = element.getBoundingClientRect().top;
      const offsetPosition = elementPosition + window.scrollY - headerOffset;

      window.scrollTo({
        top: offsetPosition,
        behavior: 'smooth',
      });
    }
  }, [isLoadingProductDetails]);

  return (
    <ProductDetailsAccordion isOpen header='Cannabinoids' id='cannabinoids'>
      {selectedVariant.total_cannabinoids ? (
        <Typography bodyStyle={bodySmallTypography}>
          <Typography bodyStyle={boldBodySmallTypography} component='span'>
            Total Cannabinoids
          </Typography>{' '}
          {selectedVariant.total_cannabinoids}
          {unit}
        </Typography>
      ) : null}
      {selectedVariant.total_active_cannabinoids ? (
        <Typography bodyStyle={bodySmallTypography}>
          <Typography bodyStyle={boldBodySmallTypography} component='span'>
            Total Active Cannabinoids
          </Typography>{' '}
          {selectedVariant.total_active_cannabinoids}
        </Typography>
      ) : null}
      {selectedVariant.stock_record?.batch?.cannabinoids?.total_terpenes &&
      !Object.keys(selectedVariant.stock_record?.batch?.terpenes || {})
        .length ? (
        <Typography bodyStyle={bodySmallTypography}>
          <Typography bodyStyle={boldBodySmallTypography} component='span'>
            Total Terpenes
          </Typography>{' '}
          {selectedVariant.stock_record.batch.cannabinoids.total_terpenes}
          {unit}
        </Typography>
      ) : null}
      <IndividualCannabinoidWrapper>
        {Object.keys(cannabinoidsData).length > 0 && (
          <>
            {Object.keys(cannabinoidsData).map((key) => {
              if (
                key !== 'lab_results_unit' &&
                key !== 'total_cannabinoids' &&
                key !== 'total_active_cannabinoids' &&
                key !== 'total_terpenes'
              ) {
                let formattedKey = key
                  .replace(/_/g, '-')
                  .split('-')
                  .map((word) =>
                    word.toLowerCase() === 'total'
                      ? 'Total'
                      : word.toUpperCase()
                  )
                  .join('-');

                if (formattedKey.startsWith('Total-')) {
                  formattedKey = formattedKey.replace('Total-', 'Total ');
                }

                const value = cannabinoidsData[key];
                return (
                  <Typography bodyStyle={bodySmallTypography}>
                    <Typography
                      bodyStyle={boldBodySmallTypography}
                      component='span'
                    >
                      {formattedKey}
                    </Typography>{' '}
                    {value}
                    {unit}
                  </Typography>
                );
              }
              return null;
            })}
          </>
        )}
      </IndividualCannabinoidWrapper>
    </ProductDetailsAccordion>
  );
};

export default ProductDetailsCannabinoidsAccordion;
