import { spacing3, spacing5 } from '@leaftrade/leaftrade-component-library';
import styled from 'styled-components';

export const StrainWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${spacing5};
`;

export const StrainHeader = styled.div`
  display: flex;
  align-items: center;
`;

export const ProductChipsWrapper = styled.div`
  flex: 1;
  display: flex;
  margin-left: ${spacing3};
`;
