import {
  Input,
  interaction40,
  interaction5,
  neutral0,
  neutral10,
  pxToRem,
  spacing3,
  spacing7,
} from '@leaftrade/leaftrade-component-library';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useStorefrontState } from '../../../../hooks/useReduxState';
import {
  editQuickOrderItemQuantity,
  getCartDataSummary,
} from '../../../../store/actions/storefrontActions';

const QuickOrderTableAllInput = ({ item }) => {
  const dispatch = useDispatch();
  const { quickOrderData, vendor } = useStorefrontState();
  const { selectedLocations } = quickOrderData;
  const { settings, slug } = vendor;
  const { batchQueuesEnabled } = settings;

  const { locations } = batchQueuesEnabled ? item : item.variants[0];

  const initialAllValue =
    !!locations.length &&
    selectedLocations.every(
      (selectedLocation) =>
        locations.find(
          (location) =>
            Number(location.dispensaryLocationId) ===
            Number(selectedLocation.id)
        )?.quantity === locations[0].quantity
    )
      ? locations[0].quantity
      : 0;

  const [allValue, setAllValue] = useState(initialAllValue);
  const [isUpdatingQuantity, setIsUpdatingQuantity] = useState(false);

  const selectedLocationsIds = selectedLocations.map((location) =>
    Number(location.id)
  );

  const handleAllLocationsQuantityBlur = (value) => {
    if (Number(value) !== Number(initialAllValue)) {
      setIsUpdatingQuantity(true);
      dispatch(
        editQuickOrderItemQuantity({
          batchQueuesEnabled,
          displayedLocationsIds: selectedLocationsIds,
          editLocationsIds: selectedLocationsIds,
          item,
          onSuccess: () => {
            setIsUpdatingQuantity(false);
            dispatch(getCartDataSummary({ slug }));
          },
          quantity: Number(value),
          slug,
        })
      );
    }
  };

  useEffect(() => {
    setAllValue(initialAllValue);
  }, [initialAllValue]);

  return (
    <Input
      disabled={isUpdatingQuantity}
      onBlur={(e) => handleAllLocationsQuantityBlur(e.target.value)}
      onChange={(e) => setAllValue(e.target.value)}
      onKeyUp={(e) => {
        if (e.key === 'Enter') {
          handleAllLocationsQuantityBlur(e.target.value);
        }
      }}
      placeholder='0'
      sx={{
        backgroundColor: interaction5.hex,
        padding: `${spacing7} ${spacing3}`,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: pxToRem(125),
        borderColor: interaction40.hex,
        border: '1px solid',
        borderBottom: 'none',
        borderTop: 'none',
        borderRadius: 0,
        '&:focus-within': {
          backgroundColor: interaction5.hex,
          borderColor: interaction40.hex,
        },
        '& input': {
          textAlign: 'center',
          backgroundColor: neutral0.hex,
          padding: `${spacing3} 0`,
          borderRadius: '4px',
          border: `1px solid ${neutral10.hex}`,
        },
      }}
      testingId='quick-order-table-item-all-input--component--input'
      type='number'
      value={allValue}
    />
  );
};

QuickOrderTableAllInput.propTypes = {
  item: PropTypes.shape({
    locations: PropTypes.arrayOf(
      PropTypes.shape({
        dispensaryLocationId: PropTypes.oneOfType([
          PropTypes.string,
          PropTypes.number,
        ]),
        quantity: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      })
    ),
    variants: PropTypes.arrayOf(
      PropTypes.shape({
        locations: PropTypes.arrayOf(
          PropTypes.shape({
            dispensaryLocationId: PropTypes.oneOfType([
              PropTypes.string,
              PropTypes.number,
            ]),
            quantity: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
          })
        ),
      })
    ),
  }),
};

export default QuickOrderTableAllInput;
