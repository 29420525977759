export const toastMessageEventKey = 'toastMessage';

export const ToastType = {
  ERROR: 'error',
  INFO: 'info',
  SUCCESS: 'success',
  WARNING: 'warning',
};

const isValidToastType = (toastType) =>
  Object.values(ToastType).some((type) => type === toastType);

export const newToastNotification = ({
  body,
  toastType,
  hasMarkup = false,
}) => {
  if (!toastType) {
    return console.error('toastType is required.');
  }

  if (!isValidToastType(toastType)) {
    return console.error(
      `toastType must be one of the following ToastTypes: ${Object.keys(
        ToastType
      )}.`
    );
  }

  const customEvent = new CustomEvent(toastMessageEventKey, {
    detail: { body, toastType, hasMarkup },
  });

  window.dispatchEvent(customEvent);

  return customEvent;
};

export const genericErrorToast = () => {
  newToastNotification({
    body: 'An error occurred. Please refresh and try again. Contact support if this problem persists.',
    toastType: ToastType.ERROR,
  });
};
