import { createSlice } from '@reduxjs/toolkit';
import vendorBrandsState from '../states/vendorBrandsState';
import {
  archiveVendorBrand,
  getVendorBrands,
  createVendorBrand,
  restoreVendorBrand,
  setFilters,
  setIsCallPending,
  updateVendorBrand,
  uploadVendorBrandLogo,
} from '../actions/vendorBrandsActions';
import { handleRejected } from '../../helpers';

export const vendorBrandsSlice = createSlice({
  name: 'vendorBrands',
  initialState: vendorBrandsState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(archiveVendorBrand.rejected, (state, action) =>
      handleRejected(state, action)
    );
    builder.addCase(getVendorBrands.pending, (state) => ({
      ...state,
      isLoading: true,
    }));
    builder.addCase(getVendorBrands.fulfilled, (state, action) => ({
      ...state,
      brands: action.payload.brands,
      brandNames: action.payload.allBrandNames,
      hasEditPermission: action.payload.hasEditPermission,
      isLoading: false,
      vendorId: action.meta.arg.vendorId,
    }));
    builder.addCase(getVendorBrands.rejected, (state, action) =>
      handleRejected(state, action)
    );
    builder.addCase(createVendorBrand.rejected, (state, action) =>
      handleRejected(state, action)
    );
    builder.addCase(restoreVendorBrand.rejected, (state, action) =>
      handleRejected(state, action)
    );
    builder.addCase(setFilters, (state, action) => ({
      ...state,
      filters: action.payload,
    }));
    builder.addCase(setIsCallPending, (state, action) => ({
      ...state,
      isCallPending: action.payload,
    }));
    builder.addCase(updateVendorBrand.rejected, (state, action) =>
      handleRejected(state, action)
    );
    builder.addCase(uploadVendorBrandLogo.rejected, (state, action) =>
      handleRejected(state, action)
    );
  },
});
