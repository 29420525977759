import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import { axiosGetData, axiosPatchData } from '../../helpers/axios';

export const setFilters = createAction('setFilters');
export const setSelectedProduct = createAction('setSelectedProduct');

export const fetchBrandsByVendor = createAsyncThunk(
  'fetchBrandsByVendor',
  async ({ data, onSuccess, url, onError }, { rejectWithValue }) =>
    axiosGetData({
      data,
      onSuccess,
      onError,
      rejectWithValue,
      url,
    })
);

export const fetchCategories = createAsyncThunk(
  'fetchCategories',
  async ({ data, onSuccess, url, onError }, { rejectWithValue }) =>
    axiosGetData({
      data,
      onSuccess,
      onError,
      rejectWithValue,
      url,
    })
);

export const fetchTags = createAsyncThunk(
  'fetchTags',
  async ({ data, onSuccess, url, onError }, { rejectWithValue }) =>
    axiosGetData({
      data,
      onSuccess,
      onError,
      rejectWithValue,
      url,
    })
);

export const fetchStrains = createAsyncThunk(
  'fetchStrains',
  async ({ data, onSuccess, url, onError }, { rejectWithValue }) =>
    axiosGetData({
      data,
      onSuccess,
      onError,
      rejectWithValue,
      url,
    })
);

export const updateProducts = createAsyncThunk(
  'updateProducts',
  async ({ data, onSuccess, url, onError }, { rejectWithValue }) =>
    axiosPatchData({
      data,
      onSuccess,
      onError,
      rejectWithValue,
      url,
      headers: { 'Content-Type': 'application/json' },
    })
);

export const setIsProductUpdating = createAction('setIsProductUpdating');
