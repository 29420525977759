import React, { Fragment, useState } from 'react';
import { ScrollSync } from 'react-scroll-sync';
import { useStorefrontState } from '../../../hooks/useReduxState';
import {
  QuickOrderTableBody,
  QuickOrderTableWrapper,
} from './QuickOrderTable.styles';
import QuickOrderTableHeader from './QuickOrderTableHeader';
import QuickOrderTableItem from './QuickOrderTableItem';
import QuickOrderTableItemBatchQueues from './QuickOrderTableItemBatchQueues';

const QuickOrderTable = () => {
  const { quickOrderData, vendor } = useStorefrontState();
  const { batchQueuesEnabled } = vendor.settings;
  const { items } = quickOrderData;

  const [showOverflowButtons, setShowOverflowButtons] = useState({
    left: false,
    right: false,
  });

  return (
    <QuickOrderTableWrapper data-testid='quick-order-table--component--div'>
      <ScrollSync>
        <div>
          <QuickOrderTableHeader
            setShowOverflowButtons={setShowOverflowButtons}
            showOverflowButtons={showOverflowButtons}
          />
          <QuickOrderTableBody>
            {items?.map((item) => (
              <Fragment key={item.cartItemId}>
                {batchQueuesEnabled ? (
                  <QuickOrderTableItemBatchQueues
                    key={item.cartItemId}
                    item={item}
                    showOverflowButtons={showOverflowButtons}
                  />
                ) : (
                  <QuickOrderTableItem
                    key={item.cartItemId}
                    item={item}
                    showOverflowButtons={showOverflowButtons}
                  />
                )}
              </Fragment>
            ))}
          </QuickOrderTableBody>
        </div>
      </ScrollSync>
    </QuickOrderTableWrapper>
  );
};

export default QuickOrderTable;
