import {
  Typography,
  neutral40,
  spacing5,
} from '@leaftrade/leaftrade-component-library';
import React from 'react';
import { useStorefrontState } from '../../../../hooks/useReduxState';

const ProductDetailsBrands = () => {
  const { productDetails } = useStorefrontState();
  const { brands } = productDetails;

  return (
    <Typography
      sx={{
        color: neutral40.hex,
        marginBottom: spacing5,
        width: 'max-content',
      }}
      testingId='product-details-content--brand--typography'
    >
      Brand
      {`${brands.length > 1 ? 's' : ''}:`}{' '}
      {`${brands.map((brand) => brand.name).join(', ')}`}
    </Typography>
  );
};

export default ProductDetailsBrands;
