import queryString from 'query-string';
import React from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { useStorefrontState } from '../../../hooks/useReduxState';
import {
  getAllSellersData,
  setSellersGridView,
} from '../../../store/actions/storefrontActions';
import {
  PageHeaderSearchStyleSortContainer,
  PageHeaderStyleSortContainer,
} from '../PageHeader/PageHeader.styles';
import PageHeaderFilters from '../PageHeader/PageHeaderFilters';
import PageHeaderInfo from '../PageHeader/PageHeaderInfo';
import PageHeaderSearch from '../PageHeader/PageHeaderSearch';
import PageHeaderSort from '../PageHeader/PageHeaderSort';
import PageHeaderStyleToggle from '../PageHeader/PageHeaderStyleToggle';
import { createCheckboxFilter } from '../../../utils/filters';

const SellersHeader = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const { sellersPageData } = useStorefrontState();
  const { brands, categories, isGridView, productTypes } = sellersPageData;

  const queryParamsParsed = queryString.parse(location.search);

  const handleSellersQuery = (sellersQuery) => {
    dispatch(getAllSellersData({ queryString: sellersQuery }));
  };

  const handleStyleToggle = (value) => {
    dispatch(setSellersGridView(value));
  };

  const handleQueryChange = (query = '') => {
    history.push({
      pathname: location.pathname,
      search: query,
    });
    handleSellersQuery(query);
  };

  const filterOptions = [
    createCheckboxFilter({
      data: productTypes,
      handleQueryChange,
      key: 'product_types_filters',
      name: 'Product Type',
      queryParamsParsed,
    }),
    createCheckboxFilter({
      data: categories,
      handleQueryChange,
      key: 'categories_filters',
      name: 'Category',
      queryParamsParsed,
    }),
    createCheckboxFilter({
      data: brands,
      handleQueryChange,
      key: 'brands_filters',
      name: 'Brand',
      queryParamsParsed,
    }),
  ];

  const sortOptions = [
    {
      key: 'name',
      value: 'name',
      label: 'Name: A-Z',
      level: 1,
      selected: queryParamsParsed.sort === 'name',
    },
    {
      key: '-name',
      value: '-name',
      label: 'Name: Z-A',
      level: 1,
      selected: queryParamsParsed.sort === '-name',
    },
    {
      key: 'favorite',
      value: 'favorite',
      label: 'My Favorites: A-Z',
      level: 1,
      selected: queryParamsParsed.sort === 'favorite',
    },
  ];

  return (
    <>
      <PageHeaderInfo title='Explore Our Sellers' />
      <PageHeaderSearchStyleSortContainer>
        <PageHeaderSearch
          onSearch={handleSellersQuery}
          placeholder='Search Sellers'
        />
        <PageHeaderStyleSortContainer>
          <PageHeaderStyleToggle
            isGridView={isGridView}
            onToggle={handleStyleToggle}
          />
          <PageHeaderSort
            onSort={handleSellersQuery}
            sortOptions={sortOptions}
          />
        </PageHeaderStyleSortContainer>
      </PageHeaderSearchStyleSortContainer>
      <PageHeaderFilters filterOptions={filterOptions} />
    </>
  );
};

export default SellersHeader;
