/* eslint-disable no-nested-ternary */
import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import {
  IconPdf,
  Link,
  bodySmallTypography,
  interaction5,
  interaction60,
  spacing3,
  useOnClickOutside,
} from '@leaftrade/leaftrade-component-library';
import {
  CartLineItemColumnLabsWrapper,
  CartLabResultsButton,
  CartLabResultsWrapper,
} from './CartLineItemColumnLabs.styles';

const CartLineItemColumnLabs = ({ labResults }) => {
  const dropdownRef = useRef();
  const [labDropDownOpen, setLabDropDownOpen] = useState(false);

  useOnClickOutside(dropdownRef, () => setLabDropDownOpen(false));

  const handleIconClick = () => {
    setLabDropDownOpen(!labDropDownOpen);
  };

  return (
    <CartLineItemColumnLabsWrapper
      data-testid='cart-line-item-column-labs--component--div'
      ref={dropdownRef}
    >
      {labResults.length > 1 ? (
        <>
          <CartLabResultsButton
            data-testid='cart-line-item-column-labs--labs-dropdown--button'
            onClick={handleIconClick}
            labDropDownOpen={labDropDownOpen}
            type='button'
          >
            <IconPdf fill={interaction60.hex} />
          </CartLabResultsButton>
          {labDropDownOpen && (
            <CartLabResultsWrapper>
              {labResults.map((labResult) => (
                <Link
                  bodyStyle={bodySmallTypography}
                  href={labResult}
                  key={decodeURI(labResult.split('/').pop())}
                  target='_blank'
                  testingId='cart-line-item-column-labs--dropdown-link--a'
                  underline='none'
                >
                  {decodeURI(labResult.split('/').pop())}
                </Link>
              ))}
            </CartLabResultsWrapper>
          )}
        </>
      ) : labResults.length === 1 ? (
        <Link
          href={labResults[0]}
          target='_blank'
          testingId='cart-line-item-column-labs--single-link--a'
          sx={{
            padding: spacing3,
            borderRadius: '4px',
            '&:hover': {
              backgroundColor: interaction5.hex,
            },
          }}
          underline='none'
        >
          <IconPdf fill={interaction60.hex} />
        </Link>
      ) : null}
    </CartLineItemColumnLabsWrapper>
  );
};

CartLineItemColumnLabs.propTypes = {
  labResults: PropTypes.arrayOf(PropTypes.string),
};

export default CartLineItemColumnLabs;
