import {
  neutral0,
  neutral10,
  pxToRem,
  spacing3,
  spacing5,
  spacing7,
} from '@leaftrade/leaftrade-component-library';
import styled from 'styled-components';

export const ListViewHeader = styled.div`
  display: flex;
  width: 100%;
  max-width: ${pxToRem(1200)};
  height: ${pxToRem(52)};
  align-items: center;
  border: 1px solid ${neutral10.hex};
  border-radius: 10px;
  background-color: ${neutral0.hex};
  margin: 0 auto ${spacing3};
`;

export const ProductsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  grid-gap: ${spacing5};
  max-width: ${pxToRem(1200)};
  margin: 0 auto;
`;

export const LoadMoreWrapper = styled.div`
  margin-bottom: ${spacing7};
`;
