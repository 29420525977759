import React from 'react';
import { useStorefrontState } from '../../../hooks/useReduxState';
import { ProductWrapper } from './ProductDetails.styles';
import ProductDetailsContentBottom from './ProductDetailsContentBottom';
import ProductDetailsContentTop from './ProductDetailsContentTop';
import ProductDetailsImage from './ProductDetailsImage';

const ProductDetails = () => {
  const { productDetails } = useStorefrontState();
  const { images } = productDetails;
  return (
    <ProductWrapper data-testid='product-details--component--div'>
      {images.length > 0 && <ProductDetailsImage />}
      <ProductDetailsContentTop />
      <ProductDetailsContentBottom />
    </ProductWrapper>
  );
};

export default ProductDetails;
