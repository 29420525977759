import {
  interaction60,
  mediumBodySmallTypography,
  neutral10,
  pxToRem,
  spacing4,
  spacing5,
  spacing6,
} from '@leaftrade/leaftrade-component-library';
import styled from 'styled-components';

export const CartLineItemBatchQueuesWrapper = styled.div`
  display: flex;
  margin-top: ${spacing6};
`;

export const CartLineItemBatchQueuesTotalFiller = styled.div`
  width: 30%;
`;

export const ShowAllButton = styled.button`
  border: 1px solid ${neutral10.hex};
  border-right: none;
  border-left: none;
  margin: ${spacing5} 0;
  background: none;
  padding: ${spacing4} 0 ${spacing4} ${pxToRem(72)};
  color: ${interaction60.hex};
  ${mediumBodySmallTypography};
  width: 100%;
  text-align: left;
`;
