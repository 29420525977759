import {
  Typography,
  bodySmallTypography,
  neutral60,
} from '@leaftrade/leaftrade-component-library';
import PropTypes from 'prop-types';
import React from 'react';
import { currencyFormatter } from '../../../../utils';

const CartLineItemColumnUnitPrice = ({ unitPrice }) => (
  <Typography
    bodyStyle={bodySmallTypography}
    sx={{
      color: neutral60.hex,
      width: '15%',
      display: 'flex',
      alignItems: 'center',
    }}
    testingId='cart-line-item-column-unit-price--component--p'
  >
    {unitPrice ? currencyFormatter.format(unitPrice) : '-'}
  </Typography>
);

CartLineItemColumnUnitPrice.propTypes = {
  unitPrice: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default CartLineItemColumnUnitPrice;
