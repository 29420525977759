import styled from 'styled-components';
import hexToRGBA from '../../../../../utils/hexToRGBA';

export const GradientWrapper = styled.div`
  background: linear-gradient(
    0deg,
    ${({ gradientBackgroundColor }) => hexToRGBA(gradientBackgroundColor, 0.9)}
      0%,
    ${({ gradientBackgroundColor }) => hexToRGBA(gradientBackgroundColor, 0.2)}
      100%
  );
  width: 100%;
  height: 100%;

  .brand-page__tile-title,
  .brand-page__tile-subtitle,
  .brand-page__tile-description {
    color: ${({ textColor }) => textColor || '#000000'};
  }
`;
