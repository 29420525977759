import {
  interaction10,
  neutral5,
  spacing5,
  spacing6,
} from '@leaftrade/leaftrade-component-library';
import styled from 'styled-components';

export const QuickOrderTableItemWrapper = styled.div`
  display: flex;
  padding: 0 ${spacing5};
  border-radius: 4px;
  &:nth-of-type(even) {
    background-color: ${neutral5.hex};
    & [data-testid='quick-order-table-item-all-input--component--input'] {
      background-color: ${interaction10.hex};
    }
  }
  &:last-of-type {
    & [data-testid='quick-order-table-item-all-input--component--input'] {
      border-radius: 0 0 4px 4px;
      border-bottom: 1px solid;
    }
  }
`;

export const QuickOrderTableLocationColumnsWrapper = styled.div`
  display: flex;
  overflow: hidden;
  flex: 1;
  position: relative;
`;

export const QuickOrderTableLocationColumns = styled.div`
  display: flex;
  align-items: center;
  overflow-x: auto;
  overflow-y: hidden;
  flex: 1;
  position: relative;
  scrollbar-width: none;
  -ms-overflow-style: none;
  &::-webkit-scrollbar {
    display: none;
  }
`;

export const QuickOrderTableLocationColumnWrapper = styled.div`
  position: relative;
  flex: 1;
`;

export const LocationColumnScrollGradient = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  width: ${spacing6};
  z-index: 1;
  ${({ isLeft }) =>
    isLeft
      ? `
      left: 0;
      background: linear-gradient(90deg, rgba(77, 72, 173, 0.2) 0%, rgba(77, 72, 173, 0) 100%)
    `
      : `
      right: 0;
      background: linear-gradient(90deg, rgba(77, 72, 173, 0) 0%, rgba(77, 72, 173, 0.2) 100%);
    `}
`;
