import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Typography } from '@leaftrade/leaftrade-component-library';
import { useDispatch } from 'react-redux';
import { BrandPageWrapper } from './BrandPage.styles';
import Tile from './Tiles/Tile';
import { downloadBrandPage } from '../../../store/actions/brandPagesActions';
import {
  newToastNotification,
  ToastType,
} from '../../../dashboard/components/ToastNotifications/toasts';
import { useBrandPagesState } from '../../../hooks/useReduxState';
import PageLoader from '../../components/PageLoader';
import useQueryParam from '../../../hooks/useQueryParam';
import CallFallback from '../../components/CallFallback';
import { brandPageViewEvent } from '../../analytics/brand_pages';

const BrandPage = () => {
  const { vendorName, pageUrlSegment } = useParams();
  const dispatch = useDispatch();
  const {
    page: {
      headerImageUrl,
      backgroundColor,
      title,
      subtitle,
      logoImageUrl,
      pageTiles,
    },
    page,
    statusError,
    isPageLoading,
  } = useBrandPagesState();

  const previewPageId = useQueryParam('pageId');

  useEffect(() => {
    dispatch(
      downloadBrandPage({
        url: previewPageId
          ? `/dashboard/api/brand-pages/${previewPageId}`
          : `/storefront2/public/api/brand-pages/vendor/${vendorName}/brands/${pageUrlSegment}`,
        onError: () => {
          newToastNotification({
            body: 'Error downloading page.',
            toastType: ToastType.ERROR,
          });
        },
        onSuccess: (result) => {
          const {
            vendor_id: vendorId,
            brand_id: brandId,
            id: brandPageId,
          } = result.brand_page;
          brandPageViewEvent({
            vendorId,
            brandId,
            brandPageId,
          });
        },
      })
    );
  }, [vendorName, pageUrlSegment]);

  if (statusError) {
    return <CallFallback statusError={statusError} />;
  }
  return (
    <BrandPageWrapper backgroundColor={backgroundColor}>
      <div className='brand-page__banner'>
        <img src={headerImageUrl} className='brand-page' />
      </div>

      {isPageLoading ? (
        <PageLoader />
      ) : (
        <div className='brand-page__container'>
          <div className='brand-page__header'>
            <div className='brand-page__logo'>
              <img src={logoImageUrl} alt='logo' />
            </div>
            <div>
              <Typography component='h4' classes={['brand-page__title']}>
                {title}
              </Typography>
              <Typography component='h6' classes={['brand-page__subtitle']}>
                {subtitle}
              </Typography>
            </div>
          </div>
          <div className='brand-page__tiles'>
            {pageTiles.map((i, position) => (
              <Tile
                key={i.id}
                position={position}
                page={page}
                type={i.tileType}
                attributes={i.attributes}
              />
            ))}
          </div>
        </div>
      )}
    </BrandPageWrapper>
  );
};

export default BrandPage;
