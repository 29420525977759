import PropTypes from 'prop-types';
import React from 'react';
import CartLineItemColumnDelete from '../CartLineItemColumnDelete';
import CartLineItemColumnItem from '../CartLineItemColumnItem';
import CartLineItemColumnLabs from '../CartLineItemColumnLabs';
import CartLineItemColumnQuantity from '../CartLineItemColumnQuantity';
import CartLineItemColumnTotal from '../CartLineItemColumnTotal';
import CartLineItemColumnUnitPrice from '../CartLineItemColumnUnitPrice';
import { CartLineItemWrapper } from './CartLineItem.styles';

const CartLineItem = ({ item }) => {
  const labResultsPresent = item?.variants.some(
    (variant) => variant?.labResultsUrl?.length
  );

  return (
    <CartLineItemWrapper data-testid='cart-line-item--component--div'>
      <CartLineItemColumnItem item={item} />
      {labResultsPresent && (
        <CartLineItemColumnLabs labResults={item?.variants[0]?.labResultsUrl} />
      )}
      <CartLineItemColumnQuantity item={item} />
      <CartLineItemColumnUnitPrice
        unitPrice={item?.variants[0]?.storefrontDisplayPrice}
      />
      <CartLineItemColumnTotal totalPrice={item?.totalPrice} />
      <CartLineItemColumnDelete item={item} />
    </CartLineItemWrapper>
  );
};

CartLineItem.propTypes = {
  item: PropTypes.shape({
    cartItemId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    totalPrice: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    variants: PropTypes.arrayOf(
      PropTypes.shape({
        storefrontDisplayPrice: PropTypes.oneOfType([
          PropTypes.string,
          PropTypes.number,
        ]),
        labResultsUrl: PropTypes.arrayOf(PropTypes.string),
      })
    ),
  }),
};

export default CartLineItem;
