import {
  AlertTooltip,
  Button,
  IconLoadingSpinner,
  Input,
  Typography,
  bodySmallTypography,
  neutral50,
  neutral20,
  spacing4,
} from '@leaftrade/leaftrade-component-library';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useStorefrontState } from '../../../../hooks/useReduxState';
import {
  addProductToCart,
  addTimedBanner,
  getCartData,
} from '../../../../store/actions/storefrontActions';
import { AddToCartWrapper, QuantitySubmitWrapper } from './AddToCart.styles';
import { addToCartQtyMsg, getVariantKey } from '../../../utils';

const AddToCart = ({
  alert,
  productId,
  selectedVariant,
  setAlert,
  slug,
  variantsByKey,
}) => {
  const dispatch = useDispatch();
  const { cart, vendor } = useStorefrontState();
  const { selectedCart } = cart;
  const { token } = selectedCart;
  const { settings, allow_overordering } = vendor;
  const { batchQueuesEnabled, isGridView, settingsDeprecated } = settings;
  const { storefront_aggregate_variants: storefrontAggregateVariants } =
    settingsDeprecated;

  const [quantity, setQuantity] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [tooltipOpen, setTooltipOpen] = useState(false);

  const handleAddToCartSuccess = async (responseData) => {
    await dispatch(getCartData({ slug, token }));
    setQuantity('');
    setIsLoading(false);
    let newQuantity = 0;
    if (responseData.quantities) {
      newQuantity = Object.values(responseData.quantities).reduce(
        (a, b) => a + b,
        0
      );
    }
    if (newQuantity > 0) {
      dispatch(
        addTimedBanner({
          id: `${productId}-${
            selectedVariant.id || selectedVariant.variant_id
          }`,
          message: 'Cart Updated',
          severity: 'success',
        })
      );
    }
    if (responseData.warning && !storefrontAggregateVariants) {
      let alertMessage = responseData.warning;
      if (responseData.quantities) {
        alertMessage = addToCartQtyMsg(newQuantity, quantity, true);
      }
      setAlert(alertMessage);
      setTooltipOpen(true);
    }
    if (responseData.errors_and_warnings && !storefrontAggregateVariants) {
      setAlert(responseData.errors_and_warnings);
      setTooltipOpen(true);
    }
  };

  const handleAddToCart = async ({
    isHandlingAggregates = false,
    requestedQuantity = quantity,
    variant = selectedVariant,
  }) => {
    setIsLoading(true);

    let data;
    if (batchQueuesEnabled) {
      data = {
        package_size_pk: variant.package_size_id || variant.id,
        case_count: parseInt(requestedQuantity, 10),
      };
    } else {
      data = [
        {
          productPK: productId,
          productName: variant.product_name || variant.name,
          variantPK: variant.id || variant.variant_id,
          quantity: parseInt(requestedQuantity, 10),
        },
      ];
    }
    let aggregateData;
    await dispatch(
      addProductToCart({
        batchQueuesEnabled,
        data,
        onSuccess: (responseData) => {
          if (isHandlingAggregates) {
            aggregateData = responseData;
          }
          handleAddToCartSuccess(responseData);
        },
        slug,
      })
    );
    return aggregateData;
  };

  const handleAggregateAddToCart = async () => {
    const key = getVariantKey(selectedVariant);
    let foundQuantity = 0;

    const processNextVariant = async (variants) => {
      if (variants.length === 0 || foundQuantity >= Number(quantity)) {
        return;
      }

      const variant = variants[0];
      const currentVariantQuantityResult = await handleAddToCart({
        isHandlingAggregates: true,
        requestedQuantity: Number(quantity) - foundQuantity,
        variant,
      });
      const currentVariantAvailableQuantity = Number(
        Object.values(currentVariantQuantityResult.quantities)[0]
      );

      if (currentVariantAvailableQuantity > 0) {
        foundQuantity += currentVariantAvailableQuantity;
      }

      await processNextVariant(variants.slice(1));
    };

    await processNextVariant(variantsByKey[key]);
  };

  return (
    <AddToCartWrapper
      data-testid='add-to-cart--component--div'
      isGridView={isGridView}
    >
      {(selectedVariant?.inventory_live_count > 0 || vendor.settings.allowOverOrdering) &&
      (selectedVariant?.is_saleable || selectedVariant?.variant_saleable) ? (
        <QuantitySubmitWrapper isGridView={isGridView}>
          <Input
            onChange={(e) => setQuantity(e.target.value)}
            onKeyUp={(e) => {
              if (e.key === 'Enter') {
                if (storefrontAggregateVariants && !batchQueuesEnabled) {
                  handleAggregateAddToCart();
                } else {
                  handleAddToCart({});
                }
              }
            }}
            placeholder='0'
            size='large'
            startAdornment={
              alert && (
                <AlertTooltip
                  alertType='warning'
                  clickable
                  id={selectedVariant.id || selectedVariant.variant_id}
                  isOpen={tooltipOpen}
                  setIsOpen={setTooltipOpen}
                  tooltipPlacement='right'
                >
                  <>
                    {typeof alert === 'string' ? (
                      <Typography bodyStyle={bodySmallTypography} gutterBottom>
                        {alert}
                      </Typography>
                    ) : (
                      <>
                        <Typography
                          bodyStyle={bodySmallTypography}
                          gutterBottom
                        >
                          {alert.title || alert.message}
                        </Typography>
                        <Typography
                          bodyStyle={bodySmallTypography}
                          sx={{ color: neutral20.hex }}
                        >
                          {alert.subtitle || alert.sub_message}
                        </Typography>
                      </>
                    )}
                  </>
                </AlertTooltip>
              )
            }
            sx={{
              width: isGridView ? '50%' : '100%',
              '& input': {
                textAlign: 'center',
              },
            }}
            testingId='add-to-cart--quantity--input'
            type='number'
            value={quantity}
          />
          <Button
            disabled={isLoading || quantity === '' || quantity === '0'}
            onClick={
              storefrontAggregateVariants && !batchQueuesEnabled
                ? handleAggregateAddToCart
                : handleAddToCart
            }
            sx={{ width: isGridView ? '50%' : '100%' }}
            testingId='add-to-cart--submit--button'
          >
            {isLoading ? (
              <IconLoadingSpinner data-testid='add-to-cart--loading-spinner--svg' />
            ) : (
              'Add'
            )}
          </Button>
        </QuantitySubmitWrapper>
      ) : (
        <Typography
          bodyStyle={bodySmallTypography}
          sx={{
            color: neutral50.hex,
            margin: `${spacing4} 0`,
          }}
        >
          Not Available
        </Typography>
      )}
    </AddToCartWrapper>
  );
};

AddToCart.propTypes = {
  selectedVariant: PropTypes.shape({
    inventory_live_count: PropTypes.number,
  }).isRequired,
  alert: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      message: PropTypes.string,
      subtitle: PropTypes.string,
      sub_message: PropTypes.string,
      title: PropTypes.string,
    }),
  ]),
  productId: PropTypes.number.isRequired,
  setAlert: PropTypes.func.isRequired,
  slug: PropTypes.string.isRequired,
  variantsByKey: PropTypes.object,
};

export default AddToCart;
