import placeholderImg from '../../images/favicon.png';

export const xsBreakpoint = 576;
export const smBreakpoint = 768;
export const mdBreakpoint = 992;
export const lgBreakpoint = 1200;

export const isMobile = () => window.innerWidth < smBreakpoint;

export const isTablet = () =>
  window.innerWidth >= smBreakpoint && window.innerWidth <= mdBreakpoint;

export const generateImageUrl = (imgUrl) => imgUrl || placeholderImg;

export const generateStorefrontUrl = (
  type,
  vendorSlug,
  brandName = null,
  disabled = false
) => {
  let actionUrl = `/shop/${vendorSlug}/products/`;
  if (type === `brands`) actionUrl = `${actionUrl}?brand=${brandName}`;
  if (disabled) actionUrl = null;
  return actionUrl;
};

export const generateStorefront2Url = (type, vendorSlug, brandId = null) => {
  let actionUrl = `/storefront/sellers/${vendorSlug}`;
  if (type === 'brands') actionUrl = `${actionUrl}?brands=${brandId}`;
  return actionUrl;
};

export const isFavoriteVendor = (vendor, favoriteVendors = null) => {
  const favoriteList = favoriteVendors.map((favorite) => favorite.id);
  return favoriteVendors && favoriteList.includes(vendor.id);
};

export const isTileEnabled = (type, row) => {
  if (type === `brands`) return row.active;
  return row.settings_deprecated.storefront_visible;
};

export const chunkArray = (myArray, chunkSize) => {
  let index = 0;
  const arrayLength = myArray.length;
  const tempArray = [];

  for (index = 0; index < arrayLength; index += chunkSize) {
    const myChunk = myArray.slice(index, index + chunkSize);
    tempArray.push(myChunk);
  }

  return tempArray;
};

export const getNumberOfDisplayCards = (content) => {
  const defaultLength = 4;
  const contentLength =
    content.length > defaultLength - 1
      ? Math.max(content.length, defaultLength)
      : content.length;
  const mobileLength = isMobile() ? 2 : contentLength;
  const tabletLength = isTablet() ? 3 : contentLength;
  return Math.min(defaultLength, contentLength, mobileLength, tabletLength);
};

export const getNumberOfSponsorshipCards = (content) => {
  const defaultLength = 2;
  const contentLength =
    content.length > defaultLength - 1
      ? Math.max(content.length, defaultLength)
      : content.length;
  const mobileLength = isMobile() ? 1 : contentLength;
  const tabletLength = isTablet() ? 2 : contentLength;
  return Math.min(defaultLength, contentLength, mobileLength, tabletLength);
};

export const parse12HrTime = (time) => {
  const splitTime = time.split(':');
  if (splitTime[0] === 0) {
    splitTime[0] = 12;
  }
  return `${splitTime[0] > 12 ? splitTime[0] % 12 : splitTime[0]}:${
    splitTime[1]
  } ${splitTime[0] > 12 ? 'PM' : 'AM'}`;
};

export function deepFreeze(object) {
  // recursively freezes objects
  // Retrieve the property names defined on object
  const propNames = Object.getOwnPropertyNames(object);

  // Freeze properties before freezing self

  propNames.forEach((name) => {
    const value = object[name];

    if (value && typeof value === 'object') {
      deepFreeze(value);
    }
  });

  return Object.freeze(object);
}

export function findDuplicates(list) {
  const set = new Set(list);

  return list.filter((item) => {
    if (set.has(item)) {
      set.delete(item);
      return null;
    }

    return item;
  });
}

export function groupOptionsForOmniSelect(array, optionGroupKey) {
  const output = {};
  array.forEach((item) => {
    if (!output[item[optionGroupKey]]) {
      output[item[optionGroupKey]] = [];
    }
    output[item[optionGroupKey]].push(item);
  });
  return Object.entries(output);
}

export const usdCurrencyFormatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
});

export function JSONStringifyAndKeepUndefinedKeys(object) {
  return JSON.stringify(object, (k, v) => (v === undefined ? null : v));
}

export const formOmniSelectOptions = ({ array, valueKey, labelKey }) =>
  array.map((item) => ({
    value: item[valueKey],
    label: item[labelKey],
  }));

// imperfect validation since it can't do an RFC domain check,
// but does the basic "is it malformed?" check
export const validateEmail = (email) =>
  !!email && /^\w+([.+-]?\w+)*@\w+([.-]?\w+)*(.\w{2,})+$/.test(email.trim());

function convertKeys(object, converter) {
  let converted = object;

  if (object && typeof object === 'object') {
    if (object.constructor === Array) {
      converted = object.map((v) => convertKeys(v, converter));
    } else if (object.constructor === Object) {
      converted = converter(object);
    }
  }

  return converted;
}

export function camelToSnake(object) {
  const converter = (object) =>
    Object.keys(object).reduce(
      (toSnake, k) =>
        Object.assign(toSnake, {
          [k
            .split(/(?=[A-Z])/)
            .map((w) => w.toLowerCase())
            .join('_')]: camelToSnake(object[k]),
        }),
      {}
    );

  return convertKeys(object, converter);
}

// please use the camelize library instead;
// this illustrates key conversion as the inverse of the above
// but we have a package for it
// (unfortunately the packages for the inverse operation operate on strings,
//  not object keys, so we have the above)
export function snakeToCamel(object) {
  const converter = (object) =>
    Object.keys(object).reduce(
      (toCamel, k) =>
        Object.assign(toCamel, {
          [k
            .split('_')
            .map((w, i) =>
              i ? `${w.charAt(0).toUpperCase()}${w.slice(1)}` : w
            )
            .join('')]: snakeToCamel(object[k]),
        }),
      {}
    );

  return convertKeys(object, converter);
}
