import {
  IconChevronLeft,
  IconChevronRight,
  Typography,
  interaction40,
  interaction5,
  mediumBodySmallTypography,
  neutral60,
  pxToRem,
  spacing2,
  spacing3,
  spacing5,
  spacing6,
} from '@leaftrade/leaftrade-component-library';
import React, { useCallback, useEffect, useState } from 'react';
import { ScrollSyncPane } from 'react-scroll-sync';
import PropTypes from 'prop-types';
import { useStorefrontState } from '../../../../hooks/useReduxState';
import {
  QuickOrderTableHeaderFixedColumns,
  QuickOrderTableHeaderScrollButton,
  QuickOrderTableHeaderScrollGradient,
  QuickOrderTableHeaderVariableColumns,
  QuickOrderTableHeaderVariableColumnsWrapper,
  QuickOrderTableHeaderWrapper,
} from './QuickOrderTableHeader.styles';

const QuickOrderTableHeader = ({
  setShowOverflowButtons,
  showOverflowButtons,
}) => {
  const { quickOrderData } = useStorefrontState();
  const { items, selectedLocations } = quickOrderData;

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const scrollClickOffset = 150;

  const scrollRef = useCallback(
    (node) => {
      if (node !== null) {
        setShowOverflowButtons({
          left: node.firstChild.scrollLeft > 0,
          right:
            node.firstChild.scrollWidth - node.firstChild.clientWidth !== 0,
        });
      }
    },
    [windowWidth, selectedLocations]
  );

  const handleOverFlowButtonClick = (e, scrollOffset) => {
    const targetScrollContainer =
      Math.sign(scrollOffset) === -1
        ? e.target.nextSibling
        : e.target.previousSibling;
    targetScrollContainer.scrollLeft += scrollOffset;
    setShowOverflowButtons({
      left: targetScrollContainer.scrollLeft > 0,
      right:
        Math.ceil(targetScrollContainer.scrollLeft) !==
        targetScrollContainer.scrollWidth - targetScrollContainer.clientWidth,
    });
  };

  const handleOverflowScroll = (e) => {
    setShowOverflowButtons({
      left: e.target.scrollLeft > 0,
      right:
        Math.ceil(e.target.scrollLeft) !==
        e.target.scrollWidth - e.target.clientWidth,
    });
  };

  useEffect(() => {
    function handleResize() {
      setWindowWidth(window.innerWidth);
    }

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <QuickOrderTableHeaderWrapper data-testid='quick-order-table-header--component--div'>
      <QuickOrderTableHeaderFixedColumns>
        <Typography
          bodyStyle={mediumBodySmallTypography}
          sx={{
            width: pxToRem(320),
            marginRight: spacing3,
          }}
        >
          Item
        </Typography>
        {selectedLocations.length > 1 && (
          <Typography
            bodyStyle={mediumBodySmallTypography}
            sx={{
              backgroundColor: interaction5.hex,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              minWidth: pxToRem(125),
              flex: 1,
              padding: `${spacing5} ${spacing3}`,
              borderRadius: items.length
                ? `${spacing2} ${spacing2} 0 0`
                : spacing2,
              borderColor: interaction40.hex,
              borderTop: '1px solid',
              borderRight: '1px solid',
              borderBottom: items.length ? 'none' : '1px solid',
              borderLeft: '1px solid',
            }}
          >
            All
          </Typography>
        )}
      </QuickOrderTableHeaderFixedColumns>
      <QuickOrderTableHeaderVariableColumnsWrapper ref={scrollRef}>
        {showOverflowButtons.left && (
          <>
            <QuickOrderTableHeaderScrollGradient isLeft />
            <QuickOrderTableHeaderScrollButton
              type='button'
              onClick={(e) => {
                handleOverFlowButtonClick(e, -scrollClickOffset);
              }}
              isLeft
            >
              <IconChevronLeft />
            </QuickOrderTableHeaderScrollButton>
          </>
        )}
        <ScrollSyncPane>
          <QuickOrderTableHeaderVariableColumns onScroll={handleOverflowScroll}>
            {[...selectedLocations]
              .sort((a, b) => a.name.localeCompare(b.name))
              .map((selectedLocation) => (
                <Typography
                  align='center'
                  bodyStyle={mediumBodySmallTypography}
                  key={selectedLocation.id}
                  noWrap
                  sx={{
                    minWidth: pxToRem(140),
                    flex: 1,
                  }}
                  title={selectedLocation.name}
                >
                  {selectedLocation.name}
                </Typography>
              ))}
          </QuickOrderTableHeaderVariableColumns>
        </ScrollSyncPane>
        {showOverflowButtons.right && (
          <>
            <QuickOrderTableHeaderScrollButton
              type='button'
              onClick={(e) => {
                handleOverFlowButtonClick(e, scrollClickOffset);
              }}
            >
              <IconChevronRight />
            </QuickOrderTableHeaderScrollButton>
            <QuickOrderTableHeaderScrollGradient />
          </>
        )}
      </QuickOrderTableHeaderVariableColumnsWrapper>
      <QuickOrderTableHeaderFixedColumns>
        <Typography
          align='center'
          bodyStyle={mediumBodySmallTypography}
          sx={{
            color: neutral60.hex,
            minWidth: pxToRem(125),
            flex: 1,
          }}
        >
          Quantity
        </Typography>
        <Typography
          bodyStyle={mediumBodySmallTypography}
          sx={{
            color: neutral60.hex,
            minWidth: pxToRem(125),
            flex: 1,
          }}
        >
          Subtotal
        </Typography>
        <Typography
          bodyStyle={mediumBodySmallTypography}
          sx={{
            width: spacing6,
            flexShrink: 0,
          }}
        >
          {' '}
        </Typography>
      </QuickOrderTableHeaderFixedColumns>
    </QuickOrderTableHeaderWrapper>
  );
};

QuickOrderTableHeader.propTypes = {
  setShowOverflowButtons: PropTypes.func.isRequired,
  showOverflowButtons: PropTypes.shape({
    left: PropTypes.bool.isRequired,
    right: PropTypes.bool.isRequired,
  }).isRequired,
};

export default QuickOrderTableHeader;
