import $ from 'jquery';
import 'jquery.cookie';
import { useRef, useState, useEffect } from 'react';
import flatpickr from 'flatpickr';
import initBootstrapSelect from './BootstrapSelect';

export default function initUI(root) {
  if (!root) {
    root = $('body');
  } else {
    root = $(root);
  }

  // Init select fields
  initBootstrapSelect(root);

  const altFormat = 'm/d/Y';

  // Init datepickers
  // Expects date format to be in Y-m-d
  // adding this back in for a bug. Delete when biotrack is completely react.
  root.find('.datepicker').each(function () {
    flatpickr(this, {
      altInput: true,
      altFormat,
      dateFormat: 'Y-m-d',
      allowInput: true,
      onClose(selectedDates, dateStr, instance) {
        if (instance._input.value === '') {
          instance.clear();
        }
      },
    });
  });

  root.find('.datetimepicker').each(function () {
    flatpickr(this, {
      altInput: true,
      altFormat: 'm/d/Y h:i K',
      enableTime: true,
      dateFormat: 'Y-m-d H:i',
      allowInput: true,
      onClose(selectedDates, dateStr, instance) {
        if (instance._input.value === '') {
          instance.clear();
        }
      },
    });
  });

  disableInputs(root);
}

// see https://docs.djangoproject.com/en/3.0/ref/csrf/
function getCookie(name) {
  let cookieValue = null;
  if (document.cookie && document.cookie !== '') {
    const cookies = document.cookie.split(';');
    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i].trim();
      if (cookie.substring(0, name.length + 1) === `${name}=`) {
        cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
        break;
      }
    }
  }
  return cookieValue;
}

const fmtUri = (pathname, position) => {
  const paths = pathname.split('/');
  paths.pop();
  paths.shift();
  let uri = '';
  paths.forEach((p) => {
    uri = `${uri}/${p}`;
  });
  return `${uri}/${position}`;
};

// Hooks
function useHover() {
  const [value, setValue] = useState(false);
  const ref = useRef(null);

  const handleMouseOver = () => setValue(true);
  const handleMouseOut = () => setValue(false);

  useEffect(
    () => {
      const node = ref.current;
      if (node) {
        node.addEventListener('mouseover', handleMouseOver);
        node.addEventListener('mouseout', handleMouseOut);

        return () => {
          node.removeEventListener('mouseover', handleMouseOver);
          node.removeEventListener('mouseout', handleMouseOut);
        };
      }
    },
    [ref.current] // Recall only if ref changes
  );
  return [ref, value];
}

export { getCookie, useHover, fmtUri };

// Disabled forms
function disableInputs(root) {
  root.find('.disable-inputs input').prop('disabled', true);
  root.find('.disable-inputs select').prop('disabled', true);
  root.find('.disable-inputs textarea').prop('disabled', true);
  root.find('.disable-inputs .custom-file-label').hide();

  root.find('.readonly-inputs input').prop('readonly', true);
  root.find('.readonly-inputs select').prop('readonly', true);
  root.find('.readonly-inputs textarea').prop('readonly', true);
  root.find('.readonly-inputs .custom-file-label').hide();
}
