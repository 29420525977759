import {
  bodyLargeTypography,
  Typography,
} from '@leaftrade/leaftrade-component-library';
import React from 'react';
import { useStorefrontState } from '../../../hooks/useReduxState';
import { currencyFormatter } from '../../../utils';
import {
  CheckoutSummaryBox,
  CheckoutSummaryBoxTotal,
} from './CheckoutSummary.styles';

const CheckoutSummary = () => {
  const { checkout } = useStorefrontState();
  const { checkoutData } = checkout;
  const { discount, estimatedTax, totalShipping, subtotal, surcharge, total } =
    checkoutData;

  return (
    <div data-testid='checkout-summary--component--div'>
      <CheckoutSummaryBox>
        <Typography bodyStyle={bodyLargeTypography}>Subtotal</Typography>
        <Typography bodyStyle={bodyLargeTypography}>
          {currencyFormatter.format(subtotal)}
        </Typography>
      </CheckoutSummaryBox>
      {!!discount && (
        <CheckoutSummaryBox>
          <Typography bodyStyle={bodyLargeTypography}>Discount</Typography>
          <Typography bodyStyle={bodyLargeTypography}>
            {currencyFormatter.format(discount)}
          </Typography>
        </CheckoutSummaryBox>
      )}
      {!!surcharge && (
        <CheckoutSummaryBox>
          <Typography bodyStyle={bodyLargeTypography}>Surcharge</Typography>
          <Typography bodyStyle={bodyLargeTypography}>
            {currencyFormatter.format(surcharge)}
          </Typography>
        </CheckoutSummaryBox>
      )}
      <CheckoutSummaryBox>
        <Typography bodyStyle={bodyLargeTypography}>Delivery</Typography>
        <Typography bodyStyle={bodyLargeTypography}>
          {totalShipping ? currencyFormatter.format(totalShipping) : '-'}
        </Typography>
      </CheckoutSummaryBox>
      {!!estimatedTax && (
        <CheckoutSummaryBox>
          <Typography bodyStyle={bodyLargeTypography}>Tax</Typography>
          <Typography bodyStyle={bodyLargeTypography}>
            {currencyFormatter.format(estimatedTax)}
          </Typography>
        </CheckoutSummaryBox>
      )}
      <CheckoutSummaryBoxTotal>
        <Typography bodyStyle={bodyLargeTypography}>Total</Typography>
        <Typography bodyStyle={bodyLargeTypography}>
          {currencyFormatter.format(total)}
        </Typography>
      </CheckoutSummaryBoxTotal>
    </div>
  );
};

export default CheckoutSummary;
