import { spacing5 } from '@leaftrade/leaftrade-component-library';
import styled from 'styled-components';

export const ExploreCardsWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  grid-gap: ${spacing5};
`;
