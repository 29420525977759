import {
  Card,
  Typography,
  bodySmallTypography,
  handleImgSrcError,
} from '@leaftrade/leaftrade-component-library';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import {
  featuredBrandCardClickEvent,
  featuredBrandCardRenderedEvent,
} from '../../../analytics/landing';
import {
  LandingPageCard,
  LandingPageCardsContainer,
} from '../LandingPage.styles';
import {
  LandingPageFeaturedBase,
  LandingPageFeaturedTitleBox,
  LandingPageFeaturedTitleBoxCopy,
  LandingPageFeaturedTitleBoxHeader,
} from './LandingPageFeatured.styles';

const LandingPageFeaturedBrands = ({ featuredObjects }) => {
  useEffect(() => {
    if (featuredObjects.length > 0) {
      featuredObjects.forEach((brandAdvertisement) => {
        featuredBrandCardRenderedEvent({
          brandAdvertisement,
        });
      });
    }
  }, [featuredObjects]);

  return (
    <LandingPageFeaturedBase data-testid='landing-page-brand-featured--component--div'>
      <LandingPageCardsContainer>
        {featuredObjects.length > 0 &&
          featuredObjects.map((featuredBrand) => (
            <LandingPageCard
              data-testid='landing-page-featured--brand-card--div'
              key={featuredBrand.name}
            >
              <a
                onClick={() => {
                  featuredBrandCardClickEvent({
                    brandAdvertisement: featuredBrand,
                  });
                }}
                href={`${featuredBrand.target_url}`}
              >
                <img
                  alt={featuredBrand.name}
                  onError={handleImgSrcError}
                  src={featuredBrand.image}
                />
                <span>{featuredBrand.name}</span>
              </a>
            </LandingPageCard>
          ))}
      </LandingPageCardsContainer>
      <Card elevation={0} paddingSize='large' sx={LandingPageFeaturedTitleBox}>
        <Typography component='h4' sx={LandingPageFeaturedTitleBoxHeader}>
          Featured Brands
        </Typography>
        <Typography
          bodyStyle={bodySmallTypography}
          sx={LandingPageFeaturedTitleBoxCopy}
        >
          Dive into an exclusive selection of the most esteemed brands!
        </Typography>
      </Card>
    </LandingPageFeaturedBase>
  );
};

LandingPageFeaturedBrands.propTypes = {
  featuredObjects: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
    })
  ).isRequired,
};

export default LandingPageFeaturedBrands;
