import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import {
  axiosPostData,
  axiosDeleteData,
  axiosPatchData,
  axiosPutData,
} from '../../helpers/axios';

export const archiveVendorBrand = createAsyncThunk(
  'archiveVendorBrand',
  async ({ brandId, vendorId, onSuccess }, { rejectWithValue }) =>
    axiosDeleteData({
      data: { id: brandId },
      onSuccess,
      rejectWithValue,
      url: `/dashboard/vendor/${vendorId}/brands/delete/`,
    })
);

export const createVendorBrand = createAsyncThunk(
  'createVendorBrand',
  async ({ vendorId, data, onSuccess }, { rejectWithValue }) =>
    axiosPostData({
      data: { ...data },
      onSuccess,
      rejectWithValue,
      url: `/dashboard/vendor/${vendorId}/brands/post/`,
    })
);

export const getVendorBrands = createAsyncThunk(
  'getVendorBrands',
  async ({ filters, vendorId, onSuccess }, { rejectWithValue }) =>
    axiosPostData({
      data: { filters },
      onSuccess,
      rejectWithValue,
      url: `/dashboard/vendor/${vendorId}/brands/brands-index/`,
    })
);

export const restoreVendorBrand = createAsyncThunk(
  'restoreVendorBrand',
  async ({ brandId, vendorId, onSuccess }, { rejectWithValue }) =>
    axiosPatchData({
      data: { id: brandId },
      onSuccess,
      rejectWithValue,
      url: `/dashboard/vendor/${vendorId}/brands/restore/`,
    })
);

export const setFilters = createAction('setFilters');

export const setIsCallPending = createAction('setIsCallPending');

export const updateVendorBrand = createAsyncThunk(
  'updateVendorBrand',
  async ({ vendorId, data, onSuccess }, { rejectWithValue }) =>
    axiosPutData({
      data: { ...data },
      onSuccess,
      rejectWithValue,
      url: `/dashboard/vendor/${vendorId}/brands/patch/`,
    })
);

export const uploadVendorBrandLogo = createAsyncThunk(
  'uploadVendorBrandLogo',
  async ({ vendorId, data, onSuccess, onError }, { rejectWithValue }) =>
    axiosPostData({
      data,
      onError,
      onSuccess,
      rejectWithValue,
      url: `/dashboard/vendor/${vendorId}/brands/logo/`,
    })
);
