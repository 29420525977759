import { Link } from '@leaftrade/leaftrade-component-library';
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useStorefrontState } from '../../../../hooks/useReduxState';
import { currencyFormatter } from '../../../../utils';
import ProductDetailsAccordion from '../ProductDetailsAccordion';

const ProductDetailsBundleContentsAccordion = () => {
  const { productDetails } = useStorefrontState();
  const { selectedVariant } = productDetails;

  return (
    <ProductDetailsAccordion
      isOpen
      header='Bundle Contents'
      id='product-bundle-contents'
    >
      <table>
        <tbody>
          <tr>
            <th>Item</th>
            <th>Batch</th>
            <th>Qty</th>
            <th>Unit Price</th>
            <th>Price</th>
          </tr>
          {selectedVariant.child_relationships.map((item) => (
            <tr key={item.child.id}>
              {item.link ? (
                <Link to={item.link} component={RouterLink}>
                  <td>{item.child.verbose_name}</td>
                </Link>
              ) : (
                <td>{item.child.verbose_name}</td>
              )}
              <td>{item.child.stock_record.batch.batch_ref}</td>
              <td>{item.quantity}</td>
              <td>{currencyFormatter.format(item.price_override)}</td>
              <td>
                {currencyFormatter.format(item.price_override * item.quantity)}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </ProductDetailsAccordion>
  );
};

export default ProductDetailsBundleContentsAccordion;
