import {
  interaction80,
  neutral0,
  neutral30,
  neutral50,
  pxToRem,
  spacing2,
  spacing3,
  spacing4,
  spacing7,
} from '@leaftrade/leaftrade-component-library';
import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { navZIndex } from '../../../../shared/styles/zIndex';

export const HeaderMobileNavWrapper = styled.div`
  position: absolute;
  inset: ${pxToRem(56)} 10% 0px 0px;
  background: ${neutral0.hex};
  overflow: auto;
  z-index: ${navZIndex};
  min-width: ${pxToRem(350)};
  & .h7 {
    margin: ${spacing2} 0 0;
  }
`;

export const headerMobileNavParentStyles = css`
  display: flex;
  align-items: center;
  width: 100%;
  background: none;
  border: none;
  padding: ${spacing4};
  color: ${interaction80.hex};
  text-decoration: none;
  &:hover {
    text-decoration: none;
  }
  & svg {
    height: ${spacing7};
    fill: ${interaction80.hex};
  }
`;

export const HeaderMobileNavParentButton = styled.button`
  ${headerMobileNavParentStyles}
  & svg {
    margin-left: auto;
  }
`;

export const HeaderMobileNavParentButtonWithIcon = styled.button`
  ${headerMobileNavParentStyles}
  & svg {
    &:first-of-type {
      margin-right: ${spacing4};
    }
    &:last-of-type {
      margin-left: auto;
    }
  }
`;

export const HeaderMobileNavParentLink = styled(Link)`
  ${headerMobileNavParentStyles}
  & svg {
    margin-right: ${spacing4};
  }
`;

export const HeaderMobileNavParentDiv = styled.div`
  ${headerMobileNavParentStyles}
  & svg {
    margin-right: ${spacing4};
  }
`;

export const HeaderMobileNavParentHref = styled.a`
  ${headerMobileNavParentStyles}
  & svg {
    margin-right: ${spacing4};
  }
`;

export const HeaderMobileTitleSubtitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  & span {
    &:first-child,
    &:last-child {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
    &:first-child {
      max-width: ${pxToRem(185)};
    }
    &:last-child {
      max-width: ${pxToRem(175)};
      color: ${neutral50.hex};
    }
  }
`;

export const HeaderMobileNavHr = styled.hr`
  border: none;
  border-top: 1px solid ${neutral30.hex};
  margin: ${spacing3} 0;
`;
