import PropTypes from 'prop-types';
import React from 'react';
import { PageHeaderHeroWrapper } from './PageHeaderHero.styles';

const PageHeaderHero = ({ heroImage, heroImageLinkTarget, name }) => (
  <PageHeaderHeroWrapper>
    {heroImageLinkTarget ? (
      <a data-testid='page-header-hero--link--a' href={heroImageLinkTarget}>
        <img
          data-testid='page-header-hero--image--img'
          src={heroImage}
          alt={`${name} hero image`}
        />
      </a>
    ) : (
      <img
        data-testid='page-header-hero--image--img'
        src={heroImage}
        alt={`${name} hero image`}
      />
    )}
  </PageHeaderHeroWrapper>
);

PageHeaderHero.defaultProps = {
  heroImageLinkTarget: null,
};

PageHeaderHero.propTypes = {
  heroImage: PropTypes.string.isRequired,
  heroImageLinkTarget: PropTypes.string,
  name: PropTypes.string.isRequired,
};

export default PageHeaderHero;
