import {
  AlertBar,
  boldBodyTypography,
  spacing4,
  spacing6,
  Typography,
} from '@leaftrade/leaftrade-component-library';
import React from 'react';
import { useStorefrontState } from '../../../hooks/useReduxState';
import { CheckoutShippingLocation } from './CheckoutHeader.styles';

const CheckoutHeader = () => {
  const { checkout, sellerPageData, shopper } = useStorefrontState();
  const { checkoutAlerts } = checkout;
  const { bannerText, bannerTitle } = sellerPageData;
  const { currentLocation, dispensary } = shopper;

  return (
    <div data-testid='checkout-header--component--div'>
      {checkoutAlerts?.length > 0 &&
        checkoutAlerts.map((alert) => (
          <AlertBar
            animationIn={null}
            alertMessages={[alert]}
            alertTitle='Attention'
            alertSeverity='error'
            hideClose
            sx={{
              marginTop: spacing6,
            }}
            testingId='checkout-header--alerts--alert-bar'
          />
        ))}
      {bannerText && (
        <AlertBar
          animationIn={null}
          hideClose
          alertMessages={
            typeof bannerText === 'string' ? [bannerText] : bannerText
          }
          alertTitle={bannerTitle || ''}
          alertSeverity='info'
          sx={{
            marginTop: spacing6,
          }}
          testingId='checkout-header--vendor-banner--alert-bar'
        />
      )}
      <Typography component='h3' sx={{ margin: `${spacing6} 0 ${spacing4}` }}>
        Order Review
      </Typography>
      <CheckoutShippingLocation>
        <Typography bodyStyle={boldBodyTypography}>
          Shipping Location:
        </Typography>
        <Typography>{dispensary.name} - </Typography>
        <Typography>{currentLocation.name}</Typography>
      </CheckoutShippingLocation>
    </div>
  );
};

export default CheckoutHeader;
