import {
  elevation2,
  minMediaQueryLargeDesktop,
  neutral10,
  pxToRem,
  spacing4,
  spacing6,
} from '@leaftrade/leaftrade-component-library';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const FavoritesCard = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${neutral10.hex};
  border-radius: 24px;
  padding: ${spacing6};
  flex: 1;
  ${minMediaQueryLargeDesktop} {
    flex: 0 1 ${pxToRem(402)};
  }
`;

export const FavoritesCardTitleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const FavoritesCardIcons = styled.div`
  position: relative;
  margin: ${spacing4} auto;
  display: flex;
  grid-gap: ${spacing4};
  align-items: center;
  flex-wrap: wrap;
  justify-content: flex-start;
  ${minMediaQueryLargeDesktop} {
    margin: auto;
  }
`;

export const FavoritesCardLink = styled(Link)`
  display: flex;
  justify-content: center;
  align-items: center;
  & img {
    object-fit: contain;
    width: ${pxToRem(78)};
    height: ${pxToRem(78)};
    border-radius: 50px;
    ${elevation2()}
  }
  & span {
    ${elevation2()}
  }
  &:hover {
    span {
      transform: matrix(-1, 0, 0, 1, 0, 0);
    }
  }
`;

export const EmptyMessageContainer = styled.div`
  position: absolute;
  left: 0;
  right: 0;
`;
