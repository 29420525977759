import {
  Typography,
  boldBodyTypography,
  spacing3,
} from '@leaftrade/leaftrade-component-library';
import React from 'react';
import { useStorefrontState } from '../../../../hooks/useReduxState';

const ProductDetailsSizeUnit = () => {
  const { productDetails } = useStorefrontState();
  const { selectedVariant } = productDetails;

  return (
    <Typography
      bodyStyle={boldBodyTypography}
      sx={{
        marginBottom: spacing3,
        width: 'max-content',
      }}
      testingId='product-details-content--price-per-unit--typography'
    >
      {`${selectedVariant.price_per_unit}/${selectedVariant.stock_record.size_unit}`}
    </Typography>
  );
};

export default ProductDetailsSizeUnit;
