import styled from 'styled-components';
import { pxToRem } from '@leaftrade/leaftrade-component-library';

export const SpinnerWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  & svg {
    width: ${pxToRem(250)};
    height: ${pxToRem(250)};
  }
`;

export const AppBase = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`;
