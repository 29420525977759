import {
  Button,
  Card,
  GlobalStyle,
  Link,
  logoDefaultColorWhite,
  spacing6,
  Typography,
} from '@leaftrade/leaftrade-component-library';
import PropTypes from 'prop-types';
import React from 'react';
import { useLocation } from 'react-router-dom';
import { useStorefrontState } from '../../../hooks/useReduxState';
import Footer from '../Footer';
import { HeaderWrapper } from '../../../shared/styles/Header.styles';
import PageWrapper from '../../../shared/components/PageWrapper';
import { BaseWrapper, CallFallbackWrapper } from './CallFallback.styles';

const CallFallback = ({ hasLimitedAccess, statusError: statusErrorProp }) => {
  const location = useLocation();
  const { statusError: statusErrorState } = useStorefrontState();
  const statusError = statusErrorProp || statusErrorState;

  if (!statusError.status === 401) {
    location.href = '/account/login';
  }

  const getCopy = () => {
    switch (statusError.status) {
      case 403:
        return {
          header: '403',
          subheader: 'Access Denied!',
          body: 'You do not have access to this content. Please contact an administrator for your account.',
        };
      case 404:
        return {
          header: '404',
          subheader: 'Resource Not Found!',
          body: 'A resource you were trying to access was not found, it may no longer exist.',
        };
      case 500:
        return {
          header: '500',
          subheader: 'Server Error',
          body: 'Please contact support if this error persists.',
        };
      default:
        return {
          header: 'Error',
          subheader: 'Something went wrong!',
          body: 'Please contact support if this error persists.',
        };
    }
  };

  const { header, subheader, body } = getCopy();

  return (
    <BaseWrapper data-testid='call-fallback--component--div'>
      <GlobalStyle />
      <HeaderWrapper>
        <Link href='/'>
          <img src={logoDefaultColorWhite} alt='Leaf Trade' />
        </Link>
      </HeaderWrapper>
      <PageWrapper>
        <CallFallbackWrapper>
          <Card
            paddingSize='large'
            sx={{
              margin: 'auto',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              gap: spacing6,
            }}
          >
            <Typography component='h1' align='center'>
              {header}
            </Typography>
            <Typography component='h2' align='center'>
              {subheader}
            </Typography>
            <Typography align='center'>{body}</Typography>
            <Typography align='center'>
              <span>Need Help?</span> Chat with our team by clicking the chat
              icon in the bottom right corner and share the error message
              provided.
            </Typography>
            {!hasLimitedAccess && (
              <Button
                onClick={() => {
                  window.location.href = '/storefront';
                }}
              >
                Return to Storefront
              </Button>
            )}
          </Card>
        </CallFallbackWrapper>
      </PageWrapper>
      <Footer />
    </BaseWrapper>
  );
};

CallFallback.propTypes = {
  statusError: PropTypes.shape({
    statusText: PropTypes.string,
    status: PropTypes.number,
  }),
};

export default CallFallback;
