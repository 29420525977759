import React, { useEffect } from 'react';
import {
  Button,
  spacing6,
  Typography,
} from '@leaftrade/leaftrade-component-library';
import { BrandPageButtonStyles } from '../../../../../shared/styles/BrandPageButton.styles';
import { HeadlineSplashBlockWrapper } from './HeadlineSplashBlock.styles';
import {
  brandPageTileClickEvent,
  brandPageTileViewEvent,
} from '../../../../analytics/brand_pages';
import { GradientWrapper } from '../styles/GradientWrapper';

const HeadlineSplashBlock = ({
  className,
  buttonLabel,
  buttonUrl,
  headline,
  imageUrl,
  subheader,
  page,
  position,
  gradientBackgroundColor,
  textColor,
}) => {
  useEffect(() => {
    brandPageTileViewEvent({
      vendorId: page.vendorId,
      brandId: page.brandId,
      brandPageId: page.id,
      brandPageTilePosition: position,
      brandPageTileMediaUrl: imageUrl,
    });
  });

  const trackAndOpenWindow = () => {
    brandPageTileClickEvent({
      vendorId: page.vendorId,
      brandId: page.brandId,
      brandPageId: page.id,
      brandPageTilePosition: position,
      brandPageTileMediaUrl: imageUrl,
      brandPageTileClickTargetUrl: buttonUrl,
    });

    window.open(buttonUrl, '_blank');
  };

  return (
    <div className={className} style={{ backgroundImage: `url(${imageUrl})` }}>
      <GradientWrapper
        gradientBackgroundColor={gradientBackgroundColor}
        textColor={textColor}
      >
        <HeadlineSplashBlockWrapper>
          <div className='brand-page__tile-content'>
            <Typography component='h3' classes={['brand-page__tile-title']}>
              {headline}
            </Typography>
            <Typography component='p' classes={['brand-page__tile-subtitle']}>
              {subheader}
            </Typography>
            <Button
              size='small'
              bodyStyle={BrandPageButtonStyles}
              onClick={trackAndOpenWindow}
              sx={{ marginTop: spacing6 }}
              classes={['brand-page__tile-button']}
            >
              {buttonLabel}
            </Button>
          </div>
        </HeadlineSplashBlockWrapper>
      </GradientWrapper>
    </div>
  );
};

export default HeadlineSplashBlock;
