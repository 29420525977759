import {
  IconCategoryPlaceholder1,
  IconCategoryPlaceholder2,
  IconCategoryPlaceholder3,
  IconCategoryPlaceholder4,
  IconCategoryPlaceholder5,
  IconCategoryPlaceholder6,
  IconCategoryPlaceholder7,
  IconCategoryPlaceholder8,
  IconCbd,
  IconConcentrate,
  IconEdible,
  IconFlower,
  IconInfused,
  IconParaphernalia,
  IconPreRoll,
  IconTincture,
  IconTopical,
  IconVape,
  Typography,
  bodyExtraSmallTypography,
} from '@leaftrade/leaftrade-component-library';
import PropTypes from 'prop-types';
import React from 'react';
import { useStorefrontState } from '../../../../hooks/useReduxState';
import { IconWrapper } from './CategoryIcon.styles';

const CategoryIcon = ({ categoryId, categoryImageIterator, categoryIndex }) => {
  const { sellersPageData } = useStorefrontState();
  const { categories, isGridView } = sellersPageData;
  const name =
    categories.find((globalCategory) => globalCategory.id === categoryId)
      ?.name || '';

  // eslint-disable-next-line no-param-reassign
  if (categoryIndex === 0) categoryImageIterator.current = 0;

  const placeholderIcons = [
    IconCategoryPlaceholder1,
    IconCategoryPlaceholder2,
    IconCategoryPlaceholder3,
    IconCategoryPlaceholder4,
    IconCategoryPlaceholder5,
    IconCategoryPlaceholder6,
    IconCategoryPlaceholder7,
    IconCategoryPlaceholder8,
  ];

  const renderIcon = () => {
    const normalizedName = name.toLowerCase();
    const CurrentPlaceholderIcon =
      placeholderIcons[categoryImageIterator.current];

    switch (normalizedName) {
      case 'flower':
        return <IconFlower data-testid='category-icon--icon-flower--svg' />;
      case 'hemp':
        return <IconCbd data-testid='category-icon--icon-cbd--svg' />;
      case 'edible':
      case 'edibles':
        return <IconEdible data-testid='category-icon--icon-edible--svg' />;
      case 'pre-roll':
      case 'pre-rolls':
      case 'pre roll':
      case 'pre rolls':
        return <IconPreRoll data-testid='category-icon--icon-preRoll--svg' />;
      case 'concentrate':
      case 'concentrates':
        return (
          <IconConcentrate data-testid='category-icon--icon-concentrate--svg' />
        );
      case 'topical':
      case 'topicals':
        return <IconTopical data-testid='category-icon--icon-topical--svg' />;
      case 'tincture':
      case 'tinctures':
        return <IconTincture data-testid='category-icon--icon-tincture--svg' />;
      case 'vape':
      case 'vapes':
      case 'vaporizer':
      case 'vaporizers':
        return <IconVape data-testid='category-icon--icon-vape--svg' />;
      case 'infused':
      case 'infusion':
      case 'infusions':
        return <IconInfused data-testid='category-icon--icon-infused--svg' />;
      case 'paraphernalia':
        return (
          <IconParaphernalia data-testid='category-icon--icon-paraphernalia--svg' />
        );
      default:
        // eslint-disable-next-line no-param-reassign
        categoryImageIterator.current =
          (categoryImageIterator.current + 1) % placeholderIcons.length;
        return (
          <CurrentPlaceholderIcon data-testid='category-icon--current-placeholder-icon--svg' />
        );
    }
  };

  return (
    name && (
      <IconWrapper
        data-testid='category-icon--component--div'
        isGridView={isGridView}
      >
        {renderIcon()}
        <Typography
          bodyStyle={bodyExtraSmallTypography}
          component='span'
          testingId={`
          category-icon--name-${name.toLowerCase()}--span
        `}
        >
          {name}
        </Typography>
      </IconWrapper>
    )
  );
};

CategoryIcon.propTypes = {
  categoryId: PropTypes.number.isRequired,
  categoryImageIterator: PropTypes.shape({
    current: PropTypes.number.isRequired,
  }).isRequired,
  categoryIndex: PropTypes.number.isRequired,
};

export default CategoryIcon;
