import {
  elevation1,
  interaction60,
  maxMediaQueryMobile,
  neutral10,
  pxToRem,
  spacing4,
  spacing5,
  spacing6,
  spacing7,
} from '@leaftrade/leaftrade-component-library';
import styled from 'styled-components';

export const StyledVendorCard = styled.div`
  display: flex;
  flex-direction: ${({ isGridView }) => (isGridView ? 'column' : 'row')};
  position: relative;
  flex: ${({ isGridView }) =>
    isGridView ? `0 1 ${pxToRem(275)}` : '1 1 100%'};
  border-radius: 10px;
  padding: ${({ isGridView }) =>
    isGridView ? `${spacing4} ${spacing5}` : `${spacing6} ${spacing5}`};
  border: 1px solid ${neutral10.hex};
  ${elevation1()}
  ${maxMediaQueryMobile} {
    flex-direction: column;
  }
`;

export const VendorCardInnerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: ${({ isGridView }) => (isGridView ? 0 : `0 ${spacing7}`)};
`;

export const FavoriteToggleWrapper = styled.div`
  position: absolute;
  right: ${pxToRem(8)};
  top: ${pxToRem(8)};
`;

export const VendorBrandsWrapper = styled.div`
  min-height: ${({ isGridView }) => (isGridView ? pxToRem(52) : spacing6)};
  margin-top: ${({ isGridView }) => (isGridView ? spacing6 : 'auto')};
  margin-bottom: ${spacing5};
`;

export const VendorCategoriesWrapper = styled.div`
  min-height: ${({ isGridView }) => (isGridView ? pxToRem(72) : spacing6)};
  margin-top: ${({ isGridView }) => (isGridView ? spacing6 : 'auto')};
  margin-bottom: 0;
`;

export const SectionTitle = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: ${({ isGridView }) => (isGridView ? spacing4 : 0)};
`;

export const ShowMoreButtonContainer = styled.div`
  height: ${pxToRem(42)};
  margin-top: ${({ isGridView }) => (isGridView ? 0 : spacing5)};
`;

export const ShowMoreButton = styled.button`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  background: none;
  border: 1px solid ${neutral10.hex};
  border-left: none;
  border-right: none;
  ${({ isGridView }) => (isGridView ? '' : 'border-bottom: none;')}
  padding: 0.5rem 0;
  color: ${interaction60.hex};
  & svg {
    width: ${pxToRem(24)};
    height: ${pxToRem(24)};
    fill: ${interaction60.hex};
  }
`;

export const ViewSellerLinkWrapper = styled.div`
  margin-top: auto;
  margin-left: ${({ isGridView }) => (isGridView ? 0 : 'auto')};
  & button {
    margin-top: ${spacing5};
    width: 100%;
  }
`;
