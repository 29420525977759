import {
  bodySmallTypography,
  Link,
  logoIconColor,
  boldBodySmallTypography,
  Typography,
  mediumBodyTypography,
  interaction60,
  neutral60,
} from '@leaftrade/leaftrade-component-library';
import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useStorefrontState } from '../../../../../hooks/useReduxState';
import { currencyFormatter } from '../../../../../utils';
import {
  QuickOrderTableItemInfoWrapper,
  QuickOrderTableItemInfoImage,
  QuickOrderTableItemInfoData,
  QuickOrderTableItemInfoDataCannabinoid,
} from './QuickOrderTableItemInfo.styles';

const QuickOrderTableItemInfo = ({ item }) => {
  const { vendor } = useStorefrontState();
  const { slug } = vendor;

  const {
    batch_ref: batchRef,
    cannabinoids,
    cannabinoidUnit,
    displayName,
    inventoryLiveCount,
    storefrontDisplayPrice,
    variantId,
  } = item.variants[0];

  return (
    <QuickOrderTableItemInfoWrapper data-testid='quick-order-table-item-info--component--div'>
      <QuickOrderTableItemInfoImage src={item.imageUrl || logoIconColor} />
      <div>
        <Link
          bodyStyle={mediumBodyTypography}
          component={RouterLink}
          sx={{
            color: interaction60.hex,
          }}
          title={item.productName}
          to={`/storefront/sellers/${slug}/${item.productId}?variant=${variantId}`}
          underline='hover'
        >
          {item.productName}
        </Link>
        <QuickOrderTableItemInfoData>
          <Typography
            bodyStyle={boldBodySmallTypography}
            sx={{ color: neutral60.hex }}
          >
            {currencyFormatter.format(storefrontDisplayPrice)}
          </Typography>
          <Typography
            bodyStyle={bodySmallTypography}
            sx={{ color: neutral60.hex }}
          >{`/${displayName}`}</Typography>
        </QuickOrderTableItemInfoData>
        <QuickOrderTableItemInfoDataCannabinoid>
          {cannabinoids?.map((cannabinoid) => (
            <Fragment key={cannabinoid.name}>
              <Typography
                bodyStyle={boldBodySmallTypography}
                sx={{ color: neutral60.hex }}
              >
                {cannabinoid.name.toUpperCase()}{' '}
              </Typography>
              <Typography
                bodyStyle={bodySmallTypography}
                sx={{ color: neutral60.hex }}
              >
                {cannabinoid.value + (cannabinoidUnit || '')}
              </Typography>
            </Fragment>
          ))}
        </QuickOrderTableItemInfoDataCannabinoid>
        {batchRef && (
          <Typography
            bodyStyle={bodySmallTypography}
            sx={{ color: neutral60.hex }}
          >
            Batch #{batchRef}
          </Typography>
        )}
        {vendor.settings.displayLiveInventoryCount && (
          <Typography
            bodyStyle={bodySmallTypography}
            sx={{ color: neutral60.hex }}
          >
            Current Inventory:{' '}
            {inventoryLiveCount >= 0 ? inventoryLiveCount.toLocaleString() : 0}
          </Typography>
        )}
      </div>
    </QuickOrderTableItemInfoWrapper>
  );
};

QuickOrderTableItemInfo.propTypes = {
  item: PropTypes.shape({
    imageUrl: PropTypes.string,
    productName: PropTypes.string,
    productId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    variants: PropTypes.arrayOf(
      PropTypes.shape({
        batch_ref: PropTypes.string,
        cannabinoids: PropTypes.arrayOf(
          PropTypes.shape({
            name: PropTypes.string,
            value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
          })
        ),
        cannabinoidUnit: PropTypes.string,
        displayName: PropTypes.string,
        inventoryLiveCount: PropTypes.oneOfType([
          PropTypes.string,
          PropTypes.number,
        ]),
        storefrontDisplayPrice: PropTypes.oneOfType([
          PropTypes.string,
          PropTypes.number,
        ]),
        variantId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      })
    ),
  }).isRequired,
};

export default QuickOrderTableItemInfo;
