import {
  resetChanges,
  setChanges,
  setSelectedItem,
} from '../actions/inventoryPricingActions';
import inventoryPricingState from '../states/inventoryPricingState';
import { createUndoableSlice } from '../utils/undoable';

export const inventoryPricingSlice = createUndoableSlice({
  name: 'inventoryPricing',
  initialState: inventoryPricingState,
  reducers: {},
  undoableFields: {
    changes: true,
  },
  extraReducers: (builder) => {
    builder.addCase(setSelectedItem, (state, action) => ({
      ...state,
      selectedItems: action.payload
        ? {
            ...state.selectedItems,
            ...(action.payload || {}),
          }
        : {},
    }));

    builder.addCase(setChanges, (state, action) => ({
      ...state,
      changes: action.payload
        ? {
            ...state.changes,
            ...Object.keys(action.payload).reduce(
              (acc, key) => ({
                ...acc,
                ...{
                  [key]: {
                    ...(state.changes?.[key] || {}),
                    ...action.payload[key],
                  },
                },
              }),
              {}
            ),
          }
        : undefined,
    }));

    builder.addCase(resetChanges, (state) => ({
      ...state,
      changes: undefined,
    }));
  },
});
