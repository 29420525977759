import queryString from 'query-string';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { AlertBar, spacing6 } from '@leaftrade/leaftrade-component-library';
import Breadcrumbs from '../../components/Breadcrumbs';
import PageLoader from '../../../shared/components/PageLoader';
import ProductDetails from '../../components/ProductDetails';
import { useStorefrontState } from '../../../hooks/useReduxState';
import { getProductDetails } from '../../../store/actions/storefrontActions';
import { BQ_DEBUG } from '../../constants';

const Product = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const { productDetails, shopper, vendor } = useStorefrontState();
  const { isVendor } = shopper;
  const { settings, slug } = vendor;
  const { batchQueuesEnabled } = settings;
  const {
    availableVariants,
    isLoadingProductDetails,
    productId,
    selectedVariantId,
    uniqueVariants,
    variants,
    vendorSettings,
  } = productDetails;

  const defaultVariantId = queryString.parse(location.search).variant || '';

  useEffect(() => {
    if (vendorSettings?.storefront_aggregate_variants) {
      if (
        defaultVariantId &&
        !uniqueVariants.find(
          (variant) => variant.id.toString() === defaultVariantId
        )
      ) {
        history.replace({
          search: `variant=${selectedVariantId}`,
        });
      }
    }
  }, [
    defaultVariantId,
    location.search,
    selectedVariantId,
    uniqueVariants,
    vendorSettings,
  ]);

  useEffect(() => {
    if (defaultVariantId && productId && slug) {
      dispatch(
        getProductDetails({
          batchQueuesEnabled,
          defaultVariantId,
          isPreview: isVendor,
          productId,
          vendorSlug: slug,
        })
      );
    }
  }, [productId, slug]);

  return (
    <>
      {isLoadingProductDetails ? (
        <PageLoader />
      ) : (
        <>
          <Breadcrumbs isProductDetailsPage />
          {location.search.includes(BQ_DEBUG) && (
            <AlertBar
              animationIn={null}
              hideClose
              alertMessages={[
                batchQueuesEnabled
                  ? 'Batch Queues Enabled'
                  : 'Batch Queues Disabled',
              ]}
              alertTitle='BQ Debug'
              alertSeverity='info'
              sx={{
                marginTop: spacing6,
              }}
              testingId='product--bq-debug-banner--alert-bar'
            />
          )}
          {!availableVariants && variants.length === 0 && (
            <AlertBar
              animationIn={null}
              hideClose
              alertMessages={['This product will not show on storefront.']}
              alertTitle='Warning!'
              alertSeverity='warning'
              sx={{
                marginTop: spacing6,
              }}
              testingId='product--not-show-banner--alert-bar'
            />
          )}
          <ProductDetails />
        </>
      )}
    </>
  );
};

export default Product;
