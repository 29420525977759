import {
  boldBodySmallTypography,
  spacing2,
  Typography,
} from '@leaftrade/leaftrade-component-library';
import PropTypes from 'prop-types';
import React from 'react';
import { useStorefrontState } from '../../../../hooks/useReduxState';
import {
  CannabinoidsWrapper,
  StyledCannabinoid,
  StyledCannabinoidTHC,
} from './FeaturedCannabinoids.styles';

const FeaturedCannabinoids = ({
  asChip,
  cannabinoids,
  labResultsUnit,
  primaryLabValues,
}) => {
  const { vendor } = useStorefrontState();
  const { isGridView } = vendor.settings;

  let cannabinoidA = null;
  let cannabinoidB = null;

  let labelA = 'THC';
  let labelB = 'CBD';

  let unit = labResultsUnit === 'percent' ? '%' : ` ${labResultsUnit}`;

  switch (primaryLabValues) {
    case 'total_cannabinoids':
      cannabinoidB = cannabinoids?.total_cannabinoids;
      cannabinoidB = !cannabinoidB ? null : cannabinoidB;
      labelB = asChip ? 'Total' : 'Total Cannabinoids';
      unit = '';
      break;
    case 'thca_cbda':
      cannabinoidA = cannabinoids?.thc_a;
      cannabinoidA = !cannabinoidA ? null : cannabinoidA;
      labelA = 'THCA';
      cannabinoidB = cannabinoids?.cbd_a;
      cannabinoidB = !cannabinoidB ? null : cannabinoidB;
      labelB = 'CBDA';
      break;
    case 'thc_cbd':
      cannabinoidA = cannabinoids?.thc;
      cannabinoidA = !cannabinoidA ? null : cannabinoidA;
      cannabinoidB = cannabinoids?.cbd;
      cannabinoidB = !cannabinoidB ? null : cannabinoidB;
      break;
    case 'total_thc_cbd':
    default:
      cannabinoidA = cannabinoids?.total_thc;
      cannabinoidA = !cannabinoidA ? null : cannabinoidA;
      cannabinoidB = cannabinoids?.total_cbd;
      cannabinoidB = !cannabinoidB ? null : cannabinoidB;
  }

  const hasCannabinoidA = cannabinoidA !== null && cannabinoidA !== -1;
  const hasCannabinoidB = cannabinoidB !== null && cannabinoidB !== -1;

  return (
    <CannabinoidsWrapper
      asChip={asChip}
      data-testid='featured-cannabinoids--component--div'
      isGridView={isGridView}
    >
      {hasCannabinoidA && (
        <StyledCannabinoidTHC
          asChip={asChip}
          hasCannabinoidB={hasCannabinoidB}
          isGridView={isGridView}
        >
          <Typography
            bodyStyle={boldBodySmallTypography}
            sx={{ marginRight: spacing2 }}
          >
            {labelA}
          </Typography>
          <Typography>{`${cannabinoidA}${unit}`}</Typography>
        </StyledCannabinoidTHC>
      )}
      {hasCannabinoidB && (
        <StyledCannabinoid asChip={asChip} isGridView={isGridView}>
          <Typography
            bodyStyle={boldBodySmallTypography}
            sx={{ marginRight: spacing2 }}
          >
            {labelB}
          </Typography>
          <Typography>{`${cannabinoidB}${unit}`}</Typography>
        </StyledCannabinoid>
      )}
    </CannabinoidsWrapper>
  );
};

FeaturedCannabinoids.defaultProps = {
  asChip: false,
  primaryLabValues: 'total_thc_cbd',
};

FeaturedCannabinoids.propTypes = {
  asChip: PropTypes.bool,
  cannabinoids: PropTypes.object.isRequired,
  labResultsUnit: PropTypes.string.isRequired,
  primaryLabValues: PropTypes.string,
};

export default FeaturedCannabinoids;
