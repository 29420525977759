import {
  bodySmallTypography,
  boldBodySmallTypography,
  pxToRem,
  spacing5,
  spacing8,
  Typography,
} from '@leaftrade/leaftrade-component-library';
import PropTypes from 'prop-types';
import React from 'react';
import { useStorefrontState } from '../../../../hooks/useReduxState';
import { SectionTitle } from '../VendorCard.styles';

const VendorBrands = ({ brands, brandsRef, showMore }) => {
  const { sellersPageData } = useStorefrontState();
  const { isGridView } = sellersPageData;

  const brandsString = brands.map((brand) => brand.name).join(', ');

  return (
    <div data-testid='vendor-brands--component--div'>
      <SectionTitle isGridView={isGridView}>
        <Typography bodyStyle={boldBodySmallTypography}>BRANDS</Typography>
        {!isGridView && (
          <Typography
            bodyStyle={bodySmallTypography}
            noWrap={!showMore}
            ref={brandsRef}
            sx={{
              width: 0,
              flexGrow: 1,
              margin: `0 ${spacing5} 0 ${spacing8}`,
            }}
          >
            {brandsString}
          </Typography>
        )}
        <Typography bodyStyle={bodySmallTypography} sx={{ marginLeft: 'auto' }}>
          {brands.length} total
        </Typography>
      </SectionTitle>
      {isGridView && (
        <Typography
          bodyStyle={bodySmallTypography}
          noWrap={!showMore}
          ref={brandsRef}
          sx={{
            maxWidth: pxToRem(240),
          }}
        >
          {brandsString}
        </Typography>
      )}
    </div>
  );
};

VendorBrands.propTypes = {
  brands: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
    })
  ).isRequired,
  brandsRef: PropTypes.shape({ current: PropTypes.any }).isRequired,
  showMore: PropTypes.bool.isRequired,
};

export default VendorBrands;
