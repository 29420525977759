import { Link, Typography } from '@leaftrade/leaftrade-component-library';
import PropTypes from 'prop-types';
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useStorefrontState } from '../../../../hooks/useReduxState';

const VendorName = ({ name, noLink, slug }) => {
  const { sellersPageData } = useStorefrontState();
  const { isGridView } = sellersPageData;
  return (
    <>
      {noLink ? (
        <Typography
          component='h5'
          gutterBottom={!isGridView}
          sx={{
            flexDirection: 'column',
            alignItems: 'flex-start',
            width: '100%',
          }}
          testingId='vendor-name--component--no-link--typography'
        >
          {name}
        </Typography>
      ) : (
        <Link
          component={RouterLink}
          gutterBottom={!isGridView}
          sx={{
            flexDirection: 'column',
            alignItems: 'flex-start',
            width: '100%',
          }}
          testingId='vendor-name--component--link'
          to={`/storefront/sellers/${slug}`}
          underline='hover'
          variant='h5'
        >
          {name}
        </Link>
      )}
    </>
  );
};

VendorName.defaultProps = {
  noLink: false,
};

VendorName.propTypes = {
  name: PropTypes.string.isRequired,
  noLink: PropTypes.bool,
  slug: PropTypes.string.isRequired,
};

export default VendorName;
