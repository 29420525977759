import { createSlice } from '@reduxjs/toolkit';
import productsState from '../states/productsState';
import {
  fetchBrandsByVendor,
  fetchCategories,
  fetchStrains,
  fetchTags,
  setFilters,
  setIsProductUpdating,
  setSelectedProduct,
  updateProducts,
} from '../actions/productsActions';
import clearObject from '../../utils/clearObject';

export const productsSlice = createSlice({
  name: 'products',
  initialState: productsState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(setFilters, (state, action) => ({
      ...state,
      filters: action.payload,
    }));

    builder.addCase(fetchBrandsByVendor.fulfilled, (state, action) => ({
      ...state,
      brands: action.payload.brands?.sort((a, b) => a.name - b.name),
    }));

    builder.addCase(fetchCategories.fulfilled, (state, action) => ({
      ...state,
      categories: action.payload.categories || [],
      subcategories: action.payload.subcategories || [],
    }));

    builder.addCase(fetchStrains.fulfilled, (state, action) => ({
      ...state,
      strains: action.payload,
    }));

    builder.addCase(fetchTags.fulfilled, (state, action) => ({
      ...state,
      tags: action.payload.tags?.sort((a, b) => a.name - b.name),
    }));

    builder.addCase(setSelectedProduct, (state, action) => ({
      ...state,
      selectedProducts: action.payload
        ? clearObject({
            ...state.selectedProducts,
            ...(action.payload || {}),
          })
        : {},
    }));

    builder.addCase(updateProducts.pending, (state) => ({
      ...state,
      isProductUpdating: true,
    }));

    builder.addCase(updateProducts.fulfilled, (state) => ({
      ...state,
      isProductUpdating: false,
    }));

    builder.addCase(updateProducts.rejected, (state) => ({
      ...state,
      isProductUpdating: false,
    }));

    builder.addCase(setIsProductUpdating, (state, action) => ({
      ...state,
      isProductUpdating: action.payload,
    }));
  },
});
