import { spacing3, spacing4 } from '@leaftrade/leaftrade-component-library';
import styled from 'styled-components';

export const LoginWrapper = styled.div`
  height: calc(100vh - 100px);
  display: flex;
  & hr {
    width: 90%;
    margin: ${spacing3} auto;
  }
`;

export const LoginActions = styled.div`
  display: flex;
  margin: 0 auto;
  gap: ${spacing4};
`;

export const LoginExtras = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${spacing4};
`;
