import styled from 'styled-components';
import {
  maxMediaQueryDesktop,
  maxMediaQueryMobile,
  maxMediaQueryTablet,
  pxToRem,
  spacing2,
  spacing4,
  spacing6,
  spacing7,
} from '@leaftrade/leaftrade-component-library';
import hexToRGBA from '../../../utils/hexToRGBA';

export const BrandPageWrapper = styled.div`
  flex-grow: 1;
  position: relative;
  background-color: ${({ backgroundColor }) => backgroundColor};

  .brand-page__banner {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;

    img {
      width: 100%;
    }

    &:before {
      content: '';
      position: absolute;
      width: 100%;
      height: 100%;
      background: linear-gradient(
        0deg,
        ${({ backgroundColor }) => hexToRGBA(backgroundColor, 1)} 2.99%,
        ${({ backgroundColor }) => hexToRGBA(backgroundColor, 0)} 40.04%
      );
    }
  }

  .brand-page__container {
    position: relative;
    padding: ${pxToRem(20)};
    border-radius: ${pxToRem(40)};
    background-color: white;
    max-width: 1164px;
    margin: 313px auto 20px;

    ${maxMediaQueryTablet} {
      max-width: 590px;
    }

    ${maxMediaQueryMobile} {
      padding: ${pxToRem(12)};
      margin-top: 150px;
      border-radius: 0;
    }
  }

  .brand-page__logo {
    margin-top: -60px;
    margin-left: -${pxToRem(20)};
    width: 128px;
    height: 128px;
    flex-shrink: 0;
    padding: ${pxToRem(10)};
    border-radius: 50%;
    background-color: white;
    box-shadow: 0 16px 24px 0 #919eab3d, 0 4px 8px 0 #919eab0f;

    img {
      width: 100%;
      height: 100%;
      border-radius: 50%;
    }

    ${maxMediaQueryMobile} {
      margin-top: -30px;
      margin-left: -${pxToRem(10)};
      padding: ${spacing2};
      width: 64px;
      height: 64px;
    }
  }

  .brand-page__header {
    display: flex;
    column-gap: ${spacing6};
    margin-bottom: ${spacing6};
  }

  .brand-page__tile {
    max-width: 550px;
    max-height: 560px;
    aspect-ratio: 550 / 560;
    border-radius: ${spacing6};
    display: flex;
    width: calc(50% - ${spacing4});
    justify-content: center;
    align-items: center;
    overflow: hidden;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;

    &.brand-page__tile--clickable {
      cursor: pointer;

      &:hover {
        box-shadow: 0 16px 24px 0 #919eab3d, 0 4px 8px 0 #919eab0f;
      }
    }

    ${maxMediaQueryTablet} {
      width: 100%;
      aspect-ratio: 324 / 330;
    }
  }

  .brand-page__tiles {
    display: flex;
    gap: ${spacing6};
    max-width: 1124px;
    flex-wrap: wrap;
  }

  .brand-page__tile-content {
    width: 100%;
    height: 100%;
    padding: ${spacing7};

    ${maxMediaQueryDesktop} {
      padding: ${pxToRem(20)};
    }
  }

  .brand-page__tile-title {
    ${maxMediaQueryDesktop} {
      font-size: ${pxToRem(18)};
      line-height: ${pxToRem(30)};
    }
  }

  .brand-page__tile-subtitle {
    font-weight: 500;

    ${maxMediaQueryDesktop} {
      font-size: ${pxToRem(12)};
      line-height: ${pxToRem(20)};
    }
  }

  .brand-page__tile-button {
    margin-top: ${spacing6};

    ${maxMediaQueryTablet} {
      margin-top: ${spacing4};
    }
  }

  .brand-page__title {
    ${maxMediaQueryDesktop} {
      font-size: ${pxToRem(21)};
      line-height: ${pxToRem(18)};
    }
  }

  .brand-page__subtitle {
    ${maxMediaQueryDesktop} {
      font-size: ${pxToRem(12)};
      line-height: ${pxToRem(18)};
    }
  }
`;
