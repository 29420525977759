import styled from 'styled-components';
import {
  elevation1,
  interaction5,
  neutral0,
  neutral10,
  pxToRem,
  spacing1,
  spacing2,
  spacing3,
  spacing4,
  spacing6,
} from '@leaftrade/leaftrade-component-library';

export const CartLineItemColumnLabsWrapper = styled.div`
  width: ${pxToRem(70)};
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  position: relative;
  & svg {
    width: ${spacing6};
    height: ${spacing6};
  }
`;

export const CartLabResultsButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  margin: 0;
  padding: ${spacing3};
  border-radius: 4px;
  background-color: ${({ labDropDownOpen }) =>
    labDropDownOpen ? interaction5.hex : neutral0.hex};
  &:hover {
    background-color: ${interaction5.hex};
  }
`;

export const CartLabResultsWrapper = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  background-color: ${neutral0.hex};
  z-index: 1;
  border: 1px solid ${neutral10.hex};
  border-radius: 4px;
  top: ${pxToRem(55)};
  left: ${pxToRem(14)};
  min-width: ${pxToRem(150)};
  padding: ${spacing3} ${spacing4};
  ${elevation1()}
  & p {
    padding: ${spacing2} ${spacing1};
    &:hover {
      background-color: ${neutral10.hex};
    }
  }
`;
