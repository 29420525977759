import {
  Typography,
  mediumBodyTypography,
  neutral60,
  spacing3,
  handleImgSrcError,
  spacing5,
} from '@leaftrade/leaftrade-component-library';
import React from 'react';
import { useStorefrontState } from '../../../hooks/useReduxState';
import { QuickOrderHeaderWrapper } from './QuickOrderHeader.styles';

const QuickOrderHeader = () => {
  const { vendor } = useStorefrontState();
  const { logo, name } = vendor;

  return (
    <QuickOrderHeaderWrapper data-testid='quick-order-header--component--div'>
      <div>
        <img src={logo} alt={name} onError={handleImgSrcError} />
        <Typography
          component='h3'
          sx={{ marginLeft: spacing5 }}
          testingId='quick-order-header--quick-order--h3'
        >
          Quick Order
        </Typography>
        <Typography
          component='h3'
          sx={{ color: neutral60.hex, marginLeft: spacing3 }}
          testingId='quick-order-header--vendor--h3'
        >
          : {name}
        </Typography>
      </div>
      <Typography
        bodyStyle={mediumBodyTypography}
        gutterBottom
        sx={{ flexBasis: '100%' }}
        testingId='quick-order-header--disclaimer-1--p'
      >
        Changes made on the highlighted “All” column will update all carts
        unless otherwise specified. Locations to shop for can be specified in
        the dropdown.
      </Typography>
      <Typography
        gutterBottom
        sx={{ color: neutral60.hex, flexBasis: '100%' }}
        testingId='quick-order-header--disclaimer-2--p'
      >
        Submitted order quantities and pricing are subject to the seller&apos;s
        packaged inventory availability and are not always guaranteed. You will
        be notified if the vendor makes any changes to your order. Surcharges
        and cart-based discounts are not represented in the Quick Order grid.
        They can be reviewed at an individual cart level.
      </Typography>
    </QuickOrderHeaderWrapper>
  );
};

export default QuickOrderHeader;
