import {
  IconFavoriteHeart,
  Link,
  Typography,
  bodyLargeTypography,
  handleImgSrcError,
  interaction60,
  mediumBodyLargeTypography,
  mediumBodySmallTypography,
  neutral5,
  pxToRem,
  rose60,
  spacing2,
} from '@leaftrade/leaftrade-component-library';
import PropTypes from 'prop-types';
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import {
  favoriteVendorClickEvent,
  favoriteVendorsAllClickEvent,
} from '../../../analytics/landing';
import {
  LandingPageGradientIcon,
  LandingPageGradientIconBackground,
} from '../LandingPage.styles';
import {
  EmptyMessageContainer,
  FavoritesCard,
  FavoritesCardIcons,
  FavoritesCardLink,
  FavoritesCardTitleContainer,
} from './LandingPageFavorites.styles';

const LandingPageFavorites = ({ favoriteVendors }) => (
  <FavoritesCard data-testid='landing-page-favorites--component--div'>
    <FavoritesCardTitleContainer>
      <Typography component='h5'>Your Favorites</Typography>
      <Link
        bodyStyle={mediumBodySmallTypography}
        component={RouterLink}
        onClick={favoriteVendorsAllClickEvent}
        sx={{
          color: interaction60.hex,
        }}
        to={{
          pathname: `/storefront/sellers`,
          search: favoriteVendors.length > 0 ? `?sort=favorite` : '',
        }}
        underline='hover'
      >
        {favoriteVendors.length > 0 ? 'View All' : 'Browse Sellers'}
      </Link>
    </FavoritesCardTitleContainer>
    <FavoritesCardIcons>
      {favoriteVendors.length === 0 ? (
        <>
          {Array.from({ length: 8 }, (_, i) => (
            <svg
              key={i}
              viewBox='0 0 100 100'
              xmlns='http://www.w3.org/2000/svg'
              style={{
                width: pxToRem(78),
                height: pxToRem(78),
                fill: neutral5.hex,
              }}
            >
              <circle cx='50' cy='50' r='50' />
            </svg>
          ))}
          <EmptyMessageContainer>
            <Typography
              align='center'
              bodyStyle={mediumBodyLargeTypography}
              gutterBottom
              sx={{ width: '100%', zIndex: 1 }}
            >
              Have sellers you can&apos;t get enough of?
            </Typography>
            <Typography
              align='center'
              bodyStyle={bodyLargeTypography}
              sx={{ width: '100%', zIndex: 1 }}
            >
              Hit the{' '}
              <IconFavoriteHeart
                style={{
                  display: 'inline-block',
                  fill: rose60.hex,
                  height: pxToRem(24),
                  width: pxToRem(24),
                }}
              />{' '}
              to save them here for quicker access
            </Typography>
          </EmptyMessageContainer>
        </>
      ) : (
        <>
          {[...favoriteVendors].length === 8
            ? [...favoriteVendors].slice(0, 8).map((favoriteVendor) => (
                <FavoritesCardLink
                  key={favoriteVendor.id}
                  onClick={() => {
                    favoriteVendorClickEvent({
                      vendor: favoriteVendor,
                    });
                  }}
                  to={`/storefront/sellers/${favoriteVendor.slug}`}
                >
                  <img
                    alt={favoriteVendor.name}
                    src={favoriteVendor.logo_url}
                    onError={handleImgSrcError}
                  />
                </FavoritesCardLink>
              ))
            : [...favoriteVendors].slice(0, 7).map((favoriteVendor) => (
                <FavoritesCardLink
                  key={favoriteVendor.id}
                  onClick={() => {
                    favoriteVendorClickEvent({
                      vendor: favoriteVendor,
                    });
                  }}
                  to={`/storefront/sellers/${favoriteVendor.slug}`}
                >
                  <img
                    alt={favoriteVendor.name}
                    src={favoriteVendor.logo_url}
                    onError={handleImgSrcError}
                  />
                </FavoritesCardLink>
              ))}
          {[...favoriteVendors].slice(8).length > 0 && (
            <FavoritesCardLink
              data-testid='landing-page-favorites--more--link'
              onClick={favoriteVendorsAllClickEvent}
              to={{
                pathname: `/storefront/sellers`,
                search: `?sort=favorite`,
              }}
            >
              <LandingPageGradientIcon>
                <LandingPageGradientIconBackground />
                <Typography component='h4' sx={{ marginTop: spacing2 }}>
                  + {[...favoriteVendors].slice(7).length}
                </Typography>
              </LandingPageGradientIcon>
            </FavoritesCardLink>
          )}
        </>
      )}
    </FavoritesCardIcons>
  </FavoritesCard>
);

LandingPageFavorites.propTypes = {
  favoriteVendors: PropTypes.arrayOf(
    PropTypes.shape({
      coming_soon: PropTypes.bool,
      id: PropTypes.number,
      is_favorite: PropTypes.bool,
      logo: PropTypes.string,
      name: PropTypes.string,
      slug: PropTypes.string,
    })
  ).isRequired,
};

export default LandingPageFavorites;
