import {
  handleImgSrcError,
  Typography,
  neutral60,
  spacing3,
  spacing5,
} from '@leaftrade/leaftrade-component-library';
import React from 'react';
import { useStorefrontState } from '../../../hooks/useReduxState';
import { CartHeaderWrapper } from './CartHeader.styles';

const CartHeader = () => {
  const { shopper, vendor } = useStorefrontState();
  const { currentLocation } = shopper;
  const { logo } = vendor;

  return (
    <CartHeaderWrapper data-testid='cart-header--component--div'>
      <div>
        <img src={logo} alt={vendor.name} onError={handleImgSrcError} />
        <Typography
          component='h3'
          sx={{ marginLeft: spacing5 }}
          testingId='cart-header--shopping-cart--h3'
        >
          Shopping Cart
        </Typography>
        <Typography
          component='h3'
          sx={{ color: neutral60.hex, marginLeft: spacing3 }}
          testingId='cart-header--location--h3'
        >
          : {currentLocation.name}
        </Typography>
      </div>
      <Typography
        gutterBottom
        sx={{ color: neutral60.hex, flexBasis: '100%' }}
        testingId='cart-header--disclaimer--p'
      >
        Submitted order quantities and pricing are subject to the seller&apos;s
        packaged inventory availability and are not always guaranteed. You will
        be notified if the seller makes any changes to your order.
      </Typography>
    </CartHeaderWrapper>
  );
};

export default CartHeader;
