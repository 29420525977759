import {
  IconLoadingSpinner,
  seafoam60,
} from '@leaftrade/leaftrade-component-library';
import React from 'react';

const PageLoader = () => (
  <div
    style={{
      height: '80vh',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    }}
  >
    <IconLoadingSpinner
      mainLineColor={seafoam60.hex}
      secondaryLineColor='transparent'
      width='128px'
      height='128px'
      lineWidth='16px'
      rotateTiming='1.5s'
    />
  </div>
);
export default PageLoader;
