import {
  boldBodyLargeTypography,
  Button,
  Modal,
  Typography,
} from '@leaftrade/leaftrade-component-library';
import React from 'react';
import { useDispatch } from 'react-redux';
import { useStorefrontState } from '../../../../hooks/useReduxState';
import { toggleTermsOfServiceModal } from '../../../../store/actions/storefrontActions';
import TermsOfService from '../../../routes/TermsOfService';

const TermsOfServiceModal = () => {
  const dispatch = useDispatch();
  const { checkout } = useStorefrontState();
  const { termsOfServiceModal } = checkout;

  const TermsOfServiceModalJsx = {
    actions: (
      <>
        <Button
          onClick={() => {
            dispatch(toggleTermsOfServiceModal({ isOpen: false }));
          }}
        >
          Close
        </Button>
      </>
    ),
    body: <TermsOfService />,
    header: (
      <Typography bodyStyle={boldBodyLargeTypography} gutterBottom>
        Terms of Service
      </Typography>
    ),
  };

  return (
    <Modal
      actions={TermsOfServiceModalJsx.actions}
      header={TermsOfServiceModalJsx.header}
      open={termsOfServiceModal.isOpen}
      testingId='terms-of-service-modal--component--div'
    >
      {TermsOfServiceModalJsx.body}
    </Modal>
  );
};

export default TermsOfServiceModal;
