import { spacing3, spacing5 } from '@leaftrade/leaftrade-component-library';
import styled from 'styled-components';

export const AddToCartWrapper = styled.div`
  margin: ${({ isGridView }) => (isGridView ? `${spacing3} 0 0` : 'auto 0')};
`;

export const QuantitySubmitWrapper = styled.div`
  display: flex;
  flex-direction: ${({ isGridView }) => (isGridView ? 'row' : 'column')};
  gap: ${spacing5};
`;
