import {
  maxMediaQueryDesktop,
  neutral0,
  pxToRem,
  spacing6,
} from '@leaftrade/leaftrade-component-library';
import styled from 'styled-components';
import { headerZIndex } from './zIndex';

export const headerBgColor = '#275463';

export const HeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  background: ${headerBgColor};
  padding: 0 ${spacing6};
  height: 56px;
  position: sticky;
  top: 0;
  z-index: ${headerZIndex};
  color: ${neutral0.hex};
  ${maxMediaQueryDesktop} {
    ${({ isDashboard }) => !isDashboard && 'display: none;'}
  }
`;

export const HeaderLogo = styled.a`
  background: linear-gradient(
    90deg,
    rgba(59, 118, 115, 0) 0%,
    #3b7673 39.9%,
    #3b7673 60.22%,
    rgba(59, 118, 115, 0) 100%
  );
  padding: 12px 48px;
  & img {
    height: ${pxToRem(32)};
  }
`;
