import {
  boldBodyLargeTypography,
  boldBodyTypography,
  Button,
  IconLoadingSpinner,
  Modal,
  Typography,
} from '@leaftrade/leaftrade-component-library';
import React from 'react';
import { useDispatch } from 'react-redux';
import { useStorefrontState } from '../../../../hooks/useReduxState';
import {
  addTimedBanner,
  clearQuickOrder,
  toggleQuickOrderClearModal,
} from '../../../../store/actions/storefrontActions';

const ClearQuickOrderModal = () => {
  const dispatch = useDispatch();
  const { quickOrderData, vendor } = useStorefrontState();
  const { clearQuickOrderModal, selectedLocations } = quickOrderData;
  const { slug } = vendor;

  const selectedLocationsIds = selectedLocations.map((location) =>
    Number(location.id)
  );

  const clearQuickOrderModalJsx = {
    actions: !clearQuickOrderModal.isClearingQuickOrder ? (
      <>
        <Button
          onClick={() =>
            dispatch(
              toggleQuickOrderClearModal({
                isOpen: false,
              })
            )
          }
          testingId='confirmationModal__cancelButton'
          variant='secondary'
        >
          Cancel
        </Button>
        <Button
          onClick={() =>
            dispatch(
              clearQuickOrder({
                locationIds: selectedLocationsIds,
                onSuccess: () => {
                  dispatch(
                    addTimedBanner({
                      id: 'quick-order-cleared',
                      message: 'All Carts Cleared',
                      severity: 'success',
                    })
                  );
                },
                slug,
              })
            )
          }
          testingId='confirmationModal__confirmButton'
          variant='destructive'
        >
          Clear All Carts
        </Button>
      </>
    ) : (
      <Button variant='destructive'>
        <IconLoadingSpinner />
      </Button>
    ),
    header: (
      <Typography bodyStyle={boldBodyLargeTypography} gutterBottom>
        Confirm Clear Carts?
      </Typography>
    ),
    body: (
      <>
        <Typography bodyStyle={boldBodyTypography} gutterBottom>
          Are you sure you want to remove all items from the selected carts?
        </Typography>
        <Typography bodyStyle={boldBodyTypography} gutterBottom>
          Locations:
        </Typography>
        <ul>
          {[...selectedLocations]
            .sort((a, b) => a.name.localeCompare(b.name))
            .map((location) => (
              <li key={location.id}>{location.name}</li>
            ))}
        </ul>
      </>
    ),
  };

  return (
    <Modal
      actions={clearQuickOrderModalJsx.actions}
      header={clearQuickOrderModalJsx.header}
      hideClose={clearQuickOrderModal.isClearingQuickOrder}
      onClose={() =>
        dispatch(
          toggleQuickOrderClearModal({
            isOpen: false,
          })
        )
      }
      open={clearQuickOrderModal.isOpen}
      testingId='clear-quick-order-modal--component--div'
    >
      {clearQuickOrderModalJsx.body}
    </Modal>
  );
};

export default ClearQuickOrderModal;
