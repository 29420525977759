import {
  Button,
  IconChevronDown,
  IconChevronUp,
  Typography,
  neutral0,
  sRadius,
  spacing5,
} from '@leaftrade/leaftrade-component-library';
import React from 'react';

const HeaderNavCTA = ({ activeSection, handleSectionClick, section }) => (
  <Button
    endIcon={
      activeSection?.name === section.name ? (
        <IconChevronUp />
      ) : (
        <IconChevronDown />
      )
    }
    key={section.name}
    sx={{
      borderRadius: sRadius,
      background:
        activeSection?.name === section.name ? '#264D4B' : 'transparent',
      '&:hover': {
        background:
          activeSection?.name === section.name ? '#264D4B' : '#3B7673',
      },
      '&:active': { background: '#264D4B' },
      '& span': {
        padding: 0,
        lineHeight: 0,
      },
      '& svg': {
        fill: neutral0.hex,
        maxWidth: spacing5,
        maxHeight: spacing5,
      },
    }}
    onClick={() => handleSectionClick(section)}
    testingId='header--nav-cta--component--div'
    variant='transparent'
  >
    <Typography
      classes={['h7']}
      component='span'
      sx={{
        color: neutral0.hex,
        lineHeight: 0,
      }}
    >
      {section.name}
    </Typography>
  </Button>
);

export default HeaderNavCTA;
