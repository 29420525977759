import {
  bodyTypography,
  mediumBodyTypography,
  neutral0,
  interaction80,
  neutral30,
  pxToRem,
  spacing4,
  spacing5,
} from '@leaftrade/leaftrade-component-library';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { headerMobileNavParentStyles } from '../HeaderMobileNav.styles';

export const HeaderMobileNavPanelBack = styled.button`
  ${headerMobileNavParentStyles}
  text-transform: capitalize;
  border-bottom: 1px solid ${neutral30.hex};
  top: 0;
  position: sticky;
  background: ${neutral0.hex};
  & svg {
    margin-right: ${spacing4};
  }
`;

export const HeaderMobileNavChildPanelBack = styled(HeaderMobileNavPanelBack)`
  top: 3.6rem;
`;

export const HeaderMobileNavPanelWrapper = styled.div`
  color: ${interaction80.hex};
`;

export const HeaderMobileNavPanelContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const HeaderMobileNavPanelActions = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  & button,
  & a {
    display: flex;
    align-items: center;
    width: 100%;
    background: none;
    border: none;
    padding: ${spacing5};
    text-decoration: none;
    color: ${interaction80.hex};
    text-transform: capitalize;
    ${bodyTypography}
    &:hover {
      text-decoration: none;
    }
    & svg {
      height: ${pxToRem(24)};
      margin-left: auto;
    }
  }
`;

export const HeaderMobileNavPanelShopAll = styled(Link)`
  display: flex;
  text-decoration: none;
  text-transform: capitalize;
  color: ${interaction80.hex};
  padding: ${spacing5};
  ${mediumBodyTypography}
  &:hover {
    text-decoration: none;
  }
  & svg {
    height: ${pxToRem(24)};
    margin-left: auto;
  }
`;
