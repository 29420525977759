import React, { Component } from 'react';
import PropTypes from 'prop-types';
import 'bootstrap';
import { IconLoadingSpinner } from '@leaftrade/leaftrade-component-library';

export default class ApiTokenPreferences extends Component {
  static propTypes = {
    list_url: PropTypes.string,
    create_url: PropTypes.string,
  }
  constructor(props) {
    super(props);
    this.state = {
      'tokens': [],
      'loading': false,
      'error': false,
    };
  }

  componentDidMount() {
    this.refresh();
  }

  delete = (token) => {
    if (confirm("Really revoke token " + token.key) + "?") {
      return fetch(token.url, {
        method: 'DELETE'
      }).catch(() => {
        this.setState({
          error: true,
          loading: false,
        });
      }).then(() => {
        this.refresh();
      });
    }
  }

  refresh = () => {
    if (this.state.loading) {
      return;
    }

    this.setState({
      loading: true,
    });

    return fetch(this.props.list_url).then((res) => {
      return res.json();
    }).catch(() => {
      this.setState({
        loading: false,
        error: true,
      });
    }).then((json) => {
      this.setState({
        loading: false,
        tokens: json.results
      });
    }).catch(() => {
      this.setState({
        error: true,
        loading: false,
      });
    });
  }

  render() {
    let {tokens, error, loading} = this.state;
    if (loading) {
      return <IconLoadingSpinner />;
    }

    return (<div className='fluid-container'>
      <form method="POST" className="card" id="form_notifications" style={{border: 'none'}}>
        <div className="card-body">
          <div className="h2">
            <span className="card-title">
              API Access
            </span>
          </div>
          <table className="table table-striped">
            <thead>
              <tr>
                <th>Token</th>
                <th>Created</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {tokens.length ? tokens.map((token) => <tr key={token.key}>
                <td>{token.key}</td>
                <td>{token.created}</td>
                <td>
                  <button type="button" className="btn btn-primary" onClick={(e) => this.delete(token)}>Revoke</button>
                </td>
              </tr>) : <tr><td colSpan={3}>No API token generated</td></tr> }
            </tbody>
          </table>
          {error ? <p className="error">Error performing operation.</p> : ''}
        </div>
      </form>
    </div>);
  }
}
