import {
  IconSeller,
  ListMenu,
  Typography,
  bodyTypography,
  pxToRem,
  spacing4,
  spacing5,
} from '@leaftrade/leaftrade-component-library';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useStorefrontState } from '../../../hooks/useReduxState';
import { setSelectedQuickOrderLocations } from '../../../store/actions/storefrontActions';
import { QuickOrderLocationSelectPlaceholder } from './QuickOrderLocationSelect.styles';

const QuickOrderLocationSelect = () => {
  const dispatch = useDispatch();
  const { quickOrderData, shopper } = useStorefrontState();
  const { selectedLocations } = quickOrderData;
  const { locations } = shopper;

  const [placeholder, setPlaceholder] = useState('All Locations');

  const getLocationString = () => {
    if (selectedLocations.length === locations.length) {
      return 'All Locations';
    }
    if (selectedLocations.length === 1) {
      return '1 Location';
    }
    return `${selectedLocations.length} Locations`;
  };

  const handleAllToggle = () => {
    if (selectedLocations.length === locations.length) {
      dispatch(setSelectedQuickOrderLocations([]));
    } else {
      dispatch(
        setSelectedQuickOrderLocations(locations.map((location) => location))
      );
    }
  };

  const handleLocationToggle = (location) => {
    if (
      selectedLocations.find(
        (selectedLocation) => selectedLocation.id === location.id
      )
    ) {
      dispatch(
        setSelectedQuickOrderLocations(
          selectedLocations.filter(
            (selectedLocation) => selectedLocation.id !== location.id
          )
        )
      );
    } else {
      dispatch(
        setSelectedQuickOrderLocations([...selectedLocations, location])
      );
    }
  };

  const listMenuOptions = [
    {
      action: () => handleAllToggle(),
      isChecked: selectedLocations.length === locations.length,
      level: 1,
      label: 'Shop For All Locations',
      key: 'all',
      name: 'all',
      type: 'switch',
      value: 'all',
    },
    ...locations.map((location) => ({
      action: () => handleLocationToggle(location),
      isChecked: Boolean(
        selectedLocations.find(
          (selectedLocation) => selectedLocation.id === location.id
        )
      ),
      level: 1,
      label: location.name,
      key: location.id,
      name: location.name,
      type: 'checkbox',
      value: location,
    })),
  ];

  useEffect(() => {
    setPlaceholder(getLocationString());
  }, [selectedLocations]);

  return (
    <>
      <ListMenu
        hasClose
        options={listMenuOptions}
        placeholder={
          <QuickOrderLocationSelectPlaceholder>
            <IconSeller />
            <Typography
              bodyStyle={bodyTypography}
              component='span'
              sx={{ marginLeft: spacing4 }}
            >
              {placeholder}
            </Typography>
          </QuickOrderLocationSelectPlaceholder>
        }
        showClear
        sx={{
          maxWidth: pxToRem(300),
          marginTop: spacing5,
          zIndex: 1,
          '& > button': {
            maxWidth: pxToRem(250),
          },
        }}
        testingId='quick-order-location-select--component--list-menu'
        title='Locations'
        variant='secondary'
      />
    </>
  );
};

export default QuickOrderLocationSelect;
