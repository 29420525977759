import { createSlice } from '@reduxjs/toolkit';
import clearObject from '../../utils/clearObject';
import {
  fetchCategories,
  fetchContext,
  fetchStockLocations,
  fetchVariantTemplates,
  setFilters,
  setSelectedBatch,
  updateBatches,
  runModalAction,
  createVariants,
  fetchTemplate,
  setTemplate,
  saveTemplates,
  fetchVariantSelectedProducts,
} from '../actions/batchesActions';
import batchesState from '../states/batchesState';
import objectToCamelCase from '../../utils/objectToCamelCase';

export const batchesSlice = createSlice({
  name: 'batches',
  initialState: batchesState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(setFilters, (state, action) => ({
      ...state,
      filters: action.payload,
    }));
    builder.addCase(setSelectedBatch, (state, action) => ({
      ...state,
      selectedBatches: action.payload
        ? objectToCamelCase(
            clearObject({
              ...state.selectedBatches,
              ...(action.payload || {}),
            })
          )
        : {},
    }));

    builder.addCase(updateBatches.pending, (state) => ({
      ...state,
      isBatchesUpdating: true,
    }));

    builder.addCase(updateBatches.fulfilled, (state) => ({
      ...state,
      isBatchesUpdating: false,
      selectedBatches: {},
    }));

    builder.addCase(updateBatches.rejected, (state) => ({
      ...state,
      isBatchesUpdating: false,
    }));

    builder.addCase(createVariants.pending, (state) => ({
      ...state,
      isBatchesUpdating: true,
    }));

    builder.addCase(createVariants.fulfilled, (state) => ({
      ...state,
      isBatchesUpdating: false,
      selectedBatches: {},
    }));

    builder.addCase(createVariants.rejected, (state) => ({
      ...state,
      isBatchesUpdating: false,
    }));

    builder.addCase(fetchStockLocations.fulfilled, (state, action) => ({
      ...state,
      stockLocations: action.payload.stock_locations,
      isStockLocationsLoading: false,
    }));

    builder.addCase(fetchStockLocations.pending, (state) => ({
      ...state,
      isStockLocationsLoading: true,
    }));

    builder.addCase(fetchStockLocations.rejected, (state) => ({
      ...state,
      isStockLocationsLoading: false,
    }));

    builder.addCase(fetchCategories.fulfilled, (state, action) => ({
      ...state,
      categories: action.payload.categories,
      isCategoriesLoading: false,
    }));

    builder.addCase(fetchCategories.pending, (state) => ({
      ...state,
      isCategoriesLoading: true,
    }));

    builder.addCase(fetchCategories.rejected, (state) => ({
      ...state,
      isCategoriesLoading: false,
    }));

    builder.addCase(fetchContext.fulfilled, (state, action) => ({
      ...state,
      context: objectToCamelCase(action.payload),
    }));

    builder.addCase(fetchVariantTemplates.fulfilled, (state, action) => ({
      ...state,
      variantTemplates: action.payload.results,
    }));

    builder.addCase(runModalAction.fulfilled, (state) => ({
      ...state,
      isModalLoading: false,
    }));

    builder.addCase(runModalAction.pending, (state) => ({
      ...state,
      isModalLoading: true,
    }));

    builder.addCase(runModalAction.rejected, (state) => ({
      ...state,
      isModalLoading: false,
    }));

    builder.addCase(fetchTemplate.fulfilled, (state, action) => ({
      ...state,
      downloadedTemplate: action.payload,
      template: action.payload,
      isTemplateLoading: false,
    }));

    builder.addCase(fetchTemplate.pending, (state) => ({
      ...state,
      isTemplateLoading: true,
    }));

    builder.addCase(fetchTemplate.rejected, (state) => ({
      ...state,
      template: null,
      downloadedTemplate: null,
      isTemplateLoading: false,
    }));
    builder.addCase(setTemplate, (state, action) => ({
      ...state,
      template: { ...state.template, ...action.payload },
    }));

    builder.addCase(saveTemplates.fulfilled, (state) => ({
      ...state,
      downloadedTemplate: state.template,
      isTemplateLoading: false,
    }));

    builder.addCase(saveTemplates.pending, (state) => ({
      ...state,
      isTemplateLoading: true,
    }));

    builder.addCase(saveTemplates.rejected, (state) => ({
      ...state,
      isTemplateLoading: false,
    }));

    builder.addCase(
      fetchVariantSelectedProducts.fulfilled,
      (state, action) => ({
        ...state,
        variantSelectedProducts: {
          ...state.variantSelectedProducts,
          ...action.payload.results.reduce(
            (acc, cur) => ({
              ...acc,
              [cur.id]: cur.name,
            }),
            {}
          ),
        },
      })
    );
  },
});
