import {
  interaction60,
  neutral0,
  neutral20,
  spacing3,
  spacing5,
  spacing6,
  spacing7,
} from '@leaftrade/leaftrade-component-library';
import styled from 'styled-components';

export const QuickOrderTableHeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  border-bottom: 1px solid ${neutral20.hex};
  padding: ${spacing5} ${spacing5} 0;
`;

export const QuickOrderTableHeaderFixedColumns = styled.div`
  display: flex;
  align-items: center;
`;

export const QuickOrderTableHeaderVariableColumnsWrapper = styled.div`
  position: relative;
  overflow: hidden;
  display: flex;
  flex: 1;
  padding: ${spacing5} 0;
`;

export const QuickOrderTableHeaderVariableColumns = styled.div`
  display: flex;
  align-items: center;
  overflow: auto;
  flex: 1;
  position: relative;
  scrollbar-width: none;
  -ms-overflow-style: none;
  &::-webkit-scrollbar {
    display: none;
  }
`;

export const QuickOrderTableHeaderScrollGradient = styled.div`
  position: absolute;
  top: 20px;
  bottom: 0;
  width: 20px;
  z-index: 1;
  ${({ isLeft }) =>
    isLeft
      ? 'left: 0; background: linear-gradient(90deg, rgba(77, 72, 173, 0.2) 0%, rgba(77, 72, 173, 0) 100%);'
      : 'right: 0; background: linear-gradient(90deg, rgba(77, 72, 173, 0) 0%, rgba(77, 72, 173, 0.2) 100%);'}
`;

export const QuickOrderTableHeaderScrollButton = styled.button`
  position: absolute;
  z-index: 2;
  background-color: ${interaction60.hex};
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  top: ${spacing3};
  border: none;
  width: ${spacing7};
  height: ${spacing7};
  ${({ isLeft }) => (isLeft ? 'left: 0;' : 'right: 0;')}
  & svg {
    width: ${spacing6};
    height: ${spacing6};
    fill: ${neutral0.hex};
    pointer-events: none;
  }
`;
