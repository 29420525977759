import { createAsyncThunk } from '@reduxjs/toolkit';
import { axiosPostData } from '../../../helpers/axios';

const createThunkPost = (type, params = {}) =>
  createAsyncThunk(
    type,
    async ({ data, onSuccess, url, onError }, { rejectWithValue }) =>
      axiosPostData({
        data,
        onSuccess,
        onError,
        rejectWithValue,
        url,
        headers: { 'Content-Type': 'application/json' },
        ...params,
      })
  );

export default createThunkPost;
