import styled from 'styled-components';
import {
  spacing4,
  spacing5,
  spacing8,
  pxToRem,
  maxMediaQueryDesktop,
  maxMediaQueryTablet,
} from '@leaftrade/leaftrade-component-library';
import { pageSearchSortZIndex } from '../../../shared/styles/zIndex';

export const PageHeaderSearchStyleSortContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  grid-gap: ${spacing5};
  padding: 0 ${pxToRem(135)} ${spacing4};
  z-index: ${pageSearchSortZIndex};
  ${maxMediaQueryDesktop} {
    padding: 0 ${pxToRem(70)} ${spacing4};
  }
  ${maxMediaQueryTablet} {
    padding: 0 ${spacing8} ${spacing4};
    flex-direction: column;
  }
  & > div {
    flex-basis: 48%;
    max-width: ${pxToRem(375)};
    ${maxMediaQueryTablet} {
      flex-basis: unset;
      max-width: unset;
      width: 100%;
    }
  }
`;

export const PageHeaderStyleSortContainer = styled.div`
  display: flex;
  align-items: center;
`;
