import {
  baseTransition,
  neutral0,
  pxToRem,
  sRadius,
  spacing3,
  spacing4,
  spacing5,
} from '@leaftrade/leaftrade-component-library';
import styled from 'styled-components';

export const HeaderSelectCTA = styled.span`
  border: none;
  background: ${({ isOpen }) => (isOpen ? '#264D4B' : 'none')};
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: ${({ hasSubtitle }) =>
    hasSubtitle ? `${pxToRem(6)} ${spacing3}` : `${spacing3}`};
  color: ${neutral0.hex};
  border-radius: ${sRadius};
  text-decoration: none;
  font-size: ${spacing4};
  ${baseTransition}
  &:hover {
    background: #3b7673;
    color: inherit;
    text-decoration: none;
  }
  &:active {
    background: #264d4b;
    color: inherit;
    text-decoration: none;
  }
  & svg {
    &:first-child {
      margin-right: ${spacing3};
    }
    &:last-child {
      height: ${spacing5};
      margin-left: ${spacing3};
      margin-right: 0;
    }
  }
`;
