export const DEFAULT_FILTER = {
  name: null,
  status: 'active',
  categoriesId: null,
  brandsId: null,
};

const productsState = {
  filters: DEFAULT_FILTER,
  categories: null,
  subcategories: null,
  brands: null,
  strains: null,
  selectedProducts: {},
  isProductUpdating: false,
  tags: null,
};

export default productsState;
