import {
  pxToRem,
  spacing6,
  spacing5,
  interaction20,
} from '@leaftrade/leaftrade-component-library';
import styled from 'styled-components';

export const LandingPageCarouselWrapper = styled.div`
  margin: 0 0 ${spacing6} 0;
  grid-gap: ${spacing5};
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  flex-direction: column;
  padding-bottom: ${pxToRem(10)};

  .carousel-inner {
    border-radius: 16px;
    box-shadow: 0px 4px 8px 0px rgba(145, 158, 171, 0.24);
    max-height: ${pxToRem(224)};
    width: 100%;
    .carousel-item {
      height: auto;
      img {
        width: 100vw;
      }
    }
  }

  .carousel-indicators {
    bottom: ${pxToRem(-50)};
    li {
      background-color: ${interaction20.hex};
    }
  }
`;
