import PropTypes from 'prop-types';
import React from 'react';
import { usePageViewTracking } from '../../../hooks/usePageViewTracking';
import { StyledPageWrapper } from './PageWrapper.styles';

const PageWrapper = ({ children, sidePadding, unsetOverflow }) => {
  usePageViewTracking();
  return (
    <StyledPageWrapper
      data-testid='page-wrapper--component--div'
      sidePadding={sidePadding}
      unsetOverflow={unsetOverflow}
    >
      {children}
    </StyledPageWrapper>
  );
};

PageWrapper.defaultProps = {
  sidePadding: 'default',
};

PageWrapper.propTypes = {
  children: PropTypes.node.isRequired,
  sidePadding: PropTypes.oneOf(['none', 'small', 'default']), // default matches header, nav, and footer paddings
};

export default PageWrapper;
