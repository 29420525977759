import {
  pxToRem,
  spacing2,
  spacing3,
} from '@leaftrade/leaftrade-component-library';
import styled from 'styled-components';

export const QuickOrderTableItemInfoWrapper = styled.div`
  display: flex;
  align-items: center;
  width: ${pxToRem(320)};
  margin-right: ${spacing3};
`;

export const QuickOrderTableItemInfoImage = styled.img`
  flex-shrink: 0;
  width: ${pxToRem(48)};
  height: ${pxToRem(48)};
  margin-right: ${spacing3};
`;

export const QuickOrderTableItemInfoData = styled.div`
  display: flex;
`;

export const QuickOrderTableItemInfoDataCannabinoid = styled(
  QuickOrderTableItemInfoData
)`
  gap: ${spacing2};
`;
