import {
  Button,
  IconCart,
  IconTrash,
  pxToRem,
} from '@leaftrade/leaftrade-component-library';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useStorefrontState } from '../../../../hooks/useReduxState';
import { AddToQuickOrderWrapper } from './AddToQuickOrder.styles';
import {
  addProductToQuickOrder,
  toggleQuickOrderDeleteModal,
} from '../../../../store/actions/storefrontActions';
import { determineCannabinoids } from '../../../utils';

const AddToQuickOrder = ({
  inventoryLiveCount: inventoryLiveCountProduct,
  selectedVariant,
  productId,
}) => {
  const dispatch = useDispatch();
  const { quickOrderData, vendor } = useStorefrontState();
  const { settings } = vendor;
  const { batchQueuesEnabled, settingsDeprecated } = settings;
  const { primary_lab_values: primaryLabValues } = settingsDeprecated;

  const {
    batch,
    cannabinoids,
    default_image: defaultImage,
    inventory_live_count: inventoryLiveCountVariant,
    name,
    package_size_id: packageSizeId,
    price_display: displayPrice,
    product_name: productName,
  } = selectedVariant;
  const { lab_results_unit: labResultsUnit } = cannabinoids;
  const { batch_ref: batchRef } = batch;

  const [isHovered, setIsHovered] = useState(false);

  const itemInCart = batchQueuesEnabled
    ? quickOrderData.items.find((item) => item.cartItemId === packageSizeId)
    : quickOrderData.items.find(
        (item) => item.cartItemId === selectedVariant.id
      );

  const createItemToAdd = () => {
    const itemToAdd = {
      imageUrl: defaultImage,
      productId,
      productName,
      selectedLocationTotal: 0,
      selectedLocationCount: 0,
    };
    if (batchQueuesEnabled) {
      itemToAdd.cartItemId = packageSizeId;
      itemToAdd.inventoryLiveCount = inventoryLiveCountProduct;
      itemToAdd.displayName = name;
      itemToAdd.locations = [];
      itemToAdd.packageSizes = [
        {
          id: selectedVariant.id,
          cannabinoids: determineCannabinoids({
            primaryLabValues,
            selectedVariant,
          }),
          cannabinoidUnit:
            labResultsUnit === 'percent' ? '%' : ` ${labResultsUnit}`,
          quantity: 0,
          subTotal: 0.0,
          storefrontDisplayPrice: displayPrice.replace(/[^0-9.-]+/g, ''),
          batch_ref: batchRef || '',
          displayName: name,
        },
      ];
    } else {
      itemToAdd.cartItemId = selectedVariant.id;
      itemToAdd.variants = [
        {
          variantId: selectedVariant.id,
          locations: [],
          cannabinoids: determineCannabinoids({
            primaryLabValues,
            selectedVariant,
          }),
          cannabinoidUnit:
            labResultsUnit === 'percent' ? '%' : ` ${labResultsUnit}`,
          quantity: 0,
          subTotal: 0.0,
          storefrontDisplayPrice: displayPrice.replace(/[^0-9.-]+/g, ''),
          batch_ref: batchRef || '',
          displayName: selectedVariant.name,
          inventoryLiveCount: inventoryLiveCountVariant,
        },
      ];
    }
    return itemToAdd;
  };

  const handleAddToQuickOrder = () => {
    dispatch(
      addProductToQuickOrder({
        item: createItemToAdd(),
      })
    );
  };

  const handleRemoveFromQuickOrder = () => {
    dispatch(
      toggleQuickOrderDeleteModal({
        isOpen: true,
        item: itemInCart,
      })
    );
  };

  return (
    <AddToQuickOrderWrapper
      data-testid='add-to-quick-order--component--div'
      isGridView={false}
    >
      {!itemInCart ? (
        <Button
          onClick={handleAddToQuickOrder}
          sx={{
            '& span': {
              display: 'flex',
              alignItems: 'center',
              width: '85px',
              justifyContent: 'center',
            },
          }}
          testingId='add-to-quick-order--add--button'
        >
          Add To Cart
        </Button>
      ) : (
        <Button
          onClick={handleRemoveFromQuickOrder}
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
          sx={{
            '& span': {
              display: 'flex',
              alignItems: 'center',
              gap: '8px',
              width: '85px',
              justifyContent: 'center',
            },
            '& svg': {
              width: pxToRem(20),
              height: pxToRem(20),
            },
          }}
          testingId='add-to-quick-order--remove--button'
        >
          {isHovered ? (
            <>
              <IconTrash /> Remove
            </>
          ) : (
            <>
              <IconCart /> In Cart
            </>
          )}
        </Button>
      )}
    </AddToQuickOrderWrapper>
  );
};

AddToQuickOrder.propTypes = {
  selectedVariant: PropTypes.shape({
    inventory_live_count: PropTypes.number,
  }).isRequired,
  productId: PropTypes.number.isRequired,
};

export default AddToQuickOrder;
