import { Typography, spacing3 } from '@leaftrade/leaftrade-component-library';
import PropTypes from 'prop-types';
import React from 'react';
import { useDispatch } from 'react-redux';
import { getSellerNavData } from '../../../../store/actions/storefrontActions';
import FavoriteToggle from '../../FavoriteToggle';
import { PageHeaderInfoWrapper } from './PageHeaderInfo.styles';

const PageHeaderInfo = ({
  imgSrc,
  description,
  hasFavorite,
  isFavorite,
  slug,
  title,
  vendorId,
}) => {
  const dispatch = useDispatch();

  const handleFavoriteToggleSuccess = () => {
    dispatch(
      getSellerNavData({
        slug,
      })
    );
  };

  return (
    <PageHeaderInfoWrapper>
      {imgSrc && <img alt={title} src={imgSrc} />}
      <div>
        <Typography
          component='h4'
          testingId='page-header-info--title--typography-h4'
        >
          {title}
        </Typography>
        {description && (
          <Typography
            component='p'
            testingId='page-header-info--description--typography-p'
            sx={{
              marginTop: spacing3,
            }}
          >
            {description}
          </Typography>
        )}
      </div>
      {hasFavorite && (
        <FavoriteToggle
          isFavorite={isFavorite}
          onSuccess={handleFavoriteToggleSuccess}
          vendorId={vendorId}
        />
      )}
    </PageHeaderInfoWrapper>
  );
};

PageHeaderInfo.defaultProps = {
  imgSrc: null,
  description: null,
  hasFavorite: false,
  isFavorite: false,
  slug: null,
  vendorId: null,
};

PageHeaderInfo.propTypes = {
  imgSrc: PropTypes.string,
  description: PropTypes.string,
  hasFavorite: PropTypes.bool,
  isFavorite: PropTypes.bool,
  slug: PropTypes.string,
  title: PropTypes.string.isRequired,
  vendorId: PropTypes.number,
};

export default PageHeaderInfo;
