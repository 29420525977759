import { spacing5 } from '@leaftrade/leaftrade-component-library';
import styled from 'styled-components';

export const HeaderNavWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: ${spacing5};
`;

export const HeaderNavSection = styled.div`
  position: relative;
`;
