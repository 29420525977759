import {
  IconButton,
  IconChevronLeft,
  IconChevronRight,
  useWindowSize,
  handleImgSrcError,
} from '@leaftrade/leaftrade-component-library';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import ItemsCarousel from 'react-items-carousel';
import useDebouncedEffect from '../../../../hooks/useDebouncedEffect';
import {
  sponsoredAdClickEvent,
  sponsoredAdViewEvent,
} from '../../../analytics/landing';
import {
  LandingPageSponsoredItem,
  LandingPageSponsoredWrapper,
} from './LandingPageSponsored.styles';

const LandingPageSponsored = ({ sponsors }) => {
  const size = useWindowSize();
  const defaultNoOfCardsDesktop = sponsors.length >= 3 ? 2.5 : sponsors.length;
  const defaultNoOfCardsTablet = sponsors.length >= 2 ? 1.5 : sponsors.length;

  const [activeItemIndex, setActiveItemIndex] = useState(0);
  const [noOfCards, setNoOfCards] = useState(defaultNoOfCardsDesktop);
  const [slidesToScroll, setSlidesToScroll] = useState(2);
  const [isEnd, setIsEnd] = useState(false);

  // uncomment below for autoplay
  // probable need to change the noOfCards & slidesToScroll
  // const noOfItems = sponsors.length;
  // const autoPlayDelay = 3500;

  // useEffect(() => {
  //   const interval = setInterval(() => {
  //     setActiveItemIndex(
  //       (prevState) => (prevState + 1) % (noOfItems - noOfCards + 1)
  //     );
  //   }, autoPlayDelay);

  //   return () => {
  //     clearInterval(interval);
  //   };
  // }, []);

  const onChange = (value) => {
    setActiveItemIndex(value);
  };

  const handleScrollToLastItem = (activeState) => {
    if (activeState.isLastScroll) {
      setIsEnd(true);
    } else {
      setIsEnd(false);
    }
  };

  useDebouncedEffect(
    () => {
      if (!size) return;
      if (size.width >= 1440) {
        setNoOfCards(defaultNoOfCardsDesktop);
        setSlidesToScroll(2);
      } else if (size.width >= 768) {
        setNoOfCards(defaultNoOfCardsTablet);
        setSlidesToScroll(1);
      } else {
        setNoOfCards(1);
        setSlidesToScroll(1);
      }
    },
    [size],
    150
  );

  useEffect(() => {
    if (sponsors.length > 0) {
      sponsors.forEach((sponsor) => {
        sponsoredAdViewEvent({
          sponsoredAd: sponsor,
        });
      });
    }
  }, [sponsors]);

  return (
    <LandingPageSponsoredWrapper
      data-testid='landing-page-sponsored--component--div'
      isEnd={isEnd}
    >
      <ItemsCarousel
        activeItemIndex={activeItemIndex}
        chevronWidth={48}
        classes={{
          wrapper: 'carousel-wrapper',
          itemsWrapper: 'carousel-items-wrapper',
          itemsInnerWrapper: 'carousel-items-inner-wrapper',
          itemWrapper: 'carousel-item-wrapper',
          rightChevronWrapper: 'carousel-right-chevron-wrapper',
          leftChevronWrapper: 'carousel-left-chevron-wrapper',
        }}
        gutter={16}
        leftChevron={
          <IconButton
            icon={<IconChevronLeft />}
            size='xl'
            testingId='landing-page-sponsored--previous-item--button'
            variant='secondary'
          />
        }
        numberOfCards={noOfCards}
        onActiveStateChange={handleScrollToLastItem}
        outsideChevron
        requestToChangeActive={onChange}
        rightChevron={
          <IconButton
            icon={<IconChevronRight />}
            size='xl'
            testingId='landing-page-sponsored--next-item--button'
            variant='secondary'
          />
        }
        slidesToScroll={slidesToScroll}
      >
        {sponsors.map((sponsor) => (
          <LandingPageSponsoredItem
            key={sponsor.id}
            onClick={() =>
              sponsoredAdClickEvent({
                sponsoredAd: sponsor,
              })
            }
          >
            {!sponsor.target_url ? (
              <img
                alt={sponsor.name}
                onError={handleImgSrcError}
                src={sponsor.image}
              />
            ) : (
              <a
                href={sponsor.target_url}
                rel='noopener noreferrer'
                target='_blank'
              >
                <img
                  alt={sponsor.name}
                  onError={handleImgSrcError}
                  src={sponsor.image}
                />
              </a>
            )}
          </LandingPageSponsoredItem>
        ))}
      </ItemsCarousel>
    </LandingPageSponsoredWrapper>
  );
};

LandingPageSponsored.propTypes = {
  sponsors: PropTypes.arrayOf(
    PropTypes.shape({
      target_url: PropTypes.string,
      id: PropTypes.number,
      image: PropTypes.string,
      name: PropTypes.string,
    })
  ).isRequired,
};

export default LandingPageSponsored;
