import React from 'react';
import PropTypes from 'prop-types';

const NotificationCheckbox = ({ type = 'checkbox', name, checked = false, onChange, label = '', disabled = false }) => (
  <>
    <div className="row">
      <div className="col-1">
        <input id={name} type={type} name={name} checked={checked} onChange={onChange} disabled={disabled} />
      </div>
      <div className="col-10">
        <label htmlFor={name} key={name.concat('_htmlfor')}>{label}</label>
      </div>
    </div>
  </>);

NotificationCheckbox.propTypes = {
  type: PropTypes.string,
  name: PropTypes.string.isRequired,
  checked: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  label: PropTypes.string,
  disabled: PropTypes.bool
};

export default NotificationCheckbox;
