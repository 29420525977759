import { spacing6 } from '@leaftrade/leaftrade-component-library';
import styled from 'styled-components';

export const ProductDetailsInfoChipsWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: ${spacing6};
  margin-top: ${spacing6};
  gap: ${spacing6};
`;
