import {
  bodySmallTypography,
  boldBodyLargeTypography,
  Modal,
  Typography,
} from '@leaftrade/leaftrade-component-library';
import React from 'react';

const LimitPendingCallsModal = () => {
  const limitPendingCallsModalJsx = {
    header: (
      <Typography bodyStyle={boldBodyLargeTypography} gutterBottom>
        Woah there cowboy!
      </Typography>
    ),
    body: (
      <Typography bodyStyle={bodySmallTypography} gutterBottom>
        Our hamsters are working hard to process your request. Please wait a
        few.
      </Typography>
    ),
  };

  return (
    <Modal
      header={limitPendingCallsModalJsx.header}
      hideClose
      open
      testingId='limit-pending-calls-modal--component--div'
    >
      {limitPendingCallsModalJsx.body}
    </Modal>
  );
};

export default LimitPendingCallsModal;
