import { createSlice } from '@reduxjs/toolkit';
import { handleRejected } from '../../helpers';
import {
  getConfiguration,
  saveConfiguration,
  setPayload,
} from '../actions/webhookDashboardActions';
import webhookDashboardState from '../states/webhookDashboardState';

export const webhookDashboardSlice = createSlice({
  name: 'webhookDashboard',
  initialState: webhookDashboardState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getConfiguration.fulfilled, (state, action) => ({
      ...state,
      ...action.meta.arg.getTransformer(action.payload),
    }));
    builder.addCase(getConfiguration.rejected, (state, action) =>
      handleRejected(state, action)
    );
    builder.addCase(saveConfiguration.pending, (state, action) => ({
      ...state,
      isLoadingConfigs: {
        ...(state.isLoadingConfig || {}),
        [action.meta.arg.url]: true,
      },
    }));
    builder.addCase(saveConfiguration.fulfilled, (state, action) => ({
      ...state,
      isLoadingConfigs: {
        ...(state.isLoadingConfig || {}),
        [action.meta.arg.url]: false,
      },
    }));
    builder.addCase(saveConfiguration.rejected, (state, action) =>
      handleRejected(state, action)
    );
    builder.addCase(setPayload, (state, action) => ({
      ...state,
      ...action.payload,
    }));
  },
});
