import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  Typography,
  amber20,
  bodySmallTypography,
  boldBodySmallTypography,
  interaction100,
  neutral60,
  rose20,
  royal20,
  sky20,
  spacing1,
  spacing2,
  spacing4,
  spacing5,
  violet20,
  xsRadius,
} from '@leaftrade/leaftrade-component-library';
import { getTerpeneTypes } from '../../../../store/actions/storefrontActions';
import { useStorefrontState } from '../../../../hooks/useReduxState';
import ProductDetailsAccordion from '../ProductDetailsAccordion';

const TERPENE_COLORS = [amber20, rose20, violet20, royal20, sky20];

const ProductDetailsTerpenesAccordion = () => {
  const dispatch = useDispatch();
  const [draw, setDraw] = useState();
  const { productDetails, terpeneTypes } = useStorefrontState();
  const { isLoadingProductDetails, selectedVariant } = productDetails;
  const { lab_results_unit: labResultsUnit } = selectedVariant;
  const unit = labResultsUnit === 'percent' ? '%' : ` ${labResultsUnit}`;

  // disregarding terpenes with value 0, create mappable list of terpene objects with slug, value ordered descending by value, limited to top 5
  const terpenes = Object.keys(selectedVariant.stock_record.batch.terpenes)
    .filter((slug) => !!selectedVariant.stock_record.batch.terpenes[slug])
    .reduce(
      (objs, slug) => [
        ...objs,
        { slug, value: selectedVariant.stock_record.batch.terpenes[slug] },
      ],
      []
    )
    .sort(
      (a, b) =>
        (a.value === b.value && (a.slug > b.slug ? 1 : -1)) ||
        (a.value < b.value ? 1 : -1)
    )
    .slice(0, 5);
  const denominator = (terpenes[0] || {}).value;

  useEffect(() => {
    const { hash } = window.location;
    if (!isLoadingProductDetails && hash === '#terpenes') {
      const element = document.getElementById('terpenes');
      const headerOffset = 75;
      const elementPosition = element.getBoundingClientRect().top;
      const offsetPosition = elementPosition + window.scrollY - headerOffset;

      window.scrollTo({
        top: offsetPosition,
        behavior: 'smooth',
      });
    }
  }, [isLoadingProductDetails]);

  useEffect(() => {
    setDraw(true);
  }, [terpeneTypes]);

  useEffect(() => {
    const fetchTerpeneTypes = terpenes
      .map(({ slug }) => (!terpeneTypes || !terpeneTypes[slug]) && slug)
      .filter(Boolean);
    if (fetchTerpeneTypes.length) {
      dispatch(getTerpeneTypes({ slug: fetchTerpeneTypes }));
    } else {
      setDraw(true);
    }
  }, []);

  return (
    <ProductDetailsAccordion isOpen header='Terpenes' id='terpenes'>
      {selectedVariant.stock_record?.batch?.cannabinoids?.total_terpenes ? (
        <Typography
          bodyStyle={bodySmallTypography}
          sx={{ marginBottom: spacing5 }}
          testingId='product-details-total-terpenes'
        >
          <Typography bodyStyle={boldBodySmallTypography} component='span'>
            Total Terpenes
          </Typography>{' '}
          {selectedVariant.stock_record.batch.cannabinoids.total_terpenes}
          {unit}
        </Typography>
      ) : null}
      {!!terpenes.length &&
        terpenes.map(({ slug, value }, index) => (
          <div
            data-testid={`product-details-terpene-${slug}`}
            key={`product-details-terpene-${slug}`}
            style={{ margin: `${spacing2} 0`, position: 'relative' }}
          >
            <div
              style={{
                background: TERPENE_COLORS[index].hex,
                borderRadius: xsRadius,
                height: '100%',
                position: 'absolute',
                transition: 'width 2s',
                width: draw ? `${(value / denominator) * 100}%` : 0,
              }}
            />
            <div
              style={{
                padding: `${spacing1} ${spacing4}`,
                position: 'relative',
              }}
            >
              <Typography
                bodyStyle={bodySmallTypography}
                component='span'
                sx={{
                  color: interaction100.hex,
                  marginRight: spacing4,
                  opacity: terpeneTypes && terpeneTypes[slug]?.name ? 1 : 0,
                  transition: 'opacity 1s',
                }}
              >
                {terpeneTypes && terpeneTypes[slug]?.name}
              </Typography>
              <Typography
                bodyStyle={bodySmallTypography}
                component='span'
                sx={{
                  color: neutral60.hex,
                  opacity: terpeneTypes && terpeneTypes[slug]?.name ? 1 : 0,
                  transition: 'opacity 1s',
                }}
              >
                {value}%
              </Typography>
            </div>
          </div>
        ))}
    </ProductDetailsAccordion>
  );
};

export default ProductDetailsTerpenesAccordion;
