import {
  Link,
  Typography,
  bodySmallTypography,
} from '@leaftrade/leaftrade-component-library';
import PropTypes from 'prop-types';
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { ExploreCard } from './LandingPageExploreCard.styles';

const LandingPageExploreCard = ({
  className,
  title,
  description,
  handleOnClick,
  linkTo,
  linkText,
}) => (
  <ExploreCard
    className={className}
    data-testid='landing-page-explore-card--component--div'
  >
    <Typography component='h5'>{title}</Typography>
    <Typography bodyStyle={bodySmallTypography}>{description}</Typography>
    <Link
      asButton
      buttonVariant='outlined'
      component={RouterLink}
      onClick={handleOnClick}
      to={linkTo}
      variant='span'
    >
      {linkText}
    </Link>
  </ExploreCard>
);

LandingPageExploreCard.propTypes = {
  className: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  handleOnClick: PropTypes.func.isRequired,
  linkTo: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  linkText: PropTypes.string.isRequired,
};

export default LandingPageExploreCard;
