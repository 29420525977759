import {
  elevation2,
  neutral0,
  pxToRem,
} from '@leaftrade/leaftrade-component-library';
import styled, { css, keyframes } from 'styled-components';

const pulse = keyframes`
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
`;

export const IconButtonWrapper = styled.div`
  ${({ size }) => {
    switch (size) {
      case 'small':
        return `
          width: ${pxToRem(24)};
          height: ${pxToRem(24)};
        `;
      case 'medium':
        return `
          width: ${pxToRem(32)};
          height: ${pxToRem(32)};
        `;
      case 'large':
        return `
          width: ${pxToRem(40)};
          height: ${pxToRem(40)};
        `;
      default:
        return `
          width: ${pxToRem(40)};
          height: ${pxToRem(40)};
        `;
    }
  }};
  margin: 0 0 auto auto;
  border-radius: 50px;
  background-color: ${neutral0.hex};
  ${({ isToggling }) => {
    if (isToggling) {
      return css`
        animation: ${pulse} 1s infinite;
      `;
    }
    return null;
  }}
  ${elevation2()}
`;
