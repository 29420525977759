import { pxToRem, spacing4 } from '@leaftrade/leaftrade-component-library';
import styled from 'styled-components';

export const ProductDetailsContentTopWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: max-content;
  max-width: ${pxToRem(420)};
  ${({ hasNoImages }) => hasNoImages && 'margin-right: auto;'}
`;

export const AddToCartWrapper = styled.div`
  margin-top: ${spacing4};
`;
