import styled from 'styled-components';
import {
  neutral10,
  neutral60,
  spacing3,
  spacing4,
  spacing5,
  pxToRem,
  sRadius,
} from '@leaftrade/leaftrade-component-library';

export const CartPreviewLineItemWrapper = styled.div`
  padding: ${spacing3} ${spacing4} ${spacing3} 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const CartPreviewLineItemImage = styled.img`
  width: ${pxToRem(48)};
  height: ${pxToRem(48)};
  border-radius: 4px;
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  margin-right: ${spacing3};
`;

export const CartPreviewLineItemNameWrapper = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  justify-content: space-between;
  padding: ${spacing3} 0;
`;

export const CartPreviewLineItemQuantityPriceWrapper = styled.div`
  display: flex;
  min-width: ${pxToRem(115)};
  justify-content: space-between;
  margin-left: ${spacing4};
  padding: ${spacing3} 0;
`;

export const CartPreviewFooterWrapper = styled.div`
  display: flex;
`;

export const CartPreviewFooterTotal = styled.div`
  background-color: ${neutral10.hex};
  border-radius: ${sRadius};
  display: flex;
  justify-content: flex-end;
  gap: ${spacing4};
  padding: ${spacing4};
  margin-left: auto;
`;

export const CartPreviewEmptyWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: ${spacing5};
  & svg {
    width: ${pxToRem(125)};
    height: ${pxToRem(125)};
    fill: ${neutral60.hex};
  }
`;
