import React from 'react';
import PropTypes from 'prop-types';
import $ from 'jquery';

const NotificationSelect = ({ name, onChange, options }) => (
  <select id={name} name={name} className="selectpicker" data-width="100%" onChange={onChange}>
    {options.map((i, ind) =>
      <option key={'select_'.concat(i[0])} value={i[0]}>{i[1]}</option>
    )}
  </select>
);

NotificationSelect.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.array.isRequired
};

export default NotificationSelect;
