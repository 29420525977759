import {
  interaction60,
  maxMediaQueryTablet,
  minMediaQueryTablet,
  pxToRem,
  spacing6,
} from '@leaftrade/leaftrade-component-library';
import styled from 'styled-components';

export const LandingPageSponsoredWrapper = styled.div`
  padding: 0 ${pxToRem(48)};
  ${maxMediaQueryTablet} {
    padding: 0 ${spacing6};
  }
  & .carousel-items-wrapper {
    overflow: hidden;
    ${minMediaQueryTablet} {
      mask: linear-gradient(
          to ${({ isEnd }) => (isEnd ? 'left' : 'right')},
          rgba(0, 0, 0, 1) 0,
          rgba(0, 0, 0, 1) 85%,
          rgba(0, 0, 0, 0) 100%,
          rgba(0, 0, 0, 0) 0
        )
        100% 50% / 100% 100% repeat-x;
    }
  }
  & .carousel-right-chevron-wrapper {
    right: -${pxToRem(10)};
    ${maxMediaQueryTablet} {
      right: -${pxToRem(25)};
    }
  }
  & .carousel-left-chevron-wrapper {
    left: -${pxToRem(10)};
    ${maxMediaQueryTablet} {
      right: -${pxToRem(25)};
    }
  }
  & .carousel-right-chevron-wrapper,
  & .carousel-left-chevron-wrapper {
    width: ${pxToRem(48)};
    height: ${pxToRem(48)};
    border-radius: 50px;
    top: 0;
    bottom: 0;
    margin: auto 0;
    & svg {
      width: ${pxToRem(24)};
      height: ${pxToRem(24)};
      fill: ${interaction60.hex};
    }
  }
`;

export const LandingPageSponsoredItem = styled.div`
  height: ${pxToRem(175)};
  display: flex;
  justify-content: center;
  align-items: center;
  a,
  img {
    display: inline-block;
    height: 100%;
    border-radius: 4px;
    object-fit: contain;
  }
`;
