import {
  Typography,
  boldBodySmallTypography,
  pxToRem,
  spacing6,
} from '@leaftrade/leaftrade-component-library';
import queryString from 'query-string';
import React, { useEffect, useState } from 'react';
import { useInView } from 'react-intersection-observer';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import NoResults from '../../components/NoResults';
import PageLoader from '../../../shared/components/PageLoader';
import PageWrapper from '../../../shared/components/PageWrapper';
import ProductCard from '../../components/ProductCard';
import ProductCardBatchQueues from '../../components/ProductCardBatchQueues';
import SellerHeader from '../../components/SellerHeader';
import { STOREFRONT_MODE } from '../../constants';
import { useStorefrontState } from '../../../hooks/useReduxState';
import { getSellerStorefrontData } from '../../../store/actions/storefrontActions';
import {
  ListViewHeader,
  LoadMoreWrapper,
  ProductsWrapper,
} from './Seller.styles';
import { getParamFromMatchPatch } from '../../../utils';

const Seller = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const { ref, inView } = useInView();
  const { hasLimitedAccess, sellerPageData, shopper, vendor, vendorPreview } =
    useStorefrontState();
  const {
    hasAdditionalProduct,
    isLoadingSellerNavData,
    isLoadingSellerStorefrontData,
    products,
  } = sellerPageData;
  const { settings, slug } = vendor;
  const { currentLocation } = shopper;
  const { batchQueuesEnabled, isGridView, settingsDeprecated } = settings;
  const { storefront_mode: storefrontMode } = settingsDeprecated || {};

  const [pageNumber, setPageNumber] = useState(0);

  const queryParamsParsed = queryString.parse(location.search);

  const handleClearQueryParams = () => {
    history.push({
      pathname: location.pathname,
    });
    dispatch(
      getSellerStorefrontData({
        slug,
      })
    );
  };

  const vendorPreviewPathMatch = getParamFromMatchPatch({
    location,
    path: `/storefront/sellers/vendor-preview/:slug/select-customer-location`,
    paramKey: 'slug',
  });

  useEffect(() => {
    if ((slug && currentLocation?.id) || (slug && hasLimitedAccess)) {
      dispatch(
        getSellerStorefrontData({
          dispensaryId: vendorPreview?.location?.id,
          slug,
          queryString: queryString.stringify(queryParamsParsed),
        })
      );
    }
  }, [slug, currentLocation?.id, hasLimitedAccess]);

  useEffect(() => {
    setPageNumber(0);
  }, [location.search]);

  useEffect(() => {
    if (inView && hasAdditionalProduct) {
      queryParamsParsed.page_num = pageNumber + 1;

      dispatch(
        getSellerStorefrontData({
          dispensaryId: vendorPreview?.location?.id,
          isLoadingMore: true,
          slug,
          queryString: queryString.stringify(queryParamsParsed),
        })
      );

      setPageNumber(pageNumber + 1);
    }
  }, [inView]);

  useEffect(() => {
    if (
      (!vendorPreview.customer || !vendorPreview.location) &&
      vendorPreviewPathMatch &&
      slug
    ) {
      history.push(
        `/storefront/sellers/vendor-preview/${slug}/select-customer-location`
      );
    }
  }, [vendorPreview.customer, vendorPreview.location, slug]);

  return (
    <>
      {isLoadingSellerNavData ? (
        <PageLoader />
      ) : (
        <>
          <SellerHeader />
          {isLoadingSellerNavData || isLoadingSellerStorefrontData ? (
            <PageLoader />
          ) : (
            <>
              <PageWrapper sidePadding='small'>
                {!isGridView && (
                  <ListViewHeader>
                    <Typography
                      bodyStyle={boldBodySmallTypography}
                      sx={{
                        marginRight: 'auto',
                        marginLeft: pxToRem(135),
                      }}
                    >
                      Product
                    </Typography>
                    <Typography
                      bodyStyle={boldBodySmallTypography}
                      sx={{
                        marginRight: spacing6,
                        width: pxToRem(125),
                      }}
                    >
                      Cannabinoids
                    </Typography>
                    <Typography
                      bodyStyle={boldBodySmallTypography}
                      sx={{
                        width: pxToRem(95),
                      }}
                    >
                      Size
                    </Typography>
                    <Typography
                      align='right'
                      bodyStyle={boldBodySmallTypography}
                      sx={{
                        marginRight: spacing6,
                        width: pxToRem(95),
                      }}
                    >
                      Price
                    </Typography>
                    {storefrontMode !== STOREFRONT_MODE.READ_ONLY_MENU && (
                      <Typography
                        bodyStyle={boldBodySmallTypography}
                        sx={{
                          marginRight: pxToRem(110),
                        }}
                      >
                        Quantity
                      </Typography>
                    )}
                  </ListViewHeader>
                )}
                <ProductsWrapper>
                  {products?.length ? (
                    products.map((product) =>
                      batchQueuesEnabled ? (
                        <ProductCardBatchQueues
                          key={product.id}
                          product={product}
                          slug={slug}
                        />
                      ) : (
                        <ProductCard
                          key={product.id}
                          product={product}
                          slug={slug}
                        />
                      )
                    )
                  ) : (
                    <NoResults
                      onClear={handleClearQueryParams}
                      resultsType='products'
                    />
                  )}
                </ProductsWrapper>
              </PageWrapper>
              {hasAdditionalProduct && (
                <LoadMoreWrapper ref={ref}>
                  <PageLoader message='Fetching more results' />
                </LoadMoreWrapper>
              )}
            </>
          )}
        </>
      )}
    </>
  );
};

export default Seller;
