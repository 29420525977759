import {
  Typography,
  bodySmallTypography,
  boldBodySmallTypography,
} from '@leaftrade/leaftrade-component-library';
import React from 'react';
import { useStorefrontState } from '../../../../hooks/useReduxState';
import ProductDetailsAccordion from '../ProductDetailsAccordion';
import { BrandsWrapper } from './ProductDetailsBrandsAccordion.styles';

const ProductDetailsBrandsAccordion = () => {
  const { productDetails } = useStorefrontState();
  const { brands } = productDetails;

  return (
    <ProductDetailsAccordion
      isOpen
      header={brands.length > 1 ? 'Brands' : 'Brand'}
      id='product-brands'
    >
      {brands.map((brand) => (
        <BrandsWrapper key={brand.id}>
          <Typography bodyStyle={boldBodySmallTypography}>
            {brand.name}
          </Typography>
          <Typography bodyStyle={bodySmallTypography}>
            {brand.description || ''}
          </Typography>
        </BrandsWrapper>
      ))}
    </ProductDetailsAccordion>
  );
};

export default ProductDetailsBrandsAccordion;
