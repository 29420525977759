import React from 'react';
import {
  IconLoadingSpinner,
  seafoam60,
} from '@leaftrade/leaftrade-component-library';

const Loading = () => (
  <div className='loader' data-testid='loader--div'>
    <div>
      <IconLoadingSpinner
        mainLineColor={seafoam60.hex}
        secondaryLineColor='transparent'
        width='128px'
        height='128px'
        lineWidth='16px'
        rotateTiming='1.5s'
      />
    </div>
  </div>
);

export default Loading;
