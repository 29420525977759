import { pxToRem } from '@leaftrade/leaftrade-component-library';
import styled from 'styled-components';

export const IconWrapper = styled.div`
  flex-basis: ${({ isGridView }) => (isGridView ? 'calc(100% / 3)' : 'auto')};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  & svg,
  & img {
    width: ${pxToRem(24)};
    height: ${pxToRem(24)};
  }
`;
