import {
  IconAdd,
  Typography,
  mediumBodyTypography,
  pxToRem,
} from '@leaftrade/leaftrade-component-library';
import React from 'react';
import { useDispatch } from 'react-redux';
import { useStorefrontState } from '../../../hooks/useReduxState';
import { currencyFormatter } from '../../../utils';
import {
  QuickOrderFooterAddItemButton,
  QuickOrderFooterTotals,
  QuickOrderFooterWrapper,
} from './QuickOrderFooter.styles';
import { toggleQuickOrderAddModal } from '../../../store/actions/storefrontActions';

const QuickOrderFooter = () => {
  const dispatch = useDispatch();
  const { quickOrderData } = useStorefrontState();
  const { totalItems, totalPrice } = quickOrderData;

  const handleAddItemClick = () => {
    dispatch(toggleQuickOrderAddModal({ isOpen: true }));
  };

  return (
    <QuickOrderFooterWrapper data-testid='quick-order-footer--component--div'>
      <QuickOrderFooterAddItemButton onClick={handleAddItemClick}>
        <IconAdd />
        <Typography bodyStyle={mediumBodyTypography}>Add Item</Typography>
      </QuickOrderFooterAddItemButton>
      <QuickOrderFooterTotals>
        <Typography
          align='center'
          bodyStyle={mediumBodyTypography}
          sx={{ minWidth: pxToRem(125) }}
        >
          {totalItems}
        </Typography>
        <Typography
          bodyStyle={mediumBodyTypography}
          sx={{ minWidth: pxToRem(160) }}
        >
          {currencyFormatter.format(totalPrice || '0')}
        </Typography>
      </QuickOrderFooterTotals>
    </QuickOrderFooterWrapper>
  );
};

export default QuickOrderFooter;
