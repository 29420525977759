import { pxToRem } from '@leaftrade/leaftrade-component-library';
import styled from 'styled-components';

export const CartLineItemColumnQuantityWrapper = styled.div`
  width: 15%;
  display: flex;
  align-items: center;
  justify-content: center;
  & > svg {
    width: ${pxToRem(42)};
    height: ${pxToRem(42)};
  }
`;
