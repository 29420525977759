import {
  Link,
  Typography,
  bodySmallTypography,
  handleImgSrcError,
  interaction60,
  mediumBodyTypography,
  neutral60,
  spacing1,
  spacing2,
  spacing4,
} from '@leaftrade/leaftrade-component-library';
import React from 'react';
import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import { useStorefrontState } from '../../../../hooks/useReduxState';
import { CartLineItemColumnItemWrapper } from './CartLineItemColumnItem.styles';

const CartLineItemColumnItem = ({ item }) => {
  const { imageUrl, productId, productName, variants } = item;
  const { vendor } = useStorefrontState();
  const { slug } = vendor;

  return (
    <CartLineItemColumnItemWrapper data-testid='cart-line-item-column-item--component--div'>
      <img
        data-testid='cart-line-item-column-item--item-image--img'
        src={imageUrl}
        onError={handleImgSrcError}
      />
      <div>
        <Link
          bodyStyle={mediumBodyTypography}
          component={RouterLink}
          sx={{
            color: interaction60.hex,
          }}
          testingId='cart-line-item-column-item--item-name--link'
          to={`/storefront/sellers/${slug}/${productId}?variant=${variants[0].variantId}`}
          underline='hover'
        >
          {productName}
        </Link>
        <Typography
          bodyStyle={bodySmallTypography}
          sx={{
            color: neutral60.hex,
            margin: `${spacing1} 0`,
          }}
          testingId='cart-line-item-column-item--item-variant--typography'
        >
          {variants[0].displayName}{' '}
          {variants[0].batch_ref ? ` - Batch #${variants[0].batch_ref}` : ''}
        </Typography>
        <Typography
          bodyStyle={bodySmallTypography}
          sx={{
            color: neutral60.hex,
            '& span': {
              '&:nth-child(odd)': {
                marginRight: spacing2,
                fontWeight: 700,
              },
              '&:nth-child(even)': {
                marginRight: spacing4,
              },
            },
          }}
          testingId='cart-line-item-column-item--item-cannabinoids--typography'
        >
          {variants[0]?.cannabinoids?.map((cannabinoid) => (
            <React.Fragment key={cannabinoid.name}>
              <span>{cannabinoid.name.toUpperCase()}</span>
              <span>{cannabinoid.value}</span>
            </React.Fragment>
          ))}
        </Typography>
      </div>
    </CartLineItemColumnItemWrapper>
  );
};

CartLineItemColumnItem.propTypes = {
  item: PropTypes.shape({
    imageUrl: PropTypes.string,
    productId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    productName: PropTypes.string,
    variants: PropTypes.arrayOf(
      PropTypes.shape({
        batch_ref: PropTypes.string,
        cannabinoidProfile: PropTypes.arrayOf(
          PropTypes.shape({
            name: PropTypes.string,
            value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
          })
        ),
        cannabinoids: PropTypes.arrayOf(
          PropTypes.shape({
            name: PropTypes.string,
            value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
          })
        ),
        displayName: PropTypes.string,
        subTotal: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        unitPrice: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        variantId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      })
    ),
  }),
};

export default CartLineItemColumnItem;
