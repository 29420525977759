import {
  Link,
  Typography,
  bodySmallTypography,
  interaction60,
  logoIconColor,
  mediumBodyTypography,
  neutral60,
} from '@leaftrade/leaftrade-component-library';
import PropTypes from 'prop-types';
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useStorefrontState } from '../../../../../hooks/useReduxState';
import {
  QuickOrderTableItemInfoData,
  QuickOrderTableItemInfoImage,
  QuickOrderTableItemInfoWrapper,
} from '../../QuickOrderTableItem/QuickOrderTableItemInfo/QuickOrderTableItemInfo.styles';

const QuickOrderTableItemInfoBatchQueues = ({ item }) => {
  const { vendor } = useStorefrontState();
  const { settings, slug } = vendor;
  const { displayLiveInventoryCount } = settings;

  const {
    cartItemId,
    displayName,
    imageUrl,
    inventoryLiveCount,
    productId,
    productName,
  } = item;

  return (
    <QuickOrderTableItemInfoWrapper data-testid='quick-order-table-item-info-batch-queues--component--div'>
      <QuickOrderTableItemInfoImage src={imageUrl || logoIconColor} />
      <div>
        <Link
          bodyStyle={mediumBodyTypography}
          component={RouterLink}
          sx={{
            color: interaction60.hex,
          }}
          title={productName}
          to={`/storefront/sellers/${slug}/${productId}?variant=${cartItemId}`}
          underline='hover'
        >
          {productName}
        </Link>
        <QuickOrderTableItemInfoData>
          <Typography
            bodyStyle={bodySmallTypography}
            sx={{ color: neutral60.hex }}
          >
            {displayName}
          </Typography>
        </QuickOrderTableItemInfoData>
        {displayLiveInventoryCount && (
          <Typography
            bodyStyle={bodySmallTypography}
            sx={{ color: neutral60.hex }}
          >
            Current Inventory:{' '}
            {inventoryLiveCount >= 0 ? inventoryLiveCount.toLocaleString() : 0}
          </Typography>
        )}
      </div>
    </QuickOrderTableItemInfoWrapper>
  );
};

QuickOrderTableItemInfoBatchQueues.propTypes = {
  item: PropTypes.shape({
    cartItemId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    displayName: PropTypes.string,
    imageUrl: PropTypes.string,
    inventoryLiveCount: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]),
    productId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    productName: PropTypes.string,
  }).isRequired,
};

export default QuickOrderTableItemInfoBatchQueues;
