import {
  elevation1,
  interaction60,
  maxMediaQueryDesktop,
  maxMediaQueryTablet,
  neutral0,
  pxToRem,
  spacing4,
  spacing5,
  spacing8,
} from '@leaftrade/leaftrade-component-library';
import styled from 'styled-components';
import { pageFiltersZIndex } from '../../../../shared/styles/zIndex';

export const PageHeadersFiltersContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  grid-gap: ${spacing5};
  background-color: ${neutral0.hex};
  position: sticky;
  top: ${pxToRem(56)};
  padding: ${spacing4} ${pxToRem(135)};
  z-index: ${pageFiltersZIndex};
  ${elevation1()}
  ${maxMediaQueryDesktop} {
    top: ${pxToRem(0)};
    padding: ${spacing4} ${pxToRem(70)};
  }
  ${maxMediaQueryTablet} {
    padding: ${spacing4} ${spacing8};
  }
`;

export const PageHeadersFilterContainer = styled.div`
  position: relative;
  flex: 1 1 auto;
  max-width: ${pxToRem(250)};
  & > svg {
    width: ${spacing4};
    height: ${spacing4};
    fill: ${interaction60.hex};
    position: absolute;
    top: ${pxToRem(-6)};
    right: ${pxToRem(-6)};
  }
`;
