import {
  neutral10,
  pxToRem,
  spacing3,
} from '@leaftrade/leaftrade-component-library';
import styled from 'styled-components';

export const CannabinoidsWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: ${({ asChip, isGridView }) =>
    asChip || isGridView ? 'row' : 'column'};
`;

export const StyledCannabinoid = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: ${({ isGridView }) => (isGridView ? 'nowrap' : 'wrap')};
  flex-direction: ${({ asChip, isGridView }) =>
    isGridView && !asChip ? 'row' : 'column'};
  ${({ asChip }) =>
    asChip &&
    `
    height: ${pxToRem(70)};
    width: ${pxToRem(70)};
    border-radius: 100%;
    justify-content: center;
    background-color: ${neutral10.hex};
    text-align: center;
  `}
`;

export const StyledCannabinoidTHC = styled(StyledCannabinoid)`
  margin-right: ${({ isGridView }) => (isGridView ? spacing3 : 'unset')};
  padding-right: ${({ isGridView, asChip }) =>
    isGridView && !asChip ? spacing3 : 'unset'};
  ${({ hasCannabinoidB, isGridView }) =>
    hasCannabinoidB &&
    `border-${isGridView ? 'right' : 'bottom'}: 1px solid ${neutral10.hex};`}
`;
