import {
  bodyExtraSmallTypography,
  Typography,
} from '@leaftrade/leaftrade-component-library';
import PropTypes from 'prop-types';
import React from 'react';
import { StyledProductChip } from './ProductChip.styles';

const ProductChip = ({ size, text, type }) => (
  <StyledProductChip
    data-testid='product-chip--component--div'
    size={size}
    type={type}
  >
    <Typography align='center' bodyStyle={bodyExtraSmallTypography} noWrap>
      {text}
    </Typography>
  </StyledProductChip>
);

ProductChip.defaultProps = {
  size: 'default',
};

ProductChip.propTypes = {
  size: PropTypes.oneOf(['default', 'large']),
  text: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
};

export default ProductChip;
