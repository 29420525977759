import { pxToRem } from '@leaftrade/leaftrade-component-library';
import styled from 'styled-components';

export const CartLineItemColumnDeleteWrapper = styled.div`
  width: ${pxToRem(30)};
  display: flex;
  align-items: center;
  & button {
    width: ${pxToRem(30)};
    height: ${pxToRem(30)};
    padding: 0;
    margin: 0;
    border: none;
    background: none;
    cursor: pointer;
  }
  & svg {
    width: ${pxToRem(30)};
    height: ${pxToRem(30)};
  }
`;
