import {
  pxToRem,
  spacing5,
  spacing7,
} from '@leaftrade/leaftrade-component-library';
import styled from 'styled-components';

export const CategoryIcons = styled.div`
  display: flex;
  flex-wrap: wrap;
  height: ${({ showMore }) => (showMore ? 'auto' : pxToRem(40))};
  overflow: hidden;
  margin: ${({ isGridView }) => (isGridView ? '0' : `0 ${spacing5}`)};
  ${({ isGridView }) =>
    !isGridView &&
    `
    flex: 1;
    gap: ${spacing7};
  `}
`;
