import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useInView } from 'react-intersection-observer';
import { Carousel, CarouselIndicators, CarouselItem } from 'reactstrap';
import {
  sponsoredAdClickEvent,
  sponsoredAdViewEvent,
} from '../../../analytics/landing';
import { LandingPageCarouselWrapper } from './LandingPageCarousel.styles';

const LandingPageCarousel = ({ sponsors }) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [animating, setAnimating] = useState(false);
  const [sponsoredAdViews, setSponsoredAdViews] = useState([]);

  const next = () => {
    if (animating) return;
    const nextIndex = activeIndex === sponsors.length - 1 ? 0 : activeIndex + 1;
    setActiveIndex(nextIndex);
  };

  const previous = () => {
    if (animating) return;
    const nextIndex = activeIndex === 0 ? sponsors.length - 1 : activeIndex - 1;
    setActiveIndex(nextIndex);
  };

  const goToIndex = (newIndex) => {
    if (animating) return;
    setActiveIndex(newIndex);
  };

  const sendSponsoredAdView = (sponsorship) => {
    if (sponsoredAdViews.indexOf(sponsorship) === -1) {
      sponsoredAdViewEvent({
        sponsoredAd: sponsorship,
      });
      setSponsoredAdViews([...sponsoredAdViews].concat(sponsorship));
    }
  };

  const slides = sponsors.map((sponsorship) => {
    const { ref, inView } = useInView();
    if (inView) {
      sendSponsoredAdView(sponsorship);
    }

    return (
      <CarouselItem
        onClick={() => sponsoredAdClickEvent({ sponsoredAd: sponsorship })}
        onExiting={() => setAnimating(true)}
        onExited={() => setAnimating(false)}
        key={`${sponsorship.image}-${sponsorship.id}`}
      >
        <img
          alt={sponsorship.altText}
          data-testid={`landing-page-carousel${
            sponsorship.target_url ? '-with-link' : ''
          }--image`}
          onClick={async () => {
            await sponsoredAdClickEvent({ sponsoredAd: sponsorship });
            if (sponsorship.target_url) {
              window.open(sponsorship.target_url, '_blank');
            }
          }}
          ref={ref}
          src={sponsorship.image}
          style={{
            cursor: sponsorship.target_url ? 'pointer' : 'default',
          }}
        />
      </CarouselItem>
    );
  });

  return (
    <LandingPageCarouselWrapper data-testid='landing-page-carousel--component--div'>
      <Carousel activeIndex={activeIndex} next={next} previous={previous}>
        <CarouselIndicators
          items={sponsors}
          activeIndex={activeIndex}
          onClickHandler={goToIndex}
        />
        {slides}
      </Carousel>
    </LandingPageCarouselWrapper>
  );
};

LandingPageCarousel.propTypes = {
  sponsors: PropTypes.arrayOf(
    PropTypes.shape({
      target_url: PropTypes.string,
      id: PropTypes.number,
      image: PropTypes.string,
      name: PropTypes.string,
    })
  ).isRequired,
};

export default LandingPageCarousel;
