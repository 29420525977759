import {
  Button,
  IconCart,
  IconLoadingSpinner,
  Link,
  pxToRem,
} from '@leaftrade/leaftrade-component-library';
import { Link as RouterLink, useHistory } from 'react-router-dom';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useStorefrontState } from '../../../hooks/useReduxState';
import { CartActionsWrapper } from './CartActions.styles';
import {
  getCartData,
  toggleCartClearModal,
} from '../../../store/actions/storefrontActions';

const CartActions = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { cart, vendor } = useStorefrontState();
  const { selectedCart } = cart;
  const { token } = selectedCart;
  const { slug } = vendor;

  const [isProcessingCheckout, setIsProcessingCheckout] = useState(false);

  const handleClearCart = () => {
    dispatch(
      toggleCartClearModal({
        isOpen: true,
      })
    );
  };

  const handleCheckout = async () => {
    setIsProcessingCheckout(true);
    await dispatch(
      getCartData({
        getNotices: true,
        onSuccess: async (responseData) => {
          const {
            cart_notices: cartNotices,
            cart_item_notices: cartItemNotices,
          } = responseData.cartNotices;

          const hasError = (notices) =>
            notices?.some((notice) => notice.level === 'error');

          if (hasError(cartNotices) || hasError(cartItemNotices)) {
            window.scrollTo(0, 0);
          } else {
            history.push(`/storefront/sellers/${slug}/checkout/`);
          }
          setIsProcessingCheckout(false);
        },
        slug,
        token,
      })
    );
  };

  return (
    <CartActionsWrapper data-testid='cart-actions--component--div'>
      <Button
        onClick={handleClearCart}
        size='large'
        testingId='cart-actions--clear-cart--button'
        variant='secondary'
      >
        Clear Cart
      </Button>
      <Link
        asButton
        buttonSize='large'
        buttonVariant='secondary'
        testingId='cart-actions--keep-shopping--link'
        to={`/storefront/sellers/${slug}`}
        component={RouterLink}
      >
        Keep Shopping
      </Link>
      <Button
        disabled={isProcessingCheckout}
        onClick={handleCheckout}
        size='large'
        startIcon={<IconCart />}
        sx={{
          justifyContent: 'center',
          marginLeft: 'auto',
          width: pxToRem(240),
          '& span': {
            flex: 0,
          },
        }}
        testingId='cart-actions--checkout--button'
      >
        {isProcessingCheckout ? <IconLoadingSpinner /> : 'Checkout'}
      </Button>
    </CartActionsWrapper>
  );
};

export default CartActions;
