import {
  elevation1,
  maxMediaQueryMobile,
  neutral10,
  pxToRem,
  spacing3,
  spacing4,
  spacing5,
  spacing6,
} from '@leaftrade/leaftrade-component-library';
import styled from 'styled-components';

export const StyledProductCard = styled.div`
  display: flex;
  flex-direction: ${({ isGridView }) => (isGridView ? 'column' : 'row')};
  position: relative;
  flex: ${({ isGridView }) =>
    isGridView ? `0 1 ${pxToRem(275)}` : '1 1 100%'};
  border-radius: 10px;
  padding: ${({ isGridView }) =>
    isGridView ? `${spacing4} ${spacing5}` : spacing5};
  border: 1px solid ${neutral10.hex};
  ${elevation1()}
  ${({ isGridView }) =>
    !isGridView
      ? `
    min-height: ${pxToRem(160)};
    overflow: scroll unset;
  `
      : ''};
  ${maxMediaQueryMobile} {
    flex-direction: column;
  }
`;

export const ProductChipsWrapper = styled.div`
  display: flex;
  height: ${spacing6};
  margin-bottom: ${spacing4};
  gap: ${spacing3};
`;

export const VariantInfoWrapper = styled.div`
  height: ${({ isGridView }) => (isGridView ? pxToRem(66) : 'unset')};
  margin-bottom: ${({ isGridView }) => (isGridView ? spacing3 : 'unset')};
  display: flex;
  flex-direction: ${({ isGridView }) => (isGridView ? 'column' : 'row')};
`;

export const ProductInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  flex: 1;
  width: ${({ isGridView }) => (isGridView ? '100%' : 0)};
  margin-right: ${({ isGridView }) => (isGridView ? 0 : spacing6)};
  min-width: ${pxToRem(250)};
`;

export const ProductPriceWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-width: ${({ isGridView }) => (isGridView ? 'unset' : pxToRem(95))};
  text-align: ${({ isGridView }) => (isGridView ? 'left' : 'right')};
  margin-right: ${({ isGridView }) => (isGridView ? 'unset' : spacing6)};
`;

export const ProductQuantityWrapper = styled.div`
  display: flex;
  flex-direction: ${({ isGridView }) =>
    isGridView ? 'column' : 'column-reverse'};
  justify-content: center;
  min-width: ${({ isGridView }) => (isGridView ? 'unset' : pxToRem(145))};
`;
