import camelize from 'camelize';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import {
  newToastNotification,
  ToastType,
} from '../../dashboard/components/ToastNotifications/toasts';
import joinArray from '../../utils/joinArray';
import { getCookie } from '../../dashboard/ui';
import { camelToSnake } from '../../dashboard/utils';

const baseQuery = fetchBaseQuery({
  baseUrl: '/dashboard/',
  prepareHeaders: (headers) => {
    const csrfToken = getCookie('csrftoken');
    if (csrfToken) {
      headers.set('X-CSRFToken', csrfToken);
    }
    return headers;
  },
});

const baseQueryWithToast = async (args, api, extraOptions) => {
  const result = await baseQuery(args, api, extraOptions);

  if (result.error) {
    newToastNotification({
      body: 'An error occurred',
      toastType: ToastType.ERROR,
    });
  }
  return result;
};

const serializeFilters = (filters, serializationMap) => {
  const serialized = {};
  for (const [key, value] of Object.entries(filters)) {
    if (Array.isArray(value)) {
      if (serializationMap[key] === 'commaDelimited') {
        serialized[key] = `${key}=${encodeURIComponent(value.join(','))}`;
      } else {
        serialized[key] = value
          .map((v) => `${key}=${encodeURIComponent(v)}`)
          .join('&');
      }
    } else {
      serialized[key] = `${key}=${encodeURIComponent(value)}`;
    }
  }
  return Object.values(serialized).join('&');
};
const serializeOrdering = (ordering) => {
  if (ordering[0]) {
    return `${ordering[0].desc ? '-' : ''}${ordering[0].id}`;
  }
};

export const vendorApi = createApi({
  reducerPath: 'vendor',
  baseQuery: baseQueryWithToast,
  endpoints: (builder) => ({
    getBrands: builder.query({
      query: ({ vendorId }) => `api/vendor/${vendorId}/brands/`,
      transformResponse: (response) => response.brands,
    }),

    getCategories: builder.query({
      query: ({ vendorId }) => `api/vendor/${vendorId}/categories/`,
      transformResponse: (response) => response.categories,
    }),

    getStockLocations: builder.query({
      query: ({ vendorId }) => `vendor/${vendorId}/stock-locations/`,
      transformResponse: (response) => response.stock_locations,
    }),

    getProductAttributes: builder.query({
      query: ({ vendorId }) => ({
        url: `api/vendor/${vendorId}/products/product-attributes`,
        params: {
          slug: 'sample',
        },
      }),
      transformResponse: (response) => {
        if (response.results && response.results.length > 0) {
          return response.results[0].values;
        }
        return [];
      },
    }),

    getInventoryPrices: builder.query({
      query: ({ vendorId, page, pageSize, ordering, filters }) => ({
        url: `vendor/${vendorId}/products/inventory-prices/?${serializeFilters(
          filters,
          { sample_type: 'commaDelimited' }
        )}`,
        method: 'GET',
        params: {
          page,
          page_size: pageSize,
          ordering: serializeOrdering(ordering),
        },
      }),
    }),

    getLogEntries: builder.query({
      query: ({ filters, page, pageSize, vendorId }) => ({
        url: `api/vendor/${vendorId}/log-entries/?${serializeFilters(
          camelToSnake(filters),
        )}`,
        method: 'GET',
        params: {
          page,
          page_size: pageSize,
        },
      }),
      transformResponse: camelize,
    }),

    saveInventory: builder.mutation({
      query: ({ vendorId, changes }) => ({
        url: `vendor/${vendorId}/products/inventory-prices/multiple_update/`,
        method: 'PATCH',
        body: {
          variants: Object.keys(changes).map((key) => ({
            variant_pk: key,
            ...changes[key],
          })),
        },
      }),
      onQueryStarted: async (_, { queryFulfilled }) => {
        try {
          const { data } = await queryFulfilled;
          const { errors = [] } = data;

          if (errors.length) {
            newToastNotification({
              body: joinArray(errors),
              toastType: ToastType.ERROR,
            });
          } else {
            newToastNotification({
              body: 'Inventory is successfully updated!',
              toastType: ToastType.SUCCESS,
            });
          }
        } catch {
          // error handled in baseQuery
        }
      },
    }),
  }),
});

export const {
  useLazyGetBrandsQuery,
  useLazyGetCategoriesQuery,
  useLazyGetStockLocationsQuery,
  useLazyGetProductAttributesQuery,
  useGetInventoryPricesQuery,
  useGetLogEntriesQuery,
  useSaveInventoryMutation,
} = vendorApi;
