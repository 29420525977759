import { useOnClickOutside } from '@leaftrade/leaftrade-component-library';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useStorefrontState } from '../../../../hooks/useReduxState';
import { HeaderNavSection, HeaderNavWrapper } from './HeaderNav.styles';
import HeaderNavCTA from './HeaderNavCTA';
import HeaderNavSectionCard from './HeaderNavSectionCard';
import {
  addTimedBanner,
  setVendorSlug,
  setNavigationSections,
} from '../../../../store/actions/storefrontActions';

const HeaderNav = () => {
  const activeSectionRef = useRef();
  const dispatch = useDispatch();
  const { cart, navigation, vendor } = useStorefrontState();
  const { selectedCart } = cart;
  const { brands, vendors, sections } = navigation;

  const [activeSection, setActiveSection] = useState(null);
  const [activeCategory, setActiveCategory] = useState(null);

  const handleLinkClick = ({ slug }) => {
    setActiveSection(null);
    setActiveCategory(null);
    if (selectedCart.quantity && selectedCart.quantity > 0) {
      dispatch(
        addTimedBanner({
          id: `${vendor.name}-${selectedCart?.locationName}-cart-saved`,
          message: `Your cart on ${vendor.name} for ${selectedCart?.locationName} was saved.`,
          severity: 'success',
        })
      );
    }
    if (slug) dispatch(setVendorSlug(slug));
  };

  const handleSectionClick = (section) => {
    setActiveCategory(null);
    if (activeSection?.name === section.name) {
      setActiveSection(null);
    } else {
      setActiveSection(section);
    }
  };

  const sortCategories = (a, b) => {
    if (a.name === 'cannabis') return -1;
    if (b.name === 'cannabis') return 1;
    if (a.name === 'ancillary') return 1;
    if (b.name === 'ancillary') return -1;
    return 0;
  };

  useEffect(() => {
    const navSections = [
      {
        name: 'Sellers',
        shopAll: '/storefront/sellers?product_types_filters=1',
        categories: Object.keys(vendors)
          .map((key) => ({
            name: key,
            // eslint-disable-next-line no-nested-ternary
            id: key === 'cannabis' ? 1 : 2,
            children: vendors[key].map((vendor) => ({
              id: vendor.id,
              name: vendor.name,
              slug: vendor.slug,
            })),
          }))
          .sort((a, b) => sortCategories(a, b)),
      },
      {
        name: 'Brands',
        categories: Object.keys(brands)
          .map((key) => ({
            name: key,
            children: brands[key].map((brand) => ({
              ids: brand.ids,
              name: brand.name,
              slugs: brand.slugs,
            })),
          }))
          .sort((a, b) => sortCategories(a, b)),
      },
    ];
    dispatch(setNavigationSections(navSections));
  }, [brands, vendors, dispatch]);

  useOnClickOutside(activeSectionRef, () => setActiveSection(null));

  return (
    <HeaderNavWrapper
      data-testid='header--nav--component--div'
      ref={activeSectionRef}
    >
      {sections?.map((section) => (
        <HeaderNavSection key={section.name}>
          <HeaderNavCTA
            activeSection={activeSection}
            handleSectionClick={handleSectionClick}
            section={section}
          />
          {activeSection?.name === section.name && (
            <HeaderNavSectionCard
              activeSection={activeSection}
              activeCategory={activeCategory}
              handleLinkClick={handleLinkClick}
              setActiveCategory={setActiveCategory}
            />
          )}
        </HeaderNavSection>
      ))}
    </HeaderNavWrapper>
  );
};

export default HeaderNav;
