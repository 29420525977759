import $ from 'jquery';
import 'bootstrap-select';

$.fn.selectpicker.Constructor.BootstrapVersion = '4';

// Intention is to disable the multi-select only on certain routes because there's a racing condition between the bootstrap
// e.g. /dashboard/vendor/22/integrations/metrc/333/review
const disableSelect = () => {
    const { pathname } = new URL(window.location.href);

    const isMetrcIntegration = (match) => match && match.length > 4 ? match[3] === 'integrations' && match[4] === 'metrc' : false;

    const isProductIssues = (match) => match && match.length > 5 ? match[3] === 'products' && match[4] === 'issues' : false;

    return isMetrcIntegration(pathname.match(/[^/?]*[^/?]/g))
        || isProductIssues(pathname.match(/[^/?]*[^/?]/g))
        || false;
};

export default function initBootstrapSelect(root) {
    if (disableSelect()) return;
    console.log(`%c multi-select enabled`, 'background: #222; color: #ffff00');
    root.find('select').filter((i, e) =>
        $(e).parents().filter('.form-placeholder').length === 0
    ).selectpicker({'liveSearch': true, 'actionsBox': true});
}
