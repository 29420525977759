import {
  bodySmallTypography,
  boldBodyLargeTypography,
  Button,
  IconLoadingSpinner,
  Modal,
  Typography,
} from '@leaftrade/leaftrade-component-library';
import React from 'react';
import { useDispatch } from 'react-redux';
import { useStorefrontState } from '../../../../hooks/useReduxState';
import {
  deleteCartItem,
  toggleCartDeleteModal,
} from '../../../../store/actions/storefrontActions';

const DeleteCartItemModal = () => {
  const dispatch = useDispatch();
  const { cart, vendor } = useStorefrontState();
  const { deleteItemModal, selectedCart } = cart;
  const { settings, slug } = vendor;
  const { batchQueuesEnabled } = settings;

  const deleteCartItemModalJsx = {
    actions: !deleteItemModal.isRemovingItem ? (
      <>
        <Button
          onClick={() => {
            dispatch(
              toggleCartDeleteModal({
                isOpen: false,
                item: undefined,
              })
            );
          }}
          testingId='confirmationModal__cancelButton'
          variant='secondary'
        >
          Cancel
        </Button>
        <Button
          onClick={() =>
            dispatch(
              deleteCartItem({
                batchQueuesEnabled,
                item: deleteItemModal.item,
                locationIds: [selectedCart?.locationId],
                slug,
              })
            )
          }
          testingId='confirmationModal__confirmButton'
          variant='destructive'
        >
          Remove From Cart
        </Button>
      </>
    ) : (
      <Button variant='destructive'>
        <IconLoadingSpinner />
      </Button>
    ),
    header: (
      <Typography bodyStyle={boldBodyLargeTypography} gutterBottom>
        Remove {deleteItemModal.item?.productName} From Cart?
      </Typography>
    ),
    body: (
      <Typography bodyStyle={bodySmallTypography} gutterBottom>
        Are you sure you want to remove this product from the selected cart? (
        {selectedCart?.locationName} location)
      </Typography>
    ),
  };

  return (
    <Modal
      actions={deleteCartItemModalJsx.actions}
      header={deleteCartItemModalJsx.header}
      hideClose={deleteItemModal.isRemovingItem}
      onClose={() =>
        dispatch(
          toggleCartDeleteModal({
            isOpen: false,
            item: undefined,
          })
        )
      }
      open={deleteItemModal.isOpen}
      testingId='delete-cart-item-modal--component--div'
    >
      {deleteCartItemModalJsx.body}
    </Modal>
  );
};

export default DeleteCartItemModal;
