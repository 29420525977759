import {
  IconInfo,
  Link,
  Typography,
  bodyExtraSmallTypography,
  boldBodyExtraSmallTypography,
} from '@leaftrade/leaftrade-component-library';
import PropTypes from 'prop-types';
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Tooltip } from 'react-tooltip';
import { useStorefrontState } from '../../../../hooks/useReduxState';
import { CannabinoidsTooltipWrapper } from './CannabinoidsTooltip.styles';

const CannabinoidsTooltip = ({
  isProductDetailsPage,
  primaryLabValues,
  productId,
  url,
}) => {
  const { vendor } = useStorefrontState();
  const { isGridView } = vendor.settings;

  const scrollToCannabinoidsSection = () => {
    const element = document.getElementById('cannabinoids');
    const headerOffset = 75;
    const elementPosition = element.getBoundingClientRect().top;
    const offsetPosition = elementPosition + window.scrollY - headerOffset;

    window.scrollTo({
      top: offsetPosition,
      behavior: 'smooth',
    });
  };

  const renderTooltipMessage = () => {
    switch (primaryLabValues) {
      case 'total_cannabinoids':
        return (
          <Typography bodyStyle={bodyExtraSmallTypography} gutterBottom>
            Total Cannabinoid value is sourced from the seller&apos;s provided
            lab results.
          </Typography>
        );
      case 'thca_cbda':
        return (
          <Typography bodyStyle={bodyExtraSmallTypography} gutterBottom>
            THCA and CBDA values are sourced from this seller&apos;s provided
            lab results.
          </Typography>
        );
      case 'thc_cbd':
        return (
          <Typography bodyStyle={bodyExtraSmallTypography} gutterBottom>
            THC and CBD values are sourced from this seller&apos;s provided lab
            results.
          </Typography>
        );
      case 'total_thc_cbd':
      default:
        return (
          <>
            <Typography bodyStyle={boldBodyExtraSmallTypography}>
              Estimated Total THC
            </Typography>
            <Typography bodyStyle={bodyExtraSmallTypography} gutterBottom>
              THC + (0.877*THC-A)
            </Typography>
            <Typography bodyStyle={boldBodyExtraSmallTypography}>
              Estimated Total CBD
            </Typography>
            <Typography bodyStyle={bodyExtraSmallTypography} gutterBottom>
              CBD + (0.877*CBD-A)
            </Typography>
          </>
        );
    }
  };

  return (
    <CannabinoidsTooltipWrapper
      data-testid='cannabinoids-tooltip--component--div'
      isGridView={isGridView}
      isProductDetailsPage={isProductDetailsPage}
    >
      <IconInfo
        data-testid='cannabinoids-tooltip--toggle--svg'
        id={`cannabinoids-tooltip--${productId}`}
      />
      <Tooltip
        anchorSelect={`#cannabinoids-tooltip--${productId}`}
        clickable
        data-testid='cannabinoids-tooltip--tooltip--div'
        place='right'
        style={{ zIndex: 2 }}
      >
        {renderTooltipMessage()}
        {url ? (
          <Link
            bodyStyle={bodyExtraSmallTypography}
            component={RouterLink}
            to={url}
          >
            See more cannabinoid information
          </Link>
        ) : (
          <button onClick={scrollToCannabinoidsSection} type='button'>
            See more cannabinoid information
          </button>
        )}
      </Tooltip>
    </CannabinoidsTooltipWrapper>
  );
};

CannabinoidsTooltip.defaultProps = {
  isProductDetailsPage: false,
  primaryLabValues: 'total_thc_cbd',
  url: '',
};

CannabinoidsTooltip.propTypes = {
  isProductDetailsPage: PropTypes.bool,
  primaryLabValues: PropTypes.string,
  productId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  url: PropTypes.string,
};

export default CannabinoidsTooltip;
