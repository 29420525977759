import { spacing3 } from '@leaftrade/leaftrade-component-library';
import styled from 'styled-components';

export const PageHeaderSearchContainer = styled.div`
  display: flex;
  align-items: center;
  & button {
    margin-left: ${spacing3};
  }
`;
