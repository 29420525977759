import {
  Typography,
  bodySmallTypography,
} from '@leaftrade/leaftrade-component-library';
import React from 'react';
import { useStorefrontState } from '../../../../hooks/useReduxState';
import ProductDetailsAccordion from '../ProductDetailsAccordion';

const ProductDetailsDescriptionAccordion = () => {
  const { productDetails } = useStorefrontState();
  const { description } = productDetails;

  return (
    <ProductDetailsAccordion isOpen header='Description' id='description'>
      <Typography bodyStyle={bodySmallTypography}>{description}</Typography>
    </ProductDetailsAccordion>
  );
};

export default ProductDetailsDescriptionAccordion;
