import {
  interaction60,
  neutral0,
  neutral10,
  pxToRem,
  spacing2,
  spacing3,
  spacing5,
  spacing6,
  spacing7,
} from '@leaftrade/leaftrade-component-library';
import styled from 'styled-components';

export const QuickOrderFooterWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-top: ${spacing7};
`;

export const QuickOrderFooterAddItemButton = styled.button`
  background-color: transparent;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 0;
  margin: 0;
  width: ${pxToRem(125)};
  & svg {
    background-color: ${interaction60.hex};
    fill: ${neutral0.hex};
    margin-right: ${spacing3};
    width: ${spacing7};
    height: ${spacing7};
    border-radius: 50%;
    padding: ${spacing2};
  }
`;

export const QuickOrderFooterTotals = styled.div`
  display: flex;
  align-items: center;
  width: ${pxToRem(285)};
  margin-top: ${spacing6};
  margin-left: auto;
  padding: ${spacing5} 0;
  background-color: ${neutral10.hex};
  border-radius: 8px;
`;
