import React, { Component } from 'react';
import PropTypes from 'prop-types';
import $ from 'jquery';
import 'bootstrap';
import '../ui';
import Loading from '../Loading';
import NotificationCheckbox from './shared/NotificationCheckbox';
import NotificationSelect from './shared/NotificationSelect';

export default class NotificationEmailSetup extends Component {
  static propTypes = {
    url: PropTypes.string.isRequired,
    locations: PropTypes.object.isRequired,
    userRole: PropTypes.string,
    extras: PropTypes.string
  }
  constructor(props) {
    super(props);
    this.state = {
      'extras': props.extras,
      'url': props.url,
      'userRole': props.userRole,
      'vendor': [],
      'dispensary': [],
      'checked': {'_all': {}, 'id_loc': '_all'},
      'location': props.locations,
      'disabled': false,
      'd': {},
      'v': {}
    };
    this.callFetch.bind(this);
    this.buildInput.bind(this);
    this.performSubmit.bind(this);
    this.checkSelect.bind(this);
    this.handleToggleAll.bind(this);
    this.handleSelectChange.bind(this);
  }
  componentDidMount() {
    this.handleSelectChange();
  }
  handleToggleAll(e) {
    let self = this;
    let {checked, userRole} = self.state;
    let sel = $('#id_loc_'.concat(userRole)).find(':selected').val();
    let ch = $(e.target).closest('.select-all-parent').find('input:checkbox');
    for (let i = 0; i < ch.length; i++) {
      checked[sel][ch[i].id] = e.target.checked;
    }
    checked[sel][e.target.id] = e.target.checked;
    self.setState({checked: checked});
  }
  handleSelectChange(e) {
    let self = this;
    let {d, v, extras, checked, userRole} = self.state;
    let sel = '_all';
    let dict = {form: (userRole) || 'dispensary'};
    if (typeof e !== 'undefined') {
      sel = e.target.value;
      dict = Object.assign(dict, {'loc': sel, 'submitted_form': extras});
    }
    checked['id_loc'] = sel;
    var state = {
      'checked': checked};
    if (sel in v) {
      state['vendor'] = v[sel];
      state['v'] = v;
      self.setState(state);
    } else if (sel in d) {
      state['dispensary'] = d[sel];
      state['d'] = d;
      self.setState(state);
    } else {
      self.callFetch(dict).then((json) => {
        let {dispensary, vendor, url, location, disabled} = json;
        if (location.length === 1 && location[0][0] !== '_all') {
          sel = location[0][0];
        }
        d[sel] = dispensary;
        self.createStates(dispensary, sel);
        v[sel] = vendor;
        self.createStates(vendor, sel);
        return {
          'vendor': vendor,
          'dispensary': dispensary,
          'v': v,
          'd': d,
          'location': location,
          'url': url,
          'disabled': disabled};
      }).then((state) => {
        self.setState(state);
      }).then(() => {
        $('#id_loc_'.concat(this.state.userRole)).selectpicker('show');
      });
    }
  }
  async callFetch(e) {
    let {url} = this.state;
    let gets = [];
    for (var k in e) {
      gets.push(`${k}=${e[k]}`);
    }
    url = url.concat('?').concat(gets.join('&'));
    try {
      const res = await fetch(url);
      return res.json();
    } catch (e) {
      $.notify(e, {
        allow_dismiss: true,
        type: 'warning',
        delay: 1000
      });
    }
  }
  checkSelect(c) {
    let {checked} = this.state;
    let sel = checked['id_loc'];
    if (typeof sel === 'undefined') {
      sel = '_all';
    }
    checked[sel][c.target.id] = c.target.checked;
    this.setState({'checked': checked});
  }
  createStates(lst, sel = '_all') {
    let self = this;
    let {checked, userRole} = self.state;
    if (!(sel in checked)) {
      checked[sel] = {};
    }

    let all = lst[0]['name'].split(':')[1].concat('email').concat(`:${userRole}`);
    let count = 0;
    for (let e in lst) {
      let name = lst[e]['name'];
      checked[sel][name] = lst[e]['value'];
      count += lst[e]['value'] ? 1 : 0;
    }
    checked[sel][all] = count === lst.length;
    checked['id_loc'] = sel;
    this.setState({'checked': checked});
  }
  buildInput(lst) {
    let self = this;
    let {checked, userRole} = self.state;
    let sel = checked['id_loc'];
    if (typeof sel === 'undefined') {
      sel = '_all';
    }
    let inputs = [];
    if (lst.length > 0) {
      let all = lst[0]['name'].split(':')[1].concat('email').concat(`:${userRole}`);
      inputs.push(
        <div className="col-sm-12" key={all.concat('div')}>
          <NotificationCheckbox
            key={all}
            name={all}
            checked={self.state.checked[sel][all]}
            onChange={self.handleToggleAll.bind(self)}
            label={'Toggle All'}
            disabled={this.state.disabled}
          />
        </div>);
    }
    for (let e in lst) {
      let name = lst[e]['name'];
      let label = lst[e]['label'];
      let key = name.concat(sel);
      inputs.push(
        <div className="col-sm-6" key={key.concat('div')}>
          <NotificationCheckbox key={key}
            name={name}
            checked={self.state.checked[sel][name]}
            onChange={self.checkSelect.bind(self)}
            label={label}
            disabled={this.state.disabled}
          />
        </div>);
    }
    return inputs;
  }
  performSubmit(e) {
    let self = this;
    self.setState({'location': null});
    let {url, checked, extras} = self.state;
    let data = new FormData();
    let loc = checked['id_loc'];
    for (const [key, value] of Object.entries(checked[loc])) {
      data.append(key, value);
    }
    data.append('id_loc', loc);
    data.append('submitted_form', extras);
    data.append('csrfmiddlewaretoken', $.cookie('csrftoken'));
    fetch(url, {
      method: 'POST',
      body: data
    }).then((res) => {
      return res['url'];
    }).then((target) => {
      window.location.href = target;
    });
  }
  render() {
    let self = this;
    let {location, dispensary, vendor, userRole} = self.state;
    let left = 'Emails from Vendor';
    let right = 'Emails From Team';
    if (userRole === 'vendor') {
      left = 'Emails from Customer';
      right = 'Emails From Team';
    }
    if (location == null) {
        return <Loading/>;
    } else if (location.length > 0) {
      return (<div className='fluid-container'>
        <form method="POST" className="card" id="form_notifications" style={{border: 'none'}}>
          <div className="card-body">
            <div className="h2">
              <span className="card-title">
                Notification Preferences
              </span>
            </div>
            <div className="row">
              <div className="col-sm-12">
                <NotificationSelect name={'id_loc_'.concat(userRole)} onChange={self.handleSelectChange.bind(self)} options={location} />
              </div>
            </div>
            <hr />
            <div className="row">
              <div className="col-md-6 select-all-parent">
                <div className='text-center'>
                  <h4 className="email-category">{left}</h4>
                </div>
                <div className="row">
                  {userRole === 'vendor' ? self.buildInput(dispensary) : self.buildInput(vendor)}
                </div>
              </div>
              <div className="col-md-6 select-all-parent">
                <div className='text-center'>
                  <h4 className="email-category">{right}</h4>
                </div>
                <div className="row">
                  {userRole === 'vendor' ? self.buildInput(vendor) : self.buildInput(dispensary)}
                </div>
              </div>
            </div>
            <footer><button id='submit_button' type="button" onClick={this.performSubmit.bind(this)} className="btn btn-primary narrow">Save</button></footer>
          </div>
        </form>
      </div>);
    } else {
      return (<div className="fluid-container">
        <h4 className="text-center">No Locations Configured for User</h4>
      </div>);
    }
  }
}
