import {
  Typography,
  neutral40,
  spacing3,
  spacing5,
} from '@leaftrade/leaftrade-component-library';
import React from 'react';
import { useStorefrontState } from '../../../../hooks/useReduxState';

const ProductDetailsPrice = () => {
  const { productDetails } = useStorefrontState();
  const { selectedVariant } = productDetails;

  return (
    <>
      {selectedVariant && selectedVariant.case_size > 1 ? (
        <>
          <Typography
            component='h5'
            sx={{
              width: 'max-content',
            }}
            testingId='product-details-content--price-per-case--typography'
          >
            {selectedVariant.price_per_case}
            <span style={{ fontWeight: '100' }}>/case</span>
          </Typography>
          <Typography
            sx={{
              color: neutral40.hex,
              marginBottom: spacing5,
              width: 'max-content',
            }}
            testingId='product-details-content--price-per-each--typography'
          >
            {`${selectedVariant.price_per_each} per unit`}
          </Typography>
        </>
      ) : (
        <Typography
          component='h5'
          sx={{
            marginBottom: spacing3,
            width: 'max-content',
          }}
          testingId='product-details-content--price--typography'
        >
          {selectedVariant
            ? selectedVariant.storefront_display_price
            : productDetails.formatted_price}
        </Typography>
      )}
    </>
  );
};

export default ProductDetailsPrice;
