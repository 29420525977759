import { useMemo } from 'react';
import EditorialBlockTile from '../Tiles/EditorialBlock/EditorialBlockTile';
import HeadlineBlock from '../Tiles/HeadlineBlock/HeadlineBlock';
import HeadlineSplashBlock from '../Tiles/HeadlineSplashBlock/HeadlineSplashBlock';
import MediaBlock from '../Tiles/MediaBlock/MediaBlock';

const useTilesMap = () =>
  useMemo(
    () => ({
      editorial_block: EditorialBlockTile,
      headline_block: HeadlineBlock,
      headline_splash_block: HeadlineSplashBlock,
      media_block: MediaBlock,
    }),
    []
  );

export default useTilesMap;
