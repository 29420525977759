import { spacing6 } from '@leaftrade/leaftrade-component-library';
import styled from 'styled-components';

export const QuickOrderTableWrapper = styled.div`
  position: relative;
  margin-top: ${spacing6};
`;

export const QuickOrderTableBody = styled.div`
  max-height: 50vh;
  overflow-y: auto;
`;
