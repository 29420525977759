import { AlertBar, spacing6 } from '@leaftrade/leaftrade-component-library';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import Breadcrumbs from '../../components/Breadcrumbs';
import PageLoader from '../../../shared/components/PageLoader';
import QuickOrderActions from '../../components/QuickOrderActions';
import QuickOrderFooter from '../../components/QuickOrderFooter';
import QuickOrderHeader from '../../components/QuickOrderHeader';
import QuickOrderLocationSelect from '../../components/QuickOrderLocationSelect';
import QuickOrderTable from '../../components/QuickOrderTable';
import AddToQuickOrderModal from '../../components/modals/AddToQuickOrderModal';
import ClearQuickOrderModal from '../../components/modals/ClearQuickOrderModal';
import DeleteQuickOrderItemModal from '../../components/modals/DeleteQuickOrderItemModal';
import LimitPendingCallsModal from '../../components/modals/LimitPendingCallsModal';
import { useStorefrontState } from '../../../hooks/useReduxState';
import { getQuickOrderData } from '../../../store/actions/storefrontActions';
import { BQ_DEBUG } from '../../constants';

const QuickOrder = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const { quickOrderData, sellerPageData, vendor } = useStorefrontState();
  const { bannerText, bannerTitle } = sellerPageData;
  const {
    addToQuickOrderModal,
    clearQuickOrderModal,
    deleteItemModal,
    isLoadingQuickOrderData,
    numberOfPendingEdits,
    quickOrderAlerts,
    selectedLocations,
  } = quickOrderData;
  const { settings, slug } = vendor;
  const { batchQueuesEnabled } = settings;

  useEffect(() => {
    if (selectedLocations.length > 0) {
      dispatch(
        getQuickOrderData({
          locationIds: selectedLocations.map(
            (selectedLocation) => selectedLocation.id
          ),
          slug,
        })
      );
    }
  }, [dispatch, selectedLocations, slug]);
  return (
    <>
      <Breadcrumbs isQuickOrderPage />
      {location.search.includes(BQ_DEBUG) && (
        <AlertBar
          animationIn={null}
          hideClose
          alertMessages={[
            batchQueuesEnabled
              ? 'Batch Queues Enabled'
              : 'Batch Queues Disabled',
          ]}
          alertTitle='BQ Debug'
          alertSeverity='info'
          sx={{
            marginTop: spacing6,
          }}
          testingId='quick-order--bq-debug-banner--alert-bar'
        />
      )}
      {bannerText && (
        <AlertBar
          animationIn={null}
          hideClose
          alertMessages={
            typeof bannerText === 'string' ? [bannerText] : bannerText
          }
          alertTitle={bannerTitle || ''}
          alertSeverity='info'
          sx={{
            marginTop: spacing6,
          }}
          testingId='quick-order--vendor-banner--alert-bar'
        />
      )}
      <QuickOrderHeader />
      <QuickOrderLocationSelect />
      {isLoadingQuickOrderData ? (
        <PageLoader />
      ) : (
        <>
          {quickOrderAlerts?.length > 0 &&
            quickOrderAlerts.map((alert) => (
              <AlertBar
                animationIn={null}
                hideClose
                alertMessages={[
                  alert.subtitle || alert.sub_message || '',
                  alert.level === 'error'
                    ? 'Please review and update below.'
                    : 'Please review below.',
                ]}
                alertTitle={alert.title || alert.message || ''}
                alertSeverity={alert.level}
                sx={{
                  marginTop: spacing6,
                }}
                testingId='quick-order--cart-alerts--alert-bar'
                key={alert.id}
              />
            ))}
          <QuickOrderTable />
          <QuickOrderFooter />
          <QuickOrderActions />
        </>
      )}
      {addToQuickOrderModal.isOpen && <AddToQuickOrderModal />}
      {clearQuickOrderModal.isOpen && <ClearQuickOrderModal />}
      {deleteItemModal.isOpen && <DeleteQuickOrderItemModal />}
      {numberOfPendingEdits >= 5 && <LimitPendingCallsModal />}
    </>
  );
};

export default QuickOrder;
