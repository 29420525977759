import {
  IconButton,
  IconEdit,
  Link,
  Typography,
  interaction60,
  pxToRem,
  spacing3,
  spacing5,
  OverflowTooltip,
} from '@leaftrade/leaftrade-component-library';
import React from 'react';
import { useStorefrontState } from '../../../../hooks/useReduxState';

const ProductDetailsContentName = () => {
  const { productDetails, shopper } = useStorefrontState();
  const { id, name, vendorId } = productDetails;
  const { isStaff } = shopper;

  return (
    <Typography
      component='h5'
      sx={{
        position: 'relative',
        marginBottom: spacing3,
        width: 'max-content',
        maxWidth: '100%',
      }}
      testingId='product-details-content--name--typography'
    >
      <OverflowTooltip
        tooltipId='product-name-tooltip'
        overflowHeightOffset={4}
      >
        {name}
      </OverflowTooltip>
      {isStaff && (
        <Link
          href={`/dashboard/vendor/${vendorId}/products/${id}/update/`}
          sx={{
            marginBottom: spacing3,
            position: 'absolute',
            right: pxToRem(-32),
            top: pxToRem(-16),
            width: 'unset',
            height: 'unset',
            '& svg': {
              height: spacing5,
              width: spacing5,
            },
          }}
          underline='none'
        >
          <IconButton
            icon={<IconEdit fill={interaction60.hex} />}
            onClick={() => {}}
            variant='secondary'
            size='medium'
          />
        </Link>
      )}
    </Typography>
  );
};

export default ProductDetailsContentName;
