import { useLayoutEffect, useState } from 'react';

export const useIsOverflow = ({ ref, callback, checkHorizontal = false }) => {
  const [isOverflow, setIsOverflow] = useState(undefined);

  useLayoutEffect(() => {
    const { current } = ref;

    const trigger = () => {
      const hasOverflow = checkHorizontal
        ? current.scrollWidth > current.clientWidth
        : current.scrollHeight > current.clientHeight;

      setIsOverflow(hasOverflow);

      if (callback) callback(hasOverflow);
    };

    if (current) {
      trigger();
    }
  }, [callback, ref]);

  return isOverflow;
};
