import { IconTrash, neutral60 } from '@leaftrade/leaftrade-component-library';
import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { useStorefrontState } from '../../../../hooks/useReduxState';
import {
  toggleCartClearModal,
  toggleCartDeleteModal,
} from '../../../../store/actions/storefrontActions';
import { CartLineItemColumnDeleteWrapper } from './CartLineItemColumnDelete.styles';

const CartLineItemColumnDelete = ({ item }) => {
  const dispatch = useDispatch();
  const { cart } = useStorefrontState();
  const { selectedCart } = cart;

  const handleDeleteClick = () => {
    if (selectedCart?.cartItems?.length === 1) {
      dispatch(
        toggleCartClearModal({
          isOpen: true,
        })
      );
    } else {
      dispatch(
        toggleCartDeleteModal({
          isOpen: true,
          item,
        })
      );
    }
  };

  return (
    <CartLineItemColumnDeleteWrapper data-testid='cart-line-item-column-delete--component--div'>
      <button onClick={handleDeleteClick} type='button'>
        <IconTrash fill={neutral60.hex} />
      </button>
    </CartLineItemColumnDeleteWrapper>
  );
};

CartLineItemColumnDelete.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    product: PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    }),
    totalQuantity: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }).isRequired,
};

export default CartLineItemColumnDelete;
