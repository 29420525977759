import {
  interaction60,
  neutral5,
  pxToRem,
  spacing2,
  spacing5,
} from '@leaftrade/leaftrade-component-library';
import styled from 'styled-components';

export const ProductImage = styled.div`
  display: flex;
  justify-content: center;
  position: relative;
`;

export const ProductImagesList = styled.div`
  display: flex;
  flex-direction: column;
  height: ${pxToRem(350)};
  width: ${pxToRem(70)};
  overflow: hidden;
  scroll-behavior: smooth;
  margin-right: ${spacing5};
`;

export const ProductImageOption = styled.button`
  height: ${pxToRem(70)};
  width: ${pxToRem(70)};
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  margin-bottom: ${spacing2};
  flex-shrink: 0;
  background-color: ${neutral5.hex};
  cursor: pointer;
  border: ${({ selected }) =>
    selected ? `2px solid ${interaction60.hex}` : 'none'};
  & img {
    max-width: ${pxToRem(68)};
    max-height: ${pxToRem(68)};
    border-radius: 4px;
  }
`;

export const ProductImageSelected = styled.img`
  height: ${pxToRem(350)};
  width: ${pxToRem(350)};
  object-fit: contain;
`;

export const ProductImagesOverlayControls = styled.div`
  position: absolute;
  width: ${pxToRem(70)};
  pointer-events: none;
  left: 0;
  top: ${pxToRem(-20)};
  bottom: ${pxToRem(-20)};
`;

export const ProductImagesOverlayTile = styled.div`
  pointer-events: none;
  height: ${pxToRem(70)};
  width: ${pxToRem(70)};
  display: flex;
  justify-content: center;
  border-radius: 4px;
  margin-bottom: 4px;
  flex-shrink: 0;
  position: absolute;
`;

export const ProductImagesOverlayTileUp = styled(ProductImagesOverlayTile)`
  top: 0;
  align-items: flex-start;
  background: linear-gradient(0deg, rgba(255, 255, 255, 0) 0%, #ffffff 100%);
`;

export const ProductImagesOverlayTileDown = styled(ProductImagesOverlayTile)`
  bottom: 0px;
  align-items: flex-end;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #ffffff 100%);
`;
