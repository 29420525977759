import styled from 'styled-components';
import {
  maxMediaQueryDesktop,
  pxToRem,
} from '@leaftrade/leaftrade-component-library';

export const EditorialBlockWrapper = styled.div`
  width: 100%;
  height: 100%;

  .brand-page__tile-description {
    font-size: ${pxToRem(14)};
    line-height: ${pxToRem(20)};
    width: calc(100% - 162px);
    max-height: calc(100% - 100px);
    overflow: hidden;

    ${maxMediaQueryDesktop} {
      width: calc(100% - 83px);
      font-size: ${pxToRem(12)};
      line-height: ${pxToRem(14)};
    }
  }
`;
