import { Typography, neutral40 } from '@leaftrade/leaftrade-component-library';
import React from 'react';
import { useStorefrontState } from '../../../../hooks/useReduxState';

const ProductDetailsExpiration = () => {
  const { productDetails } = useStorefrontState();
  const { selectedVariant } = productDetails;

  return (
    <Typography
      sx={{
        color: neutral40.hex,
        width: 'max-content',
      }}
      testingId='product-details-content--batch-expiration-date--typography'
    >
      Expiration Date: {selectedVariant.batch_expiration_date}
    </Typography>
  );
};

export default ProductDetailsExpiration;
