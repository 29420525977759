import {
  Card,
  IconCart,
  Typography,
  boldBodyLargeTypography,
  helperTextTypography,
  interaction60,
  maxMediaQueryDesktop,
  mediumBodySmallTypography,
  neutral60,
  pxToRem,
  spacing7,
  spacing8,
} from '@leaftrade/leaftrade-component-library';
import React from 'react';
import { useDispatch } from 'react-redux';
import { useStorefrontState } from '../../../hooks/useReduxState';
import { setLocationFromCart } from '../../../store/actions/storefrontActions';
import { currencyFormatter } from '../../../utils';
import {
  MultiCartSummaryBoxLocation,
  MultiCartSummaryBoxLocationInfo,
  MultiCartSummaryBoxLocationName,
  MultiCartSummaryBoxLocationQuantity,
  MultiCartSummaryBoxTotalWrapper,
} from './MultiCartSummaryBox.styles';

const MultiCartSummaryBox = () => {
  const dispatch = useDispatch();
  const { cart, shopper, vendor } = useStorefrontState();
  const { multiCartSummary, selectedCart } = cart;
  const { dispensary } = shopper;
  const { slug } = vendor;

  const handleCartClick = (summaryCart) => {
    if (summaryCart.token === selectedCart.token) return;
    dispatch(
      setLocationFromCart({
        locationId: summaryCart.locationId,
        dispensaryId: dispensary.id,
        vendorSlug: slug,
      })
    );
  };

  return (
    <Card
      elevation={5}
      paddingSize='large'
      sx={{
        flexDirection: 'column',
        width: pxToRem(280),
        marginRight: spacing8,
        position: 'sticky',
        top: pxToRem(72),
        [maxMediaQueryDesktop]: {
          position: 'relative',
          marginRight: 'unset',
          marginBottom: spacing7,
          width: '100%',
          top: 'unset',
        },
      }}
      testingId='multi-cart-summary-box--component--div'
    >
      <Typography
        bodyStyle={boldBodyLargeTypography}
        gutterBottom
        testingId='multi-cart-summary-box--title--typography'
      >
        Order Summary
      </Typography>
      {multiCartSummary.carts.map((summaryCart) => (
        <MultiCartSummaryBoxLocation
          data-testid='multi-cart-summary-box--location--button'
          key={summaryCart.token}
          isCurrentCart={summaryCart.token === selectedCart.token}
          onClick={() => handleCartClick(summaryCart)}
          type='button'
        >
          <IconCart fill={interaction60.hex} />
          <MultiCartSummaryBoxLocationInfo>
            <MultiCartSummaryBoxLocationName data-testid='multi-cart-summary-box--location-name--span'>
              {summaryCart.locationName}
            </MultiCartSummaryBoxLocationName>
            <span>
              <MultiCartSummaryBoxLocationQuantity data-testid='multi-cart-summary-box--location-quantity--span'>
                {summaryCart.quantity}
              </MultiCartSummaryBoxLocationQuantity>
              <span data-testid='multi-cart-summary-box--location-total--span'>
                {currencyFormatter.format(summaryCart.total)}
              </span>
            </span>
          </MultiCartSummaryBoxLocationInfo>
        </MultiCartSummaryBoxLocation>
      ))}
      <MultiCartSummaryBoxTotalWrapper>
        <Typography
          bodyStyle={mediumBodySmallTypography}
          testingId='multi-cart-summary-box--total-carts--typography'
        >
          Carts ({multiCartSummary.carts.length}) Total
        </Typography>
        <Typography
          bodyStyle={mediumBodySmallTypography}
          testingId='multi-cart-summary-box--subtotal--typography'
        >
          {currencyFormatter.format(multiCartSummary.subTotal)}
        </Typography>
      </MultiCartSummaryBoxTotalWrapper>
      <Typography
        bodyStyle={helperTextTypography}
        sx={{
          color: neutral60.hex,
        }}
        testingId='multi-cart-summary-box--total-items--typography'
      >
        {multiCartSummary.totalItems} units
      </Typography>
    </Card>
  );
};

export default MultiCartSummaryBox;
