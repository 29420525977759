import {
  IconArrowLeft,
  Typography,
} from '@leaftrade/leaftrade-component-library';
import PropTypes from 'prop-types';
import React from 'react';
import {
  HeaderMobileNavPanelBack,
  HeaderMobileNavPanelContainer,
  HeaderMobileNavPanelWrapper,
} from './HeaderMobileNavPanel.styles';
import HeaderMobileNavPanelAccount from './HeaderMobileNavPanelAccount';
import HeaderMobileNavPanelLocation from './HeaderMobileNavPanelLocation';
import HeaderMobileNavPanelSellersBrands from './HeaderMobileNavPanelSellersBrands';

const HeaderMobileNavPanel = ({
  activePanel,
  closeAllPanels,
  setIsMobileNavOpen,
}) => (
  <HeaderMobileNavPanelContainer
    data-testid={`header-mobile-nav-panel--${activePanel}-panel-component--div`}
  >
    <HeaderMobileNavPanelBack
      data-testid='header-mobile-nav-panel--main-menu--button'
      onClick={closeAllPanels}
      type='button'
    >
      <IconArrowLeft />
      <Typography classes={['h7']} component='span'>
        Main Menu
      </Typography>
    </HeaderMobileNavPanelBack>
    <HeaderMobileNavPanelWrapper>
      {activePanel === 'location' && (
        <HeaderMobileNavPanelLocation
          closeAllPanels={closeAllPanels}
          setIsMobileNavOpen={setIsMobileNavOpen}
        />
      )}
      {activePanel === 'account' && (
        <HeaderMobileNavPanelAccount
          closeAllPanels={closeAllPanels}
          setIsMobileNavOpen={setIsMobileNavOpen}
        />
      )}
      {activePanel === 'sellers' && (
        <HeaderMobileNavPanelSellersBrands
          closeAllPanels={closeAllPanels}
          setIsMobileNavOpen={setIsMobileNavOpen}
          activeSection='sellers'
        />
      )}
      {activePanel === 'brands' && (
        <HeaderMobileNavPanelSellersBrands
          closeAllPanels={closeAllPanels}
          setIsMobileNavOpen={setIsMobileNavOpen}
          activeSection='brands'
        />
      )}
    </HeaderMobileNavPanelWrapper>
  </HeaderMobileNavPanelContainer>
);

HeaderMobileNavPanel.propTypes = {
  activePanel: PropTypes.string.isRequired,
  closeAllPanels: PropTypes.func.isRequired,
  setIsMobileNavOpen: PropTypes.func.isRequired,
};

export default HeaderMobileNavPanel;
