import React from 'react';
import { useStorefrontState } from '../../../hooks/useReduxState';
import { CartLineItemsWrapper } from './CartLineItems.styles';
import CartLineItem from './CartLineItem';
import CartLineItemsHeader from './CartLineItemsHeader';
import CartLineItemBatchQueues from './CartLineItemBatchQueues';

const CartLineItems = () => {
  const { cart, vendor } = useStorefrontState();
  const { selectedCart } = cart;
  const { batchQueuesEnabled } = vendor.settings;

  return (
    <CartLineItemsWrapper data-testid='cart-line-items--component--div'>
      <CartLineItemsHeader />
      {selectedCart?.cartItems?.map((item) =>
        batchQueuesEnabled ? (
          <CartLineItemBatchQueues key={item?.cartItemId} item={item} />
        ) : (
          <CartLineItem key={item?.cartItemId} item={item} />
        )
      )}
    </CartLineItemsWrapper>
  );
};
export default CartLineItems;
