import React, { useCallback, useEffect, useMemo } from 'react';
import {
  Button,
  Modal,
  Select,
  spacing3,
  spacing5,
  Typography,
} from '@leaftrade/leaftrade-component-library';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import {
  getVendorPreviewCustomers,
  getVendorPreviewCustomerLocations,
  setVendorPreviewCustomer,
  setVendorPreviewCustomerLocation,
} from '../../../../store/actions/storefrontActions';
import { useStorefrontState } from '../../../../hooks/useReduxState';
import { getParamFromMatchPatch } from '../../../../utils';

const VendorPreviewModal = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const { authUser, vendorPreview } = useStorefrontState();
  const { customers, locations } = vendorPreview;

  const vendorSlug = getParamFromMatchPatch({
    location,
    path: '/storefront/sellers/vendor-preview/:slug/select-customer-location',
    paramKey: 'slug',
  });

  const currentVendor = useMemo(
    () => authUser.vendors.find((v) => v.slug === vendorSlug),
    [authUser, vendorSlug]
  );

  const customerOptions = useMemo(
    () =>
      customers.map((customer) => ({
        key: customer.id,
        label: customer.name,
        level: 1,
        selected: Number(customer.id) === Number(vendorPreview?.customer?.id),
        value: customer.id,
      })),
    [customers, vendorPreview.customer]
  );

  const locationOptions = useMemo(
    () =>
      locations.map((location) => ({
        key: location.id,
        label: location.name,
        level: 1,
        selected: Number(location.id) === Number(vendorPreview?.location?.id),
        value: location.id,
      })),
    [locations, vendorPreview.location]
  );

  const handleCustomerSelect = useCallback(
    (selected) => {
      const { label, value } = selected || {};
      if (!value) return;
      dispatch(
        setVendorPreviewCustomer({
          id: value,
          name: label,
        })
      );
      dispatch(
        getVendorPreviewCustomerLocations({
          dispensaryId: value,
          vendorSlug,
        })
      );
    },
    [vendorSlug]
  );

  const handleLocationSelect = useCallback((selected) => {
    const { label, value } = selected || {};
    if (!value) return;
    dispatch(
      setVendorPreviewCustomerLocation({
        id: value,
        name: label,
      })
    );
  }, []);

  const handleCancel = useCallback(() => {
    window.location.href = `/dashboard/vendor/${currentVendor.id}/`;
  }, [currentVendor]);

  const handleProceed = useCallback(() => {
    history.push(`/storefront/sellers/vendor-preview/${vendorSlug}/`);
  }, [vendorSlug]);

  const handleDefault = useCallback(() => {
    dispatch(
      setVendorPreviewCustomer({
        id: 0,
        name: 'Default',
      })
    );
    dispatch(
      setVendorPreviewCustomerLocation({
        id: 0,
        name: 'Default',
      })
    );
    handleProceed();
  }, [vendorSlug]);

  useEffect(() => {
    dispatch(
      getVendorPreviewCustomers({
        vendorSlug,
      })
    );
  }, [vendorSlug]);

  return (
    <Modal
      actions={
        <>
          <Button onClick={handleCancel} variant='outlined'>
            Cancel
          </Button>
          <Button
            disabled={!vendorPreview.customer && !vendorPreview.location}
            onClick={handleProceed}
          >
            Proceed
          </Button>
        </>
      }
      header={<Typography component='h5'>Vendor Storefront Preview</Typography>}
      hideClose
      open
      onClose={handleCancel}
      sx={{
        '[data-testid="modal--content--div"]': {
          display: 'flex',
          flexDirection: 'column',
          gap: spacing5,
          padding: `${spacing5} ${spacing3}`,
        },
      }}
      testingId='vendor-preview-modal--component--div'
    >
      <Button
        onClick={handleDefault}
        shape='pill'
        sx={{ width: 'max-content', margin: '0 auto' }}
        variant='outlined'
      >
        Default - No Rules
      </Button>
      <Typography sx={{ margin: '0 auto' }}>- OR -</Typography>
      <Select
        label='Select a customer'
        onSelect={handleCustomerSelect}
        options={customerOptions}
        optionsParentElement={document.body}
        sx={{
          '& > div': {
            zIndex: 1,
          },
        }}
      />
      <Select
        disabled={!vendorPreview.customer}
        label='Select a location'
        onSelect={handleLocationSelect}
        options={locationOptions}
        optionsParentElement={document.body}
      />
    </Modal>
  );
};

export default VendorPreviewModal;
