import {
  IconChevronDown,
  IconChevronUp,
  Typography,
  boldBodyTypography,
} from '@leaftrade/leaftrade-component-library';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import {
  ProductDetailsAccordionContent,
  ProductDetailsAccordionHeader,
  ProductDetailsAccordionWrapper,
} from './ProductDetailsAccordion.styles';

const ProductDetailsAccordion = ({ children, header, id, isOpen }) => {
  const [isAccordionOpen, setIsAccordionOpen] = useState(isOpen);

  return (
    <ProductDetailsAccordionWrapper
      data-testid='product-details-accordion--component--div'
      id={id}
    >
      <ProductDetailsAccordionHeader
        onClick={() => setIsAccordionOpen(!isAccordionOpen)}
      >
        <Typography bodyStyle={boldBodyTypography}>{header}</Typography>
        {isAccordionOpen ? <IconChevronUp /> : <IconChevronDown />}
      </ProductDetailsAccordionHeader>
      {isAccordionOpen && (
        <ProductDetailsAccordionContent>
          {children}
        </ProductDetailsAccordionContent>
      )}
    </ProductDetailsAccordionWrapper>
  );
};

ProductDetailsAccordion.defaultProps = {
  isOpen: false,
};

ProductDetailsAccordion.propTypes = {
  children: PropTypes.node.isRequired,
  header: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  isOpen: PropTypes.bool,
};

export default ProductDetailsAccordion;
