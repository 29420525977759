import { pxToRem, spacing5 } from '@leaftrade/leaftrade-component-library';
import styled from 'styled-components';

export const CartHeaderWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: ${spacing5};
  & > div {
    display: flex;
    align-items: center;
    margin-bottom: ${spacing5};
    & > img {
      width: ${pxToRem(48)};
      height: ${pxToRem(48)};
      object-fit: contain;
    }
  }
`;
