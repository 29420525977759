import queryString from 'query-string';
import { Button, SearchField } from '@leaftrade/leaftrade-component-library';
import PropTypes from 'prop-types';
import React, { useCallback, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { PageHeaderSearchContainer } from './PageHeaderSearch.styles';

const PageHeaderSearch = ({ onSearch, placeholder }) => {
  const history = useHistory();
  const { pathname, search } = useLocation();

  const searchParams = queryString.parse(search);
  const [searchQuery, setSearchQuery] = useState(
    searchParams.name_search || ''
  );

  const updateSearchQuery = useCallback(
    (newSearchQuery) => {
      setSearchQuery(newSearchQuery);
      searchParams.name_search = newSearchQuery;
      if (!newSearchQuery) {
        delete searchParams.name_search;
      }
      history.push({ pathname, search: queryString.stringify(searchParams) });
      onSearch(queryString.stringify(searchParams));
    },
    [history, searchParams, pathname]
  );

  return (
    <PageHeaderSearchContainer data-testid='page-header-search--component--div'>
      <SearchField
        placeholder={placeholder}
        onChange={setSearchQuery}
        onSearch={updateSearchQuery}
        searchOn='enter'
        showClear={Boolean(searchQuery)}
        size='large'
        sx={{
          flex: 1,
          borderRadius: '4px',
        }}
        testingId='page-header-search--search-field--input'
        value={searchQuery || ''}
      />
      <Button
        onClick={() => updateSearchQuery(searchQuery)}
        testingId='page-header-search--search-button--button'
      >
        Search
      </Button>
    </PageHeaderSearchContainer>
  );
};

PageHeaderSearch.propTypes = {
  onSearch: PropTypes.func.isRequired,
  placeholder: PropTypes.string.isRequired,
};

export default PageHeaderSearch;
