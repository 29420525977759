import {
  IconPdf,
  IconImage,
  ListMenu,
  spacing3,
  spacing6,
} from '@leaftrade/leaftrade-component-library';
import PropTypes from 'prop-types';
import React from 'react';

const ProductDetailsAttachmentDropdown = ({ options, label }) => {
  const buildAttachmentOptionLabel = (option) => {
    const extension = option.split('.').pop();
    const Icon = extension === 'pdf' ? IconPdf : IconImage;
    return (
      <>
        <Icon
          style={{
            marginRight: spacing3,
          }}
        />
        {option.split('/').pop()}
      </>
    );
  };

  const buildMenuOptions = (options) => {
    const menuOptions = [];

    options.forEach((option) => {
      if (option.section) {
        menuOptions.push({
          label: option.section,
          value: option.section,
          level: 0,
          key: option.section,
        });
        option.attachments.forEach((attachment) => {
          menuOptions.push({
            action: () => window.open(attachment, '_blank'),
            label: buildAttachmentOptionLabel(attachment),
            value: attachment,
            level: 1,
            key: buildAttachmentOptionLabel(attachment),
          });
        });
      } else {
        menuOptions.push({
          action: () => window.open(option, '_blank'),
          label: buildAttachmentOptionLabel(option),
          value: option,
          level: 1,
          key: buildAttachmentOptionLabel(option),
        });
      }
    });

    return menuOptions;
  };

  return (
    <ListMenu
      hasClose
      shape='rounded'
      variant='secondary'
      options={buildMenuOptions(options)}
      placeholder={label}
      sx={{
        '& > div': {
          zIndex: 1,
        },
        '& svg': {
          height: spacing6,
          width: spacing6,
        },
      }}
      testingId='product-details-attachment-dropdown--component--list-menu'
      title={label}
    />
  );
};

ProductDetailsAttachmentDropdown.propTypes = {
  options: PropTypes.array.isRequired,
  label: PropTypes.node.isRequired,
};

export default ProductDetailsAttachmentDropdown;
