import {
  maxMediaQueryDesktop,
  maxMediaQueryTablet,
  pxToRem,
  spacing6,
  spacing7,
  spacing8,
} from '@leaftrade/leaftrade-component-library';
import styled from 'styled-components';

export const PageHeaderInfoWrapper = styled.div`
  display: flex;
  padding: ${spacing6} ${pxToRem(135)};
  ${maxMediaQueryDesktop} {
    padding: ${spacing6} ${pxToRem(70)};
  }
  ${maxMediaQueryTablet} {
    padding: ${spacing6} ${spacing8};
  }
  & img {
    max-height: ${pxToRem(170)};
    max-width: ${pxToRem(170)};
    margin-right: ${spacing7};
    object-fit: contain;
  }
`;
