import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import Breadcrumbs from '../../components/Breadcrumbs';
import CheckoutActions from '../../components/CheckoutActions';
import CheckoutHeader from '../../components/CheckoutHeader';
import CheckoutItems from '../../components/CheckoutItems';
import CheckoutSummary from '../../components/CheckoutSummary';
import PageLoader from '../../../shared/components/PageLoader';
import AddBankAccountModal from '../../components/modals/AddBankAccountModal';
import PrivacyPolicyModal from '../../components/modals/PrivacyPolicyModal';
import TermsOfServiceModal from '../../components/modals/TermsOfServiceModal';
import { useStorefrontState } from '../../../hooks/useReduxState';
import { CheckoutWrapper } from './Checkout.styles';
import {
  getCheckoutData,
  toggleAddBankAccountModal,
} from '../../../store/actions/storefrontActions';
import EmptyCart from '../../components/EmptyCart';

const Checkout = () => {
  const dispatch = useDispatch();
  const { checkout, shopper, vendor } = useStorefrontState();
  const {
    addBankAccountModal,
    checkoutData,
    isAdmin,
    isLoadingCheckoutData,
    privacyPolicyModal,
    termsOfServiceModal,
  } = checkout;
  const { currentLocation } = shopper;
  const { slug } = vendor;

  useEffect(() => {
    if (currentLocation.id) {
      dispatch(
        getCheckoutData({
          slug,
          onSuccess: ({
            bank_accounts: bankAccounts,
            leaf_pay_enabled: leafPayEnabled,
          }) => {
            if (isAdmin && leafPayEnabled && bankAccounts.length === 0) {
              dispatch(toggleAddBankAccountModal({ isOpen: true }));
            }
          },
        })
      );
    }
  }, [currentLocation.id]);

  if (isLoadingCheckoutData) {
    return (
      <CheckoutWrapper>
        <PageLoader />
      </CheckoutWrapper>
    );
  }

  if (!checkoutData?.items || checkoutData.items.length === 0) {
    return (
      <CheckoutWrapper>
        <Breadcrumbs isCheckoutPage />
        <CheckoutHeader />
        <EmptyCart isCheckout />
      </CheckoutWrapper>
    );
  }

  return (
    <CheckoutWrapper>
      <Breadcrumbs isCheckoutPage />
      <CheckoutHeader />
      <CheckoutItems />
      <CheckoutSummary />
      <CheckoutActions />
      {addBankAccountModal.isOpen && <AddBankAccountModal />}
      {privacyPolicyModal.isOpen && <PrivacyPolicyModal />}
      {termsOfServiceModal.isOpen && <TermsOfServiceModal />}
    </CheckoutWrapper>
  );
};

export default Checkout;
