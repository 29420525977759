import {
  bodyExtraSmallTypography,
  neutral0,
  pxToRem,
  spacing3,
} from '@leaftrade/leaftrade-component-library';
import styled from 'styled-components';

export const CannabinoidsTooltipWrapper = styled.div`
  position: relative;
  ${({ isGridView, isProductDetailsPage }) => {
    if (isProductDetailsPage) {
      return `
        margin-left: unset;
        margin-right: unset;
      `;
    }
    if (isGridView) {
      return `
        margin-left: auto;
        margin-right: unset;
      `;
    }
    return `
      margin-left: unset;
      margin-right: ${spacing3};
    `;
  }}
  & svg {
    width: ${pxToRem(24)};
    height: ${pxToRem(24)};
  }
  & button {
    padding: 0;
    background: none;
    border: none;
    text-decoration: underline;
    color: ${neutral0.hex};
    ${bodyExtraSmallTypography}
  }
`;
