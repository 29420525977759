import {
  Link,
  Typography,
  handleImgSrcError,
  logoIconColor,
  pxToRem,
} from '@leaftrade/leaftrade-component-library';
import PropTypes from 'prop-types';
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useStorefrontState } from '../../../../hooks/useReduxState';
import { VendorLogoImage } from './VendorLogo.styles';

const VendorLogo = ({ logo, name, noLink, slug }) => {
  const { sellersPageData } = useStorefrontState();
  const { isGridView } = sellersPageData;
  return (
    <>
      {noLink ? (
        <Typography
          component='span'
          sx={{
            flexDirection: 'column',
            alignItems: 'flex-start',
            maxWidth: isGridView ? pxToRem(230) : pxToRem(135),
          }}
          testingId='vendor-logo--component--no-link--typography'
        >
          <VendorLogoImage
            isGridView={isGridView}
            src={logo || logoIconColor}
            alt={name}
            onError={handleImgSrcError}
          />
        </Typography>
      ) : (
        <Link
          component={RouterLink}
          sx={{
            flexDirection: 'column',
            alignItems: 'flex-start',
            width: '100%',
          }}
          testingId='vendor-logo--component--link'
          to={`/storefront/sellers/${slug}`}
          underline='none'
          variant='span'
        >
          <VendorLogoImage
            isGridView={isGridView}
            src={logo || logoIconColor}
            alt={name}
            onError={handleImgSrcError}
          />
        </Link>
      )}
    </>
  );
};

VendorLogo.defaultProps = {
  logo: '',
  noLink: false,
};

VendorLogo.propTypes = {
  logo: PropTypes.string,
  name: PropTypes.string.isRequired,
  noLink: PropTypes.bool,
  slug: PropTypes.string.isRequired,
};

export default VendorLogo;
