import camelize from 'camelize';
import { createSlice } from '@reduxjs/toolkit';
import {
  newToastNotification,
  ToastType,
} from '../../dashboard/components/ToastNotifications/toasts';
import {
  getOrders,
  getTopVendors,
  setPayload,
} from '../actions/dispensaryDashboardActions';
import dispensaryDashboardState from '../states/dispensaryDashboardState';

const handleRejected = (state, { meta, payload: { response } }) => {
  newToastNotification({
    body: 'An error occurred.',
    toastType: ToastType.ERROR,
  });

  return {
    ...state,
    isLoading: {
      ...(state.isLoading || {}),
      [meta.arg.module]: false,
    },
    errors: { ...(state.errors || {}), ...camelize(response?.data || {}) },
  };
};

export const dispensaryDashboardSlice = createSlice({
  name: 'dispensaryDashboard',
  initialState: dispensaryDashboardState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getOrders.pending, (state, action) => ({
      ...state,
      isLoading: {
        ...(state.isLoading || {}),
        [action.meta.arg.module]: true,
      },
    }));
    builder.addCase(getOrders.fulfilled, (state, action) => ({
      ...state,
      isLoading: {
        ...(state.isLoading || {}),
        [action.meta.arg.module]: false,
      },
      modules: {
        ...(state.modules || {}),
        [action.meta.arg.module]: camelize(action.payload),
      },
    }));
    builder.addCase(getOrders.rejected, (state, action) =>
      handleRejected(state, action, 'GET')
    );
    builder.addCase(getTopVendors.pending, (state, action) => ({
      ...state,
      isLoading: {
        ...(state.isLoading || {}),
        [action.meta.arg.module]: true,
      },
    }));
    builder.addCase(getTopVendors.fulfilled, (state, action) => ({
      ...state,
      isLoading: {
        ...(state.isLoading || {}),
        [action.meta.arg.module]: false,
      },
      modules: {
        ...(state.modules || {}),
        [action.meta.arg.module]: camelize(action.payload),
      },
    }));
    builder.addCase(getTopVendors.rejected, (state, action) =>
      handleRejected(state, action, 'GET')
    );
    builder.addCase(setPayload, (state, action) => ({
      ...state,
      ...action.payload,
    }));
  },
});
