import {
  IconButton,
  IconClose,
  IconList,
  neutral0,
  sRadius,
  seafoam80,
  spacing4,
  spacing7,
} from '@leaftrade/leaftrade-component-library';
import React from 'react';

const HeaderHamburger = ({ isOpen, onClick }) => (
  <IconButton
    variant='transparent'
    icon={
      isOpen ? (
        <IconClose data-testid='header-hamburger--close-icon--svg' />
      ) : (
        <IconList data-testid='header-hamburger--open-icon--svg' />
      )
    }
    onClick={onClick}
    size='large'
    sx={{
      background: isOpen ? seafoam80.hex : 'transparent',
      borderRadius: sRadius,
      height: spacing7,
      padding: 0,
      marginRight: spacing4,
      minHeight: 'unset',
      minWidth: 'unset',
      width: spacing7,
      '&:hover, &:focus, &:active': {
        background: seafoam80.hex,
      },
      '& svg': {
        fill: neutral0.hex,
      },
    }}
    testingId='header-hamburger--component--icon-button'
  />
);

export default HeaderHamburger;
