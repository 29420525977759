import {
  bodySmallTypography,
  boldBodySmallTypography,
  Typography,
} from '@leaftrade/leaftrade-component-library';
import PropTypes from 'prop-types';
import React, { useRef } from 'react';
import { useStorefrontState } from '../../../../hooks/useReduxState';
import CategoryIcon from '../CategoryIcon';
import { SectionTitle } from '../VendorCard.styles';
import { CategoryIcons } from './VendorCategories.styles';

const VendorCategories = ({
  categories,
  categoriesGridRef,
  categoriesListRef,
  showMore,
}) => {
  const { sellersPageData } = useStorefrontState();
  const { isGridView } = sellersPageData;
  const categoryImageIterator = useRef(0);

  return (
    <div data-testid='vendor-categories--component--div'>
      <SectionTitle isGridView={isGridView}>
        <Typography bodyStyle={boldBodySmallTypography}>CATEGORIES</Typography>
        {!isGridView && (
          <CategoryIcons
            ref={categoriesListRef}
            showMore={showMore}
            isGridView={isGridView}
          >
            {categories.map((categoryId, i) => (
              <CategoryIcon
                key={categoryId}
                categoryId={categoryId}
                categoryImageIterator={categoryImageIterator}
                categoryIndex={i}
              />
            ))}
          </CategoryIcons>
        )}
        <Typography bodyStyle={bodySmallTypography} sx={{ marginLeft: 'auto' }}>
          {categories.length} total
        </Typography>
      </SectionTitle>
      {isGridView && (
        <CategoryIcons
          ref={categoriesGridRef}
          showMore={showMore}
          isGridView={isGridView}
        >
          {categories.map((categoryId, i) => (
            <CategoryIcon
              key={categoryId}
              categoryId={categoryId}
              categoryImageIterator={categoryImageIterator}
              categoryIndex={i}
            />
          ))}
        </CategoryIcons>
      )}
    </div>
  );
};

VendorCategories.propTypes = {
  categories: PropTypes.arrayOf(PropTypes.number).isRequired,
  categoriesGridRef: PropTypes.shape({ current: PropTypes.any }).isRequired,
  categoriesListRef: PropTypes.shape({ current: PropTypes.any }).isRequired,
  showMore: PropTypes.bool.isRequired,
};

export default VendorCategories;
