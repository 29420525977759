import {
  IconLoadingSpinner,
  Typography,
  seafoam60,
} from '@leaftrade/leaftrade-component-library';
import PropTypes from 'prop-types';
import React from 'react';
import { SpinnerWrapper } from './PageLoader.styles';

const PageLoader = ({ message }) => (
  <SpinnerWrapper data-testid='page-loader--component--div'>
    <IconLoadingSpinner
      mainLineColor={seafoam60.hex}
      secondaryLineColor='transparent'
      width='128px'
      height='128px'
      lineWidth='16px'
      rotateTiming='1.5s'
    />
    {message && <Typography>{message}</Typography>}
  </SpinnerWrapper>
);

PageLoader.defaultProps = {
  message: '',
};

PageLoader.propTypes = {
  message: PropTypes.string,
};

export default PageLoader;
