import {
  Typography,
  mediumBodySmallTypography,
  pxToRem,
  spacing5,
} from '@leaftrade/leaftrade-component-library';
import React from 'react';
import { useStorefrontState } from '../../../../hooks/useReduxState';
import { CartLineItemsHeaderWrapper } from './CartLineItemsHeader.styles';

const CartLineItemsHeader = () => {
  const { cart } = useStorefrontState();
  const { selectedCart } = cart;

  const labResultsPresent = selectedCart?.cartItems?.some(
    (item) => item?.variants[0]?.labResultsUrl?.length
  );

  return (
    <CartLineItemsHeaderWrapper data-testid='cart-line-items-header--component--div'>
      <Typography
        bodyStyle={mediumBodySmallTypography}
        sx={{
          width: '40%',
          flexGrow: 1,
          paddingLeft: spacing5,
          display: 'flex',
        }}
        testingId='cart-line-items-header--item--p'
      >
        Item
      </Typography>
      {labResultsPresent && (
        <Typography
          bodyStyle={mediumBodySmallTypography}
          sx={{
            width: pxToRem(70),
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexShrink: 0,
          }}
          testingId='cart-line-items-header--lab-results--p'
        >
          Lab Results
        </Typography>
      )}
      <Typography
        bodyStyle={mediumBodySmallTypography}
        sx={{
          width: '15%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
        testingId='cart-line-items-header--quantity--p'
      >
        Qty
      </Typography>
      <Typography
        bodyStyle={mediumBodySmallTypography}
        sx={{
          width: '15%',
          display: 'flex',
          alignItems: 'center',
        }}
        testingId='cart-line-items-header--unit-price--p'
      >
        Unit Price
      </Typography>
      <Typography
        bodyStyle={mediumBodySmallTypography}
        sx={{
          width: '15%',
          display: 'flex',
          alignItems: 'center',
        }}
        testingId='cart-line-items-header--total--p'
      >
        Total
      </Typography>
      <Typography
        bodyStyle={mediumBodySmallTypography}
        sx={{
          width: pxToRem(30),
          display: 'flex',
          alignItems: 'center',
        }}
        testingId='cart-line-items-header--delete--p'
      >
        {' '}
      </Typography>
    </CartLineItemsHeaderWrapper>
  );
};

export default CartLineItemsHeader;
