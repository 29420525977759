import React, { useEffect } from 'react';
import useIsVideo from '../../hooks/useIsVideo';
import { MediaBlockWrapper } from './MediaBlock.styles';
import {
  brandPageTileClickEvent,
  brandPageTileViewEvent,
} from '../../../../analytics/brand_pages';

const MediaBlock = ({ page, position, imageUrl, targetUrl, className }) => {
  const isVideo = useIsVideo(imageUrl);

  useEffect(() => {
    brandPageTileViewEvent({
      vendorId: page.vendorId,
      brandId: page.brandId,
      brandPageId: page.id,
      brandPageTilePosition: position,
      brandPageTileMediaUrl: targetUrl,
    });
  });

  const trackAndOpenWindow = () => {
    brandPageTileClickEvent({
      vendorId: page.vendorId,
      brandId: page.brandId,
      brandPageId: page.id,
      brandPageTilePosition: position,
      brandPageTileMediaUrl: imageUrl,
      brandPageTileClickTargetUrl: targetUrl,
    });

    window.open(targetUrl, '_blank');
  };

  return (
    <div
      className={`${className} brand-page__tile--clickable`}
      onClick={trackAndOpenWindow}
    >
      <MediaBlockWrapper>
        {isVideo ? (
          <video src={imageUrl} muted autoPlay loop />
        ) : (
          <img src={imageUrl} alt={targetUrl} />
        )}
      </MediaBlockWrapper>
    </div>
  );
};

export default MediaBlock;
