import {
  Card,
  Typography,
  boldBodyTypography,
  neutral10,
  spacing5,
  spacing8,
} from '@leaftrade/leaftrade-component-library';
import React from 'react';
import { useStorefrontState } from '../../../hooks/useReduxState';
import { currencyFormatter } from '../../../utils';

const CartSummaryBox = () => {
  const { cart } = useStorefrontState();
  const { selectedCart } = cart;
  const { discounts, surcharges, quantity, total, subTotal } = selectedCart;

  return (
    <Card
      elevation={0}
      paddingSize='large'
      sx={{
        backgroundColor: neutral10.hex,
        flexDirection: 'column',
        marginLeft: 'auto',
        marginTop: spacing8,
        width: 'max-content',
        gap: spacing5,
        '& > div': {
          display: 'flex',
          justifyContent: 'space-between',
          gap: spacing5,
        },
      }}
      testingId='cart-summary-box--component--div'
    >
      <div data-testid='cart-summary-box--quantity--div'>
        <Typography>Quantity</Typography>
        <Typography bodyStyle={boldBodyTypography}>{quantity}</Typography>
      </div>
      <div data-testid='cart-summary-box--subtotal--div'>
        <Typography>Subtotal</Typography>
        <Typography bodyStyle={boldBodyTypography}>
          {currencyFormatter.format(subTotal)}
        </Typography>
      </div>
      {discounts != 0 && (
        <div data-testid='cart-summary-box--discounts--div'>
          <Typography>Discounts</Typography>
          <Typography bodyStyle={boldBodyTypography}>
            {currencyFormatter.format(discounts)}
          </Typography>
        </div>
      )}
      {surcharges != 0 && (
        <div data-testid='cart-summary-box--surcharges--div'>
          <Typography>Surcharges</Typography>
          <Typography bodyStyle={boldBodyTypography}>
            {currencyFormatter.format(surcharges)}
          </Typography>
        </div>
      )}
      <div data-testid='cart-summary-box--total--div'>
        <Typography>Estimated total</Typography>
        <Typography bodyStyle={boldBodyTypography}>
          {currencyFormatter.format(total)}
        </Typography>
      </div>
    </Card>
  );
};

export default CartSummaryBox;
