import {
  boldBodyLargeTypography,
  Button,
  Modal,
  Typography,
  TextField,
  Select,
  Checkbox,
  neutral10,
  spacing5,
  spacing3,
  bodySmallTypography,
  pxToRem,
} from '@leaftrade/leaftrade-component-library';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useStorefrontState } from '../../../../hooks/useReduxState';
import {
  addBankAccount,
  addTimedBanner,
  toggleAddBankAccountModal,
  togglePrivacyPolicyModal,
  toggleTermsOfServiceModal,
} from '../../../../store/actions/storefrontActions';
import {
  AddBankAccountForm,
  BankAccountSection,
  AddressSection,
  PrivacyTermsCta,
} from './AddBankAccountModal.styles';
import { countriesArray, stateSelectArray } from '../../../utils';

const AddBankAccountModal = () => {
  const dispatch = useDispatch();
  const { checkout, shopper } = useStorefrontState();
  const { addBankAccountModal, leafPayData } = checkout;
  const { bankAccounts } = leafPayData;
  const { dispensary } = shopper;
  const [bankAccount, setBankAccount] = useState({
    account_type: '',
    nickname: '',
    bank_name: '',
    account_entity_name: '',
    routing_number: '',
    account_number: '',
    address_line1: '',
    address_line2: '',
    city: '',
    state: '',
    postal_code: '',
    country: '',
    make_default: false,
    agreed_to_privacy_policy: false,
    agreed_to_terms_of_service: false,
  });
  const [errors, setErrors] = useState({});

  const submitDisabled =
    !bankAccount.account_type ||
    !bankAccount.nickname ||
    !bankAccount.bank_name ||
    !bankAccount.account_entity_name ||
    !bankAccount.routing_number ||
    !bankAccount.account_number ||
    !bankAccount.address_line1 ||
    !bankAccount.city ||
    !bankAccount.state ||
    !bankAccount.postal_code ||
    !bankAccount.country ||
    !bankAccount.agreed_to_privacy_policy ||
    !bankAccount.agreed_to_terms_of_service;

  const handlePostAddBankAccount = (responseData) => {
    if (responseData?.errors && Object.keys(responseData.errors).length > 0) {
      setErrors(responseData.errors);
    } else {
      dispatch(
        addTimedBanner({
          id: `add-bank-account-${bankAccount.nickname}-success`,
          message: `Successfully added ${bankAccount.nickname}`,
          severity: 'success',
        })
      );
      dispatch(toggleAddBankAccountModal({ isOpen: false }));
    }
  };

  const AddBankAccountModalJsx = {
    actions: (
      <>
        {bankAccounts.length > 0 && (
          <Button
            onClick={() =>
              dispatch(toggleAddBankAccountModal({ isOpen: false }))
            }
            variant='secondary'
          >
            Cancel
          </Button>
        )}
        <Button
          disabled={submitDisabled}
          onClick={() => {
            dispatch(
              addBankAccount({
                data: bankAccount,
                dispensaryId: dispensary.id,
                onSuccess: (responseData) =>
                  handlePostAddBankAccount(responseData),
              })
            );
          }}
        >
          Submit
        </Button>
      </>
    ),
    body: (
      <AddBankAccountForm>
        <Select
          label='Account Type'
          onSelect={({ value }) => {
            setBankAccount({
              ...bankAccount,
              account_type: value,
            });
          }}
          options={[
            {
              label: 'Checking',
              level: 1,
              key: 'checking',
              value: 'checking',
            },
            {
              label: 'Savings',
              level: 1,
              key: 'savings',
              value: 'savings',
            },
          ]}
          placeholder='Select Account Type'
          required
          shape='rounded'
          value={bankAccount.account_type}
        />
        <BankAccountSection>
          <TextField
            error={errors?.nickname}
            label='Nickname'
            message={errors?.nickname?.[0] || ''}
            onChange={({ target }) => {
              setBankAccount({
                ...bankAccount,
                nickname: target.value,
              });
            }}
            placeholder='Enter Nickname'
            required
            size='large'
            value={bankAccount.nickname}
          />
          <TextField
            error={errors?.bank_name}
            label='Bank Name'
            message={errors?.bank_name?.[0] || ''}
            onChange={({ target }) => {
              setBankAccount({
                ...bankAccount,
                bank_name: target.value,
              });
            }}
            placeholder='Enter Bank Name'
            required
            size='large'
            value={bankAccount.bank_name}
          />
          <TextField
            error={errors?.account_entity_name}
            label='Account Entity Name'
            message={errors?.account_entity_name?.[0] || ''}
            onChange={({ target }) => {
              setBankAccount({
                ...bankAccount,
                account_entity_name: target.value,
              });
            }}
            placeholder='Enter Account Entity Name'
            required
            size='large'
            value={bankAccount.account_entity_name}
          />
          <TextField
            error={errors?.routing_number}
            label='Routing Number'
            message={errors?.routing_number?.[0] || ''}
            onChange={({ target }) => {
              setBankAccount({
                ...bankAccount,
                routing_number: target.value,
              });
            }}
            placeholder='Enter Routing Number'
            required
            size='large'
            value={bankAccount.routing_number}
          />
          <TextField
            error={errors?.account_number}
            label='Account Number'
            message={errors?.account_number?.[0] || ''}
            onChange={({ target }) => {
              setBankAccount({
                ...bankAccount,
                account_number: target.value,
              });
            }}
            placeholder='Enter Account Number'
            required
            size='large'
            value={bankAccount.account_number}
          />
        </BankAccountSection>
        <AddressSection>
          <TextField
            error={errors?.address_line1}
            label='Address Line 1'
            message={errors?.address_line1?.[0] || ''}
            onChange={({ target }) => {
              setBankAccount({
                ...bankAccount,
                address_line1: target.value,
              });
            }}
            placeholder='Enter Address Line 1'
            required
            size='large'
            value={bankAccount.address_line1}
          />
          <TextField
            error={errors?.address_line2}
            label='Address Line 2'
            message={errors?.address_line2?.[0] || ''}
            onChange={({ target }) => {
              setBankAccount({
                ...bankAccount,
                address_line2: target.value,
              });
            }}
            placeholder='Enter Address Line 2'
            size='large'
            value={bankAccount.address_line2}
          />
          <TextField
            error={errors?.city}
            label='City'
            message={errors?.city?.[0] || ''}
            onChange={({ target }) => {
              setBankAccount({
                ...bankAccount,
                city: target.value,
              });
            }}
            placeholder='Enter City'
            required
            size='large'
            value={bankAccount.city}
          />
          <Select
            label='State'
            onSelect={({ value }) => {
              setBankAccount({
                ...bankAccount,
                state: value,
              });
            }}
            options={stateSelectArray}
            placeholder='Select State'
            required
            shape='rounded'
            sx={{
              zIndex: '2',
              '& > div': {
                maxHeight: `${pxToRem(150)} !important`,
              },
            }}
            value={bankAccount.state}
          />
          <TextField
            error={errors?.postal_code}
            label='Postal Code'
            message={errors?.postal_code?.[0] || ''}
            onChange={({ target }) => {
              setBankAccount({
                ...bankAccount,
                postal_code: target.value,
              });
            }}
            placeholder='Enter Postal Code'
            required
            size='large'
            value={bankAccount.postal_code}
          />
          <Select
            label='Country'
            onSelect={({ value }) => {
              setBankAccount({
                ...bankAccount,
                country: value,
              });
            }}
            options={countriesArray}
            placeholder='Select Country'
            required
            shape='rounded'
            sx={{
              zIndex: '1',
              '& > div': {
                maxHeight: `${pxToRem(150)} !important`,
              },
            }}
            value={bankAccount.country}
          />
        </AddressSection>
        <Checkbox
          checked={bankAccount.make_default}
          id='makeDefault'
          label='Make Default Payment Method'
          onChange={({ target }) => {
            setBankAccount({
              ...bankAccount,
              make_default: target.checked,
            });
          }}
        />
        <Checkbox
          checked={bankAccount.agreed_to_privacy_policy}
          id='agreedToPrivacyPolicy'
          label={
            <>
              I agree to the{' '}
              <PrivacyTermsCta
                onClick={() => {
                  dispatch(togglePrivacyPolicyModal({ isOpen: true }));
                }}
              >
                Privacy Policy
              </PrivacyTermsCta>
            </>
          }
          onChange={({ target }) => {
            setBankAccount({
              ...bankAccount,
              agreed_to_privacy_policy: target.checked,
            });
          }}
          required
        />
        <Checkbox
          checked={bankAccount.agreed_to_terms_of_service}
          id='agreedToTermsOfService'
          label={
            <>
              I agree to the{' '}
              <PrivacyTermsCta
                onClick={() => {
                  dispatch(toggleTermsOfServiceModal({ isOpen: true }));
                }}
              >
                Terms of Service
              </PrivacyTermsCta>
            </>
          }
          onChange={({ target }) => {
            setBankAccount({
              ...bankAccount,
              agreed_to_terms_of_service: target.checked,
            });
          }}
          required
        />
      </AddBankAccountForm>
    ),
    header: (
      <>
        <Typography bodyStyle={boldBodyLargeTypography} gutterBottom>
          Add Payment Information
        </Typography>
        <Typography
          bodyStyle={bodySmallTypography}
          sx={{
            margin: `0 0 ${spacing5}`,
            padding: `${spacing3} 0`,
            borderBottom: `1px solid ${neutral10.hex}`,
            borderTop: `1px solid ${neutral10.hex}`,
          }}
        >
          This vendor requires you to pay for your order via the LeafTrade
          platform. Please add your account info below.
        </Typography>
      </>
    ),
  };

  return (
    <Modal
      actions={AddBankAccountModalJsx.actions}
      header={AddBankAccountModalJsx.header}
      hideClose={bankAccounts.length === 0}
      onClose={() => dispatch(toggleAddBankAccountModal({ isOpen: false }))}
      open={addBankAccountModal.isOpen}
      sx={{
        '& > div': {
          maxHeight: '90vh',
          minHeight: '80vh',
          display: 'flex',
          flexDirection: 'column',
        },
        '& [data-testid="modal--content--div"]': {
          flex: '1',
        },
      }}
      testingId='add-bank-account-modal--component--div'
    >
      {AddBankAccountModalJsx.body}
    </Modal>
  );
};

export default AddBankAccountModal;
