import {
  Link,
  Typography,
  handleImgSrcError,
  logoIconColor,
  pxToRem,
  spacing5,
  spacing6,
  spacing8,
} from '@leaftrade/leaftrade-component-library';
import PropTypes from 'prop-types';
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useStorefrontState } from '../../../../hooks/useReduxState';
import { StyledProductImage } from './ProductImage.styles';

const ProductImage = ({ imgSrc, name, productId, selectedVariantId, slug }) => {
  const { hasLimitedAccess, publicMenu, vendor } = useStorefrontState();
  const { isGridView } = vendor.settings;
  return (
    <>
      {publicMenu?.products ? (
        <Typography
          sx={{
            margin: isGridView
              ? `${spacing6} auto ${spacing8}`
              : `auto ${spacing5} auto 0`,
            width: isGridView ? pxToRem(170) : pxToRem(100),
            height: isGridView ? pxToRem(170) : pxToRem(100),
          }}
          testingId='product-image--component--public--typography'
          variant='span'
        >
          <StyledProductImage
            src={imgSrc || logoIconColor}
            alt={name}
            onError={handleImgSrcError}
          />
        </Typography>
      ) : (
        <Link
          component={RouterLink}
          sx={{
            width: isGridView ? pxToRem(170) : pxToRem(100),
            height: isGridView ? pxToRem(170) : pxToRem(100),
          }}
          testingId='product-image--component--link'
          to={`/storefront/sellers/${
            hasLimitedAccess ? 'vendor-preview/' : ''
          }${slug}/${productId}?variant=${selectedVariantId}`}
          TypographySx={{
            margin: isGridView
              ? `${spacing6} auto ${spacing8}`
              : `auto ${spacing5} auto 0`,
          }}
          variant='span'
        >
          <StyledProductImage
            src={imgSrc || logoIconColor}
            alt={name}
            onError={handleImgSrcError}
          />
        </Link>
      )}
    </>
  );
};

ProductImage.propTypes = {
  imgSrc: PropTypes.string,
  name: PropTypes.string.isRequired,
  productId: PropTypes.number.isRequired,
  selectedVariantId: PropTypes.number.isRequired,
  slug: PropTypes.string.isRequired,
};

export default ProductImage;
