import { Input, pxToRem } from '@leaftrade/leaftrade-component-library';
import PropTypes from 'prop-types';
import React from 'react';
import { CartLineItemColumnQuantityWrapper } from '../CartLineItemColumnQuantity/CartLineItemColumnQuantity.styles';

const CartLineItemColumnQuantityVariant = ({ quantity }) => (
  <CartLineItemColumnQuantityWrapper data-testid='cart-line-item-column-quantity-variant--component--div'>
    <Input
      disabled
      size='large'
      sx={{
        width: pxToRem(80),
        '& input': {
          textAlign: 'center',
        },
      }}
      testingId='cart-line-item-column-quantity-variant--input'
      type='number'
      value={quantity || 0}
    />
  </CartLineItemColumnQuantityWrapper>
);

CartLineItemColumnQuantityVariant.propTypes = {
  quantity: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default CartLineItemColumnQuantityVariant;
