import PropTypes from 'prop-types';
import React from 'react';
import { ExploreCardsWrapper } from './LandingPageExplore.styles';
import LandingPageExploreCard from './LandingPageExploreCard';
import {
  exploreAllSellersCardClickEvent,
  shopFlowerCardClickEvent,
  shopAncillaryCardClickEvent,
} from '../../../analytics/landing';

const LandingPageExplore = ({ categories }) => (
  <ExploreCardsWrapper data-testid='landing-page-explore--component--div'>
    <LandingPageExploreCard
      className='landing-page-explore--explore-card--gradient-1'
      description='Shop a curated collection of the most reputable and trusted cannabis sellers.'
      handleOnClick={exploreAllSellersCardClickEvent}
      linkText='Explore Sellers'
      linkTo='/storefront/sellers'
      title='Explore All Available Sellers'
    />
    <LandingPageExploreCard
      className='landing-page-explore--explore-card--gradient-2'
      description='Shop sellers with available flower and pluck your favorites.'
      handleOnClick={shopFlowerCardClickEvent}
      linkText='Shop Flower'
      linkTo={{
        pathname: '/storefront/sellers',
        search: `?categories_filters=${categories.category_flower_id}`,
      }}
      title='Find Your New Best Bud'
    />
    <LandingPageExploreCard
      className='landing-page-explore--explore-card--gradient-3'
      description='Get the goods you need for everyday operations.'
      handleOnClick={shopAncillaryCardClickEvent}
      linkText='Shop Non-Cannabis'
      linkTo={{
        pathname: '/storefront/sellers',
        search: `?product_types_filters=2`,
      }}
      title='Browse Accessories, Gear & More'
    />
  </ExploreCardsWrapper>
);

LandingPageExplore.propTypes = {
  categories: PropTypes.shape({
    category_flower_id: PropTypes.number,
    product_type_ancillary_id: PropTypes.number,
  }).isRequired,
};

export default LandingPageExplore;
