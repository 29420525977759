import '../../scss/storefront/storefront.scss';

import React from 'react';
import ReactDOM from 'react-dom';

import StorefrontRouter from './router';

import NotificationEmailSetup from '../dashboard/components/NotificationEmailSetup';
import LogIn from '../shared/components/LogIn';
import ApiTokenPreferences from '../shared/components/ApiTokenPreferences';

window.React = React;
window.ReactDOM = ReactDOM;

// Components to be loaded via django-react-templatetags
window.ReactComponents = {
  NotificationEmailSetup,
  StorefrontRouter,
  LogIn,
  ApiTokenPreferences,
};
