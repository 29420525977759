import { spacing6 } from '@leaftrade/leaftrade-component-library';
import styled from 'styled-components';

export const QuickOrderLocationSelectPlaceholder = styled.span`
  display: flex;
  & svg {
    height: ${spacing6};
    width: ${spacing6};
  }
`;
