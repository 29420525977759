import { createSlice } from '@reduxjs/toolkit';
import camelize from 'camelize';
import userState from '../states/userState';
import {
  setDownloadedUser,
  setFilters,
  setSelectedUser,
  setViewMode,
  updateUsers,
} from '../actions/userActions';
import objectToCamelCase from '../../utils/objectToCamelCase';
import clearObject from '../../utils/clearObject';

export const userSlice = createSlice({
  name: 'user',
  initialState: userState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(setFilters, (state, action) => ({
      ...state,
      filters: camelize(action.payload),
    }));

    builder.addCase(setDownloadedUser, (state, action) => ({
      ...state,
      downloadedUser: action.payload
        ? {
            ...state.downloadedUser,
            ...camelize(action.payload),
          }
        : {},
    }));

    builder.addCase(setSelectedUser, (state, action) => ({
      ...state,
      selectedUsers: action.payload
        ? objectToCamelCase(
            clearObject({
              ...state.selectedUsers,
              ...(action.payload || {}),
            })
          )
        : {},
    }));

    builder.addCase(updateUsers.pending, (state) => ({
      ...state,
      isUsersUpdating: true,
    }));

    builder.addCase(updateUsers.fulfilled, (state) => ({
      ...state,
      isUsersUpdating: false,
    }));

    builder.addCase(updateUsers.rejected, (state) => ({
      ...state,
      isUsersUpdating: false,
    }));

    builder.addCase(setViewMode, (state, action) => ({
      ...state,
      viewMode: action.payload,
    }));
  },
});
