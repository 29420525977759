import React from 'react';
import { useStorefrontState } from '../../../hooks/useReduxState';
import {
  LandingPageExploreFavoritesWrapper,
  LandingPageWrapper,
} from './LandingPage.styles';
import LandingPageCarousel from './LandingPageCarousel';
import LandingPageExplore from './LandingPageExplore';
import LandingPageFavorites from './LandingPageFavorites';
import LandingPageFeaturedVendors from './LandingPageFeatured/LandingPageFeaturedVendors';
import LandingPageFeaturedBrands from './LandingPageFeatured/LandingPageFeaturedBrands';
import LandingPageSponsored from './LandingPageSponsored';

const LandingPage = () => {
  const { landingPageData } = useStorefrontState();
  const {
    carouselSponsors,
    categories,
    favoriteVendors,
    featuredBrands,
    featuredVendors,
    sponsors,
  } = landingPageData;

  return (
    <LandingPageWrapper data-testid='landing-page--component--div'>
      {carouselSponsors?.length ? (
        <LandingPageCarousel sponsors={carouselSponsors} />
      ) : null}
      {featuredVendors?.length ? (
        <LandingPageFeaturedVendors featuredObjects={featuredVendors} />
      ) : null}
      {featuredBrands?.length ? (
        <LandingPageFeaturedBrands featuredObjects={featuredBrands} />
      ) : null}
      {sponsors?.length ? <LandingPageSponsored sponsors={sponsors} /> : null}
      <LandingPageExploreFavoritesWrapper>
        {categories?.category_flower_id &&
        categories?.product_type_ancillary_id ? (
          <LandingPageExplore categories={categories} />
        ) : null}
        <LandingPageFavorites favoriteVendors={favoriteVendors} />
      </LandingPageExploreFavoritesWrapper>
    </LandingPageWrapper>
  );
};

export default LandingPage;
