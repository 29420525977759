import {
  pxToRem,
  spacing3,
  spacing5,
  spacing6,
} from '@leaftrade/leaftrade-component-library';
import styled from 'styled-components';

export const VariantCannabinoids = styled.div`
  display: flex;
  align-items: center;
  flex-direction: ${({ isGridView }) => (isGridView ? 'row' : 'row-reverse')};
  justify-content: ${({ isGridView }) => (isGridView ? 'unset' : 'flex-end')};
  margin-bottom: ${({ isGridView }) => (isGridView ? spacing5 : 'unset')};
  margin-right: ${({ isGridView }) => (isGridView ? 'unset' : spacing6)};
  height: ${({ isGridView }) => (isGridView ? spacing6 : 'unset')};
  width: ${({ isGridView }) => (isGridView ? 'unset' : pxToRem(125))};
`;

export const VariantSize = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: ${({ isGridView }) => (isGridView ? spacing3 : 'unset')};
  width: ${({ isGridView }) => (isGridView ? 'unset' : pxToRem(95))};
`;
