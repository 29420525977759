import {
  Typography,
  mediumBodySmallTypography,
} from '@leaftrade/leaftrade-component-library';
import PropTypes from 'prop-types';
import React from 'react';
import { currencyFormatter } from '../../../../utils';
import { CartLineItemColumnTotalWrapper } from './CartLineItemColumnTotal.styles';

const CartLineItemColumnTotal = ({ totalPrice }) => (
  <CartLineItemColumnTotalWrapper data-testid='cart-line-item-column-total--component--div'>
    <Typography bodyStyle={mediumBodySmallTypography}>
      {currencyFormatter.format(totalPrice)}
    </Typography>
  </CartLineItemColumnTotalWrapper>
);

CartLineItemColumnTotal.propTypes = {
  totalPrice: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
};

export default CartLineItemColumnTotal;
