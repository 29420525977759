import styled from 'styled-components';

export const MediaBlockWrapper = styled.div`
  height: 100%;
  video,
  img {
    height: 100%;
    width: 100%;
    object-fit: fill;
  }
`;
