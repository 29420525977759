import {
  breakpointDesktop,
  maxMediaQueryDesktop,
  spacing8,
} from '@leaftrade/leaftrade-component-library';
import styled from 'styled-components';

export const ProductWrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin: ${spacing8} auto;
  gap: ${spacing8};
  max-width: ${breakpointDesktop};
  ${maxMediaQueryDesktop} {
    flex-direction: column;
  }
`;
