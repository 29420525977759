import {
  pxToRem,
  spacing4,
  spacing5,
  spacing6,
  spacing8,
  maxMediaQueryTablet,
} from '@leaftrade/leaftrade-component-library';
import styled from 'styled-components';

export const VendorLogoImage = styled.img`
  height: ${({ isGridView }) => (isGridView ? pxToRem(175) : pxToRem(135))};
  max-width: ${({ isGridView }) => (isGridView ? pxToRem(230) : pxToRem(135))};
  ${({ isGridView }) => !isGridView && `width: ${pxToRem(135)};`}
  margin: ${({ isGridView }) =>
    isGridView ? `${spacing4} auto ${spacing8}` : `${spacing4} ${spacing6}`};
  object-fit: contain;
  padding-top: ${({ isGridView }) => (isGridView ? spacing5 : 0)};
  ${maxMediaQueryTablet} {
    height: ${({ isGridView }) => (isGridView ? pxToRem(175) : pxToRem(75))};
    max-width: ${({ isGridView }) => (isGridView ? pxToRem(230) : pxToRem(75))};
  }
`;
