import PropTypes from 'prop-types';
import React, { useState } from 'react';
import CartLineItemColumnDelete from '../CartLineItemColumnDelete';
import CartLineItemColumnItemBatchQueues from '../CartLineItemColumnItemBatchQueues';
import CartLineItemColumnQuantity from '../CartLineItemColumnQuantity';
import CartLineItemBatchQueuesVariant from '../CartLineItemBatchQueuesVariant';
import {
  CartLineItemBatchQueuesWrapper,
  CartLineItemBatchQueuesTotalFiller,
  ShowAllButton,
} from './CartLineItemBatchQueues.styles';

const CartLineItemBatchQueues = ({ item }) => {
  const [showMore, setShowMore] = useState(false);

  const visibleVariants = item.variants.slice(0, 2);
  const hiddenVariants = item.variants.slice(2);

  return (
    <>
      <CartLineItemBatchQueuesWrapper data-testid='cart-line-item-batch-queues--component--div'>
        <CartLineItemColumnItemBatchQueues item={item} />
        <CartLineItemColumnQuantity item={item} />
        <CartLineItemBatchQueuesTotalFiller />
        <CartLineItemColumnDelete item={item} />
      </CartLineItemBatchQueuesWrapper>
      {visibleVariants.map((variant) => (
        <CartLineItemBatchQueuesVariant
          key={variant.variantId}
          variant={variant}
        />
      ))}
      {showMore &&
        hiddenVariants.map((variant) => (
          <CartLineItemBatchQueuesVariant
            key={variant.variantId}
            variant={variant}
          />
        ))}
      {hiddenVariants.length > 0 && (
        <ShowAllButton type='button' onClick={() => setShowMore(!showMore)}>
          {showMore ? '- Show Less' : '+ Show All'}
        </ShowAllButton>
      )}
    </>
  );
};

CartLineItemBatchQueues.propTypes = {
  item: PropTypes.shape({
    cartItemId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    totalPrice: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    variants: PropTypes.array,
  }),
};

export default CartLineItemBatchQueues;
