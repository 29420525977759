import React from 'react';

const PrivacyPolicy = () => (
  <div>
    <h1>LEAF TRADE INC. PRIVACY POLICY</h1>
    <h2>1. INTRODUCTION</h2>
    <p>
      Welcome to the family of websites, including http://leaf.trade, provided
      by Leaf Trade Inc. (“Leaf Trade”, “we”, “us”, or “our”). This Privacy
      Policy applies to each website where it is posted or provided (“Website”
      or collectively, the “Sites”) and describes the information we gather from
      you when you use the Sites, mobile applications, and related services
      (together, the “Services”) and how we use, process, and disclose that
      information. This Privacy Policy applies only to the Sites, not to any
      other third-party sites that may be accessible from the Sites.
    </p>
    <p>
      We may change the terms of this Privacy Policy from time to time. By
      continuing to use any of the Sites and/or the Services or submitting
      personal information through the Sites or the Services, you expressly
      consent and agree to the transfer of your personal data to our servers for
      our collection, use, and disclosure in accordance with this Privacy
      Policy.
    </p>
    <p>
      <b>
        We process your information and personal information only to the extent
        necessary for the performance of our obligations to you, which is in
        providing the Sites and Services to you under our Terms of Service at:{' '}
        <a href='https://app.leaf.trade/tos/' target='_blank' rel='noreferrer'>
          https://app.leaf.trade/tos/
        </a>
        . We set out below the types of information and personal information
        that we collect and how we use it.
      </b>
      Except as set forth in this Privacy Policy, your personal information will
      not be used for any other purpose without your consent. We will not
      actively collect personal information for the purpose of sale to third
      parties or marketing in a way that specifically identifies the individual.
      In other words, we don&apos;t sell customer lists. You may withdraw your
      consent to our processing of your personal information at any time.
      However, withdrawing consent may result in your inability to continue
      using the Sites and/or the Services.
    </p>

    <h2>2. INFORMATION WE COLLECT</h2>
    <p>
      When you create an account, register and/or use the Services, we collect
      certain information from you, which may include, but is not limited to,
      your: name; contact information such as your address, email address, and
      phone number; demographics information such as your gender and location;
      product descriptions, photographs, pricing information, and laboratory
      results; and all other information, such as reviews, content, and bio, you
      may provide us. This information is collected for reasons of compliance,
      verification of authorized licenses/businesses/accounts/users, and other
      general information that&apos;s provided voluntarily by both sellers and
      buyers for their mutual benefit as it relates to conducting a transaction.
    </p>
    <p>
      We automatically collect information about how you use our services, for
      example, pages you have viewed. We also collect certain technical
      information about your device including your Internet protocol address,
      geo-location information, metadata, marketing communications, statistics,
      your browser type, language and identifying information, your operating
      system and application version, device types, device model and
      manufacturer, device identifiers, and your device operating system type
      and version. This information is collected to continuously improve the
      Sites and Services for your experience and added value to you.
    </p>
    <p>
      If you send us an e-mail message, you provide us with your e-mail address.
      If you make a purchase via the Sites or through use of the Services, we
      may collect your credit card number and the other information necessary to
      provide the Services. We may also collect information submitted by you on
      the name and date of events specific to a particular activity, customer,
      recipient, your address book and address lists, products, your profile and
      customization data, photographs, and other information submitted by you to
      the Sites. This information is collected to continuously improve the Sites
      and Services for your experience and added value to you.
    </p>
    <p>
      <b>
        We do not currently respond to “do not track” signals or other
        mechanisms that might enable an opt-out of tracking on our Site.
      </b>
    </p>

    <h3>Cookies</h3>
    <p>
      We may also use cookies, web beacons, and URL information to gather
      information regarding the date and time of your visit and the information
      for which you searched and which you viewed. Cookies are small pieces of
      information that a website sends to your computer&apos;s hard drive or
      other storage media while you are viewing a website. We may use both
      session cookies (which expire once you close your web browser) and
      persistent cookies (which usually stay on your computer until you delete
      them) to provide you with a more personal and interactive experience on
      our Site. Web beacons are digital images that are used to log information
      on the Services or in our emails. We use web beacons to manage cookies,
      count visits, and to learn what marketing works and what does not. We also
      use web beacons to tell if you open or act on our emails.
    </p>

    <h3>Third-Party Analytics</h3>
    <p>
      Others, including third-party analytics service providers and advertising
      partners may also collect personally identifiable information about your
      online activities over time and across different Sites when you use the
      Services, including as described in this Privacy Policy. This Privacy
      Policy does not apply to, and we are not responsible for, those other
      parties. Third-party analytics services may use cookies and web beacons
      through the Sites and platform device identifiers and software agents on
      and through our mobile services to provide us with information about how
      you use and interact with the Sites. Please contact us at{' '}
      <a href='mailto:Legal@LeafTrade.com'>Legal@LeafTrade.com</a> for a list of
      third-party service providers. We encourage you to review the privacy
      policies of these third parties to learn about your choices about
      information they collect from you.
    </p>

    <h3>Google Analytics</h3>
    <p>
      This site may use Google Analytics tools and advertising features. Google
      Analytics Advertising Features allow features in Analytics that
      aren&apos;t available through standard implementations.
    </p>

    <p>
      Advertising features include:
      <ul>
        <li>Remarketing with Google Analytics</li>
        <li>Google Display Network Impression Reporting</li>
        <li>Google Analytics Demographics and Interest Reporting</li>
        <li>
          Integrated services that require Google Analytics to collect data via
          advertising cookies and anonymous identifiers
        </li>
      </ul>
    </p>

    <p>
      The Advertising Features enable Google Analytics to collect data about
      website traffic via Google advertising cookies and anonymous identifiers,
      in addition to data collected through a standard Google Analytics
      implementation. The data is sent to Google Analytics (for example, via the
      Google Analytics tracking code, Google Analytics SDK, or the Measurement
      Protocol) for analysis and reporting. Your use of the Sites will not
      facilitate the merging of personally identifiable information with
      non-personally identifiable information collected through any Google
      advertising product or feature unless you have robust notice of, and the
      user&apos;s prior affirmative (i.e., opt-in) consent to, that merger. For
      information about Google&apos;s advertising cookies and anonymous
      identifiers, please visit the following resources:{' '}
      <a
        href='http://www.google.com/policies/technologies/types/'
        target='_blank'
        rel='noreferrer'
      >
        http://www.google.com/policies/technologies/types/
      </a>{' '}
      and{' '}
      <a
        href='http://www.google.com/policies/privacy/key-terms/#toc-terms-identifier'
        target='_blank'
        rel='noreferrer'
      >
        https://www.google.com/policies/privacy/key-terms/#toc-terms-identifier
      </a>
      .
    </p>

    <p>
      Visitors can opt-out of the Google Analytics Advertising Features used on
      this website, including through Ads Settings, Ad Settings for mobile apps,
      or any other available means (for example, the NAI&apos;s consumer
      opt-out) by using Google Analytics&apos; currently available opt-outs for
      the web at:{' '}
      <a
        href='https://tools.google.com/dlpage/gaoptout/'
        target='_blank'
        rel='noreferrer'
      >
        https://tools.google.com/dlpage/gaoptout/
      </a>
      .
    </p>

    <h3>Geographic Restrictions</h3>
    <p>
      The Sites are intended to be accessed and used by viewers in the United
      States of America only. If users access the Sites from other
      jurisdictions, they do so giving express consent to perform any data
      collection, sharing and usage that takes place on any site, app, email
      publication or other property as a consequence of your use of the Sites,
      Services and/or Google products; and you acknowledge and agree that, as an
      end user, you have been provided with clear and comprehensive information
      about, and consent to, the storing and accessing of cookies or other
      information on the end user&apos;s device where such activity occurs in
      connection with a product to which this policy applies.
    </p>
    <p>
      You represent and warrant that you will not provide or disclose to us any
      personal information that is subject to the European Union General Data
      Protection Regulation or to any other data privacy laws or regulations
      outside the United States (“Restricted Data”) or otherwise use the Sites
      or the Services to upload, store or process Restricted Data. If you
      provide us with any Restricted Data or uses the Sites or Services to
      upload, store or process Restricted Data, then you shall indemnify and
      hold harmless Leaf Trade and its personnel against all fines, penalties,
      costs, fees, expenses, damages and liabilities, including attorney fees
      and any other fees or defense costs, associated with any data subject,
      private party or government claim, investigation, or enforcement
      proceeding, relating to or arising from any Restricted Data. This
      indemnification is intended to apply to the fullest extent allowed by law,
      regardless of the grounds or nature of any claim, investigation,
      enforcement proceeding, fine, penalty, liability, or damages asserted,
      including, without limitation, to claims, liability or damages based on
      principles of contract, negligence or other tort, fiduciary duty,
      warranty, indemnity, statute or common law. This indemnification will also
      apply after termination of this Agreement.
    </p>

    <h2>3. RETENTION</h2>
    <p>
      We will keep your personal information only for as long as reasonably
      necessary to fulfill the purposes for which personal information is
      collected as stated herein; for as long as is necessary for the
      performance of the contract between you and us, if any; and to comply with
      legal and statutory obligations, such as in tax, trade and corporate laws.
      When we no longer need your personal information for our purposes, we will
      destroy, delete or erase that personal information or convert it into an
      anonymous form.
    </p>

    <h2>4. SHARING OF YOUR INFORMATION</h2>
    <p>
      The Services offered by Leaf Trade may allow you to connect and share your
      actions, comments, content, profile, and information publicly or with
      other people. You may control some of the categories of personal and
      Included Entity information shared. Please be mindful of your own privacy
      needs as you choose who to connect with and what to share and make
      available on the Sites. We cannot control the privacy or security of
      information you choose to make available on the Sites or share with
      others.
    </p>

    <h2>5. HOW WE USE YOUR INFORMATION</h2>
    <p>
      Leaf Trade generally may use your information to:
      <ul>
        <li>
          Facilitate the creation of and secure your account to use the Services
          and on the Sites;
        </li>
        <li>Identify you as a user in our system;</li>
        <li>Provide, personalize, and improve the Sites and the Services;</li>
        <li>
          Communicate with you about your use of the Sites and the Services;
        </li>
        <li>Develop new products and services;</li>
        <li>
          Customize your experience and the advertising you view and recommend
          content;
        </li>
        <li>
          Fulfill your requests and facilitate the transmission of orders and
          communications between users;
        </li>
        <li>
          Send newsletters, surveys, offers and promotional materials related to
          the Sites and the Services and for other marketing purposes of Leaf
          Trade;
        </li>
        <li>
          Protect, investigate, and prevent potentially fraudulent,
          unauthorized, or illegal activities;
        </li>
        <li>Protect our rights and the rights of other users; and</li>
        <li>
          As otherwise described in this Privacy Policy or in notices we provide
          to you.
        </li>
      </ul>
    </p>
    <p>
      We may also use your information to verify your geographic location. We
      may use your geographic location data to personalize the Sites and the
      Services, to recommend content, or determine whether the information you
      have requested is available in your location.
    </p>

    <h2>6. HOW WE DISCLOSE YOUR INFORMATION</h2>
    <p>
      We may share your information as follows:
      <ul>
        <li>
          We may share your personal or Included Entity information with your
          consent or at your direction.
        </li>
        <li>
          We may also share your information with others who perform services on
          our behalf.
        </li>
        <li>
          We may disclose your information if we believe we are required to do
          so by law, or to comply with a court order, judicial or other
          government subpoena, or warrant.
        </li>
        <li>
          We also may disclose your information if we believe doing so is
          appropriate or necessary to prevent any liability, fraudulent,
          abusive, or unlawful uses; to protect Leaf Trade, the Sites, and the
          Services; to enforce our Terms and Conditions; or to protect any
          rights, property, or personal safety of Leaf Trade or others at our
          discretion.
        </li>
        <li>
          In the event that Leaf Trade is or may be acquired by or merged with
          another company or involved in any other business deal (or negotiation
          of a business deal) involving sale or transfer of all or part of our
          business or assets, we may transfer or assign your information as part
          of or in connection with the transaction. In the event of insolvency,
          bankruptcy, or receivership, information may be transferred as a
          business asset.
        </li>
      </ul>
    </p>
    <p>
      We may also share anonymized aggregated and disaggregated data with our
      partners, advertisers, and other third parties.
    </p>
    <p>
      We will not disclose or transfer your personal information to third
      parties without your permission, except as specified in this Privacy
      Policy (see further Important Exceptions below).
    </p>
    <p>
      As at the date of this Privacy Policy, we share personal information about
      you in respect of the Sites or Services only with our service providers
      and partners which include website hosting, cloud service providers,
      analytics providers, email marketing providers and payment processors. Our
      service providers may host data worldwide and accordingly your personal
      information may be available to governments worldwide under a lawful
      order, irrespective of the safeguards we have put in place for the
      protection of your personal information.
    </p>
    <p>
      From time to time we may employ third parties to help us improve the Sites
      and/or the Services. These third parties may have limited access to
      databases of user information solely for the purpose of helping us to
      improve the Sites and/or the Services and they will be subject to
      contractual restrictions prohibiting them from using the information about
      users or third parties for any other purpose.
    </p>
    <p>
      <b>
        <i>Important Exceptions:</i>
      </b>
      We may disclose your personal information to third parties without your
      consent if we have reason to believe that disclosing this information is
      necessary to identify, contact or bring legal action against someone who
      may be causing injury to or interference with (either intentionally or
      unintentionally) our rights or property, other websites, other users of
      the Service, or anyone else (including the rights or property of anyone
      else) that could be harmed by such activities. We may disclose your
      personal information when we believe in good faith that such disclosure is
      required by and in accordance with the law.
    </p>
    <p>
      We may also disclose your personal information in connection with a
      corporate re-organization, a merger or amalgamation with another entity, a
      sale of all or a substantial portion of our assets or stock, including any
      due diligence exercise carried out in relation to the same, provided that
      the information disclosed continues to be used for the purposes permitted
      by this Privacy Policy by the entity acquiring the information.
    </p>

    <h2>7. THIRD-PARTY ADVERTISERS</h2>
    <p>
      We may permit advertisements to be delivered to you by third-party
      Internet advertising companies (also called ad networks or network
      advertisers). These companies may use cookies, web beacons, platform
      device identifiers, software agents, and other technologies to collect
      non-personally identifiable information about your visits over time on the
      Sites and the Service and across other websites to deliver advertisements
      to you targeted to your interests, measure their effectiveness and
      personalize advertising content, and to understand the usage and
      visitation of the Sites and the Services and the other applications and
      websites tracked by these advertising companies. We do not have access to
      or control over cookies, web beacons, platform device identifiers,
      software agents, or other technologies that they may use. We are not
      responsible for the privacy practices of third-party advertisers. Please
      contact us at <a href='mailto:Legal@LeafTrade.com'>Legal@LeafTrade.com</a>{' '}
      for a list of third-party service advertisers. You should check the
      privacy policy of the third-party advertiser to determine how it handles
      information it separately collects from you.
    </p>

    <h2>8. SECURITY</h2>
    <p>
      Leaf Trade takes commercially reasonable technical, organizational,
      contractual, administrative, and physical security steps to help protect
      your information and personal information against loss, misuse and
      unauthorized access, or disclosure at a level of security appropriate to
      the risk. No company can fully prevent security risks, however. While we
      strive to protect your personal and Included Entity information, we cannot
      guarantee its absolute security. To help protect yourself and your
      information, choose a unique password for the Sites and the Services and
      do not use a password on the Sites or the Services that you would use on
      any other website or online service.
    </p>

    <h2>9. DISPUTE RESOLUTION</h2>
    <p>
      If you believe that Leaf Trade has not adhered to this Privacy Policy,
      please contact Leaf Trade by e-mail at{' '}
      <a href='mailto:Legal@LeafTrade.com'>Legal@LeafTrade.com</a>. We will do
      our best to address your concerns. If you feel that your complaint has
      been addressed incompletely, we invite you to let us know for further
      investigation.
    </p>

    <h2>10. INFORMATION CHOICES</h2>
    <p>
      You may opt out of receiving promotional emails from Leaf Trade and/or its
      users by following the instructions in those emails or by reviewing the
      settings in your account. If you opt out, we may still send you
      non-promotional emails, such as emails about your accounts or our ongoing
      business relations. You may also send requests about your personal or
      Included Entity information by emailing{' '}
      <a href='mailto:Legal@LeafTrade.com'>Legal@LeafTrade.com</a>. Please note
      that we will review such requests on a case-by-case basis and make all
      determinations in our sole and absolute discretion.
    </p>
    <p>
      When you visit the Sites, we and others give you the following choices
      about use of mechanisms for tracking, including tracking of your online
      activities over time and across different websites and online services by
      third parties. Most web browsers are set to accept cookies by default. If
      you prefer, you can usually choose to set your browser to remove cookies
      and to reject cookies from the Sites or from third parties. If you choose
      to remove cookies or reject cookies, this could affect certain features or
      services of the Sites. In addition to the steps discussed above, to
      opt-out of Google Analytics web tracking for certain browsers, you can
      download Google Analytics Opt-out Browser Add-on. You may opt out of other
      cookies that may be present on the Sites by following the directions of
      our third-party service providers. You can also choose to opt-out of use
      of cookies by some of our third-party advertising partners to deliver ads
      tailored to your profile and preferences. Please contact us at{' '}
      <a href='mailto:Legal@LeafTrade.com'>Legal@LeafTrade.com</a> for a list of
      third-party service providers and advertisers. However, while we and
      others give you choices as described in this policy, there are many ways
      in which web browser signals and other similar mechanisms can indicate
      your choice to disable tracking, and the Sites may not be aware of or
      honor every mechanism.
    </p>
    <p>
      You may have the right to know what personal or Included Entity
      information Leaf Trade has about you and to correct any inaccuracies.
      Please direct any such requests by email to{' '}
      <a href='mailto:Legal@LeafTrade.com'>Legal@LeafTrade.com</a>.
    </p>
    <p>
      In certain jurisdictions where we may operate, you may have the right
      under applicable law to: obtain confirmation that we hold personal
      information about you, request access to and receive information about the
      personal information we maintain about you, receive copies of the personal
      information we maintain about you, update and correct inaccuracies in your
      personal information, object to or restrict the processing of your
      personal information, under certain circumstances, provide instructions
      for the use of your personal information after death, request to have
      deleted any personal information collected at the time you qualified as a
      minor under applicable law, have your personal information blocked,
      anonymized or deleted, as appropriate; and withdraw any consent you
      previously provided to us regarding the use and processing of your
      personal information.
    </p>

    <h2>11. CHILDREN</h2>
    <p>
      The Sites are not intended for children under the age of 18. We will not
      knowingly collect information from children under the age of 18 and do not
      target our Sites or Services to children under 18. We encourage parents
      and legal guardians to monitor their children&apos;s Internet usage and to
      help enforce our Privacy Policy. If you have any reason to believe that
      any child under the age of 18 has provided personal information to us
      through the Sites, please contact us, and we will endeavor to delete that
      information from our databases.
    </p>
    <h3>Children Under 13</h3>
    <p>
      We comply with the Children&apos;s Online Privacy Protection Act
      (“COPPA”). Children under the age of 13 are not permitted to use the Sites
      or Services we provide. However, we have no way of distinguishing the age
      of individuals who access our Sites or Services, and so we carry out the
      same Privacy Policy for all users for which age is unknown. If a child,
      including any child under the age of 13, has provided us with
      personally-identifying information without parental or guardian consent,
      the parent or guardian should contact us to remove the information and opt
      out of promotional opportunities. Additionally, if you have any reason to
      believe that any child under the age of 13 has provided personal
      information to us through the Sites, please contact us, and we will
      endeavor to delete that information from our databases.
    </p>

    <h2>12. YOUR CALIFORNIA PRIVACY RIGHTS</h2>
    <p>
      If you are a resident of the state of California, the following rights are
      available to you under the California Consumer Privacy Act.
    </p>
    <h3>Right to access</h3>
    <p>
      You have the right to request that we disclose the categories of personal
      information collected, the categories of sources from which personal
      information is collected, the business or commercial purpose for
      collection, the categories of third parties with which we share personal
      information, and the specific pieces of personal information that we have
      about you. You also have the right to request that we provide your
      personal information to you in a readily useable format.
    </p>
    <h3>Right to request deletion</h3>
    <p>
      You have the right to request that we delete personal information that we
      collected from you. Note, however, that certain requests to delete
      personal information may be denied if we are required to retain the
      information as a matter of law, the information is necessary for detecting
      security incidents, exercising free speech, protecting or defending
      against legal claims, or for internal uses reasonably aligned with
      consumer expectations, including transaction data.
    </p>
    <h3>Right to non-discrimination</h3>
    <p>
      You have the right not to receive discriminatory treatment by us for
      exercise of these privacy rights. We do not offer financial incentives
      related to the provision of data.
    </p>
    <h3>Authorized agent</h3>
    <p>
      You have the right to designate an authorized agent to make these requests
      on your behalf.
    </p>
    <p>
      To exercise any of these rights, please contact us at +1-888-507-7747 or{' '}
      <a href='mailto:Legal@LeafTrade.com'>Legal@LeafTrade.com</a>. We may need
      to request specific information from you to help us confirm your identity
      and ensure your right to access the information (or to exercise any of
      your other rights). This is another appropriate security measure to ensure
      that personal information is not disclosed to any person who has no right
      to receive it.
    </p>

    <h2>13. CHANGES AND UPDATES TO THIS PRIVACY POLICY</h2>
    <p>
      As noted above, from time to time, we may revise the Privacy Policy. To
      help you stay current of any changes, we note the date the Privacy Policy
      was last updated below. We reserve the right to modify this Privacy Policy
      at any time and without notice, and to retroactively apply the modified
      policy to information already collected.
    </p>

    <h2>14. LEAF TRADE CONTACT INFORMATION</h2>
    <p>
      Please contact Leaf Trade with any questions or comments about this
      Privacy Policy, your information, our third-party disclosure practices, or
      your consent choices.
    </p>

    <p>
      Leaf Trade Inc.
      <br />
      Attention: Privacy Officer
      <br />
      225 W Hubbard Street
      <br />
      Floor 3<br />
      Chicago, Illinois 60654
      <br />
      Email: <a href='mailto:Legal@LeafTrade.com'>Legal@LeafTrade.com</a>
      <br />
    </p>

    <p>Last updated on March 6, 2023.</p>

    <p>© {new Date().getFullYear()} Leaf Trade Inc.. All Rights Reserved</p>
  </div>
);

export default PrivacyPolicy;
