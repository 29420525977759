import styled from 'styled-components';
import { spacing5, pxToRem } from '@leaftrade/leaftrade-component-library';

export const CartLineItemColumnItemWrapper = styled.div`
  width: 40%;
  flex-grow: 1;
  padding-left: ${spacing5};
  display: flex;
  align-items: center;
  & > img {
    flex-shrink: 0;
    max-height: ${pxToRem(48)};
    max-width: ${pxToRem(48)};
    width: ${pxToRem(48)};
    display: flex;
    justify-content: center;
    align-items: flex-start;
    margin-right: ${spacing5};
  }
`;
