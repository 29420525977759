import styled from 'styled-components';
import {
  spacing2,
  spacing4,
  neutral10,
  spacing3,
} from '@leaftrade/leaftrade-component-library';

export const CheckoutItemsHeader = styled.div`
  display: flex;
  align-items: center;
  padding: 0 0 ${spacing2};
  border-bottom: 1px solid ${neutral10.hex};
  margin-bottom: ${spacing4};
`;

export const CheckoutItem = styled.div`
  display: flex;
  align-items: center;
  padding: ${spacing3} 0;
  border-bottom: 1px solid ${neutral10.hex};
`;

export const CheckoutItemName = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${spacing2};
  flex: 0 0 50%;
`;
