import camelize from 'camelize';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import {
  AlertBar,
  Button,
  Card,
  Link,
  Typography,
  fadeInAnimation,
  fadeOutAnimation,
  TextField,
  IconReveal,
  IconHide,
  spacing4,
} from '@leaftrade/leaftrade-component-library';
import { jsonGet } from '../../../dashboard/components/shared/jsonAPI';
import { validateEmail } from '../../../dashboard/utils';
import { LoginActions, LoginExtras, LoginWrapper } from './Login.styles';

const LogIn = ({
  email = '',
  errors = {},
  next,
  password = '',
  urlAccountReset,
  urlGetSso,
  urlSso,
  userRegistrationHubspot,
  vendorRegistrationUrl,
}) => {
  const [eml, setEmail] = useState(email);
  const [pwd, setPassword] = useState(password);
  const [showPassword, setShowPassword] = useState(false);
  const [sso, setSso] = useState();
  const showDefaultLogIn = !sso;

  const fetchSso = async () => {
    let logInSso = null;
    const response = await jsonGet(
      `${urlGetSso}?email=${encodeURIComponent(eml)}`
    );

    if (response.ok) {
      const payload = await response.json();
      const ssos = Object.values(payload);
      if (ssos && !!ssos.length) {
        logInSso = camelize(ssos);
      }
    }

    setSso(logInSso);
  };

  useEffect(() => {
    if (validateEmail(eml)) {
      fetchSso();
    } else {
      setSso(undefined);
    }
  }, [eml]);

  // eslint-disable-next-line no-underscore-dangle
  const globalErrors = errors.__all__;

  return (
    <LoginWrapper>
      <Card
        elevation={2}
        paddingSize='large'
        sx={{
          flexDirection: 'column',
          gap: spacing4,
          margin: 'auto',
          alignItems: 'center',
          width: '80%',
          minWidth: '300px',
          maxWidth: '750px',
        }}
      >
        <Typography component='h2'>Sign In to Leaf Trade</Typography>
        {!!globalErrors &&
          globalErrors.map((error) => (
            <AlertBar
              alertMessages={[error]}
              alertSeverity='error'
              alertTitle='Error'
              animationIn={fadeInAnimation}
              animationOut={fadeOutAnimation}
              key={error}
              sx={{
                margin: `${spacing4} 0`,
              }}
            />
          ))}
        <TextField
          autoFocus
          error={!!errors.username}
          id='id_username'
          label='Email'
          maxLength={254}
          message={errors.username}
          name='username'
          onChange={({ target: { value } }) => {
            setSso(undefined);
            setEmail(value);
          }}
          placeholder='Email'
          sx={{ width: '100%' }}
          type='email'
          value={eml}
        />
        {showDefaultLogIn && (
          <TextField
            endAdornment={
              showPassword ? (
                <IconHide onClick={() => setShowPassword(!showPassword)} />
              ) : (
                <IconReveal onClick={() => setShowPassword(!showPassword)} />
              )
            }
            error={!!errors.password}
            id='id_password'
            label='Password'
            message={errors.password}
            name='password'
            onChange={({ target: { value } }) => setPassword(value)}
            placeholder='Password'
            sx={{ width: '100%' }}
            type={showPassword ? 'text' : 'password'}
            value={pwd}
          />
        )}
        <LoginActions>
          {sso &&
            sso.map(({ displayName, name }) => (
              <Link
                asButton
                href={`${urlSso.replace(
                  ':sso',
                  name
                )}?email=${eml}&next=${next}`}
              >
                Sign in with {displayName}
              </Link>
            ))}
          {showDefaultLogIn && (
            <>
              <Button
                buttonType='submit'
                onClick={(e) => {
                  if (sso === undefined) {
                    e.preventDefault();
                  }
                }}
                testingId={`log-in-button${
                  sso === undefined ? '-disabled' : ''
                }`}
              >
                Log In
              </Button>
              <Link asButton buttonVariant='secondary' href={urlAccountReset}>
                Forgot password?
              </Link>
            </>
          )}
        </LoginActions>
        <hr />
        <LoginExtras>
          <Link
            asButton
            buttonVariant='outlined'
            href={userRegistrationHubspot}
          >
            Request a Seller Demo
          </Link>
          <Link asButton buttonVariant='outlined' href={vendorRegistrationUrl}>
            Set Up a Buyer Account
          </Link>
        </LoginExtras>
      </Card>
    </LoginWrapper>
  );
};

LogIn.propTypes = {
  email: PropTypes.string,
  errors: PropTypes.object,
  next: PropTypes.string,
  password: PropTypes.string,
  urlAccountReset: PropTypes.string.isRequired,
  urlGetSso: PropTypes.string.isRequired,
  urlSso: PropTypes.string.isRequired,
  userRegistrationHubspot: PropTypes.string.isRequired,
  vendorRegistrationUrl: PropTypes.string.isRequired,
};

export default LogIn;
