import { axiosGetData } from '../../helpers/axios';

export const setLocationCall = ({
  locationId,
  dispensaryId,
  onSuccess,
  rejectWithValue,
  vendorSlug,
}) => {
  let url = '/storefront2/shop-as';
  if (vendorSlug) {
    url += `/vendor/${vendorSlug}`;
  }
  if (dispensaryId) {
    url += `/dispensary/${dispensaryId}`;
  }
  if (locationId) {
    url += `/location/${locationId}`;
  }
  return axiosGetData({
    onSuccess,
    rejectWithValue,
    url,
  });
};
