import toCamelCase from './toCamelCase';

function objectToCamelCase(obj) {
  if (Array.isArray(obj)) {
    return obj.map((v) => objectToCamelCase(v));
  }
  if (obj !== null && typeof obj === 'object') {
    return Object.keys(obj).reduce((acc, cur) => {
      const camelCaseKey = toCamelCase(cur);
      const value = objectToCamelCase(obj[cur]);
      return {
        ...acc,
        [camelCaseKey]: value,
      };
    }, {});
  }
  return obj;
}

export default objectToCamelCase;
