import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import { axiosGetData } from '../../helpers/axios';

export const getOrders = createAsyncThunk(
  'getOrders',
  async ({ onSuccess, url }, { rejectWithValue }) =>
    axiosGetData({
      onSuccess,
      rejectWithValue,
      url,
    })
);

export const getTopVendors = createAsyncThunk(
  'getTopVendors',
  async ({ onSuccess, url }, { rejectWithValue }) =>
    axiosGetData({
      onSuccess,
      rejectWithValue,
      url,
    })
);

export const setPayload = createAction('setPayload');
