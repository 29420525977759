export const brandPageViewEvent = ({
  vendorId,
  brandId,
  brandPageId,
}) => {
  window.dataLayer.push({
    event: 'brand_page_view',
    vendor_id: vendorId,
    brand_id: brandId,
    brand_page_id: brandPageId
  });
};

export const brandPageTileViewEvent = ({
  vendorId,
  brandId,
  brandPageId,
  brandPageTilePosition,
  brandPageTileMediaUrl,
}) => {
  window.dataLayer.push({
    event: 'brand_page_tile_view',
    vendor_id: vendorId,
    brand_id: brandId,
    brand_page_id: brandPageId,
    brand_page_tile_position: brandPageTilePosition,
    brand_page_tile_media_url: brandPageTileMediaUrl,
  });
};

export const brandPageTileClickEvent = ({
  vendorId,
  brandId,
  brandPageId,
  brandPageTilePosition,
  brandPageTileMediaUrl,
  brandPageTileClickTargetUrl,
}) => {
  window.dataLayer.push({
    event: 'brand_page_tile_click',
    vendor_id: vendorId,
    brand_id: brandId,
    brand_page_id: brandPageId,
    brand_page_tile_position: brandPageTilePosition,
    brand_page_tile_media_url: brandPageTileMediaUrl,
    brand_page_tile_click_target_url: brandPageTileClickTargetUrl,
  });
};
