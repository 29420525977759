import { useSelector } from 'react-redux';

export const useCannabisBatchState = () =>
  useSelector((state) => state.cannabisBatch);
export const useBrandPagesState = () =>
  useSelector((state) => state.brandPages);
export const useWebhookDashboardState = () =>
  useSelector((state) => state.webhookDashboard);
export const useDispensaryDashboardState = () =>
  useSelector((state) => state.dispensaryDashboard);
export const useOrderCreateState = () =>
  useSelector((state) => state.orderCreate);
export const useStorefrontState = () =>
  useSelector((state) => state.storefront);
export const useProductsState = () => useSelector((state) => state.products);
export const useBatchesState = () => useSelector((state) => state.batches);
export const useUserState = () => useSelector((state) => state.user);
export const useVendorBrandsState = () =>
  useSelector((state) => state.vendorBrands);
export const useInventoryPricingState = () =>
  useSelector((state) => state.inventoryPricing);
export const useCommonState = () => useSelector((state) => state.common);
