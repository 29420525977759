import PropTypes from 'prop-types';
import React from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation, Link } from 'react-router-dom';
import {
  IconDashboard,
  IconOrders,
  IconSeller,
  IconUser,
} from '@leaftrade/leaftrade-component-library';
import { STOREFRONT_MODE } from '../../../constants';
import { useStorefrontState } from '../../../../hooks/useReduxState';
import {
  addTimedBanner,
  setLocationFromHeader,
} from '../../../../store/actions/storefrontActions';
import {
  HeaderActionsWrapper,
  HeaderActionsLocationName,
  HeaderActionsLocationNameWrapper,
  HeaderActionsCTAWrapper,
} from '../../../../shared/styles/HeaderActions.styles';
import HeaderCart from '../HeaderCart';
import HeaderSelect from '../HeaderSelect';

const HeaderActions = ({ hasLimitedAccess, loggedOutAccess }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const { authUser, cart, navigation, shopper, vendor } = useStorefrontState();
  const { dispensaries, vendors } = authUser;
  const { selectedCart } = cart;
  const { dispensary, currentLocation, locations } = shopper;
  const { quick_order_enabled: quickOrderEnabled } = dispensary || {};
  const { links } = navigation;
  const { settings, slug } = vendor;
  const { settingsDeprecated } = settings || {};
  const {
    storefront_aggregate_variants: storefrontAggregateVariants,
    storefront_mode: storefrontMode,
  } = settingsDeprecated || {};

  const isQuickOrderPage = location.pathname.includes('/quick-order');

  const handleLocationSelection = (dispensaryId, locationId) => {
    if (locationId.toString() === currentLocation?.id.toString()) return;
    dispatch(
      setLocationFromHeader({
        dispensaryId,
        locationId,
        vendorSlug: slug || null,
      })
    );
    if (selectedCart.quantity && selectedCart.quantity > 0) {
      dispatch(
        addTimedBanner({
          id: `${currentLocation?.id}-cart-saved`,
          message: `Your cart for ${currentLocation?.name} has been saved`,
          severity: 'success',
        })
      );
    }
  };

  const locationSelectMenuItems = !hasLimitedAccess
    ? [
        {
          label: dispensary.name,
          level: 0,
          key: dispensary.name,
        },
        ...locations.map((loc) => ({
          action: () => handleLocationSelection(dispensary.id, loc.id),
          label: loc.name,
          level: 1,
          key: loc.name,
        })),
      ]
    : [];

  const accountSelectMenuItems = [
    {
      label: 'Account',
      level: 0,
      key: 'account',
    },
    ...links.map((link) => ({
      action: () => window.location.assign(link.href),
      label: link.text,
      level: 1,
      key: link.text,
    })),
  ];

  if (vendors.length) {
    accountSelectMenuItems.push({
      label: 'Vendor Accounts',
      level: 0,
      key: 'vendor-accounts',
    });
    accountSelectMenuItems.push(
      ...vendors.map((vendor) => ({
        action: () => window.location.assign(`/dashboard/vendor/${vendor.id}`),
        label: vendor.name,
        level: 1,
        key: vendor.name,
      }))
    );
  }

  const handleDispensarySelection = async (dispensaryId) => {
    if (dispensaryId === dispensary.id) return;
    await dispatch(
      setLocationFromHeader({
        dispensaryId,
        vendorSlug: slug || null,
      })
    );
    if (selectedCart.quantity && selectedCart.quantity > 0) {
      await dispatch(
        addTimedBanner({
          id: `${dispensary?.name}-${currentLocation?.name}-cart-saved`,
          message: `Your cart for ${dispensary?.name} at ${currentLocation?.name} has been saved`,
          severity: 'success',
        })
      );
    }
    await history.push(`/storefront/`);
  };

  if (dispensaries.length) {
    accountSelectMenuItems.push({
      label: 'Customer Accounts',
      level: 0,
      key: 'customer-accounts',
    });
    accountSelectMenuItems.push(
      ...dispensaries.map((dispensary) => ({
        label: dispensary.name,
        level: 1,
        key: dispensary.name,
        action: hasLimitedAccess
          ? () => window.location.assign(`/shop-as/?customer=${dispensary.id}`)
          : () => handleDispensarySelection(dispensary.id),
      }))
    );
  }

  const showQuickOrderLink =
    !isQuickOrderPage &&
    !hasLimitedAccess &&
    quickOrderEnabled &&
    !storefrontAggregateVariants &&
    storefrontMode === STOREFRONT_MODE.PURCHASING_ACTIVE &&
    slug;

  const showCurrentLocationName = !isQuickOrderPage && slug;

  return (
    <>
      <HeaderActionsWrapper data-testid='header-actions--component--div'>
        {!hasLimitedAccess && (
          <>
            {locations.length > 1 && !isQuickOrderPage ? (
              <HeaderSelect
                icon={<IconSeller />}
                title={dispensary.name}
                subtitle={currentLocation.name}
                menuItems={locationSelectMenuItems}
                testingId='header-actions--header-select--location--component'
              />
            ) : (
              <HeaderActionsLocationNameWrapper>
                <IconSeller />
                <HeaderActionsLocationName>
                  <span>{dispensary.name}</span>
                  {showCurrentLocationName && (
                    <span>{currentLocation.name}</span>
                  )}
                </HeaderActionsLocationName>
              </HeaderActionsLocationNameWrapper>
            )}
            {showQuickOrderLink && (
              <HeaderActionsCTAWrapper>
                <Link
                  data-testid='header-actions--quick-order--link'
                  to={`/storefront/sellers/${slug}/quick-order`}
                >
                  <IconOrders />
                  Quick Order
                </Link>
              </HeaderActionsCTAWrapper>
            )}
            <HeaderActionsCTAWrapper>
              <a
                data-testid='header-actions--dashboard--a'
                href={`/dashboard/customer/${dispensary.id}`}
              >
                <IconDashboard />
                Dashboard
              </a>
            </HeaderActionsCTAWrapper>
          </>
        )}
        {loggedOutAccess ? (
          <HeaderActionsCTAWrapper>
            <a data-testid='header-actions--dashboard--a' href='/account/login'>
              <IconUser />
              Login
            </a>
          </HeaderActionsCTAWrapper>
        ) : (
          <HeaderSelect
            icon={<IconUser />}
            title='Account'
            menuItems={accountSelectMenuItems}
            testingId='header-actions--header-select--account--component'
          />
        )}
      </HeaderActionsWrapper>
      {slug &&
        currentLocation?.id &&
        !isQuickOrderPage &&
        !hasLimitedAccess && <HeaderCart />}
    </>
  );
};

HeaderActions.propTypes = {
  hasLimitedAccess: PropTypes.bool,
  loggedOutAccess: PropTypes.bool,
};

HeaderActions.defaultProps = {
  hasLimitedAccess: false,
  loggedOutAccess: false,
};

export default HeaderActions;
