import {
  neutral10,
  spacing5,
  spacing6,
} from '@leaftrade/leaftrade-component-library';
import styled from 'styled-components';

export const ProductDetailsAccordionWrapper = styled.div`
  border-bottom: 1px solid ${neutral10.hex};
`;

export const ProductDetailsAccordionHeader = styled.button`
  background: none;
  border: none;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: ${spacing5} 0;
  & svg {
    width: ${spacing6};
    height: ${spacing6};
  }
`;

export const ProductDetailsAccordionContent = styled.div`
  padding-bottom: ${spacing6};
`;
