import {
  error20,
  success20,
  warning20,
} from '@leaftrade/leaftrade-component-library';

export const getProductFilterTypeFromCategoryName = (categoryName) => {
  switch (categoryName) {
    case 'cannabis':
      return 1;
    case 'hemp':
      return 1;
    case 'ancillary':
      return 2;
    default:
      return null;
  }
};

export const formChildCategoryUrl = (child, sectionName) => {
  if (sectionName.toLowerCase() === 'brands') {
    if (child.slugs.length > 1) {
      return {
        url: `/storefront/sellers?brands_filters=${child.ids.toString()}`,
        slug: undefined,
      };
    }
    return {
      url: `/storefront/sellers/${
        child.slugs[0]
      }?brands=${child.ids.toString()}`,
      slug: child.slugs[0],
    };
  }
  return {
    url: `/storefront/sellers/${child.slug}`,
    slug: child.slug,
  };
};

export const findCannabinoidsTooltipDisplayValue = (
  primaryLabValues,
  currentlySelectedVariant
) => {
  switch (primaryLabValues) {
    case 'total_cannabinoids':
      return (
        (currentlySelectedVariant?.cannabinoids?.total_cannabinoids &&
          currentlySelectedVariant?.cannabinoids?.total_cannabinoids >= 0) ||
        null
      );
    case 'thca_cbda':
      return (
        (currentlySelectedVariant?.cannabinoids?.thc_a &&
          currentlySelectedVariant?.cannabinoids?.thc_a >= 0) ||
        (currentlySelectedVariant?.cannabinoids?.cbd_a &&
          currentlySelectedVariant?.cannabinoids?.cbd_a >= 0) ||
        null
      );
    case 'thc_cbd':
      return (
        (currentlySelectedVariant?.cannabinoids?.thc &&
          currentlySelectedVariant?.cannabinoids?.thc >= 0) ||
        (currentlySelectedVariant?.cannabinoids?.cbd &&
          currentlySelectedVariant?.cannabinoids?.cbd >= 0) ||
        null
      );

    case 'total_thc_cbd':
    default:
      return (
        (currentlySelectedVariant.cannabinoids?.total_thc &&
          currentlySelectedVariant.cannabinoids?.total_thc >= 0) ||
        (currentlySelectedVariant.cannabinoids?.total_cbd &&
          currentlySelectedVariant.cannabinoids?.total_cbd >= 0) ||
        null
      );
  }
};

export const getVariantKey = (variant) =>
  variant.batch == null
    ? variant.id
    : `${variant.verbose_name}-` +
      `${variant.size}${variant.size_unit}-` +
      `${variant.batch.batch_ref}-` +
      `${variant.is_saleable}-` +
      `${variant.price}`;

export const aggregateUniqueVariants = (variants) => {
  const variantsByKey = {};
  const uniqueVariants = [];

  variants.forEach((variant) => {
    const key = getVariantKey(variant);

    const variantArray = variantsByKey[key] || [];
    variantArray.push(variant);
    variantsByKey[key] = variantArray;

    if (variantArray.length === 1) {
      uniqueVariants.push(variant);
    }
  });

  return {
    uniqueVariants,
    variantsByKey,
  };
};

export const PHENOTYPE_BADGE_CLASS = {
  hybrid: 'hybrid-chip',
  indica: 'indica-chip',
  'indica dominant': 'indica-d-chip',
  sativa: 'sativa-chip',
  'sativa dominant': 'sativa-d-chip',
  cbd: 'cbd-chip',
};

export const printPhenotype = (derivedPhenotype) => {
  switch (derivedPhenotype) {
    case 'Sativa Dominant':
      return 'Sativa Dom.';
    case 'Indica Dominant':
      return 'Indica Dom.';
    default:
      return derivedPhenotype;
  }
};

export const determineCannabinoids = ({
  primaryLabValues,
  selectedVariant,
}) => {
  if (
    !selectedVariant.stock_record?.batch ||
    selectedVariant.stock_record?.batch?.cannabinoids.length === 0
  ) {
    return [];
  }
  let cannabinoidsArray = [];
  switch (primaryLabValues) {
    case 'total_cannabinoids':
      cannabinoidsArray = [
        { name: 'Total', value: selectedVariant.total_cannabinoids },
      ];
      break;
    case 'thca_cbda':
      cannabinoidsArray.push(
        selectedVariant.stock_record?.batch?.cannabinoids.find(
          (cannabinoid) => cannabinoid.name === 'THC-A'
        )
      );
      cannabinoidsArray.push(
        selectedVariant.stock_record?.batch?.cannabinoids.find(
          (cannabinoid) => cannabinoid.name === 'CBD-A'
        )
      );
      break;
    case 'thc_cbd':
      cannabinoidsArray.push(
        selectedVariant.stock_record?.batch?.cannabinoids.find(
          (cannabinoid) => cannabinoid.name === 'THC'
        )
      );
      cannabinoidsArray.push(
        selectedVariant.stock_record?.batch?.cannabinoids.find(
          (cannabinoid) => cannabinoid.name === 'CBD'
        )
      );
      break;
    case 'total_thc_cbd':
    default:
      cannabinoidsArray = [
        { name: 'THC', value: selectedVariant.total_thc },
        { name: 'CBD', value: selectedVariant.total_cbd },
      ];
  }
  return cannabinoidsArray;
};

export const addToCartQtyMsg = (newQuantity, requestedQuantity, add) => {
  const msg = { title: '', subtitle: '' };
  if (newQuantity < requestedQuantity) {
    msg.title =
      'Due to high demand, you have reached the order limit for this item. ';
    if (add) {
      msg.subtitle = `${newQuantity} added to your cart.`;
    } else {
      msg.subtitle = `${newQuantity} are in your cart.`;
    }
  } else {
    msg.title = 'There is a minimum quantity for this item. ';
    if (add) {
      msg.subtitle = `${newQuantity} were added to your cart.`;
    } else {
      msg.subtitle = `${newQuantity} are in your cart.`;
    }
  }
  return msg;
};

export const getArrowColor = (alertType) => {
  switch (alertType) {
    case 'success':
      return success20.hex;
    case 'warning':
      return warning20.hex;
    case 'error':
      return error20.hex;
    default:
      return success20.hex;
  }
};

export const stateSelectArray = [
  { label: 'AL', level: 1, key: 'AL', value: 'AL' },
  { label: 'AK', level: 1, key: 'AK', value: 'AK' },
  { label: 'AS', level: 1, key: 'AS', value: 'AS' },
  { label: 'AZ', level: 1, key: 'AZ', value: 'AZ' },
  { label: 'AR', level: 1, key: 'AR', value: 'AR' },
  { label: 'CA', level: 1, key: 'CA', value: 'CA' },
  { label: 'CO', level: 1, key: 'CO', value: 'CO' },
  { label: 'CT', level: 1, key: 'CT', value: 'CT' },
  { label: 'DE', level: 1, key: 'DE', value: 'DE' },
  { label: 'DC', level: 1, key: 'DC', value: 'DC' },
  { label: 'FM', level: 1, key: 'FM', value: 'FM' },
  { label: 'FL', level: 1, key: 'FL', value: 'FL' },
  { label: 'GA', level: 1, key: 'GA', value: 'GA' },
  { label: 'GU', level: 1, key: 'GU', value: 'GU' },
  { label: 'HI', level: 1, key: 'HI', value: 'HI' },
  { label: 'ID', level: 1, key: 'ID', value: 'ID' },
  { label: 'IL', level: 1, key: 'IL', value: 'IL' },
  { label: 'IN', level: 1, key: 'IN', value: 'IN' },
  { label: 'IA', level: 1, key: 'IA', value: 'IA' },
  { label: 'KS', level: 1, key: 'KS', value: 'KS' },
  { label: 'KY', level: 1, key: 'KY', value: 'KY' },
  { label: 'LA', level: 1, key: 'LA', value: 'LA' },
  { label: 'ME', level: 1, key: 'ME', value: 'ME' },
  { label: 'MH', level: 1, key: 'MH', value: 'MH' },
  { label: 'MD', level: 1, key: 'MD', value: 'MD' },
  { label: 'MA', level: 1, key: 'MA', value: 'MA' },
  { label: 'MI', level: 1, key: 'MI', value: 'MI' },
  { label: 'MN', level: 1, key: 'MN', value: 'MN' },
  { label: 'MS', level: 1, key: 'MS', value: 'MS' },
  { label: 'MO', level: 1, key: 'MO', value: 'MO' },
  { label: 'MT', level: 1, key: 'MT', value: 'MT' },
  { label: 'NE', level: 1, key: 'NE', value: 'NE' },
  { label: 'NV', level: 1, key: 'NV', value: 'NV' },
  { label: 'NH', level: 1, key: 'NH', value: 'NH' },
  { label: 'NJ', level: 1, key: 'NJ', value: 'NJ' },
  { label: 'NM', level: 1, key: 'NM', value: 'NM' },
  { label: 'NY', level: 1, key: 'NY', value: 'NY' },
  { label: 'NC', level: 1, key: 'NC', value: 'NC' },
  { label: 'ND', level: 1, key: 'ND', value: 'ND' },
  { label: 'MP', level: 1, key: 'MP', value: 'MP' },
  { label: 'OH', level: 1, key: 'OH', value: 'OH' },
  { label: 'OK', level: 1, key: 'OK', value: 'OK' },
  { label: 'OR', level: 1, key: 'OR', value: 'OR' },
  { label: 'PW', level: 1, key: 'PW', value: 'PW' },
  { label: 'PA', level: 1, key: 'PA', value: 'PA' },
  { label: 'PR', level: 1, key: 'PR', value: 'PR' },
  { label: 'RI', level: 1, key: 'RI', value: 'RI' },
  { label: 'SC', level: 1, key: 'SC', value: 'SC' },
  { label: 'SD', level: 1, key: 'SD', value: 'SD' },
  { label: 'TN', level: 1, key: 'TN', value: 'TN' },
  { label: 'TX', level: 1, key: 'TX', value: 'TX' },
  { label: 'UT', level: 1, key: 'UT', value: 'UT' },
  { label: 'VT', level: 1, key: 'VT', value: 'VT' },
  { label: 'VI', level: 1, key: 'VI', value: 'VI' },
  { label: 'VA', level: 1, key: 'VA', value: 'VA' },
  { label: 'WA', level: 1, key: 'WA', value: 'WA' },
  { label: 'WV', level: 1, key: 'WV', value: 'WV' },
  { label: 'WI', level: 1, key: 'WI', value: 'WI' },
  { label: 'WY', level: 1, key: 'WY', value: 'WY' },
];

export const countriesArray = [
  {
    label: 'United States',
    level: 1,
    key: 'US',
    value: 'US',
  },
];

export const getBoxShadowStringFromElevation = (elevation) =>
  elevation.toString().replace('box-shadow: ', '').replace(';', '');
