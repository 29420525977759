import React from 'react';
import { useStorefrontState } from '../../../../hooks/useReduxState';
import ProductDetailsAttachmentsAccordion from '../ProductDetailsAttachmentsAccordion';
import ProductDetailsBrandsAccordion from '../ProductDetailsBrandsAccordion';
import ProductDetailsBundleContentsAccordion from '../ProductDetailsBundleContentsAccordion';
import ProductDetailsCannabinoidsAccordion from '../ProductDetailsCannabinoidsAccordion';
import ProductDetailsDescriptionAccordion from '../ProductDetailsDescriptionAccordion';
import ProductDetailsStrainsAccordion from '../ProductDetailsStrainsAccordion';
import ProductDetailsTerpenesAccordion from '../ProductDetailsTerpenesAccordion';
import { ProductDetailsContentBottomWrapper } from './ProductDetailsContentBottom.styles';

const ProductDetailsContentBottom = () => {
  const { productDetails } = useStorefrontState();
  const {
    description,
    selectedVariant,
    variants,
    strains,
    brands,
    marketingMaterials,
    instructions,
  } = productDetails;

  return (
    <ProductDetailsContentBottomWrapper>
      {description && <ProductDetailsDescriptionAccordion />}
      {Boolean(selectedVariant?.child_relationships?.length) && (
        <ProductDetailsBundleContentsAccordion />
      )}
      {selectedVariant?.stock_record?.batch &&
        Boolean(
          Object.keys(selectedVariant?.stock_record?.batch?.cannabinoids).length
        ) && <ProductDetailsCannabinoidsAccordion />}
      {selectedVariant?.stock_record?.batch &&
        Boolean(
          Object.keys(selectedVariant?.stock_record?.batch?.terpenes).length
        ) && <ProductDetailsTerpenesAccordion />}
      {Boolean(
        variants.some(
          (variant) => variant.stock_record?.batch?.attachments?.length
        ) ||
          marketingMaterials.length ||
          instructions.length
      ) && <ProductDetailsAttachmentsAccordion />}
      {Boolean(strains.length) && <ProductDetailsStrainsAccordion />}
      {Boolean(brands.length) && <ProductDetailsBrandsAccordion />}
    </ProductDetailsContentBottomWrapper>
  );
};

export default ProductDetailsContentBottom;
