import {
  error20,
  error40,
  neutral0,
  neutral100,
  neutral90,
  pxToRem,
  spacing3,
  spacing4,
  success20,
  success40,
} from '@leaftrade/leaftrade-component-library';
import styled from 'styled-components';
import { STOREFRONT_MODE } from '../../../constants';

export const BadgeWrapper = styled.div`
  position: absolute;
  left: -${pxToRem(8)};
  display: flex;
  height: ${pxToRem(20)};
  margin-right: ${spacing3};
  padding: 0 ${spacing4};
  border-radius: 12px;
  color: ${neutral0.hex};
  background-color: ${({ storefrontMode }) => {
    switch (storefrontMode) {
      case STOREFRONT_MODE.COMING_SOON:
        return success20.hex;
      case STOREFRONT_MODE.INACTIVE:
        return neutral100.hex;
      case STOREFRONT_MODE.READ_ONLY_MENU:
        return error20.hex;
      default:
        return neutral90.hex;
    }
  }};
  &:first-of-type {
    border-radius: 0 12px 12px 0;
    &::after {
      content: '';
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 0 8px 8px 0;
      left: 0;
      top: ${pxToRem(20)};
      position: absolute;
      border-color: transparent
        ${({ storefrontMode }) => {
          switch (storefrontMode) {
            case STOREFRONT_MODE.COMING_SOON:
              return success40.hex;
            case STOREFRONT_MODE.INACTIVE:
              return neutral100.hex;
            case STOREFRONT_MODE.READ_ONLY_MENU:
              return error40.hex;
            default:
              return neutral100.hex;
          }
        }}
        transparent transparent;
    }
  }
`;
