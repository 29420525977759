import {
  Card,
  Link,
  spacing6,
  Typography,
} from '@leaftrade/leaftrade-component-library';
import PropTypes from 'prop-types';
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { PageNotFoundWrapper } from './PageNotFound.styles';

const PageNotFound = ({ hasLimitedAccess }) => (
  <PageNotFoundWrapper data-testid='page-not-found--component--div'>
    <Card
      paddingSize='large'
      sx={{
        margin: 'auto',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        gap: spacing6,
      }}
    >
      <Typography component='h1' align='center'>
        404
      </Typography>
      <Typography component='h2' align='center'>
        Page Not Found!
      </Typography>
      <Typography align='center'>
        You might have tried an invalid URL or the resource you were trying to
        access may no longer exist.
      </Typography>
      <Typography align='center'>
        <span>Need Help?</span> Chat with our team by clicking the chat icon in
        the bottom right corner.
      </Typography>
      {!hasLimitedAccess && (
        <Link asButton component={RouterLink} to='/storefront'>
          Return to Storefront
        </Link>
      )}
    </Card>
  </PageNotFoundWrapper>
);

PageNotFound.defaultProps = {
  hasLimitedAccess: false,
  statusError: {},
};

PageNotFound.propTypes = {
  hasLimitedAccess: PropTypes.bool,
  statusError: PropTypes.shape({
    statusText: PropTypes.string,
    status: PropTypes.number,
  }),
};

export default PageNotFound;
