import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useStorefrontState } from '../../../hooks/useReduxState';
import PageLoader from '../../../shared/components/PageLoader';
import { getLandingPageData } from '../../../store/actions/storefrontActions';
import LandingPage from '../../components/LandingPage';

const Landing = () => {
  const dispatch = useDispatch();
  const { landingPageData } = useStorefrontState();
  const { isLoadingLandingData } = landingPageData;

  useEffect(() => {
    dispatch(getLandingPageData());
  }, []);

  return <>{isLoadingLandingData ? <PageLoader /> : <LandingPage />}</>;
};

export default Landing;
