import {
  AlertTooltip,
  Button,
  IconLoadingSpinner,
  IconRefresh,
  Input,
  Typography,
  bodySmallTypography,
  boldBodySmallTypography,
  neutral0,
  neutral20,
  pxToRem,
  spacing3,
} from '@leaftrade/leaftrade-component-library';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useStorefrontState } from '../../../../hooks/useReduxState';
import {
  editQuickOrderItemQuantity,
  getCartDataSummary,
} from '../../../../store/actions/storefrontActions';
import { QuickOrderTableLocationInputWrapper } from './QuickOrderTableLocationInput.styles';

const QuickOrderTableLocationInput = ({ item, itemAlert, location }) => {
  const dispatch = useDispatch();
  const { quickOrderData, vendor } = useStorefrontState();
  const { editingAllItemQuantity, selectedLocations } = quickOrderData;
  const { settings, slug } = vendor;
  const { batchQueuesEnabled } = settings;

  const currentItem = batchQueuesEnabled ? item : item.variants[0];

  const initialLocationValue =
    currentItem.locations.find(
      (variantLocation) =>
        Number(variantLocation.dispensaryLocationId) === Number(location.id)
    )?.quantity || 0;

  const [locationValue, setLocationValue] = useState(initialLocationValue);
  const [isUpdatingQuantity, setIsUpdatingQuantity] = useState(false);
  const [isRefreshing, setIsRefreshing] = useState(false);
  const [tooltipOpen, setTooltipOpen] = useState(false);

  const selectedLocationsIds = selectedLocations.map((location) =>
    Number(location.id)
  );

  const handleQuickOrderRefresh = () => {
    setIsRefreshing(true);
    dispatch(
      editQuickOrderItemQuantity({
        batchQueuesEnabled,
        displayedLocationsIds: selectedLocationsIds,
        editLocationsIds: [Number(location.id)],
        item,
        isRefresh: true,
        onSuccess: () => {
          setIsRefreshing(false);
        },
        slug,
      })
    );
  };

  const handleLocationQuantityBlur = (value) => {
    if (Number(value) !== Number(initialLocationValue)) {
      setIsUpdatingQuantity(true);
      dispatch(
        editQuickOrderItemQuantity({
          batchQueuesEnabled,
          displayedLocationsIds: selectedLocationsIds,
          editLocationsIds: [Number(location.id)],
          item,
          onSuccess: () => {
            setIsUpdatingQuantity(false);
            dispatch(getCartDataSummary({ slug }));
          },
          quantity: Number(value),
          slug,
        })
      );
    }
  };

  const isDisabled =
    editingAllItemQuantity?.item?.cartItemId === item.cartItemId ||
    isUpdatingQuantity;

  const alertIsError = itemAlert?.level === 'error';

  const alertIsStockIssue =
    itemAlert?.message?.toLowerCase().includes('out of stock') ||
    itemAlert?.message?.toLowerCase().includes('low stock');

  useEffect(() => {
    setLocationValue(initialLocationValue);
  }, [initialLocationValue]);

  return (
    <>
      {isRefreshing ? (
        <IconLoadingSpinner />
      ) : (
        <QuickOrderTableLocationInputWrapper>
          <Input
            disabled={isDisabled}
            onBlur={(e) => handleLocationQuantityBlur(e.target.value)}
            onChange={(e) => setLocationValue(e.target.value)}
            onKeyUp={(e) => {
              if (e.key === 'Enter') {
                handleLocationQuantityBlur(e.target.value);
              }
            }}
            placeholder='0'
            startAdornment={
              itemAlert && (
                <AlertTooltip
                  alertType={itemAlert.level}
                  clickable
                  id={`${item.cartItemId}-${location.id}`}
                  isOpen={tooltipOpen}
                  setIsOpen={setTooltipOpen}
                  tooltipPlacement='left'
                >
                  <>
                    {typeof itemAlert === 'string' ? (
                      <Typography bodyStyle={bodySmallTypography} gutterBottom>
                        {itemAlert}
                      </Typography>
                    ) : (
                      <>
                        <Typography
                          bodyStyle={bodySmallTypography}
                          gutterBottom
                        >
                          {itemAlert.title || itemAlert.message}
                        </Typography>
                        <Typography
                          bodyStyle={bodySmallTypography}
                          sx={{ color: neutral20.hex }}
                        >
                          {itemAlert.subtitle || itemAlert.sub_message}
                        </Typography>
                      </>
                    )}
                  </>
                </AlertTooltip>
              )
            }
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              backgroundColor: neutral0.hex,
              minWidth: pxToRem(125),
              flex: 1,
              '&:focus-within': {
                backgroundColor: neutral0.hex,
              },
              '& input': {
                textAlign: 'center',
                backgroundColor: neutral0.hex,
                padding: `${spacing3} 0`,
              },
            }}
            testingId='quick-order-table-item-location-input--component--input'
            type='number'
            value={locationValue}
          />
          {batchQueuesEnabled && alertIsError && alertIsStockIssue && (
            <Button
              onClick={handleQuickOrderRefresh}
              size='xs'
              startIcon={<IconRefresh width='16px' height='16px' />}
              variant='secondary'
              sx={{
                position: 'absolute',
                bottom: pxToRem(3),
                left: 0,
                right: 0,
                margin: '0 auto',
                '&:hover': {
                  boxShadow: 'none',
                },
              }}
            >
              <Typography bodyStyle={boldBodySmallTypography} component='span'>
                Recheck
              </Typography>
            </Button>
          )}
        </QuickOrderTableLocationInputWrapper>
      )}
    </>
  );
};

QuickOrderTableLocationInput.propTypes = {
  item: PropTypes.shape({
    cartItemId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    locations: PropTypes.arrayOf(
      PropTypes.shape({
        dispensaryLocationId: PropTypes.oneOfType([
          PropTypes.string,
          PropTypes.number,
        ]),
        quantity: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      })
    ),
    itemAlert: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.shape({
        cartItemId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        level: PropTypes.string,
        locationId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        message: PropTypes.string,
        sub_message: PropTypes.string,
        subtitle: PropTypes.string,
        title: PropTypes.string,
      }),
    ]),
    variants: PropTypes.arrayOf(
      PropTypes.shape({
        locations: PropTypes.arrayOf(
          PropTypes.shape({
            dispensaryLocationId: PropTypes.oneOfType([
              PropTypes.string,
              PropTypes.number,
            ]),
            quantity: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
          })
        ),
      })
    ),
  }),
  location: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }).isRequired,
};

export default QuickOrderTableLocationInput;
