import { useLocation } from 'react-router-dom';
import { useMemo } from 'react';

function useQueryParam(key) {
  const location = useLocation();

  return useMemo(() => {
    const queryParams = new URLSearchParams(location.search);
    return queryParams.get(key);
  }, [key, location.search]);
}

export default useQueryParam;
