import { pxToRem, spacing7 } from '@leaftrade/leaftrade-component-library';
import styled from 'styled-components';

export const EmptyCartWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
`;

export const EmptyCartContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: ${spacing7};
  & svg {
    width: ${pxToRem(150)};
    height: ${pxToRem(150)};
  }
`;
