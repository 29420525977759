import {
  elevation1,
  elevation3,
  fontFamilyHeader,
  fontWeightSemiBold,
  interaction60,
  maxMediaQueryDesktop,
  maxMediaQueryTablet,
  minMediaQueryLargeDesktop,
  neutral0,
  neutral10,
  neutral60,
  neutral90,
  pxToRem,
  spacing3,
  spacing4,
  spacing5,
  spacing6,
  spacing7,
} from '@leaftrade/leaftrade-component-library';
import styled from 'styled-components';

export const LandingPageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 1440px;
  margin: 0 auto;
  padding: ${spacing7} ${pxToRem(135)};
  ${maxMediaQueryDesktop} {
    padding: ${spacing7} ${pxToRem(70)};
  }
  ${maxMediaQueryTablet} {
    padding: ${spacing7} ${spacing6};
  }
`;

export const LandingPageCardsContainer = styled.div`
  flex: 1;
  display: flex;
  flex-wrap: wrap;
  grid-gap: ${spacing5};
  align-self: center;
  padding: ${spacing5} ${spacing3};
`;

export const LandingPageNoWrapCardRow = styled.div`
  display: flex;
  grid-gap: ${pxToRem(40)};
  flex: 1;
`;

export const LandingPageWrapCardRow = styled(LandingPageNoWrapCardRow)`
  align-items: center;
  flex-wrap: wrap;
`;

export const LandingPageCardRowTitle = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${neutral10.hex};
  border-radius: 16px;
  width: ${pxToRem(240)};
  min-height: ${pxToRem(192)};
  padding: ${spacing6};
  & h4 {
    color: ${neutral90.hex};
  }
  & p {
    color: ${neutral60.hex};
  }
  & button {
    margin-top: auto;
  }
`;

export const LandingPageCard = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: ${pxToRem(160)};
  min-width: ${pxToRem(165)};
  flex: 1;
  border-radius: 12px;
  &:hover {
    ${elevation3()}
  }
  @media (hover: none) {
    ${elevation1()}
    &:active {
      ${elevation3()}
    }
  }
  & a {
    flex: 1;
    padding: ${spacing4};
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    height: 100%;
    min-width: ${pxToRem(165)};
    font-family: ${fontFamilyHeader};
    font-weight: ${fontWeightSemiBold};
    font-size: ${pxToRem(13)};
    text-decoration: none;
    color: ${interaction60.hex};
    &:hover {
      text-decoration: none;
    }
    & span {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      width: 100%;
      text-align: center;
    }
    & img {
      height: 100%;
      max-height: 110px;
      object-fit: contain;
    }
  }
`;

export const LandingPageGradientIcon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${pxToRem(80)};
  height: ${pxToRem(80)};
  margin: auto;
  position: relative;
  & h4 {
    position: absolute;
    pointer-events: none;
    color: ${neutral0.hex};
  }
  & svg {
    width: ${pxToRem(48)};
    height: ${pxToRem(48)};
    fill: ${neutral0.hex};
    position: absolute;
    pointer-events: none;
  }
`;

export const LandingPageGradientIconBackground = styled.span`
  background: linear-gradient(315deg, #bf73cc 23.44%, #9e95fc 79.06%);
  border-radius: 50px;
  width: ${pxToRem(80)};
  height: ${pxToRem(80)};
`;

export const LandingPageExploreFavoritesWrapper = styled.div`
  min-height: ${pxToRem(300)};
  margin: ${pxToRem(48)} 0 ${spacing6} 0;
  grid-gap: ${spacing5};
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  flex-direction: column;
  ${minMediaQueryLargeDesktop} {
    flex-direction: row;
  }
  & .landing-page-explore--explore-card--gradient {
    &-1 {
      background: linear-gradient(
          360deg,
          rgba(255, 255, 255, 0.2) 50%,
          rgba(255, 255, 255, 0) 100%
        ),
        linear-gradient(
          225deg,
          rgba(252, 196, 77, 0.6) 16.65%,
          rgba(241, 109, 154, 0.6) 83.34%
        ),
        linear-gradient(6.25deg, #ffffff 42.46%, rgba(255, 255, 255, 0) 83.89%),
        url('https://leaftrade-2-staging.s3.amazonaws.com/landing-explore/img_dispensary.jpg'),
        #f4f4f6;
      background-size: cover;
      background-position: center center;
    }
    &-2 {
      background: linear-gradient(
          360deg,
          rgba(255, 255, 255, 0.3) 50%,
          rgba(255, 255, 255, 0) 100%
        ),
        linear-gradient(
          225deg,
          rgba(114, 197, 189, 0.6) 16.68%,
          rgba(214, 221, 122, 0.6) 83.26%
        ),
        linear-gradient(6.25deg, #ffffff 42.46%, rgba(255, 255, 255, 0) 83.89%),
        url('https://leaftrade-2-staging.s3.amazonaws.com/landing-explore/img_flower.jpg'),
        #f4f4f6;
      background-size: cover;
      background-position: center center;
    }
    &-3 {
      background: linear-gradient(
          360deg,
          rgba(255, 255, 255, 0.5) 50%,
          rgba(255, 255, 255, 0) 100%
        ),
        linear-gradient(
          225deg,
          rgba(93, 201, 235, 0.6) 16.71%,
          rgba(158, 149, 252, 0.6) 83.45%
        ),
        linear-gradient(6.25deg, #ffffff 42.46%, rgba(255, 255, 255, 0) 83.89%),
        url('https://leaftrade-2-staging.s3.amazonaws.com/landing-explore/img_ancillary.jpg'),
        #f4f4f6;
      background-size: cover;
      background-position: center center;
    }
  }
`;
