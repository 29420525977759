import {
  interaction80,
  interaction5,
  neutral0,
  neutral5,
  spacing3,
  spacing4,
  spacing5,
  spacing7,
  spacing2,
} from '@leaftrade/leaftrade-component-library';
import styled from 'styled-components';

export const MultiCartSummaryBoxLocation = styled.button`
  display: flex;
  align-items: center;
  border: none;
  border-radius: 0;
  background: ${({ isCurrentCart }) =>
    isCurrentCart ? interaction5.hex : 'none'};
  padding: ${spacing3} ${spacing4};
  cursor: ${({ isCurrentCart }) => (isCurrentCart ? 'default' : 'pointer')};
  position: relative;
  &::before {
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    background: linear-gradient(
      to right,
      transparent 80%,
      ${({ isCurrentCart }) => (isCurrentCart ? '#e7e5fa' : `${neutral0.hex}`)}
    );
  }
  &:hover {
    background-color: ${({ isCurrentCart }) =>
      isCurrentCart ? interaction5.hex : neutral5.hex};
  }
  & svg {
    width: ${spacing5};
    height: ${spacing5};
    min-width: ${spacing5};
    min-height: ${spacing5};
  }
`;

export const MultiCartSummaryBoxLocationInfo = styled.span`
  margin-left: ${spacing3};
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: ${spacing2};
  overflow: hidden;
`;

export const MultiCartSummaryBoxLocationName = styled.span`
  color: ${interaction80.hex};
  white-space: nowrap;
`;

export const MultiCartSummaryBoxLocationQuantity = styled.span`
  background-color: #38fa7a;
  border-radius: 100px;
  margin-right: ${spacing3};
  width: min-content;
  height: min-content;
  display: inline-block;
  font-weight: 700;
  padding: 0 ${spacing2};
`;

export const MultiCartSummaryBoxTotalWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: ${spacing7};
`;
