import {
  Typography,
  bodySmallTypography,
  boldBodySmallTypography,
  mediumBodySmallTypography,
  neutral60,
  slugifyString,
} from '@leaftrade/leaftrade-component-library';
import React from 'react';
import { useStorefrontState } from '../../../../hooks/useReduxState';
import ProductChip from '../../ProductCard/ProductChip';
import ProductDetailsAccordion from '../ProductDetailsAccordion';
import {
  ProductChipsWrapper,
  StrainHeader,
  StrainWrapper,
} from './ProductDetailsStrainsAccordion.styles';

const ProductDetailsStrainsAccordion = () => {
  const { productDetails } = useStorefrontState();
  const { strains } = productDetails;

  return (
    <ProductDetailsAccordion
      isOpen
      header={strains.length > 1 ? 'Strains' : 'Strain'}
      id='product-strains'
    >
      {strains.map((strain) => (
        <StrainWrapper key={strain.id}>
          <StrainHeader>
            <Typography bodyStyle={boldBodySmallTypography}>
              {strain.name}
            </Typography>
            {Boolean(
              strain.storefront_attributes &&
                strain.storefront_attributes['Product Phenotype']
            ) && (
              <ProductChipsWrapper>
                {strain.storefront_attributes['Product Phenotype'].map(
                  (phenotype) => (
                    <ProductChip
                      key={phenotype}
                      text={phenotype}
                      type={slugifyString(phenotype)}
                    />
                  )
                )}
              </ProductChipsWrapper>
            )}
          </StrainHeader>
          {strain.description && <Typography>{strain.description}</Typography>}
          {strain?.storefront_attributes?.Effects && (
            <div>
              <Typography bodyStyle={mediumBodySmallTypography}>
                Effects
              </Typography>
              <Typography
                bodyStyle={bodySmallTypography}
                sx={{ color: neutral60.hex }}
              >
                {strain.storefront_attributes.Effects.join(', ')}
              </Typography>
            </div>
          )}
          {strain?.storefront_attributes?.Flavors && (
            <div>
              <Typography bodyStyle={mediumBodySmallTypography}>
                Flavors
              </Typography>
              <Typography
                bodyStyle={bodySmallTypography}
                sx={{ color: neutral60.hex }}
              >
                {strain.storefront_attributes.Flavors.join(', ')}
              </Typography>
            </div>
          )}
          {Boolean(
            strain.storefront_attributes &&
              strain.storefront_attributes['Medical Conditions']
          ) && (
            <div>
              <Typography bodyStyle={mediumBodySmallTypography}>
                Medical Conditions
              </Typography>
              <Typography
                bodyStyle={bodySmallTypography}
                sx={{ color: neutral60.hex }}
              >
                {strain.storefront_attributes['Medical Conditions'].join(', ')}
              </Typography>
            </div>
          )}
          {strain?.storefront_attributes?.Terpenes && (
            <div>
              <Typography bodyStyle={mediumBodySmallTypography}>
                Terpenes
              </Typography>
              <Typography
                bodyStyle={bodySmallTypography}
                sx={{ color: neutral60.hex }}
              >
                {strain.storefront_attributes.Terpenes.join(', ')}
              </Typography>
            </div>
          )}
        </StrainWrapper>
      ))}
    </ProductDetailsAccordion>
  );
};

export default ProductDetailsStrainsAccordion;
