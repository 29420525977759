import {
  AlertBar,
  maxMediaQueryDesktop,
  maxMediaQueryTablet,
  pxToRem,
  spacing6,
  spacing8,
} from '@leaftrade/leaftrade-component-library';
import PropTypes from 'prop-types';
import React from 'react';

const PageHeaderBanner = ({ bannerSeverity, bannerText, bannerTitle }) => (
  <AlertBar
    animationIn={null}
    hideClose
    alertMessages={typeof bannerText === 'string' ? [bannerText] : bannerText}
    alertTitle={bannerTitle}
    alertSeverity={bannerSeverity}
    sx={{
      margin: `0 ${pxToRem(135)} ${spacing6}`,
      [maxMediaQueryDesktop]: {
        margin: `0 ${pxToRem(70)} ${spacing6}`,
      },
      [maxMediaQueryTablet]: {
        margin: `0 ${spacing8} ${spacing6}`,
      },
    }}
    transitionSx={{ zIndex: 9997 }}
    testingId='page-header-banner--component--alert-bar'
  />
);

PageHeaderBanner.defaultProps = {
  bannerSeverity: 'info',
  bannerTitle: '',
};

PageHeaderBanner.propTypes = {
  bannerSeverity: PropTypes.string,
  bannerText: PropTypes.oneOfType([PropTypes.string, PropTypes.array])
    .isRequired,
  bannerTitle: PropTypes.string,
};

export default PageHeaderBanner;
