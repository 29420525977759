import {
  Card,
  IconArrowRight,
  Typography,
  bodySmallTypography,
  handleImgSrcError,
} from '@leaftrade/leaftrade-component-library';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import {
  featuredVendorAllCardClickEvent,
  featuredVendorCardClickEvent,
  featuredVendorCardRenderedEvent,
  featuredVendorFavoriteToggleEvent,
} from '../../../analytics/landing';
import { getLandingPageData } from '../../../../store/actions/storefrontActions';
import FavoriteToggle from '../../FavoriteToggle';
import {
  LandingPageCard,
  LandingPageCardsContainer,
  LandingPageGradientIcon,
  LandingPageGradientIconBackground,
} from '../LandingPage.styles';
import {
  FavoriteToggleWrapper,
  LandingPageFeaturedBase,
  LandingPageFeaturedShopAllCard,
  LandingPageFeaturedTitleBox,
  LandingPageFeaturedTitleBoxCopy,
  LandingPageFeaturedTitleBoxHeader,
} from './LandingPageFeatured.styles';

const LandingPageFeaturedVendors = ({ featuredObjects }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    if (featuredObjects.length > 0) {
      featuredObjects.forEach((advertisement) => {
        featuredVendorCardRenderedEvent({
          advertisement: advertisement,
        });
      });
    }
  }, [featuredObjects]);

  return (
    <LandingPageFeaturedBase data-testid='landing-page-vendor-featured--component--div'>
      <Card elevation={0} paddingSize='large' sx={LandingPageFeaturedTitleBox}>
        <Typography component='h4' sx={LandingPageFeaturedTitleBoxHeader}>
          Featured Vendors
        </Typography>
        <Typography
          bodyStyle={bodySmallTypography}
          sx={LandingPageFeaturedTitleBoxCopy}
        >
          Shop a curated collection of the most reputable and trusted cannabis
          sellers.
        </Typography>
      </Card>
      <LandingPageCardsContainer>
        {featuredObjects.length > 0 &&
          featuredObjects.map((advertisement) => (
            <LandingPageCard
              data-testid='landing-page-featured--vendor-card--div'
              key={advertisement.vendor_slug}
            >
              <FavoriteToggleWrapper>
                <FavoriteToggle
                  isFavorite={advertisement.is_favorite}
                  onSuccess={() => {
                    dispatch(getLandingPageData());
                    featuredVendorFavoriteToggleEvent({
                      toggledValue: !advertisement.is_favorite,
                      advertisement,
                    });
                  }}
                  size='small'
                  vendorId={advertisement.vendor_id}
                />
              </FavoriteToggleWrapper>
              <a
                onClick={() => {
                  featuredVendorCardClickEvent({
                    advertisement,
                  });
                }}
                href={`${advertisement.target_url}`}
                data-testid='featured-vendor-url'
              >
                <img
                  alt={advertisement.vendor_name}
                  onError={handleImgSrcError}
                  src={advertisement.image}
                />
                <span>{advertisement.vendor_name}</span>
              </a>
            </LandingPageCard>
          ))}
        <LandingPageFeaturedShopAllCard data-testid='landing-page-featured--shop-all-card--div'>
          <Link
            onClick={featuredVendorAllCardClickEvent}
            to='/storefront/sellers?product_types_filters=1'
          >
            <LandingPageGradientIcon>
              <LandingPageGradientIconBackground />
              <IconArrowRight />
            </LandingPageGradientIcon>
            Explore All Sellers
          </Link>
        </LandingPageFeaturedShopAllCard>
      </LandingPageCardsContainer>
    </LandingPageFeaturedBase>
  );
};

LandingPageFeaturedVendors.propTypes = {
  featuredObjects: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      vendor_id: PropTypes.number,
      is_favorite: PropTypes.bool,
      logo_url: PropTypes.string,
      vendor_name: PropTypes.string,
      name: PropTypes.string,
      target_url: PropTypes.string,
      vendor_slug: PropTypes.string,
    })
  ).isRequired,
};

export default LandingPageFeaturedVendors;
