import {
  Typography,
  bodySmallTypography,
  boldBodyTypography,
  elevation1,
  mediumBodySmallTypography,
  neutral0,
  neutral40,
  seafoam60lime60Gradient,
  slugifyString,
  spacing1,
  spacing2,
  spacing3,
  spacing4,
} from '@leaftrade/leaftrade-component-library';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';
import { STOREFRONT_MODE } from '../../constants';
import { useStorefrontState } from '../../../hooks/useReduxState';
import { getBoxShadowStringFromElevation } from '../../../utils';
import AddToCart from './AddToCart';
import AddToQuickOrder from './AddToQuickOrder';
import ProductBrands from './ProductBrands';
import {
  ProductChipsWrapper,
  ProductInfoWrapper,
  ProductPriceWrapper,
  ProductQuantityWrapper,
  StyledProductCard,
  VariantInfoWrapper,
} from './ProductCard.styles';
import ProductChip from './ProductChip';
import ProductImage from './ProductImage';
import ProductName from './ProductName';
import ProductVariants from './ProductVariants';
import VariantInfo from './VariantInfo';

const ProductCard = ({ product, slug }) => {
  const { cart, hasLimitedAccess, sellerPageData, vendor } =
    useStorefrontState();
  const { selectedCart } = cart;
  const { productTypes } = sellerPageData;
  const { displayLiveInventoryCount, isGridView, settingsDeprecated } =
    vendor.settings;
  const { storefront_mode: storefrontMode } = settingsDeprecated;

  const { variants, variantsByKey } = product;

  const [selectedVariant, setSelectedVariant] = useState(variants[0]);
  const [alert, setAlert] = useState();
  const [variantInCart, setVariantInCart] = useState(false);

  const isQuickOrderPage = window.location.pathname.includes('quick-order');

  const {
    brands,
    category_display_name: categoryDisplayName,
    default_image: defaultImage,
    derived_phenotype: derivedPhenotype,
    id: selectedVariantId,
    inventory_live_count: inventoryLiveCount,
    price,
    price_display: priceDisplay,
    price_per_unit: pricePerUnit,
    product_id: productId,
    product_name: productName,
    size_unit: sizeUnit,
    tags,
  } = selectedVariant;

  const isAncillaryOnly =
    productTypes.length === 1 && productTypes[0].name === 'Ancillary';

  const hideAddToCart =
    storefrontMode === STOREFRONT_MODE.READ_ONLY_MENU || hasLimitedAccess;

  const renderTags = useCallback(
    () => tags?.map((tag) => <ProductChip key={tag} text={tag} type='tag' />),
    [tags]
  );

  useEffect(() => {
    const cartItem = selectedCart.cartItems?.find(
      (item) => item.cartItemId === selectedVariantId
    );
    if (cartItem) {
      setVariantInCart(cartItem);
    } else {
      setVariantInCart(false);
    }
  }, [selectedCart.cartItems, selectedVariantId]);

  return (
    <StyledProductCard
      data-testid='product-card--component--div'
      isGridView={isGridView}
    >
      {variantInCart && (
        <Typography
          bodyStyle={mediumBodySmallTypography}
          sx={{
            position: 'absolute',
            background: seafoam60lime60Gradient,
            left: 0,
            padding: `${spacing2} ${spacing4}`,
            color: neutral0.hex,
            borderRadius: '0 8px 8px 0px',
            top: spacing4,
            boxShadow: getBoxShadowStringFromElevation(elevation1()),
          }}
        >
          {variantInCart.totalQuantity} in cart
        </Typography>
      )}
      {isGridView && (
        <ProductInfoWrapper isGridView>
          <ProductChipsWrapper
            style={{
              marginTop: spacing1,
              paddingLeft: variantInCart
                ? `${3.5 + 0.5 * String(variantInCart.totalQuantity).length}rem`
                : 0,
            }}
          >
            {renderTags()}
          </ProductChipsWrapper>
        </ProductInfoWrapper>
      )}
      <ProductImage
        imgSrc={defaultImage}
        name={productName}
        productId={productId}
        selectedVariantId={selectedVariantId}
        slug={slug}
      />
      <ProductInfoWrapper isGridView={isGridView}>
        <ProductChipsWrapper>
          {!isGridView && renderTags()}
          {categoryDisplayName && (
            <ProductChip text={categoryDisplayName} type='category' />
          )}
          {derivedPhenotype && (
            <ProductChip
              text={derivedPhenotype}
              type={slugifyString(derivedPhenotype)}
            />
          )}
        </ProductChipsWrapper>
        <ProductName
          name={productName}
          productId={productId}
          selectedVariantId={selectedVariantId}
          slug={slug}
        />
        <ProductBrands brands={brands} />
        <ProductVariants
          selectedVariantId={selectedVariantId}
          setAlert={setAlert}
          setSelectedVariant={setSelectedVariant}
          variants={variants}
        />
      </ProductInfoWrapper>
      {!isAncillaryOnly && (
        <VariantInfoWrapper isGridView={isGridView}>
          {selectedVariant?.batch?.id !== 0 && (
            <VariantInfo
              selectedVariant={selectedVariant}
              productId={product.id}
              slug={slug}
            />
          )}
        </VariantInfoWrapper>
      )}
      <ProductPriceWrapper isGridView={isGridView}>
        <Typography
          bodyStyle={!isGridView ? boldBodyTypography : null}
          component='h4'
          testingId='product-card--price--typography-p'
        >
          {selectedVariant ? priceDisplay : price || ''}
        </Typography>
        <Typography
          gutterBottom
          sx={{
            color: neutral40.hex,
            marginTop: spacing3,
          }}
          testingId='product-card--size--typography-p'
        >
          {sizeUnit ? `${pricePerUnit}/${sizeUnit}` : ''}
        </Typography>
      </ProductPriceWrapper>
      <ProductQuantityWrapper isGridView={isGridView}>
        {displayLiveInventoryCount && (
          <Typography
            bodyStyle={!isGridView ? bodySmallTypography : null}
            testingId='product-card--live-inventory--typography-p'
          >
            Current Inventory:{' '}
            {inventoryLiveCount >= 0 ? inventoryLiveCount : 0}
          </Typography>
        )}
        {!hideAddToCart && (
          <>
            {isQuickOrderPage ? (
              <AddToQuickOrder
                productId={productId}
                selectedVariant={selectedVariant}
              />
            ) : (
              <AddToCart
                alert={alert}
                productId={productId}
                selectedVariant={selectedVariant}
                setAlert={setAlert}
                slug={slug}
                variantsByKey={variantsByKey}
              />
            )}
          </>
        )}
      </ProductQuantityWrapper>
    </StyledProductCard>
  );
};

ProductCard.propTypes = {
  product: PropTypes.object.isRequired,
  slug: PropTypes.string.isRequired,
};

export default ProductCard;
