const storefrontState = {
  authUser: {
    dispensaries: [],
    vendors: [],
  },
  cart: {
    cartAlerts: [],
    cartItemAlerts: [],
    isLoadingCartData: false,
    isLoadingCartDataFromCart: false,
    multiCartSummary: undefined,
    selectedCart: {
      token: undefined,
    },
    cartPreviewModal: {
      isOpen: false,
    },
    clearCartModal: {
      isOpen: false,
      isClearingCart: false,
    },
    deleteItemModal: {
      isOpen: false,
      item: undefined,
      isRemovingItem: false,
    },
  },
  checkout: {
    addBankAccountModal: {
      isOpen: false,
      isAddingBankAccount: false,
    },
    canPlaceOrder: false,
    checkoutAlerts: [],
    checkoutData: {
      discount: '',
      estimatedTax: '',
      items: [],
      shippingMethods: '',
      subtotal: '',
      surcharge: '',
      total: '',
      totalShipping: '',
    },
    isAdmin: false,
    isLoadingCheckoutData: false,
    isPostingCheckoutData: false,
    leafPayData: {
      isEnabled: false,
      bankAccounts: [],
    },
    privacyPolicyModal: {
      isOpen: false,
    },
    termsOfServiceModal: {
      isOpen: false,
    },
  },
  favoriteVendorToggleData: {
    isTogglingFavorite: false,
    favoriteVendorId: undefined,
  },
  hasLimitedAccess: false,
  landingPageData: {
    isLoadingLandingData: true,
    categories: {},
    favoriteVendors: [],
    featuredVendors: [],
    sponsors: [],
  },
  navigation: {
    brands: undefined,
    vendors: undefined,
    sections: [],
    links: [],
  },
  productDetails: {
    availableVariants: false,
    brands: [],
    cart: undefined,
    description: undefined,
    derivedPhenotype: undefined,
    errorsAndWarnings: undefined,
    id: undefined,
    images: [],
    isSaleable: false,
    isLoadingProductDetails: true,
    name: undefined,
    phenotypes: [],
    marketingMaterials: [],
    instructions: [],
    selectedImage: undefined,
    selectedVariant: undefined,
    selectedVariantId: undefined,
    strains: [],
    uniqueVariants: undefined,
    variants: [],
    variantsByKey: undefined,
    vendorId: undefined,
  },
  publicMenu: {
    isLoadingPublicStorefrontData: true,
    confirmAgeModal: {
      isOpen: false,
    },
  },
  quickOrderData: {
    addToQuickOrderModal: {
      hasAdditionalProduct: false,
      isOpen: false,
      isAddingToQuickOrder: false,
      isLoadingQuickOrderProducts: false,
      products: [],
    },
    clearQuickOrderModal: {
      isOpen: false,
      isClearingQuickOrder: false,
    },
    deleteItemModal: {
      isOpen: false,
      item: undefined,
      isRemovingItem: false,
    },
    editingAllItemQuantity: {
      item: undefined,
      isEditing: false,
    },
    isLoadingQuickOrderData: true,
    items: [],
    numberOfPendingEdits: 0,
    quickOrderAlerts: [],
    quickOrderItemAlerts: [],
    selectedLocations: [],
    totalItems: undefined,
    totalPrice: undefined,
  },
  sellerPageData: {
    bannerText: undefined,
    bannerTitle: undefined,
    billingStatus: undefined,
    brands: [],
    cart: undefined,
    categories: [],
    description: undefined,
    hasAdditionalProduct: false,
    heroImage: undefined,
    heroImageLinkTarget: undefined,
    id: undefined,
    isActive: false,
    isFavorite: false,
    isLoadingSellerNavData: true,
    isLoadingSellerStorefrontData: true,
    logo: undefined,
    maxPrice: undefined,
    minimumOrder: undefined,
    name: undefined,
    phenotypes: [],
    products: [],
    productTypes: [],
    regions: [],
    slug: undefined,
    sortBy: undefined,
    strains: [],
    subcategories: [],
    url: undefined,
    website: undefined,
  },
  sellersPageData: {
    brands: [],
    categories: [],
    favoriteVendors: [],
    hasAdditionalSellers: false,
    isGridView: true,
    isLoadingAllSellersData: true,
    productTypes: [],
    sortBy: undefined,
    vendors: [],
  },
  shopper: {
    dispensary: undefined,
    isCustomer: false,
    isStaff: false,
    isSuperuser: false,
    isVendor: false,
    currentLocation: undefined,
    locations: [],
  },
  statusError: undefined,
  timedBanners: [],
  vendor: {
    name: undefined,
    slug: undefined,
    settings: {
      allowOverOrdering: false,
      batchQueuesEnabled: false,
      defaultSort: undefined,
      defaultListView: false,
      displayLiveInventoryCount: false,
      displayPricePerEach: false,
      isGridView: true,
      settingsDeprecated: undefined,
    },
  },
  vendorPreview: {
    customer: undefined,
    customers: [],
    location: undefined,
    locations: [],
  },
};

export default storefrontState;
