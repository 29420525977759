export const handleRejected = (state, action) => {
  const status = action.payload?.response?.status;
  const statusText = action.payload?.response?.statusText;

  return {
    ...state,
    statusError: {
      status,
      statusText,
    },
  };
};
