import { Typography, spacing3 } from '@leaftrade/leaftrade-component-library';
import React from 'react';
import { useStorefrontState } from '../../../../hooks/useReduxState';

const ProductDetailsSize = () => {
  const { productDetails } = useStorefrontState();
  const { selectedVariant } = productDetails;

  return (
    <Typography
      sx={{
        marginBottom: spacing3,
        width: 'max-content',
      }}
      testingId='product-details-content--size--typography'
    >
      Size: {selectedVariant?.stock_record.size}{' '}
      {selectedVariant?.stock_record.size_unit}
    </Typography>
  );
};

export default ProductDetailsSize;
