import { pxToRem, spacing6 } from '@leaftrade/leaftrade-component-library';
import styled from 'styled-components';

export const PageHeaderHeroWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: ${spacing6};
  & img {
    max-width: 100%;
    max-height: ${pxToRem(300)};
  }
`;
