import {
  helperTextTypography,
  maxMediaQueryDesktop,
  maxMediaQueryTablet,
  neutral0,
  pxToRem,
  spacing3,
  spacing4,
  spacing5,
  spacing6,
  spacing8,
} from '@leaftrade/leaftrade-component-library';
import styled from 'styled-components';

export const FooterWrapper = styled.div`
  background: #275463;
  display: flex;
  grid-gap: ${spacing6};
  align-items: center;
  margin-top: auto;
  height: ${pxToRem(64)};
  padding: 0 ${pxToRem(135)};
  ${helperTextTypography}
  ${maxMediaQueryDesktop} {
    padding: 0 ${spacing5};
  }
  ${maxMediaQueryTablet} {
    grid-gap: ${spacing3};
    padding: ${spacing4} ${spacing8};
    flex-direction: column;
    height: unset;
  }
  & > * {
    color: ${neutral0.hex};
    &:last-child {
      margin-right: 0;
    }
  }
  & span {
    ${maxMediaQueryTablet} {
      order: 1;
    }
  }
  & a {
    text-decoration: none;
    color: ${neutral0.hex};
    &:hover {
      text-decoration: underline;
    }
  }
`;
