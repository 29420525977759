import {
  elevation1,
  neutral0,
  pxToRem,
  seafoam100,
  seafoam60,
  seafoam80,
  spacing2,
  spacing3,
  spacing5,
} from '@leaftrade/leaftrade-component-library';
import styled from 'styled-components';

export const HeaderCartWrapper = styled.div`
  position: relative;
  margin-left: ${pxToRem(18)};
`;

export const HeaderCartButton = styled.button`
  display: flex;
  padding: ${spacing2} ${spacing3};
  justify-content: flex-end;
  align-items: center;
  gap: ${spacing2};
  background: ${({ isOpen }) => (isOpen ? seafoam100.hex : seafoam60.hex)};
  border-radius: 90px;
  border: none;
  ${elevation1()}
  &:hover {
    background: ${seafoam80.hex};
  }
  & svg {
    fill: ${neutral0.hex};
    height: ${spacing5};
    width: ${spacing5};
  }
`;
