import {
  Typography,
  bodySmallTypography,
  neutral60,
  spacing1,
  spacing2,
  spacing4,
} from '@leaftrade/leaftrade-component-library';
import React from 'react';
import PropTypes from 'prop-types';
import { CartLineItemColumnItemBatchQueuesVariantWrapper } from './CartLineItemColumnItemBatchQueuesVariant.styles';

const CartLineItemColumnItemBatchQueuesVariant = ({ variant }) => (
  <CartLineItemColumnItemBatchQueuesVariantWrapper data-testid='cart-line-item-column-item-batch-queues-variant--component--div'>
    <Typography
      bodyStyle={bodySmallTypography}
      sx={{
        color: neutral60.hex,
        margin: `${spacing1} 0`,
      }}
      testingId='cart-line-item-column-item-batch-queues--item-variant--typography'
    >
      {variant.displayName}
    </Typography>
    {variant.batch_ref && (
      <Typography
        bodyStyle={bodySmallTypography}
        sx={{
          color: neutral60.hex,
          margin: `${spacing1} 0`,
        }}
        testingId='cart-line-item-column-item-batch-queues--item-batch-ref--typography'
      >
        Batch #{variant.batch_ref}
      </Typography>
    )}
    <Typography
      bodyStyle={bodySmallTypography}
      sx={{
        color: neutral60.hex,
        '& span': {
          '&:nth-child(odd)': {
            marginRight: spacing2,
            fontWeight: 700,
          },
          '&:nth-child(even)': {
            marginRight: spacing4,
          },
        },
      }}
      testingId='cart-line-item-column-item-batch-queues--item-cannabinoids--typography'
    >
      {variant?.cannabinoids?.map((cannabinoid) => (
        <React.Fragment key={cannabinoid.name}>
          <span>{cannabinoid.name.toUpperCase()}</span>
          <span>{cannabinoid.value}</span>
        </React.Fragment>
      ))}
    </Typography>
  </CartLineItemColumnItemBatchQueuesVariantWrapper>
);

CartLineItemColumnItemBatchQueuesVariant.propTypes = {
  variant: PropTypes.shape({
    batch_ref: PropTypes.string,
    cannabinoids: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string,
        value: PropTypes.string,
      })
    ),
    displayName: PropTypes.string,
  }),
};

export default CartLineItemColumnItemBatchQueuesVariant;
