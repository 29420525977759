import { slugifyString } from '@leaftrade/leaftrade-component-library';
import React from 'react';
import { useStorefrontState } from '../../../../hooks/useReduxState';
import FeaturedCannabinoids from '../../ProductCard/FeaturedCannabinoids';
import CannabinoidsTooltip from '../../ProductCard/CannabinoidsTooltip';
import ProductChip from '../../ProductCard/ProductChip';
import { ProductDetailsInfoChipsWrapper } from './ProductDetailsInfoChips.styles';

const ProductDetailsInfoChips = () => {
  const { productDetails, vendor } = useStorefrontState();
  const { selectedVariant } = productDetails;
  const { primary_lab_values: primaryLabValues } =
    vendor.settings.settingsDeprecated;

  return (
    <ProductDetailsInfoChipsWrapper>
      {productDetails.phenotypes[0] && (
        <ProductChip
          size='large'
          text={productDetails.phenotypes[0]}
          type={slugifyString(productDetails.phenotypes[0])}
        />
      )}
      {selectedVariant?.stock_record?.batch?.cannabinoids &&
        selectedVariant?.lab_results_unit && (
          <FeaturedCannabinoids
            asChip
            cannabinoids={selectedVariant.stock_record.batch.cannabinoids}
            labResultsUnit={selectedVariant.lab_results_unit}
            primaryLabValues={primaryLabValues}
          />
        )}
      {Boolean(
        Object.keys(selectedVariant?.stock_record?.batch?.cannabinoids).length
      ) && (
        <CannabinoidsTooltip
          isProductDetailsPage
          primaryLabValues={primaryLabValues}
          productId={productDetails.selectedVariantId}
        />
      )}
    </ProductDetailsInfoChipsWrapper>
  );
};

export default ProductDetailsInfoChips;
