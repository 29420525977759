import {
  pxToRem,
  spacing5,
  spacing7,
} from '@leaftrade/leaftrade-component-library';
import styled from 'styled-components';

export const VendorsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  grid-gap: ${spacing5};
  max-width: ${pxToRem(1200)};
  margin: 0 auto;
`;

export const LoadMoreWrapper = styled.div`
  margin-bottom: ${spacing7};
`;
