import {
  amber20,
  amber40,
  amber5,
  neutral30,
  neutral5,
  neutral0,
  pxToRem,
  rose20,
  rose5,
  rose60,
  royal20,
  royal5,
  seafoam20,
  seafoam5,
  spacing1,
  spacing4,
  spacing6,
  violet20,
  violet5,
} from '@leaftrade/leaftrade-component-library';
import styled from 'styled-components';

export const StyledProductChip = styled.div`
  width: ${({ size }) => (size === 'large' ? pxToRem(70) : 'auto')};
  height: ${({ size }) => (size === 'large' ? pxToRem(70) : spacing6)};
  border: 1px solid ${neutral30.hex};
  border-radius: ${({ size }) => (size === 'large' ? '100%' : '12px')};
  padding: ${spacing1} ${spacing4};
  max-width: 100px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: flex;
  justify-content: center;
  align-content: center;
  flex-direction: column;
  ${({ type }) => {
    switch (type) {
      case 'category':
        return `
          background-color: ${neutral5.hex};
          border: 1px solid ${neutral30.hex};
        `;
      case 'hybrid':
        return `
          background-color: ${rose5.hex};
          border: 1px solid ${rose20.hex};
        `;
      case 'indica':
        return `
          background-color: ${violet5.hex};
          border: 1px solid ${violet20.hex};
        `;
      case 'indica-dominant':
        return `
          background-color: ${royal5.hex};
          border: 1px solid ${royal20.hex};
        `;
      case 'sativa':
        return `
          background-color: ${amber5.hex};
          border: 1px solid ${amber20.hex};
        `;
      case 'sativa-dominant':
        return `
          background-color: ${amber5.hex};
          border: 1px solid ${amber40.hex};
        `;
      case 'cbd':
        return `
          background-color: ${seafoam5.hex};
          border: 1px solid ${seafoam20.hex};
        `;
      case 'tag':
        return `
          background-color: ${rose60.hex};
          color: ${neutral0.hex};
        `;
      default:
        return `
          background-color: ${neutral5.hex};
          border: 1px solid ${neutral30.hex};
        `;
    }
  }}
`;
