import {
  maxMediaQueryDesktop,
  spacing5,
} from '@leaftrade/leaftrade-component-library';
import styled from 'styled-components';

export const IndividualCannabinoidWrapper = styled.div`
  display: grid;
  gap: ${spacing5};
  margin-top: ${spacing5};
  grid-template-columns: repeat(3, 1fr);
  ${maxMediaQueryDesktop} {
    grid-template-columns: repeat(2, 1fr);
  }
`;
