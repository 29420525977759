import axios from 'axios';

export const getCookie = (name) => {
  let cookieValue = null;
  if (document.cookie && document.cookie !== '') {
    const cookies = document.cookie.split(';');
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i].trim();
      if (cookie.substring(0, name.length + 1) === `${name}=`) {
        cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
        break;
      }
    }
  }
  return cookieValue;
};

export const buildQueryStringFromParams = (params) => {
  const queryString = Object.keys(params)
    .map((key) => `${key}=${params[key]}`)
    .join('&');
  return queryString;
};

export const axiosGetData = async ({
  meta,
  onError,
  onSuccess,
  rejectWithValue,
  url,
  signal,
}) => {
  try {
    const response = await axios.get(url, signal ? { signal } : undefined);
    if (onSuccess) await onSuccess(response.data);
    if (meta) {
      return {
        ...response.data,
        ...meta,
      };
    }
    return response.data;
  } catch (error) {
    if (error.code === 'ERR_CANCELED') {
      return null;
    }
    if (onError) await onError(error);
    return rejectWithValue(error);
  }
};

export const axiosGetParallelData = async ({
  getParams,
  meta,
  onError,
  onSuccess,
  rejectWithValue,
}) => {
  const { urls, keys } = getParams;
  try {
    const responses = await Promise.all(urls.map((url) => axios.get(url)));
    const data = {};
    responses.forEach((response, index) => {
      data[keys[index]] = response.data;
    });
    if (onSuccess) await onSuccess(data);
    if (meta) {
      return {
        ...data,
        ...meta,
      };
    }
    return data;
  } catch (error) {
    if (onError) await onError(error);
    return rejectWithValue(error);
  }
};

const updateData = async ({
  data,
  meta,
  method,
  onError,
  onSuccess,
  rejectWithValue,
  url,
  headers = {},
}) => {
  try {
    const response = await axios[method](url, data, {
      headers: {
        'X-CSRFToken': getCookie('csrftoken'),
        ...headers,
      },
      cache: 'no-cache',
    });
    if (onSuccess) await onSuccess(response.data);
    if (meta) {
      return {
        ...response.data,
        ...meta,
      };
    }
    return response.data;
  } catch (error) {
    if (onError) await onError(error);
    return rejectWithValue(error);
  }
};

export const axiosPatchData = async (kwargs) =>
  updateData({ method: 'patch', ...kwargs });
export const axiosPostData = async (kwargs) =>
  updateData({ method: 'post', ...kwargs });
export const axiosPutData = async (kwargs) =>
  updateData({ method: 'put', ...kwargs });

export const axiosDeleteData = async ({
  data,
  meta,
  onError,
  onSuccess,
  rejectWithValue,
  url,
}) => {
  try {
    const response = await axios.delete(url, {
      headers: {
        'X-CSRFToken': getCookie('csrftoken'),
      },
      cache: 'no-cache',
      data,
    });
    if (onSuccess) await onSuccess(response.data);
    if (meta) {
      return {
        ...response.data,
        ...meta,
      };
    }
    return response.data;
  } catch (error) {
    if (onError) await onError(error);
    return rejectWithValue(error);
  }
};

export const methodsMap = {
  get: axiosGetData,
  post: axiosPostData,
  put: axiosPutData,
  delete: axiosDeleteData,
};
