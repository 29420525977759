import React from 'react';
import { Link } from 'react-router-dom';
import { FooterWrapper } from './Footer.styles';

const Footer = () => (
  <FooterWrapper data-testid='footer--component--div'>
    <span>© {new Date().getFullYear()} Leaf Trade. All Rights Reserved</span>
    <a href='https://knowledge.leaf.trade' target='_blank' rel='noreferrer'>
      Help Center
    </a>
    <Link to='/storefront/privacy-policy/'>Privacy Policy</Link>
    <Link to='/storefront/tos/'>Terms of Service</Link>
  </FooterWrapper>
);

export default Footer;
