import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import { axiosGetData, axiosPatchData } from '../../helpers/axios';

export const getConfiguration = createAsyncThunk(
  'getConfiguration',
  async ({ onSuccess, url }, { rejectWithValue }) =>
    axiosGetData({
      onSuccess,
      rejectWithValue,
      url,
    })
);

export const saveConfiguration = createAsyncThunk(
  'saveConfiguration',
  async ({ data, onSuccess, url }, { rejectWithValue }) =>
    axiosPatchData({
      data,
      onSuccess,
      rejectWithValue,
      url,
    })
);

export const setPayload = createAction('setPayload');
