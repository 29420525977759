import {
  maxMediaQueryDesktop,
  pxToRem,
  neutral10,
  neutral60,
  neutral90,
  spacing6,
} from '@leaftrade/leaftrade-component-library';
import styled from 'styled-components';
import { LandingPageCard } from '../LandingPage.styles';

export const LandingPageFeaturedBase = styled.div`
  margin-bottom: ${pxToRem(48)};
  display: flex;
  min-height: ${pxToRem(192)};
  ${maxMediaQueryDesktop} {
    flex-direction: column;
  }
`;

export const FavoriteToggleWrapper = styled.div`
  position: absolute;
  right: ${pxToRem(4)};
  top: ${pxToRem(4)};
`;

export const LandingPageFeaturedShopAllCard = styled(LandingPageCard)`
  &:hover {
    box-shadow: unset;
    span {
      transform: matrix(-1, 0, 0, 1, 0, 0);
    }
  }
`;

export const LandingPageFeaturedTitleBoxCopy = {
  color: neutral60.hex,
};

export const LandingPageFeaturedTitleBoxHeader = {
  color: neutral90.hex,
};

export const LandingPageFeaturedTitleBox = {
  backgroundColor: neutral10.hex,
  borderRadius: '16px',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  width: pxToRem(240),
  height: 'unset',
  marginRight: spacing6,
  [maxMediaQueryDesktop]: {
    marginBottom: spacing6,
    marginRight: 'unset',
    width: '100%',
  },
};
