import {
  Link,
  Typography,
  boldBodyTypography,
  spacing3,
  OverflowTooltip,
} from '@leaftrade/leaftrade-component-library';
import PropTypes from 'prop-types';
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useStorefrontState } from '../../../../hooks/useReduxState';

const ProductName = ({ name, productId, selectedVariantId, slug }) => {
  const { hasLimitedAccess, publicMenu, vendor } = useStorefrontState();
  const { isGridView } = vendor.settings;
  return (
    <>
      {publicMenu?.products ? (
        <Typography
          bodyStyle={!isGridView ? boldBodyTypography : null}
          component='h6'
          sx={{
            flexDirection: 'column',
            alignItems: 'flex-start',
            width: '100%',
            marginBottom: spacing3,
            display: '-webkit-box',
            WebkitBoxOrient: 'vertical',
            WebkitLineClamp: isGridView ? 2 : 1,
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}
          testingId='product-name--component--public--typography'
          underline='hover'
        >
          <OverflowTooltip
            maxLineCount={isGridView ? 2 : 1}
            overflowHeightOffset={4}
            checkOverflowDeps={[isGridView]}
            tooltipId='tooltip-id-product-name--text'
          >
            {name}
          </OverflowTooltip>
        </Typography>
      ) : (
        <Link
          bodyStyle={!isGridView ? boldBodyTypography : null}
          component={RouterLink}
          sx={{
            flexDirection: 'column',
            alignItems: 'flex-start',
            width: '100%',
            marginBottom: spacing3,
          }}
          testingId='product-name--component--link'
          to={`/storefront/sellers/${
            hasLimitedAccess ? 'vendor-preview/' : ''
          }${slug}/${productId}?variant=${selectedVariantId}`}
          underline='hover'
          variant='h6'
        >
          <OverflowTooltip
            maxLineCount={isGridView ? 2 : 1}
            overflowHeightOffset={4}
            checkOverflowDeps={[isGridView]}
            tooltipId='tooltip-id-product-name--link'
          >
            {name}
          </OverflowTooltip>
        </Link>
      )}
    </>
  );
};

ProductName.propTypes = {
  name: PropTypes.string.isRequired,
  productId: PropTypes.number.isRequired,
  selectedVariantId: PropTypes.number.isRequired,
  slug: PropTypes.string.isRequired,
};

export default ProductName;
