import styled from 'styled-components';

export const HeadlineSplashBlockWrapper = styled.div`
  height: 100%;

  .brand-page__tile-content {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
  }
`;
