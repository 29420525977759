import React from 'react';
import { ScrollSyncPane } from 'react-scroll-sync';
import { useStorefrontState } from '../../../../hooks/useReduxState';
import QuickOrderTableAllInput from '../QuickOrderTableAllInput';
import QuickOrderTableItemInfo from './QuickOrderTableItemInfo';
import QuickOrderTableItemSummary from '../QuickOrderTableItemSummary';
import QuickOrderTableLocationInput from '../QuickOrderTableLocationInput';
import {
  LocationColumnScrollGradient,
  QuickOrderTableItemWrapper,
  QuickOrderTableLocationColumns,
  QuickOrderTableLocationColumnsWrapper,
} from './QuickOrderTableItem.styles';

const QuickOrderTableItem = ({ item, showOverflowButtons }) => {
  const { quickOrderData } = useStorefrontState();
  const { quickOrderItemAlerts, selectedLocations } = quickOrderData;

  return (
    <QuickOrderTableItemWrapper
      data-testid='quick-order-table-item--component--div'
      key={item.cartItemId}
    >
      <QuickOrderTableItemInfo item={item} />
      {selectedLocations.length > 1 && (
        <>
          <QuickOrderTableAllInput item={item} />
          <QuickOrderTableLocationColumnsWrapper>
            {showOverflowButtons?.left && (
              <LocationColumnScrollGradient isLeft />
            )}
            <ScrollSyncPane>
              <QuickOrderTableLocationColumns>
                {[...selectedLocations]
                  .sort((a, b) => a.name.localeCompare(b.name))
                  .map((selectedLocation) => (
                    <QuickOrderTableLocationInput
                      key={selectedLocation.id}
                      item={item}
                      itemAlert={quickOrderItemAlerts.find(
                        (itemAlert) =>
                          Number(itemAlert.cartItemId) ===
                            Number(item.cartItemId) &&
                          Number(itemAlert.locationId) ===
                            Number(selectedLocation.id)
                      )}
                      location={selectedLocation}
                    />
                  ))}
              </QuickOrderTableLocationColumns>
            </ScrollSyncPane>
            {showOverflowButtons?.right && <LocationColumnScrollGradient />}
          </QuickOrderTableLocationColumnsWrapper>
        </>
      )}
      <QuickOrderTableItemSummary item={item} />
    </QuickOrderTableItemWrapper>
  );
};

export default QuickOrderTableItem;
