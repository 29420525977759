import React, { useState } from 'react';
import { STOREFRONT_MODE } from '../../../constants';
import { useStorefrontState } from '../../../../hooks/useReduxState';
import AddToCart from '../../ProductCard/AddToCart';
import ProductDetailsBrands from '../ProductDetailsBrands';
import ProductDetailsExpiration from '../ProductDetailsExpiration';
import ProductDetailsInfoChips from '../ProductDetailsInfoChips';
import ProductDetailsName from '../ProductDetailsName';
import ProductDetailsPrice from '../ProductDetailsPrice';
import ProductDetailsSize from '../ProductDetailsSize';
import ProductDetailsSizeUnit from '../ProductDetailsSizeUnit';
import ProductDetailsVariantSelect from '../ProductDetailsVariantSelect';
import ProductDetailsVariantSelectBatchQueues from '../ProductDetailsVariantSelectBatchQueues';
import {
  AddToCartWrapper,
  ProductDetailsContentTopWrapper,
} from './ProductDetailsContentTop.styles';

const ProductDetailsContentTop = () => {
  const { hasLimitedAccess, productDetails, vendor } = useStorefrontState();
  const { images, selectedVariant, variantsByKey } = productDetails;
  const { settings, slug } = vendor;
  const { batchQueuesEnabled } = settings;
  const { storefront_mode: storefrontMode } =
    vendor.settings.settingsDeprecated;

  const [alert, setAlert] = useState();

  return (
    <ProductDetailsContentTopWrapper hasNoImages={images?.length === 0}>
      <ProductDetailsName />
      {Boolean(productDetails.brands.length) && <ProductDetailsBrands />}
      <ProductDetailsPrice />
      {selectedVariant?.stock_record.size !== null && <ProductDetailsSize />}
      {selectedVariant?.stock_record.size_unit && <ProductDetailsSizeUnit />}
      {selectedVariant?.batch_expiration_date && <ProductDetailsExpiration />}
      {selectedVariant?.stock_record?.batch && <ProductDetailsInfoChips />}
      {batchQueuesEnabled
        ? Boolean(productDetails.packageSizes.length) && (
            <ProductDetailsVariantSelectBatchQueues />
          )
        : Boolean(productDetails.variants.length) && (
            <ProductDetailsVariantSelect />
          )}
      {storefrontMode === STOREFRONT_MODE.PURCHASING_ACTIVE &&
        !hasLimitedAccess && (
          <AddToCartWrapper>
            <AddToCart
              alert={alert}
              productId={productDetails.id}
              selectedVariant={selectedVariant}
              setAlert={setAlert}
              slug={slug}
              variantsByKey={variantsByKey}
            />
          </AddToCartWrapper>
        )}
    </ProductDetailsContentTopWrapper>
  );
};

export default ProductDetailsContentTop;
