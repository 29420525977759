import {
  IconCart,
  IconLoadingSpinner,
  Typography,
  boldBodyTypography,
  logoDefaultColorOutlineWhite,
  neutral0,
  spacing2,
  spacing5,
} from '@leaftrade/leaftrade-component-library';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useStorefrontState } from '../../../hooks/useReduxState';
import HeaderHamburger from '../../../shared/components/HeaderHamburger';
import {
  HeaderMobileBase,
  HeaderMobileCartLink,
  HeaderMobileLogo,
  HeaderMobileWrapper,
} from './HeaderMobile.styles';
import HeaderMobileNav from './HeaderMobileNav';

const HeaderMobile = ({ hasLimitedAccess, testingId }) => {
  const location = useLocation();
  const { cart, shopper, vendor } = useStorefrontState();
  const { isLoadingCartData, selectedCart } = cart;
  const { currentLocation } = shopper;
  const { slug } = vendor;
  const [isMobileNavOpen, setIsMobileNavOpen] = useState(false);

  const isQuickOrderPage = location.pathname.includes('/quick-order');

  const handleMenuClick = () => {
    setIsMobileNavOpen(!isMobileNavOpen);
  };

  useEffect(() => {
    if (isMobileNavOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }
  }, [isMobileNavOpen]);

  return (
    <HeaderMobileBase data-testid={testingId}>
      <HeaderMobileWrapper>
        <HeaderHamburger isOpen={isMobileNavOpen} onClick={handleMenuClick} />
        <HeaderMobileLogo to='/'>
          <img src={logoDefaultColorOutlineWhite} alt='Leaf Trade' />
        </HeaderMobileLogo>
        {slug &&
          currentLocation?.id &&
          !isQuickOrderPage &&
          !hasLimitedAccess && (
            <HeaderMobileCartLink to={`/storefront/sellers/${slug}/cart/`}>
              {isLoadingCartData ? (
                <IconLoadingSpinner
                  style={{
                    width: spacing5,
                    height: spacing5,
                    margin: spacing2,
                  }}
                />
              ) : (
                <>
                  <IconCart />
                  <Typography
                    bodyStyle={boldBodyTypography}
                    sx={{
                      color: neutral0.hex,
                    }}
                  >
                    {selectedCart?.quantity > 999
                      ? '999+'
                      : selectedCart?.quantity || 0}
                  </Typography>
                </>
              )}
            </HeaderMobileCartLink>
          )}
      </HeaderMobileWrapper>
      {isMobileNavOpen && (
        <HeaderMobileNav
          hasLimitedAccess={hasLimitedAccess}
          setIsMobileNavOpen={setIsMobileNavOpen}
          testingId='header-mobile--header-mobile-nav--component'
        />
      )}
    </HeaderMobileBase>
  );
};

HeaderMobile.defaultProps = {
  hasLimitedAccess: false,
  testingId: 'header-mobile--component--header',
};

HeaderMobile.propTypes = {
  hasLimitedAccess: PropTypes.bool,
  testingId: PropTypes.string,
};

export default HeaderMobile;
