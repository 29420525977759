import { neutral10, spacing2 } from '@leaftrade/leaftrade-component-library';
import styled from 'styled-components';

export const CheckoutSummaryBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: ${spacing2} 0;
`;

export const CheckoutSummaryBoxTotal = styled(CheckoutSummaryBox)`
  border-top: 1px solid ${neutral10.hex};
  margin-top: ${spacing2};
  padding-top: ${spacing2};
`;
