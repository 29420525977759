/* eslint-disable no-nested-ternary */
import {
  AlertBar,
  fadeInRightAnimation,
  fadeOutRightAnimation,
} from '@leaftrade/leaftrade-component-library';
import React from 'react';
import { useDispatch } from 'react-redux';
import { useStorefrontState } from '../../../hooks/useReduxState';
import { removeTimedBanner } from '../../../store/actions/storefrontActions';
import { TimedBannersWrapper } from './TimedBanners.styles';

const TimedBanners = () => {
  const dispatch = useDispatch();
  const { timedBanners } = useStorefrontState();
  const dismissBanner = ({ bannerId, timeoutId }) => {
    clearTimeout(timeoutId);
    dispatch(removeTimedBanner(bannerId));
  };

  return (
    <TimedBannersWrapper data-testid='timed-banners--component--div'>
      {timedBanners.length > 0
        ? timedBanners.map((banner) => {
            const timeoutId = setTimeout(
              () => dispatch(removeTimedBanner(banner.id)),
              banner.timeout || 5000
            );
            return (
              <AlertBar
                alertMessages={[banner.message] || []}
                alertSeverity={banner.severity || 'info'}
                alertTitle={banner.title || ''}
                animationIn={fadeInRightAnimation}
                animationOut={fadeOutRightAnimation}
                autoClose={!banner.preventAutoClose}
                autoCloseTimeout={banner.timeout || 5000}
                onClose={() =>
                  dismissBanner({ bannerId: banner.id, timeoutId })
                }
                testingId={`timed-banner--${banner.id}--alert-bar`}
              />
            );
          })
        : null}
    </TimedBannersWrapper>
  );
};

export default TimedBanners;
