import {
  elevation5,
  spacing6,
  maxMediaQueryMobile,
} from '@leaftrade/leaftrade-component-library';
import styled from 'styled-components';

export const ShopAsModalBase = styled.div`
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ShopAsModalContent = styled.div`
  background-color: white;
  padding: ${spacing6};
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 480px;
  ${elevation5()}
  ${maxMediaQueryMobile} {
    width: 80%;
  }
  & img {
    margin-bottom: ${spacing6};
  }
`;
