import {
  baseTransition,
  neutral0,
  pxToRem,
  spacing2,
  spacing3,
  sRadius,
} from '@leaftrade/leaftrade-component-library';
import styled from 'styled-components';

export const HeaderActionsWrapper = styled.div`
  margin-left: auto;
  display: flex;
  align-items: center;
  gap: ${spacing3};
  background-color: rgba(0, 0, 0, 0.15);
  border-radius: ${sRadius};
  & svg {
    fill: ${neutral0.hex};
    height: ${pxToRem(16)};
    width: ${pxToRem(16)};
  }
`;

export const HeaderActionsLocationName = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  & span {
    & > * {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
    &:nth-child(1) {
      max-width: ${pxToRem(185)};
      font-size: ${pxToRem(12)};
      line-height: ${pxToRem(12)};
    }
    &:nth-child(2) {
      max-width: ${pxToRem(175)};
      font-size: ${pxToRem(10)};
      line-height: ${pxToRem(10)};
    }
  }
`;

export const HeaderActionsLocationNameWrapper = styled.div`
  display: flex;
  align-items: center;
  border-radius: ${sRadius};
  padding: ${spacing2} ${spacing3};
  cursor: default;
  & svg {
    margin-right: ${spacing3};
  }
`;

export const HeaderActionsCTAWrapper = styled.div`
  border-radius: ${sRadius};
  & > a {
    padding: ${spacing3};
    display: flex;
    align-items: center;
    color: ${neutral0.hex};
    border-radius: ${sRadius};
    text-decoration: none;
    background: none;
    font-size: ${pxToRem(12)};
    ${baseTransition}
    &:hover {
      background: #3b7673;
      color: inherit;
      text-decoration: none;
    }
    &:active {
      background: #264d4b;
      color: inherit;
      text-decoration: none;
    }
    & svg {
      margin-right: ${spacing3};
    }
  }
`;
