import {
  IconButton,
  IconFavoriteHeart,
  IconFavoriteHeartFilled,
  pxToRem,
  rose40,
  rose60,
} from '@leaftrade/leaftrade-component-library';
import PropTypes from 'prop-types';
import React from 'react';
import { useDispatch } from 'react-redux';
import { useStorefrontState } from '../../../hooks/useReduxState';
import { toggleFavoriteVendor } from '../../../store/actions/storefrontActions';
import { IconButtonWrapper } from './FavoriteToggle.styles';

const FavoriteToggle = ({ isFavorite, onSuccess, size, vendorId }) => {
  const dispatch = useDispatch();
  const { favoriteVendorToggleData } = useStorefrontState();
  const { isTogglingFavorite, favoriteVendorId } = favoriteVendorToggleData;

  const handleFavoriteClick = () => {
    dispatch(
      toggleFavoriteVendor({
        isFavorite,
        onSuccess,
        vendorId,
      })
    );
  };

  return (
    <IconButtonWrapper
      isToggling={isTogglingFavorite && vendorId === favoriteVendorId}
      size={size}
    >
      <IconButton
        icon={
          isFavorite ? (
            <IconFavoriteHeartFilled data-testid='favorite-toggle--heart-filled--icon' />
          ) : (
            <IconFavoriteHeart data-testid='favorite-toggle--heart-outline--icon' />
          )
        }
        onClick={handleFavoriteClick}
        size={size}
        sx={{
          borderColor: 'transparent',
          '&:hover': {
            borderColor: 'transparent',
          },
          '& svg': {
            fill: isFavorite ? rose60.hex : rose40.hex,
            width: pxToRem(16),
            height: pxToRem(16),
          },
        }}
        testingId='favorite-toggle--button--icon-button'
        variant='outlined'
      />
    </IconButtonWrapper>
  );
};

FavoriteToggle.defaultProps = {
  size: 'large',
};

FavoriteToggle.propTypes = {
  isFavorite: PropTypes.bool.isRequired,
  onSuccess: PropTypes.func.isRequired,
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  vendorId: PropTypes.number.isRequired,
};

export default FavoriteToggle;
