import PropTypes from 'prop-types';
import React from 'react';
import CartLineItemColumnItemBatchQueuesVariant from '../CartLineItemColumnItemBatchQueuesVariant';
import CartLineItemColumnLabs from '../CartLineItemColumnLabs';
import CartLineItemColumnQuantityVariant from '../CartLineItemColumnQuantityVariant';
import CartLineItemColumnTotal from '../CartLineItemColumnTotal';
import CartLineItemColumnUnitPrice from '../CartLineItemColumnUnitPrice';
import { CartLineItemDeleteFiller } from './CartLineItemBatchQueuesVariant.styles';
import { CartLineItemWrapper } from '../CartLineItem/CartLineItem.styles';

const CartLineItemBatchQueuesVariant = ({ variant }) => (
  <CartLineItemWrapper data-testid='cart-line-item-batch-queues-variant--component--div'>
    <CartLineItemColumnItemBatchQueuesVariant variant={variant} />
    {variant.labResultsUrl?.length > 0 && (
      <CartLineItemColumnLabs labResults={variant.labResultsUrl} />
    )}
    <CartLineItemColumnQuantityVariant quantity={variant.quantity} />
    <CartLineItemColumnUnitPrice unitPrice={variant.unitPrice} />
    <CartLineItemColumnTotal totalPrice={variant.subTotal} />
    <CartLineItemDeleteFiller />
  </CartLineItemWrapper>
);

CartLineItemBatchQueuesVariant.propTypes = {
  variant: PropTypes.shape({
    labResultsUrl: PropTypes.arrayOf(PropTypes.string),
    subTotal: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    unitPrice: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }),
};

export default CartLineItemBatchQueuesVariant;
