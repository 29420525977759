export const emptyPage = {
  name: '',
  headerImageUrl: null,
  logoImageUrl: null,
  backgroundColor: '',
  title: '',
  subtitle: '',
  pageTiles: [],
  brandId: null,
  vendorId: null,
};

export const emptyHeadlineTile = {
  backgroundColor: '',
  buttonLabel: '',
  gradientColor: '',
  headline: '',
  imageUrl: '',
  subheader: '',
  targetUrl: '',
  textColor: '#000000',
  gradientBackgroundColor: null,
};

export const emptyMediaTile = {
  imageUrl: '',
  targetUrl: '',
};

export const headlineSplashTile = {
  buttonLabel: '',
  buttonUrl: '',
  headline: '',
  subheader: '',
  textColor: '#000000',
  gradientBackgroundColor: null,
};

export const emptyEditorialTile = {
  imageUrl: '',
  targetUrl: '',
  bodyCopy: '',
  buttonLabel: '',
  buttonUrl: '',
  headline: '',
  subheader: '',
  textColor: '#000000',
  gradientBackgroundColor: null,
};

const brandPagesState = {
  pages: {},
  page: emptyPage,
  pageTiers: [],
  downloadedPage: null,
  createCampaignModal: {
    brands: [],
    brand: null,
    vendor: null,
  },
  vendorsList: null,
  tableFilters: {
    status: null,
    vendor_id: null,
  },
  selectedPages: {},
  isPageActionLoading: false,
  isPageLoading: false,
  isFileUploading: false,
  updatedPage: null,
  statusError: null,
  isPageDetailsValidationActive: false,
  pageTilesValidation: {},
  isPageDetailsError: false,
  pageTilesErrors: {},
  isPageRestored: false,
  publicBrandPage: null,
};

export default brandPagesState;
