import React from 'react';
import { AlertBar, spacing6 } from '@leaftrade/leaftrade-component-library';
import { useLocation } from 'react-router-dom';
import Breadcrumbs from '../../components/Breadcrumbs';
import CartHeader from '../../components/CartHeader';
import CartActions from '../../components/CartActions';
import CartSummaryBox from '../../components/CartSummaryBox';
import CartLineItems from '../../components/CartLineItems';
import EmptyCart from '../../components/EmptyCart';
import PageLoader from '../../../shared/components/PageLoader';
import ClearCartModal from '../../components/modals/ClearCartModal';
import DeleteCartItemModal from '../../components/modals/DeleteCartItemModal';
import { useStorefrontState } from '../../../hooks/useReduxState';
import MultiCartSummaryBox from '../../components/MultiCartSummaryBox';
import { CartItemsMultiCartSummaryWrapper } from './Cart.styles';
import { BQ_DEBUG } from '../../constants';

const Cart = () => {
  const location = useLocation();
  const { cart, sellerPageData, vendor } = useStorefrontState();
  const { bannerText, bannerTitle } = sellerPageData;
  const {
    cartAlerts,
    clearCartModal,
    deleteItemModal,
    isLoadingCartData,
    isLoadingCartDataFromCart,
    multiCartSummary,
    selectedCart,
  } = cart;
  const { settings } = vendor;
  const { batchQueuesEnabled } = settings;

  return (
    <>
      {isLoadingCartData || isLoadingCartDataFromCart ? (
        <PageLoader />
      ) : (
        <>
          <Breadcrumbs isCartPage />
          {location.search.includes(BQ_DEBUG) && (
            <AlertBar
              animationIn={null}
              hideClose
              alertMessages={[
                batchQueuesEnabled
                  ? 'Batch Queues Enabled'
                  : 'Batch Queues Disabled',
              ]}
              alertTitle='BQ Debug'
              alertSeverity='info'
              sx={{
                marginTop: spacing6,
              }}
              testingId='cart--bq-debug-banner--alert-bar'
            />
          )}
          {cartAlerts?.length > 0 &&
            cartAlerts.map((alert) => (
              <AlertBar
                animationIn={null}
                hideClose
                alertMessages={[
                  alert.subtitle || alert.sub_message || '',
                  alert.level === 'error'
                    ? 'Please review and update below.'
                    : 'Please review below.',
                ]}
                alertTitle={alert.title || alert.message || ''}
                alertSeverity={alert.level}
                sx={{
                  marginTop: spacing6,
                }}
                testingId='cart--alerts--alert-bar'
              />
            ))}
          {bannerText && (
            <AlertBar
              animationIn={null}
              hideClose
              alertMessages={
                typeof bannerText === 'string' ? [bannerText] : bannerText
              }
              alertTitle={bannerTitle || ''}
              alertSeverity='info'
              sx={{
                marginTop: spacing6,
              }}
              testingId='cart--vendor-banner--alert-bar'
            />
          )}
          <CartHeader />
          <CartItemsMultiCartSummaryWrapper>
            {multiCartSummary?.carts?.length > 1 && <MultiCartSummaryBox />}
            {selectedCart?.cartItems?.length > 0 ? (
              <>
                <CartLineItems />
              </>
            ) : (
              <EmptyCart />
            )}
          </CartItemsMultiCartSummaryWrapper>
          {selectedCart?.cartItems?.length > 0 && (
            <>
              <CartSummaryBox />
              <CartActions />
            </>
          )}
          {deleteItemModal.isOpen && <DeleteCartItemModal />}
          {clearCartModal.isOpen && <ClearCartModal />}
        </>
      )}
    </>
  );
};

export default Cart;
