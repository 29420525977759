import { combineReducers } from '@reduxjs/toolkit';
import batchesReducer from './batchesReducer';
import brandPagesReducer from './brandPagesReducer';
import cannabisBatchReducer from './cannabisBatchReducer';
import dispensaryDashboardReducer from './dispensaryDashboardReducer';
import orderCreateReducer from './orderCreateReducer';
import productsReducer from './productsReducer';
import storefrontReducer from './storefrontReducer';
import vendorBrandsReducer from './vendorBrandsReducer';
import webhookDashboardReducer from './webhookDashboardReducer';
import userReducer from './userReducer';
import inventoryPricingReducer from './inventoryPricingReducer';
import commonReducer from './commonReducer';
import { vendorApi } from '../queries/vendor';

export const rootReducer = combineReducers({
  batches: batchesReducer,
  brandPages: brandPagesReducer,
  common: commonReducer,
  cannabisBatch: cannabisBatchReducer,
  dispensaryDashboard: dispensaryDashboardReducer,
  orderCreate: orderCreateReducer,
  products: productsReducer,
  storefront: storefrontReducer,
  user: userReducer,
  vendorBrands: vendorBrandsReducer,
  webhookDashboard: webhookDashboardReducer,
  inventoryPricing: inventoryPricingReducer,
  [vendorApi.reducerPath]: vendorApi.reducer,
});
