import {
  boldBodyTypography,
  error40,
  helperTextTypography,
  mediumBodyTypography,
  neutral0,
  spacing2,
  spacing3,
  Typography,
} from '@leaftrade/leaftrade-component-library';
import React from 'react';
import { useStorefrontState } from '../../../hooks/useReduxState';
import { currencyFormatter } from '../../../utils';
import {
  CheckoutItem,
  CheckoutItemName,
  CheckoutItemsHeader,
} from './CheckoutItems.styles';

const CheckoutItems = () => {
  const { checkout } = useStorefrontState();
  const { checkoutData } = checkout;
  const { items } = checkoutData;

  return (
    <div data-testid='checkout-items--component--div'>
      <CheckoutItemsHeader>
        <Typography
          bodyStyle={boldBodyTypography}
          sx={{
            flex: '0 0 50%',
          }}
        >
          Item
        </Typography>
        <Typography
          align='right'
          bodyStyle={boldBodyTypography}
          sx={{
            flex: '0 0 16%',
          }}
        >
          Unit Price
        </Typography>
        <Typography
          align='right'
          bodyStyle={boldBodyTypography}
          sx={{
            flex: '0 0 16%',
          }}
        >
          Qty
        </Typography>
        <Typography
          align='right'
          bodyStyle={boldBodyTypography}
          sx={{
            flex: '1',
          }}
        >
          Total
        </Typography>
      </CheckoutItemsHeader>
      {items.map((item) => (
        <CheckoutItem key={item.id}>
          <CheckoutItemName>
            <Typography bodyStyle={mediumBodyTypography}>
              {item.productName}
            </Typography>
            <Typography>{item.displayName}</Typography>
            {item.isBackOrdered && (
              <Typography
                bodyStyle={helperTextTypography}
                sx={{
                  backgroundColor: error40.hex,
                  width: 'max-content',
                  padding: `${spacing2} ${spacing3}`,
                  borderRadius: '12px',
                  color: neutral0.hex,
                }}
              >
                Back Ordered
              </Typography>
            )}
            {item.error && (
              <Typography
                bodyStyle={helperTextTypography}
                sx={{
                  backgroundColor: error40.hex,
                  width: 'max-content',
                  padding: `${spacing2} ${spacing3}`,
                  borderRadius: '12px',
                  color: neutral0.hex,
                }}
              >
                {item.error}
              </Typography>
            )}
          </CheckoutItemName>
          <Typography
            align='right'
            sx={{
              flex: '0 0 16%',
            }}
          >
            {currencyFormatter.format(item.price)}
          </Typography>
          <Typography
            align='right'
            sx={{
              flex: '0 0 16%',
            }}
          >
            {item.quantity}
          </Typography>
          <Typography
            align='right'
            sx={{
              flex: '1',
            }}
          >
            {currencyFormatter.format(item.total)}
          </Typography>
        </CheckoutItem>
      ))}
    </div>
  );
};

export default CheckoutItems;
