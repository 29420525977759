import React from 'react';
import {
  IconCart,
  IconLoadingSpinner,
  Typography,
  boldBodyTypography,
  neutral0,
  spacing2,
  spacing5,
} from '@leaftrade/leaftrade-component-library';
import { useDispatch } from 'react-redux';
import CartPreviewModal from '../../modals/CartPreviewModal';
import { useStorefrontState } from '../../../../hooks/useReduxState';
import { toggleCartPreviewModal } from '../../../../store/actions/storefrontActions';
import { HeaderCartButton, HeaderCartWrapper } from './HeaderCart.styles';

const HeaderCart = () => {
  const dispatch = useDispatch();
  const { cart } = useStorefrontState();
  const { cartPreviewModal, isLoadingCartData, selectedCart } = cart;

  const handleCartIconClick = () => {
    dispatch(
      toggleCartPreviewModal({
        isOpen: true,
      })
    );
  };

  return (
    <HeaderCartWrapper data-testid='header--cart--component--div'>
      <HeaderCartButton
        data-testid='header--cart-button--component--button'
        isOpen={cartPreviewModal.isOpen}
        onClick={handleCartIconClick}
      >
        {isLoadingCartData ? (
          <IconLoadingSpinner
            style={{
              width: spacing5,
              height: spacing5,
              margin: spacing2,
            }}
          />
        ) : (
          <>
            <IconCart />
            <Typography
              bodyStyle={boldBodyTypography}
              sx={{
                color: neutral0.hex,
              }}
            >
              {selectedCart?.quantity > 999
                ? '999+'
                : selectedCart?.quantity || 0}
            </Typography>
          </>
        )}
      </HeaderCartButton>
      {cartPreviewModal.isOpen && <CartPreviewModal />}
    </HeaderCartWrapper>
  );
};

export default HeaderCart;
