import {
  Breadcrumbs as LTBreadcrumbs,
  Link,
  Typography,
} from '@leaftrade/leaftrade-component-library';
import PropTypes from 'prop-types';
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useStorefrontState } from '../../../hooks/useReduxState';

const Breadcrumbs = ({
  isCartPage,
  isCheckoutPage,
  isProductDetailsPage,
  isQuickOrderPage,
}) => {
  const { hasLimitedAccess, productDetails, vendor } = useStorefrontState();
  const { name, slug } = vendor;

  return (
    <LTBreadcrumbs testingId='breadcrumbs--component--div'>
      {!hasLimitedAccess && (
        <Link
          component={RouterLink}
          testingId='breadcrumbs--home--link'
          to='/storefront/'
          underline='hover'
        >
          Home
        </Link>
      )}
      {!hasLimitedAccess && (
        <Link
          component={RouterLink}
          testingId='breadcrumbs--sellers--link'
          to='/storefront/sellers/'
          underline='hover'
        >
          Sellers
        </Link>
      )}
      {slug && (
        <Link
          component={RouterLink}
          testingId='breadcrumbs--seller--link'
          to={`/storefront/sellers/${
            hasLimitedAccess ? 'vendor-preview/' : ''
          }${slug}/`}
          underline='hover'
        >
          {name || slug}
        </Link>
      )}
      {!hasLimitedAccess && isCartPage && (
        <Typography testingId='breadcrumbs--cart--p'>Cart</Typography>
      )}
      {!hasLimitedAccess && isCheckoutPage && (
        <Link
          component={RouterLink}
          testingId='breadcrumbs--cart--link'
          to={`/storefront/sellers/${slug}/cart/`}
          underline='hover'
        >
          Cart
        </Link>
      )}
      {!hasLimitedAccess && isCheckoutPage && (
        <Typography testingId='breadcrumbs--checkout--p'>Checkout</Typography>
      )}
      {!hasLimitedAccess && isQuickOrderPage && (
        <Typography testingId='breadcrumbs--quick-order--p'>
          Quick Order
        </Typography>
      )}
      {isProductDetailsPage && (
        <Typography testingId='breadcrumbs--product--p'>
          {productDetails.name || ''}
        </Typography>
      )}
    </LTBreadcrumbs>
  );
};

Breadcrumbs.defaultProps = {
  isCartPage: false,
  isCheckoutPage: false,
  isProductDetailsPage: false,
  isQuickOrderPage: false,
};

Breadcrumbs.propTypes = {
  isCartPage: PropTypes.bool,
  isCheckoutPage: PropTypes.bool,
  isProductDetailsPage: PropTypes.bool,
  isQuickOrderPage: PropTypes.bool,
};

export default Breadcrumbs;
