import {
  Link,
  Typography,
  bodySmallTypography,
  handleImgSrcError,
  interaction60,
  mediumBodyTypography,
  neutral60,
  spacing1,
} from '@leaftrade/leaftrade-component-library';
import React from 'react';
import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import { useStorefrontState } from '../../../../hooks/useReduxState';
import { CartLineItemColumnItemWrapper } from './CartLineItemColumnItemBatchQueues.styles';

const CartLineItemColumnItemBatchQueues = ({ item }) => {
  const { imageUrl, packageSizeId, productId, productName, variants } = item;
  const { vendor } = useStorefrontState();
  const { slug } = vendor;

  return (
    <CartLineItemColumnItemWrapper data-testid='cart-line-item-column-item-batch-queues--component--div'>
      <img
        data-testid='cart-line-item-column-item--item-image--img'
        src={imageUrl}
        onError={handleImgSrcError}
      />
      <div>
        <Link
          bodyStyle={mediumBodyTypography}
          component={RouterLink}
          sx={{
            color: interaction60.hex,
          }}
          testingId='cart-line-item-column-item--item-name--link'
          to={`/storefront/sellers/${slug}/${productId}?variant=${packageSizeId}`}
          underline='hover'
        >
          {productName}
        </Link>
        <Typography
          bodyStyle={bodySmallTypography}
          sx={{
            color: neutral60.hex,
            margin: `${spacing1} 0`,
          }}
          testingId='cart-line-item-column-item--item-variant--typography'
        >
          {variants[0].displayName}
        </Typography>
      </div>
    </CartLineItemColumnItemWrapper>
  );
};

CartLineItemColumnItemBatchQueues.propTypes = {
  item: PropTypes.shape({
    imageUrl: PropTypes.string,
    packageSizeId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    productId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    productName: PropTypes.string,
    variants: PropTypes.arrayOf(
      PropTypes.shape({
        batch_ref: PropTypes.string,
        cannabinoidProfile: PropTypes.arrayOf(
          PropTypes.shape({
            name: PropTypes.string,
            value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
          })
        ),
        displayName: PropTypes.string,
        variantId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      })
    ),
  }).isRequired,
};

export default CartLineItemColumnItemBatchQueues;
