import React, { useEffect } from 'react';
import {
  Button,
  spacing6,
  Typography,
} from '@leaftrade/leaftrade-component-library';
import { BrandPageButtonStyles } from '../../../../../shared/styles/BrandPageButton.styles';
import {
  brandPageTileClickEvent,
  brandPageTileViewEvent,
} from '../../../../analytics/brand_pages';
import { GradientWrapper } from '../styles/GradientWrapper';

const HeadlineBlock = ({
  buttonLabel,
  headline,
  imageUrl,
  subheader,
  targetUrl,
  className,
  position,
  page,
  gradientBackgroundColor,
  textColor,
}) => {
  useEffect(() => {
    brandPageTileViewEvent({
      vendorId: page.vendorId,
      brandId: page.brandId,
      brandPageId: page.id,
      brandPageTilePosition: position,
      brandPageTileMediaUrl: imageUrl,
    });
  });

  const trackAndOpenWindow = () => {
    brandPageTileClickEvent({
      vendorId: page.vendorId,
      brandId: page.brandId,
      brandPageId: page.id,
      brandPageTilePosition: position,
      brandPageTileMediaUrl: imageUrl,
      brandPageTileClickTargetUrl: targetUrl,
    });

    window.open(targetUrl, '_blank');
  };
  return (
    <div className={className} style={{ backgroundImage: `url(${imageUrl})` }}>
      <GradientWrapper
        gradientBackgroundColor={gradientBackgroundColor}
        textColor={textColor}
      >
        <div className='brand-page__tile-content'>
          <Typography component='h3' classes={['brand-page__tile-title']}>
            {headline}
          </Typography>
          <Typography component='p' classes={['brand-page__tile-subtitle']}>
            {subheader}
          </Typography>
          <Button
            size='small'
            bodyStyle={BrandPageButtonStyles}
            onClick={trackAndOpenWindow}
            sx={{ marginTop: spacing6 }}
            classes={['brand-page__tile-button']}
          >
            {buttonLabel}
          </Button>
        </div>
      </GradientWrapper>
    </div>
  );
};
export default HeadlineBlock;
