import { createSlice } from '@reduxjs/toolkit';
import commonState from '../states/commonState';
import { setUserRoles } from '../actions/commonActions';
import camelize from 'camelize';

export const commonSlice = createSlice({
  name: 'common',
  initialState: commonState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(setUserRoles, (state, action) => ({
      ...state,
      userRoles: camelize(action.payload),
    }));
  },
});
