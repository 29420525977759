import React, { useMemo } from 'react';
import { useStorefrontState } from '../../../../hooks/useReduxState';
import ProductDetailsAccordion from '../ProductDetailsAccordion';
import ProductDetailsAttachmentDropdown from '../ProductDetailsAttachmentDropdown';
import { ProductDetailsAttachmentDropdownWrapper } from './ProductDetailsAttachmentsAccordion.styles';

const ProductDetailsAttachmentsAccordion = () => {
  const { productDetails } = useStorefrontState();
  const { instructions, marketingMaterials, selectedVariant, variants } =
    productDetails;

  const labResults = useMemo(() => {
    const variantsWithAttachments = variants.filter(
      (variant) => variant?.stock_record?.batch?.attachments.length
    );
    const mappedAttachments = variantsWithAttachments.map(
      (variantWithAttachments) => ({
        section: variantWithAttachments.stock_record.batch.batch_ref,
        attachments: variantWithAttachments.stock_record.batch.attachments,
        currentlySelected:
          selectedVariant?.stock_record?.batch?.batch_ref ===
          variantWithAttachments.stock_record.batch.batch_ref,
      })
    );
    const sections = mappedAttachments.map((o) => o.section);
    const filteredForDuplicates = mappedAttachments.filter(
      ({ section }, index) => !sections.includes(section, index + 1)
    );
    filteredForDuplicates.sort((x, y) => {
      if (x.currentlySelected) {
        return -1;
      }
      if (y.currentlySelected) {
        return 1;
      }
      return 0;
    });
    return filteredForDuplicates;
  }, [variants, selectedVariant]);

  return (
    <ProductDetailsAccordion
      isOpen
      header='Attachments'
      id='product-attachments'
    >
      <ProductDetailsAttachmentDropdownWrapper>
        {Boolean(
          variants.some(
            (variant) => variant.stock_record?.batch?.attachments.length
          )
        ) && (
          <ProductDetailsAttachmentDropdown
            label='Lab Results'
            options={labResults}
            withSections
          />
        )}
        {Boolean(marketingMaterials?.length) && (
          <ProductDetailsAttachmentDropdown
            label='Marketing Materials'
            options={productDetails.marketingMaterials}
          />
        )}
        {Boolean(instructions?.length) && (
          <ProductDetailsAttachmentDropdown
            label='Instructions'
            options={productDetails.instructions}
          />
        )}
      </ProductDetailsAttachmentDropdownWrapper>
    </ProductDetailsAccordion>
  );
};

export default ProductDetailsAttachmentsAccordion;
