import {
  maxMediaQueryDesktop,
  maxMediaQueryTablet,
  pxToRem,
  spacing5,
  spacing6,
  spacing7,
  spacing8,
} from '@leaftrade/leaftrade-component-library';
import styled from 'styled-components';

export const StyledPageWrapper = styled.div`
  flex: 1;
  overflow-x: ${({ unsetOverflow }) => (unsetOverflow ? 'unset ' : 'hidden')};
  overflow-y: ${({ unsetOverflow }) => (unsetOverflow ? 'unset ' : 'auto')};
  ${({ sidePadding }) => {
    switch (sidePadding) {
      case 'none':
        return `padding: ${spacing7} 0;
        ${maxMediaQueryDesktop} {
          padding: ${spacing7} 0;
        }
        ${maxMediaQueryTablet} {
          padding: ${spacing7} 0;
        }`;
      case 'small':
        return `padding: ${spacing7} ${spacing8};
        ${maxMediaQueryDesktop} {
          padding: ${spacing7} ${spacing6};
        }
        ${maxMediaQueryTablet} {
          padding: ${spacing7} ${spacing5};
        }`;
      case 'default':
        return `padding: ${spacing7} ${pxToRem(135)};
        ${maxMediaQueryDesktop} {
          padding: ${spacing7} ${pxToRem(70)};
        }
        ${maxMediaQueryTablet} {
          padding: ${spacing7} ${spacing8};
        }`;
      default:
        return `padding: ${spacing7} ${pxToRem(135)};
        ${maxMediaQueryDesktop} {
          padding: ${spacing7} ${pxToRem(70)};
        }
        ${maxMediaQueryTablet} {
          padding: ${spacing7} ${spacing8};
        }`;
    }
  }}
`;
