import {
  IconChevronRight,
  IconDashboard,
  IconOrders,
  IconSeller,
  IconUser,
  Typography,
} from '@leaftrade/leaftrade-component-library';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { STOREFRONT_MODE } from '../../../constants';
import { useStorefrontState } from '../../../../hooks/useReduxState';
import {
  HeaderMobileNavHr,
  HeaderMobileNavParentButton,
  HeaderMobileNavParentButtonWithIcon,
  HeaderMobileNavParentDiv,
  HeaderMobileNavParentHref,
  HeaderMobileNavParentLink,
  HeaderMobileNavWrapper,
  HeaderMobileTitleSubtitleWrapper,
} from './HeaderMobileNav.styles';
import HeaderMobileNavPanel from './HeaderMobileNavPanel';

const HeaderMobileNav = ({
  hasLimitedAccess,
  setIsMobileNavOpen,
  testingId,
}) => {
  const location = useLocation();
  const { shopper, vendor } = useStorefrontState();
  const { dispensary, currentLocation } = shopper;
  const { settings, slug } = vendor;
  const { quick_order_enabled: quickOrderEnabled } = dispensary;
  const { settingsDeprecated } = settings || {};
  const {
    storefront_aggregate_variants: storefrontAggregateVariants,
    storefront_mode: storefrontMode,
  } = settingsDeprecated || {};

  const isQuickOrderPage = location.pathname.includes('/quick-order');

  const [isPanelOpen, setIsPanelOpen] = useState({
    location: false,
    account: false,
    sellers: false,
    brands: false,
  });

  const openPanel = (panel) => {
    setIsPanelOpen({
      location: false,
      account: false,
      sellers: false,
      brands: false,
      [panel]: true,
    });
  };

  const closeAllPanels = () => {
    setIsPanelOpen({
      location: false,
      account: false,
      sellers: false,
      brands: false,
    });
  };

  const activePanel = Object.keys(isPanelOpen).find(
    (key) => isPanelOpen[key] === true
  );

  const showQuickOrderLink =
    !isQuickOrderPage &&
    !hasLimitedAccess &&
    quickOrderEnabled &&
    !storefrontAggregateVariants &&
    storefrontMode === STOREFRONT_MODE.PURCHASING_ACTIVE &&
    slug;

  return (
    <HeaderMobileNavWrapper data-testid={testingId}>
      {Object.values(isPanelOpen).some((val) => val === true) ? (
        <HeaderMobileNavPanel
          activePanel={activePanel}
          closeAllPanels={closeAllPanels}
          setIsMobileNavOpen={setIsMobileNavOpen}
        />
      ) : (
        <>
          {!hasLimitedAccess && (
            <>
              {!hasLimitedAccess && (
                <>
                  <HeaderMobileNavParentButton
                    data-testid='header-mobile-nav--sellers-button--button'
                    onClick={() => openPanel('sellers')}
                    type='button'
                  >
                    <Typography classes={['h7']} component='span'>
                      Sellers
                    </Typography>
                    <IconChevronRight />
                  </HeaderMobileNavParentButton>
                  <HeaderMobileNavParentButton
                    data-testid='header-mobile-nav--brands-button--button'
                    onClick={() => openPanel('brands')}
                    type='button'
                  >
                    <Typography classes={['h7']} component='span'>
                      Brands
                    </Typography>
                    <IconChevronRight />
                  </HeaderMobileNavParentButton>
                  <HeaderMobileNavHr />
                </>
              )}
              {slug ? (
                <HeaderMobileNavParentButtonWithIcon
                  onClick={() => openPanel('location')}
                  type='button'
                  data-testid='header-mobile-nav--location-button--button'
                >
                  <IconSeller />
                  <HeaderMobileTitleSubtitleWrapper>
                    <Typography classes={['h7']} component='span'>
                      {dispensary.name}
                    </Typography>
                    <Typography classes={['h8']} component='span'>
                      {currentLocation.name}
                    </Typography>
                  </HeaderMobileTitleSubtitleWrapper>
                  <IconChevronRight />
                </HeaderMobileNavParentButtonWithIcon>
              ) : (
                <HeaderMobileNavParentDiv
                  type='button'
                  data-testid='header-mobile-nav--location-button--button'
                >
                  <IconSeller />
                  <HeaderMobileTitleSubtitleWrapper>
                    <Typography classes={['h7']} component='span'>
                      {dispensary.name}
                    </Typography>
                    <Typography classes={['h8']} component='span'>
                      {currentLocation.name}
                    </Typography>
                  </HeaderMobileTitleSubtitleWrapper>
                </HeaderMobileNavParentDiv>
              )}
              {showQuickOrderLink && (
                <HeaderMobileNavParentLink
                  data-testid='header-actions--quick-order--link'
                  onClick={() => setIsMobileNavOpen(false)}
                  to={`/storefront/sellers/${slug}/quick-order`}
                >
                  <IconOrders />
                  <Typography classes={['h7']} component='span'>
                    Quick order
                  </Typography>
                </HeaderMobileNavParentLink>
              )}
              <HeaderMobileNavParentHref
                data-testid='header-mobile-nav--dashboard-link--a'
                href={`/dashboard/customer/${dispensary.id}`}
              >
                <IconDashboard />
                <Typography classes={['h7']} component='span'>
                  Dashboard
                </Typography>
              </HeaderMobileNavParentHref>
            </>
          )}
          <HeaderMobileNavParentButtonWithIcon
            data-testid='header-mobile-nav--account-button--button'
            onClick={() => openPanel('account')}
            type='button'
          >
            <IconUser />
            <Typography classes={['h7']} component='span'>
              Account
            </Typography>
            <IconChevronRight />
          </HeaderMobileNavParentButtonWithIcon>
        </>
      )}
    </HeaderMobileNavWrapper>
  );
};

HeaderMobileNav.defaultProps = {
  hasLimitedAccess: false,
  testingId: 'header-mobile-nav--component--div',
};

HeaderMobileNav.propTypes = {
  hasLimitedAccess: PropTypes.bool,
  setIsMobileNavOpen: PropTypes.func.isRequired,
  testingId: PropTypes.string,
};

export default HeaderMobileNav;
