import {
  IconButton,
  IconChevronDown,
  IconChevronUp,
  neutral0,
} from '@leaftrade/leaftrade-component-library';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useStorefrontState } from '../../../../hooks/useReduxState';
import {
  ProductImage,
  ProductImageOption,
  ProductImageSelected,
  ProductImagesList,
  ProductImagesOverlayControls,
  ProductImagesOverlayTileDown,
  ProductImagesOverlayTileUp,
} from './ProductDetailsImage.styles';
import { setSelectedImage } from '../../../../store/actions/storefrontActions';

const ProductDetailsImage = () => {
  const dispatch = useDispatch();
  const { productDetails } = useStorefrontState();
  const { images, name, selectedImage } = productDetails;

  const [showUpCarousel, setShowUpCarousel] = useState(false);
  const [showDownCarousel, setShowDownCarousel] = useState(true);

  const handleCarouselDirection = (direction) => {
    const imageCarousel = document.getElementById('imageCarousel');
    const { scrollHeight, offsetHeight, scrollTop } = imageCarousel;

    const CAROUSEL_THRESHOLD = 296;

    if (direction === 'down') {
      if (scrollTop + CAROUSEL_THRESHOLD >= scrollHeight - offsetHeight) {
        setShowDownCarousel(false);
      }
      imageCarousel.scrollTop += CAROUSEL_THRESHOLD;
      setShowUpCarousel(true);
    } else {
      if (scrollTop <= CAROUSEL_THRESHOLD) {
        setShowUpCarousel(false);
      }
      imageCarousel.scrollTop -= CAROUSEL_THRESHOLD;
      setShowDownCarousel(true);
    }
  };

  return (
    <ProductImage>
      {images.length > 1 && (
        <ProductImagesList
          data-testid='product-images--carousel--div'
          id='imageCarousel'
        >
          {images.map((image) => (
            <ProductImageOption
              key={image.url}
              onClick={() => dispatch(setSelectedImage(image.url))}
              onKeyDown={(e) =>
                e.key === 'Enter' && dispatch(setSelectedImage(image.url))
              }
              selected={image.url === selectedImage}
            >
              <img src={image.url} alt={name} />
            </ProductImageOption>
          ))}
        </ProductImagesList>
      )}
      {images.length > 5 && (
        <ProductImagesOverlayControls data-testid='product-images--overlay-controls--div'>
          {showUpCarousel && (
            <ProductImagesOverlayTileUp>
              <IconButton
                icon={<IconChevronUp fill={neutral0.hex} />}
                onClick={() => handleCarouselDirection('up')}
                sx={{
                  pointerEvents: 'all',
                }}
              />
            </ProductImagesOverlayTileUp>
          )}
          {showDownCarousel && (
            <ProductImagesOverlayTileDown>
              <IconButton
                icon={<IconChevronDown fill={neutral0.hex} />}
                onClick={() => handleCarouselDirection('down')}
                sx={{
                  pointerEvents: 'all',
                }}
              />
            </ProductImagesOverlayTileDown>
          )}
        </ProductImagesOverlayControls>
      )}
      <ProductImageSelected
        data-testid='product-images--selected-image--img'
        src={selectedImage}
        alt={name}
      />
    </ProductImage>
  );
};

export default ProductDetailsImage;
