import { Notifier } from '@airbrake/browser';
import {
  Button,
  Card,
  GlobalStyle,
  Link,
  logoDefaultColorWhite,
  spacing6,
  Typography,
} from '@leaftrade/leaftrade-component-library';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import Footer from '../../../storefront/components/Footer';
import { HeaderWrapper } from '../../styles/Header.styles';
import PageWrapper from '../PageWrapper';
import { BaseWrapper, FallbackWrapper } from './Fallback.styles';

function FallBack({ error }) {
  const { NODE_ENV, AIRBRAKE_PROJECT_ID_FE, AIRBRAKE_PROJECT_KEY_FE } =
    process.env;
  if (
    NODE_ENV === 'production' &&
    AIRBRAKE_PROJECT_ID_FE &&
    AIRBRAKE_PROJECT_KEY_FE
  ) {
    const airbrake = new Notifier({
      projectId: AIRBRAKE_PROJECT_ID_FE,
      projectKey: `${AIRBRAKE_PROJECT_KEY_FE}`,
    });

    useEffect(() => {
      if (error) {
        airbrake.notify({ error });
      }
    }, [airbrake, error]);
  }

  return (
    <BaseWrapper data-testid='fallback--component--div'>
      <GlobalStyle />
      <HeaderWrapper>
        <Link href='/'>
          <img src={logoDefaultColorWhite} alt='Leaf Trade' />
        </Link>
      </HeaderWrapper>
      <PageWrapper>
        <FallbackWrapper>
          <Card
            paddingSize='large'
            sx={{
              margin: 'auto',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              gap: spacing6,
            }}
          >
            <Typography component='h3'>
              The Leaf Trade application has encountered an error. 🫤
            </Typography>
            {error?.message && (
              <>
                <Typography sx={{ alignSelf: 'flex-start' }}>
                  Support Message:{' '}
                </Typography>
                <Typography component='code' sx={{ alignSelf: 'flex-start' }}>
                  {error.message}
                </Typography>
              </>
            )}
            <Button onClick={() => window.location.reload()}>
              Reload Page
            </Button>
            <Typography
              sx={{
                '& span': {
                  fontWeight: 'bold',
                },
              }}
            >
              <span>Need Help?</span> Chat with our team by clicking the chat
              icon in the bottom right corner and share the error message
              provided.
            </Typography>
          </Card>
        </FallbackWrapper>
      </PageWrapper>
      <Footer />
    </BaseWrapper>
  );
}

FallBack.defaultProps = {
  error: null,
};

FallBack.propTypes = {
  error: PropTypes.shape({
    message: PropTypes.string,
  }),
};

export default FallBack;
