import {
  spacing3,
  spacing5,
  Typography,
} from '@leaftrade/leaftrade-component-library';
import PropTypes from 'prop-types';
import React from 'react';
import { useDispatch } from 'react-redux';
import { useStorefrontState } from '../../../../../../hooks/useReduxState';
import {
  addTimedBanner,
  setLocationFromHeader,
} from '../../../../../../store/actions/storefrontActions';
import {
  HeaderMobileNavPanelActions,
  HeaderMobileNavPanelContainer,
} from '../HeaderMobileNavPanel.styles';

const HeaderMobileNavPanelLocation = ({
  closeAllPanels,
  setIsMobileNavOpen,
}) => {
  const { cart, shopper, vendor } = useStorefrontState();
  const { selectedCart } = cart;
  const { dispensary, currentLocation, locations } = shopper;
  const { slug } = vendor;
  const dispatch = useDispatch();

  return (
    <HeaderMobileNavPanelContainer data-testid='header-mobile-nav-panel-location--component--div'>
      <Typography
        classes={['h7']}
        component='span'
        sx={{
          padding: `${spacing5} ${spacing5} ${spacing3}`,
          margin: 0,
          textTransform: 'capitalize',
        }}
      >
        {dispensary.name} Locations
      </Typography>
      <HeaderMobileNavPanelActions>
        {locations.map((loc) => (
          <button
            key={loc.id}
            type='button'
            onClick={() => {
              if (loc.id === currentLocation.id) return;
              dispatch(
                setLocationFromHeader({
                  dispensaryId: dispensary.id,
                  locationId: loc.id,
                  vendorSlug: slug || null,
                })
              );
              if (selectedCart.quantity && selectedCart.quantity > 0) {
                dispatch(
                  addTimedBanner({
                    id: `${currentLocation.id}-cart-saved`,
                    message: `Your cart for ${currentLocation.name} has been saved`,
                    severity: 'success',
                  })
                );
              }
              closeAllPanels();
              setIsMobileNavOpen(false);
            }}
          >
            <Typography component='span'>{loc.name}</Typography>
          </button>
        ))}
      </HeaderMobileNavPanelActions>
    </HeaderMobileNavPanelContainer>
  );
};

HeaderMobileNavPanelLocation.propTypes = {
  closeAllPanels: PropTypes.func.isRequired,
  setIsMobileNavOpen: PropTypes.func.isRequired,
};

export default HeaderMobileNavPanelLocation;
