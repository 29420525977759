import { createAsyncThunk } from '@reduxjs/toolkit';

const createAsyncThunkWithAbort = (actionType, payloadCreator) => {
  let abortController = null;

  return createAsyncThunk(actionType, async (args, thunkAPI) => {
    if (abortController) {
      abortController.abort();
    }
    abortController = new AbortController();
    try {
      return await payloadCreator(args, {
        ...thunkAPI,
        signal: abortController.signal,
      });
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  });
};

export default createAsyncThunkWithAbort;
