import React from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  IconGrid,
  IconList,
} from '@leaftrade/leaftrade-component-library';
import { PageHeaderStyleToggleContainer } from './PageHeaderStyleToggle.styles';

const PageHeaderStyleToggle = ({ isGridView, onToggle }) => {
  const handleStyleToggle = (isGridView) => {
    onToggle(isGridView);
  };

  return (
    <PageHeaderStyleToggleContainer data-testid='page-header-style-toggle--component--div'>
      <Button
        onClick={() => handleStyleToggle(true)}
        startIcon={<IconGrid />}
        sx={{
          borderRadius: '4px 0 0 4px',
        }}
        variant={isGridView ? 'primary' : 'outlined'}
      >
        Grid
      </Button>
      <Button
        onClick={() => handleStyleToggle(false)}
        startIcon={<IconList />}
        sx={{
          borderRadius: '0 4px 4px 0',
        }}
        variant={!isGridView ? 'primary' : 'outlined'}
      >
        List
      </Button>
    </PageHeaderStyleToggleContainer>
  );
};

PageHeaderStyleToggle.propTypes = {
  isGridView: PropTypes.bool.isRequired,
  onToggle: PropTypes.func.isRequired,
};

export default PageHeaderStyleToggle;
