import {
  Button,
  Card,
  IconArrowRight,
  IconChevronRight,
  Link,
  bodySmallTypography,
  interaction10,
  interaction100,
  interaction5,
  interaction60,
  neutral10,
  pxToRem,
  spacing3,
  spacing4,
  spacing5,
  xlRadius,
} from '@leaftrade/leaftrade-component-library';
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import HeaderNavCategoryCard from '../HeaderNavCategoryCard';

const HeaderNavSectionCard = ({
  activeSection,
  activeCategory,
  handleLinkClick,
  setActiveCategory,
}) => (
  <Card
    sx={{
      position: 'absolute',
      top: pxToRem(36),
      left: '0',
      display: 'flex',
      alignItems: 'flex-start',
      minWidth: pxToRem(220),
      maxHeight: pxToRem(330),
      padding: `${spacing4} 0`,
      borderRadius: xlRadius,
      background: neutral10.hex,
      overflow: 'hidden',
    }}
    testingId='header--nav-section-card--component--div'
  >
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        width: pxToRem(220),
        position: 'sticky',
        top: 0,
      }}
    >
      {activeSection?.shopAll?.length > 0 && (
        <Link
          classes={['h8']}
          component={RouterLink}
          onClick={handleLinkClick}
          sx={{
            display: 'flex',
            padding: `${spacing4} ${spacing5}`,
            alignItems: 'center',
            color: interaction60.hex,
            '&:hover': {
              color: interaction60.hex,
              '& svg': {
                marginLeft: spacing4,
              },
            },
            '& svg': {
              marginLeft: spacing3,
              width: spacing5,
              height: spacing5,
              fill: interaction60.hex,
              transition: 'margin-left 0.22s ease-in-out',
            },
          }}
          to={activeSection.shopAll}
          underline='none'
          variant='span'
        >
          Shop All {activeSection.name}
          <IconArrowRight />
        </Link>
      )}
      {activeSection.categories?.map((category) => (
        <>
          {category.children.length > 0 && category.name !== 'hemp' && (
            <Button
              align='left'
              bodyStyle={bodySmallTypography}
              endIcon={<IconChevronRight />}
              fullWidth
              key={category.name}
              onClick={() => setActiveCategory(category)}
              sx={{
                padding: `${spacing3} ${spacing4} ${spacing3} ${spacing5}`,
                color: interaction100.hex,
                textTransform: 'capitalize',
                backgroundColor:
                  activeCategory?.name === category.name
                    ? interaction10.hex
                    : 'transparent',
                fontWeight:
                  activeCategory?.name === category.name ? 'bold' : 'normal',
                '&:hover': {
                  backgroundColor:
                    activeCategory?.name === category.name
                      ? interaction10.hex
                      : interaction5.hex,
                },
                '& > div': {
                  padding: 0,
                  '& svg': {
                    fill: interaction100.hex,
                  },
                },
              }}
              variant='transparent'
            >
              {category.name}
            </Button>
          )}
        </>
      ))}
    </div>
    {activeCategory && (
      <HeaderNavCategoryCard
        activeSection={activeSection}
        activeCategory={activeCategory}
        handleLinkClick={handleLinkClick}
      />
    )}
  </Card>
);

export default HeaderNavSectionCard;
