import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import TagManager from 'react-gtm-module';

export const usePageViewTracking = () => {
  const location = useLocation();

  useEffect(() => {
    TagManager.dataLayer({
      event: 'pageview',
      pagePath: location.pathname,
      pageTitle: document.title,
    });
  }, [location]);
};
