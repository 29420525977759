import {
  maxMediaQueryDesktop,
  spacing7,
} from '@leaftrade/leaftrade-component-library';
import styled from 'styled-components';

export const CartItemsMultiCartSummaryWrapper = styled.div`
  display: flex;
  margin-top: ${spacing7};
  ${maxMediaQueryDesktop} {
    flex-direction: column;
  }
`;
