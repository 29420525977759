import {
  IconButton,
  IconCartEmpty,
  IconLoadingSpinner,
  IconTrash,
  Link,
  Modal,
  Typography,
  boldBodySmallTypography,
  boldBodyTypography,
  handleImgSrcError,
  logoIconColor,
  mediumBodyLargeTypography,
  mediumBodySmallTypography,
  neutral60,
  neutral70,
  pxToRem,
  royal60seafoam60Gradient,
  spacing3,
  spacing4,
  spacing6,
} from '@leaftrade/leaftrade-component-library';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';
import { useStorefrontState } from '../../../../hooks/useReduxState';
import {
  deleteCartItem,
  toggleCartPreviewModal,
} from '../../../../store/actions/storefrontActions';
import { currencyFormatter } from '../../../../utils';
import {
  CartPreviewEmptyWrapper,
  CartPreviewFooterTotal,
  CartPreviewFooterWrapper,
  CartPreviewLineItemImage,
  CartPreviewLineItemNameWrapper,
  CartPreviewLineItemQuantityPriceWrapper,
  CartPreviewLineItemWrapper,
} from './CartPreviewModal.styles';

const CartPreviewModal = () => {
  const dispatch = useDispatch();
  const { cart, shopper, vendor } = useStorefrontState();
  const { cartPreviewModal, multiCartSummary, selectedCart } = cart;
  const { currentLocation } = shopper;
  const { settings, slug } = vendor;
  const { batchQueuesEnabled } = settings;

  const [itemBeingDeleted, setItemBeingDeleted] = useState(undefined);

  const numberOfCarts = multiCartSummary?.carts?.length || 0;

  const CartPreviewModalJsx = {
    actions: selectedCart?.cartItems?.length > 0 && (
      <>
        <CartPreviewFooterWrapper data-testid='cart-preview-footer--component--div'>
          <CartPreviewFooterTotal>
            <Typography
              bodyStyle={mediumBodySmallTypography}
              sx={{ color: neutral60.hex }}
            >
              Subtotal
            </Typography>
            <Typography bodyStyle={boldBodySmallTypography}>
              {currencyFormatter.format(selectedCart.total)}
            </Typography>
          </CartPreviewFooterTotal>
        </CartPreviewFooterWrapper>
        <Link
          align='center'
          asButton
          buttonSize='large'
          component={RouterLink}
          onClick={() => {
            dispatch(
              toggleCartPreviewModal({
                isOpen: false,
              })
            );
          }}
          ButtonSx={{
            margin: '0',
            width: '100%',
            backgroundColor: 'unset',
            background: royal60seafoam60Gradient,
            border: 'none',
            ':hover, :focus, :active': {
              border: 'none',
            },
          }}
          sx={{
            ':hover': {
              color: 'inherit',
            },
          }}
          to={`/storefront/sellers/${slug}/cart/`}
        >
          {numberOfCarts > 1
            ? `Review Carts (${numberOfCarts})`
            : 'Review Cart'}
        </Link>
      </>
    ),
    body:
      selectedCart?.cartItems?.length > 0 ? (
        <>
          {selectedCart.cartItems.map((item) => (
            <CartPreviewLineItemWrapper
              data-testid='cart-preview-line-item--component--div'
              key={`${item.cartItemId}-${item.productName}`}
            >
              <Link
                component={RouterLink}
                TypographySx={{
                  display: 'flex',
                  flex: 1,
                }}
                sx={{
                  display: 'flex',
                }}
                to={`/storefront/sellers/${slug}/${item.productId}?variant=${item.cartItemId}`}
                underline='hover'
                variant='span'
              >
                <CartPreviewLineItemImage
                  src={item.imageUrl || logoIconColor}
                  alt={item.productName}
                  onError={handleImgSrcError}
                />
                <CartPreviewLineItemNameWrapper>
                  <Typography
                    bodyStyle={boldBodyTypography}
                    sx={{
                      display: '-webkit-box',
                      WebkitBoxOrient: 'vertical',
                      WebkitLineClamp: 2,
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                    }}
                  >
                    {item.productName}
                  </Typography>
                  <Typography
                    bodyStyle={mediumBodySmallTypography}
                    sx={{
                      color: neutral60.hex,
                    }}
                  >
                    {item.variants[0].displayName}
                  </Typography>
                </CartPreviewLineItemNameWrapper>
              </Link>
              <CartPreviewLineItemQuantityPriceWrapper>
                <Typography
                  bodyStyle={mediumBodySmallTypography}
                  sx={{
                    color: neutral60.hex,
                  }}
                >
                  {item.totalQuantity}
                </Typography>
                <Typography bodyStyle={boldBodySmallTypography}>
                  {currencyFormatter.format(item.totalPrice)}
                </Typography>
              </CartPreviewLineItemQuantityPriceWrapper>
              <IconButton
                icon={
                  itemBeingDeleted === item ? (
                    <IconLoadingSpinner />
                  ) : (
                    <IconTrash />
                  )
                }
                onClick={() => {
                  setItemBeingDeleted(item);
                  dispatch(
                    deleteCartItem({
                      batchQueuesEnabled,
                      item,
                      locationIds: [selectedCart?.locationId],
                      slug,
                      onSuccess: () => setItemBeingDeleted(undefined),
                    })
                  );
                }}
                sx={{
                  marginLeft: spacing4,
                }}
                testingId='cart-preview-modal--delete-item--icon-button'
                variant='destructive'
              />
            </CartPreviewLineItemWrapper>
          ))}
        </>
      ) : (
        <CartPreviewEmptyWrapper data-testid='cart-preview-empty--component--div'>
          <IconCartEmpty />
          <Typography
            align='center'
            bodyStyle={mediumBodyLargeTypography}
            sx={{
              color: neutral60.hex,
              width: pxToRem(125),
            }}
          >
            Your shopping cart is empty
          </Typography>
        </CartPreviewEmptyWrapper>
      ),
    header: (
      <Typography
        component='h6'
        sx={{
          color: neutral70.hex,
        }}
      >
        Shopping Cart: {currentLocation.name}
      </Typography>
    ),
  };

  return (
    <Modal
      actions={CartPreviewModalJsx.actions}
      header={CartPreviewModalJsx.header}
      onClose={() => {
        dispatch(
          toggleCartPreviewModal({
            isOpen: false,
          })
        );
      }}
      open={cartPreviewModal.isOpen}
      sx={{
        marginTop: pxToRem(58),
        overflow: 'visible',
        '& > div': {
          position: 'absolute',
          inset: `-${spacing3} ${spacing6} auto auto`,
          '& > [data-testid="modal--content--div"]': {
            margin: `${spacing3} 0`,
            maxHeight: '60vh',
          },
          '& > [data-testid="modal--actions--div"]': {
            flexDirection: 'column',
            gap: spacing4,
          },
        },
      }}
      testingId='cart-preview-modal--component--div'
    >
      {CartPreviewModalJsx.body}
    </Modal>
  );
};

export default CartPreviewModal;
