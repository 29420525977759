import {
  pxToRem,
  Select,
  spacing4,
  spacing5,
  spacing6,
  Typography,
} from '@leaftrade/leaftrade-component-library';
import PropTypes from 'prop-types';
import React from 'react';
import { useStorefrontState } from '../../../../hooks/useReduxState';

const ProductVariants = ({
  selectedVariantId,
  setAlert,
  setSelectedVariant,
  variants,
}) => {
  const { vendor } = useStorefrontState();
  const { isGridView } = vendor.settings;

  const handleVariantChange = (selectedOption) => {
    setAlert();
    setSelectedVariant(variants.find((v) => v.id === selectedOption.value));
  };

  const variantOptions = variants
    .map((variant) => ({
      label: variant.name,
      level: 1,
      selected: variant.id === selectedVariantId,
      value: variant.id,
      key: variant.id,
    }))
    .filter(
      (value, index, self) =>
        self.findIndex((t) => t.value === value.value) === index
    );

  if (variantOptions.length === 0) {
    return null;
  }

  return (
    <>
      {variants.length === 1 ? (
        <Typography
          noWrap
          sx={{
            maxWidth: isGridView ? pxToRem(240) : 'unset',
            marginTop: isGridView ? spacing4 : 0,
            marginBottom: spacing6,
          }}
          testingId='product-variants--component--typography-p'
        >
          {variantOptions[0].label}
        </Typography>
      ) : (
        <Select
          onSelect={handleVariantChange}
          options={variantOptions}
          sx={{
            maxWidth: isGridView ? pxToRem(240) : 'unset',
            marginBottom: isGridView ? spacing5 : 0,
            '& > button': {
              zIndex: 1,
            },
            '& > div': {
              zIndex: 2,
            },
          }}
          testingId='product-variants--component--select'
          variant='secondary'
        />
      )}
    </>
  );
};

ProductVariants.propTypes = {
  selectedVariantId: PropTypes.number.isRequired,
  setAlert: PropTypes.func.isRequired,
  setSelectedVariant: PropTypes.func.isRequired,
  variants: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    })
  ).isRequired,
};

export default ProductVariants;
