import {
  pxToRem,
  spacing6,
  Typography,
} from '@leaftrade/leaftrade-component-library';
import PropTypes from 'prop-types';
import React from 'react';
import { useStorefrontState } from '../../../../hooks/useReduxState';

const ProductBrands = ({ brands }) => {
  const { vendor } = useStorefrontState();
  const { isGridView } = vendor.settings;

  let brandsString = '';
  if (brands.length > 1) {
    brandsString = `Brands: ${brands.map((b) => b.name).join(', ')}`;
  } else if (brands.length === 1) {
    brandsString = `Brand: ${brands[0].name}`;
  }

  return (
    <Typography
      gutterBottom={isGridView}
      noWrap
      sx={{
        height: spacing6,
        maxWidth: isGridView ? pxToRem(240) : 'unset',
      }}
      testingId='product-brands--component--typography-p'
    >
      {brandsString}
    </Typography>
  );
};

ProductBrands.propTypes = {
  brands: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
    })
  ).isRequired,
};

export default ProductBrands;
