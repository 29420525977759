import { configureStore } from '@reduxjs/toolkit';
import { rootReducer } from './reducers/rootReducer';
import { createDebouncedUndoMiddleware } from './utils/undoable';
import { vendorApi } from './queries/vendor';

export const setupStore = (preloadedState) =>
  configureStore({
    reducer: rootReducer,
    preloadedState,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware()
        .concat(createDebouncedUndoMiddleware(['inventoryPricing']))
        .concat(vendorApi.middleware),
  });
