import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import {
  axiosDeleteData,
  axiosGetData,
  axiosGetParallelData,
  axiosPostData,
  axiosPutData,
} from '../../helpers/axios';
import { setLocationCall } from '../queries';
import createAsyncThunkWithAbort from './utils/createAsyncThunkWithAbort';

export const addBankAccount = createAsyncThunk(
  'addBankAccount',
  async ({ data, dispensaryId, onSuccess }, { rejectWithValue }) =>
    axiosPostData({
      data,
      onSuccess,
      rejectWithValue,
      url: `/dashboard/customer/${dispensaryId}/payments/bank_accounts/create-api/`,
    })
);

export const addProductToCart = createAsyncThunk(
  'addProductToCart',
  async (
    { batchQueuesEnabled = false, data, onSuccess, slug },
    { rejectWithValue }
  ) => {
    let url;
    if (batchQueuesEnabled) {
      url = `/shop/${slug}/cart/package_size/${data.package_size_pk}`;
    } else {
      url = `/shop/${slug}/products/add/`;
    }
    return axiosPostData({
      data,
      onSuccess,
      rejectWithValue,
      url,
    });
  }
);

export const addProductToQuickOrder = createAction('addProductToQuickOrder');

export const addTimedBanner = createAction('addTimedBanner');

export const clearCart = createAsyncThunk(
  'clearCart',
  async ({ locationIds, onSuccess, slug }, { rejectWithValue }) =>
    axiosDeleteData({
      data: JSON.stringify({ dispensaryLocationIds: locationIds }),
      onSuccess,
      rejectWithValue,
      url: `/shop/${slug}/cart/multi-cart/clear/`,
    })
);

export const clearCartAfterCheckout = createAction('clearCartAfterCheckout');

export const clearQuickOrder = createAsyncThunk(
  'clearQuickOrder',
  async ({ locationIds, onSuccess, slug }, { rejectWithValue }) =>
    axiosDeleteData({
      data: JSON.stringify({ dispensaryLocationIds: locationIds }),
      onSuccess,
      rejectWithValue,
      url: `/shop/${slug}/cart/quick-order/clear/`,
    })
);

export const clearCartToken = createAction('clearCartToken');

export const deleteCartItem = createAsyncThunk(
  'deleteCartItem',
  async (
    { batchQueuesEnabled = false, item, locationIds, onSuccess, slug },
    { rejectWithValue }
  ) => {
    let requestBody;
    if (!batchQueuesEnabled) {
      requestBody = JSON.stringify({
        variantId: item.variants[0].variantId,
        dispensaryLocationIds: locationIds,
      });
    } else {
      requestBody = JSON.stringify({
        packageSizeId: item.packageSizeId,
        dispensaryLocationIds: locationIds,
      });
    }
    return axiosDeleteData({
      data: requestBody,
      onSuccess,
      rejectWithValue,
      url: `/shop/${slug}/cart/multi-cart/`,
    });
  }
);

export const deleteQuickOrderItem = createAsyncThunk(
  'deleteQuickOrderItem',
  async (
    { batchQueuesEnabled = false, cartItemId, locationsIds, onSuccess, slug },
    { rejectWithValue }
  ) => {
    const deleteParams = {
      [batchQueuesEnabled ? 'packageSizeId' : 'variantId']: cartItemId,
      dispensaryLocationIds: locationsIds,
    };

    return axiosDeleteData({
      data: JSON.stringify(deleteParams),
      onSuccess,
      rejectWithValue,
      url: `/shop/${slug}/cart/quick-order/`,
    });
  }
);

export const editCartItemQuantity = createAsyncThunk(
  'editCartItemQuantity',
  async (
    { batchQueuesEnabled = false, item, locationId, onSuccess, quantity, slug },
    { rejectWithValue }
  ) => {
    const requestBody = {
      quantity,
      dispensaryLocationIds: [locationId],
    };
    if (!batchQueuesEnabled) {
      requestBody.variantId = item.variants[0].variantId;
    } else {
      requestBody.package_size_pk = item.packageSizeId;
    }
    return axiosPutData({
      data: JSON.stringify(requestBody),
      onSuccess,
      rejectWithValue,
      url: `/shop/${slug}/cart/multi-cart/`,
    });
  }
);

export const editQuickOrderItemQuantity = createAsyncThunk(
  'editQuickOrderItemQuantity',
  async (
    {
      batchQueuesEnabled = false,
      displayedLocationsIds,
      editLocationsIds,
      isRefresh = false,
      item,
      onSuccess,
      quantity,
      slug,
    },
    { rejectWithValue }
  ) => {
    const requestBody = {
      quantity,
      dispensaryLocationIds: editLocationsIds,
      selectedLocationIds: displayedLocationsIds,
    };
    if (batchQueuesEnabled) {
      requestBody.packageSizeId = item.cartItemId;
    } else {
      requestBody.variantId = item.variants[0].variantId;
    }
    return axiosPutData({
      data: requestBody,
      meta: {
        isRefresh,
        item,
      },
      onSuccess,
      rejectWithValue,
      url: `/shop/${slug}/cart/quick-order${
        batchQueuesEnabled ? `-batch-queues${isRefresh ? '-refresh' : ''}` : ''
      }/`,
    });
  }
);

export const getAllSellersData = createAsyncThunk(
  'getAllSellersData',
  async (
    {
      queryString,
      isLoadingMore = false,
      isRefreshingFavorites = false,
      onSuccess,
    },
    { rejectWithValue }
  ) =>
    axiosGetData({
      meta: {
        isLoadingMore,
        isRefreshingFavorites,
      },
      onSuccess,
      rejectWithValue,
      url: `/storefront2/seller-discovery/${
        queryString ? `?${queryString}` : ''
      }`,
    })
);

export const getAuthUserDispensariesAndVendors = createAsyncThunk(
  'getAuthUserDispensariesAndVendors',
  async (_, { rejectWithValue }) =>
    axiosGetData({
      rejectWithValue,
      url: '/storefront2/navigation/',
    })
);

export const getCartData = createAsyncThunk(
  'getCartData',
  async (
    { getNotices = false, onSuccess, setIsLoading = false, slug, token },
    { rejectWithValue }
  ) => {
    const urls = [
      `/shop/${slug}/cart/multi-cart/${token || ''}`,
      `/shop/${slug}/cart/multi-cart-summary/`,
    ];
    const keys = ['cartData', 'multiCartSummary'];
    if (getNotices) {
      urls.push(`/shop/${slug}/cart/${token}/notices`);
      keys.push('cartNotices');
    }
    return axiosGetParallelData({
      getParams: {
        urls,
        keys,
      },
      meta: {
        setIsLoading,
      },
      onSuccess,
      rejectWithValue,
    });
  }
);

export const getCartDataSummary = createAsyncThunk(
  'getCartDataSummary',
  async ({ slug }, { rejectWithValue }) =>
    axiosGetData({
      rejectWithValue,
      url: `/shop/${slug}/cart/multi-cart-summary/`,
    })
);

export const getCheckoutData = createAsyncThunk(
  'getCheckoutData',
  async ({ onSuccess, slug }, { rejectWithValue }) =>
    axiosGetData({
      onSuccess,
      rejectWithValue,
      url: `/shop/${slug}/checkout/summary-view/`,
    })
);

export const getShopperLocations = createAsyncThunk(
  'getShopperLocations',
  async ({ slug }, { rejectWithValue }) =>
    axiosGetData({
      rejectWithValue,
      url: `/storefront2/vendor/${slug}/user-locations/`,
    })
);

export const getLandingPageData = createAsyncThunk(
  'getLandingPageData',
  async (_, { rejectWithValue }) =>
    axiosGetData({
      rejectWithValue,
      url: '/storefront2-landing/',
    })
);

export const getNavData = createAsyncThunk(
  'getNavData',
  async (_, { rejectWithValue }) =>
    axiosGetParallelData({
      getParams: {
        urls: [
          '/storefront2/grouped-active-brands/',
          '/storefront2/grouped-active-sellers/',
        ],
        keys: ['brands', 'vendors'],
      },
      rejectWithValue,
    })
);

export const getProductDetails = createAsyncThunk(
  'getProductDetails',
  async (
    {
      batchQueuesEnabled,
      defaultVariantId,
      isPreview,
      onSuccess,
      productId,
      vendorSlug,
    },
    { rejectWithValue }
  ) => {
    let url = `/storefront2/product-details/${vendorSlug}/${productId}${
      isPreview ? `?preview` : ''
    }`;
    if (batchQueuesEnabled) {
      url = `/storefront2/product-details-batch-queues/${vendorSlug}/product/${productId}?package_size_id=${defaultVariantId}${
        isPreview ? `&preview` : ''
      }`;
    }
    return axiosGetData({
      meta: {
        batchQueuesEnabled,
        defaultVariantId,
      },
      onSuccess,
      rejectWithValue,
      url,
    });
  }
);

export const getPublicStorefrontData = createAsyncThunk(
  'getPublicStorefrontData',
  async ({ isLoadingMore, slug, queryString }, { rejectWithValue }) =>
    axiosGetData({
      meta: {
        isLoadingMore,
      },
      rejectWithValue,
      url: `/storefront2/public/${slug}/${
        queryString ? `?${queryString}` : ''
      }`,
    })
);

export const getQuickOrderData = createAsyncThunk(
  'getQuickOrderData',
  async ({ locationIds, onSuccess, slug }, { rejectWithValue }) =>
    axiosGetParallelData({
      getParams: {
        urls: [
          `/shop/${slug}/cart/quick-order/?${locationIds
            .map((locationId) => `selectedLocationIds=${locationId}`)
            .join('&')}`,
          `/shop/${slug}/cart/quick-order/notices?${locationIds
            .map((locationId) => `selectedLocationIds=${locationId}`)
            .join('&')}`,
        ],
        keys: ['quickOrderData', 'quickOrderNotices'],
      },
      onSuccess,
      rejectWithValue,
    })
);

export const getQuickOrderNotices = createAsyncThunk(
  'getQuickOrderNotices',
  async ({ locationIds, onSuccess, slug }, { rejectWithValue }) =>
    axiosGetData({
      onSuccess,
      rejectWithValue,
      url: `/shop/${slug}/cart/quick-order/notices?${locationIds
        .map((locationId) => `selectedLocationIds=${locationId}`)
        .join('&')}`,
    })
);

export const getQuickOrderProducts = createAsyncThunk(
  'getQuickOrderProducts',
  async (
    { isLoadingMore, onSuccess, queryString, slug },
    { rejectWithValue }
  ) => {
    const url = `/storefront2/quick-order-storefront/${slug}/${
      queryString ? `?${queryString}` : ''
    }`;

    return axiosGetData({
      meta: {
        isLoadingMore,
      },
      onSuccess,
      rejectWithValue,
      url,
    });
  }
);

export const getSellerNavData = createAsyncThunk(
  'getSellerNavData',
  async ({ onSuccess, slug }, { rejectWithValue }) =>
    axiosGetData({
      onSuccess,
      rejectWithValue,
      url: `/storefront2/seller-nav-data/${slug}/`,
    })
);

export const getSellerStorefrontData = createAsyncThunkWithAbort(
  'getSellerStorefrontData',
  async (
    { dispensaryId, isLoadingMore, slug, queryString },
    { rejectWithValue, signal }
  ) =>
    axiosGetData({
      meta: {
        isLoadingMore,
      },
      rejectWithValue,
      url:
        dispensaryId || dispensaryId === 0
          ? `/storefront2/seller-storefront/${slug}/preview/${dispensaryId}/${
              queryString ? `?${queryString}` : ''
            }`
          : `/storefront2/seller-storefront/${slug}/${
              queryString ? `?${queryString}` : ''
            }`,
      signal,
    })
);

export const getTerpeneTypes = createAsyncThunk(
  'getTerpeneTypes',
  async ({ slug }, { rejectWithValue }) =>
    axiosGetData({
      rejectWithValue,
      url: `/storefront2/terpene-type?${slug
        .map((s) => `slug=${s}`)
        .join('&')}`,
    })
);

export const getVendorPreviewCustomers = createAsyncThunk(
  'getVendorPreviewCustomers',
  async ({ vendorSlug, onSuccess }, { rejectWithValue }) =>
    axiosGetData({
      onSuccess,
      rejectWithValue,
      url: `/dashboard/api/vendor/${vendorSlug}/storefront-preview-customers/`,
    })
);

export const getVendorPreviewCustomerLocations = createAsyncThunk(
  'getVendorPreviewCustomerLocations',
  async ({ dispensaryId, vendorSlug, onSuccess }, { rejectWithValue }) =>
    axiosGetData({
      onSuccess,
      rejectWithValue,
      url: `/dashboard/api/vendor/${vendorSlug}/storefront-preview-customer/${dispensaryId}/locations/`,
    })
);

export const postCheckoutData = createAsyncThunk(
  'postCheckoutData',
  async ({ data, onSuccess, slug }, { rejectWithValue }) =>
    axiosPostData({
      data,
      onSuccess,
      rejectWithValue,
      url: `/shop/${slug}/checkout/summary-post/`,
    })
);

export const removeTimedBanner = createAction('removeTimedBanner');

export const resetProductDetails = createAction('resetProductDetails');

export const setHasLimitedAccess = createAction('setHasLimitedAccess');

export const setLocationFromCart = createAsyncThunk(
  'setLocationFromCart',
  async ({ locationId, dispensaryId, vendorSlug }, { rejectWithValue }) =>
    setLocationCall({ locationId, dispensaryId, rejectWithValue, vendorSlug })
);

export const setLocationFromDispensarySelection = createAsyncThunk(
  'setLocationFromDispensarySelection',
  async ({ dispensaryId, onSuccess }, { rejectWithValue }) =>
    setLocationCall({ dispensaryId, onSuccess, rejectWithValue })
);

export const setLocationFromHeader = createAsyncThunk(
  'setLocationFromHeader',
  async ({ locationId, dispensaryId, vendorSlug }, { rejectWithValue }) =>
    setLocationCall({ locationId, dispensaryId, rejectWithValue, vendorSlug })
);

export const setLocationFromLoadData = createAction('setLocationFromLoadData');

export const setNavigationLinks = createAction('setNavigationLinks');

export const setNavigationSections = createAction('setNavigationSections');

export const setProductId = createAction('setProductId');

export const setSelectedImage = createAction('setSelectedImage');

export const setSelectedQuickOrderLocations = createAction(
  'setSelectedQuickOrderLocations'
);

export const setSelectedVariant = createAction('setSelectedVariant');

export const setSellerGridView = createAction('setSellerGridView');

export const setSellersGridView = createAction('setSellersGridView');

export const setShopper = createAction('setShopper');

export const setVendorPreviewCustomer = createAction(
  'setVendorPreviewCustomer'
);

export const setVendorPreviewCustomerLocation = createAction(
  'setVendorPreviewCustomerLocation'
);

export const setVendorSlug = createAction('setVendorSlug');

export const toggleAddBankAccountModal = createAction(
  'toggleAddBankAccountModal'
);

export const toggleCartClearModal = createAction('toggleCartClearModal');

export const toggleCartDeleteModal = createAction('toggleCartDeleteModal');

export const toggleCartPreviewModal = createAction('toggleCartPreviewModal');

export const toggleConfirmAgeModal = createAction('toggleConfirmAgeModal');

export const toggleFavoriteVendor = createAsyncThunk(
  'toggleFavoriteVendor',
  async ({ isFavorite, onSuccess, vendorId }, { rejectWithValue }) => {
    let action = 'add';
    if (isFavorite) {
      action = 'remove';
    }
    const formData = new FormData();
    formData.append('action', action);

    return axiosPostData({
      data: formData,
      meta: {
        vendorId,
      },
      onSuccess,
      rejectWithValue,
      url: `/profile/favorite-vendor/${vendorId}/edit/`,
    });
  }
);

export const togglePrivacyPolicyModal = createAction(
  'togglePrivacyPolicyModal'
);

export const toggleQuickOrderAddModal = createAction(
  'toggleQuickOrderAddModal'
);

export const toggleQuickOrderDeleteModal = createAction(
  'toggleQuickOrderDeleteModal'
);

export const toggleQuickOrderClearModal = createAction(
  'toggleQuickOrderClearModal'
);

export const toggleTermsOfServiceModal = createAction(
  'toggleTermsOfServiceModal'
);
