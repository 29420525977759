import {
  Typography,
  bodySmallTypography,
} from '@leaftrade/leaftrade-component-library';
import PropTypes from 'prop-types';
import React from 'react';
import { BadgeWrapper } from './StorefrontModeBadge.styles';
import { STOREFRONT_MODE } from '../../../constants';

const StorefrontModeBadge = ({ storefrontMode }) => {
  const modeString = () => {
    switch (storefrontMode) {
      case STOREFRONT_MODE.COMING_SOON:
        return 'Coming Soon';
      case STOREFRONT_MODE.INACTIVE:
        return 'Inactive';
      case STOREFRONT_MODE.READ_ONLY_MENU:
        return 'View Only';
      default:
        return '';
    }
  };

  return (
    <BadgeWrapper
      data-testid='storefront-mode-badge--component--div'
      storefrontMode={storefrontMode}
    >
      <Typography bodyStyle={bodySmallTypography} component='span'>
        {modeString()}
      </Typography>
    </BadgeWrapper>
  );
};

StorefrontModeBadge.propTypes = {
  storefrontMode: PropTypes.string.isRequired,
};

export default StorefrontModeBadge;
