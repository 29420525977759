import {
  IconCartEmpty,
  Link,
  Typography,
  bodyLargeTypography,
  pxToRem,
} from '@leaftrade/leaftrade-component-library';
import propTypes from 'prop-types';
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useStorefrontState } from '../../../hooks/useReduxState';
import { EmptyCartContent, EmptyCartWrapper } from './EmptyCart.styles';

const EmptyCart = ({ isCheckout }) => {
  const { vendor } = useStorefrontState();
  const { slug } = vendor;

  return (
    <EmptyCartWrapper data-testid='empty-cart--component--div'>
      <EmptyCartContent>
        <IconCartEmpty />
        <Typography
          align='center'
          bodyStyle={bodyLargeTypography}
          sx={{ maxWidth: pxToRem(150) }}
          testingId='empty-cart--empty--typography'
        >
          Your shopping cart is <strong>empty</strong>.{' '}
          {isCheckout && 'There is nothing to checkout.'}
        </Typography>
      </EmptyCartContent>
      <Link
        asButton
        component={RouterLink}
        testingId='empty-cart--add-products--link'
        to={`/storefront/sellers/${slug}`}
      >
        Add Products
      </Link>
    </EmptyCartWrapper>
  );
};

EmptyCart.defaultProps = {
  isCheckout: false,
};

EmptyCart.propTypes = {
  isCheckout: propTypes.bool,
};

export default EmptyCart;
