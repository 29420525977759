import {
  IconTrash,
  Typography,
  pxToRem,
  spacing3,
  spacing7,
} from '@leaftrade/leaftrade-component-library';
import PropTypes from 'prop-types';
import React from 'react';
import { useDispatch } from 'react-redux';
import { toggleQuickOrderDeleteModal } from '../../../../store/actions/storefrontActions';
import { currencyFormatter } from '../../../../utils';
import {
  QuickOrderTableItemSummaryDelete,
  QuickOrderTableItemSummaryWrapper,
} from './QuickOrderTableItemSummary.styles';

const QuickOrderTableItemSummary = ({ item }) => {
  const dispatch = useDispatch();

  const { selectedLocationTotal = 0, selectedLocationCount = 0 } = item;

  const handleDeleteClick = () => {
    dispatch(
      toggleQuickOrderDeleteModal({
        item,
        isOpen: true,
      })
    );
  };

  const quantitySubtotalBaseStyleObject = {
    padding: `${spacing7} ${spacing3}`,
    display: 'flex',
    alignItems: 'center',
    minWidth: pxToRem(125),
    flex: 1,
  };

  return (
    <QuickOrderTableItemSummaryWrapper
      className='fixedColumns'
      data-testid='quick-order-table-item-summary--component--div'
    >
      <Typography
        sx={{
          ...quantitySubtotalBaseStyleObject,
          justifyContent: 'center',
        }}
      >
        {selectedLocationCount}
      </Typography>
      <Typography
        sx={{
          ...quantitySubtotalBaseStyleObject,
          justifyContent: 'flex-start',
        }}
      >
        {currencyFormatter.format(selectedLocationTotal)}
      </Typography>
      <QuickOrderTableItemSummaryDelete
        type='button'
        onClick={handleDeleteClick}
      >
        <IconTrash />
      </QuickOrderTableItemSummaryDelete>
    </QuickOrderTableItemSummaryWrapper>
  );
};

QuickOrderTableItemSummary.propTypes = {
  item: PropTypes.shape({
    selectedLocationTotal: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]),
    selectedLocationCount: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]),
  }).isRequired,
};

export default QuickOrderTableItemSummary;
