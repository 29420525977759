import {
  boldBodyLargeTypography,
  Button,
  Modal,
  Typography,
} from '@leaftrade/leaftrade-component-library';
import React from 'react';
import { useDispatch } from 'react-redux';
import { useStorefrontState } from '../../../../hooks/useReduxState';
import { toggleConfirmAgeModal } from '../../../../store/actions/storefrontActions';

const ConfirmAgeModal = () => {
  const dispatch = useDispatch();
  const { publicMenu } = useStorefrontState();
  const { confirmAgeModal } = publicMenu;

  const ConfirmAgeModalJsx = {
    actions: (
      <>
        <Button
          onClick={() => {
            sessionStorage.setItem('isOfAge', true);
            dispatch(toggleConfirmAgeModal({ isOpen: false }));
          }}
        >
          Yes
        </Button>
        <Button
          variant='destructive'
          onClick={() => {
            window.location.href = 'https://leaf.trade/';
          }}
        >
          Not Yet
        </Button>
      </>
    ),
    header: (
      <Typography bodyStyle={boldBodyLargeTypography} gutterBottom>
        Are you 21 years of age or older?
      </Typography>
    ),
  };

  return (
    <Modal
      actions={ConfirmAgeModalJsx.actions}
      header={ConfirmAgeModalJsx.header}
      hideClose
      open={confirmAgeModal.isOpen}
      testingId='confirm-age-modal--component--div'
    >
      {ConfirmAgeModalJsx.body}
    </Modal>
  );
};

export default ConfirmAgeModal;
