import React from 'react';

const TermsOfService = () => (
  <div>
    <h1>LEAF TRADE INC. TERMS OF SERVICE</h1>

    <h2>1. INTRODUCTION</h2>
    <p>
      Welcome to the family of websites provided by Leaf Trade Inc. (“Leaf
      Trade”, “we”, “us”, or “our”). This Agreement applies to each website
      where it is posted or provided (“Website” or collectively, the “Sites”)
      and related services (together, the “Services”). By using one of the Sites
      or any of the Services provided thereon or clicking “I agree …” or “I
      accept …” statements during the registration process, you (the person
      authorized by Leaf Trade to use the Sites and who has been given a user
      account and login credentials), any business entity that you either own,
      control, are employed by, or are affiliated with (including, but not
      limited to, the business entity authorized to use the Sites by Leaf Trade)
      (collectively, the “Included Entities”) and the Included Entities&apos;
      direct and indirect owners, officers, members, shareholders, employees,
      agents and representatives, and their respective affiliates (each an
      “Included Person” and, collectively, the “Included Persons”) (collectively
      referred to herein as “you” or the “User”), accept this Agreement.
    </p>
    <p>
      You, the person using the Sites, hereby certify that you are above the
      greater of 18 years of age, or the age of majority in your jurisdiction,
      and the applicable age restrictions imposed by your jurisdiction related
      to the purchase and/or sale of cannabis-related products or at which you
      are legally permitted to transact in business with the products and
      Services described within the Sites (“Legal Age”). Minors, including those
      under the Legal Age, and especially including children under 13, are not
      permitted to use the Sites or associated Services.
    </p>
    <p>
      You, the person authorized to use the Sites, hereby represent that you,
      the individual who have been given a user account and login credentials by
      Leaf Trade, has the authority to bind the Included Entities, Included
      Persons and any other relevant parties listed above to the terms of this
      Agreement.
    </p>
    <p>
      PLEASE READ THESE TERMS CAREFULLY, AS THEY CONTAIN CONTRACTUAL TERMS
      REGARDING YOUR RIGHTS TO USE OUR SITES AND SERVICES, RESTRICTIONS ON
      RIGHTS YOU CAN ENFORCE AGAINST LEAF TRADE, AND LIMITATIONS OF LEAF TRADE'S
      LIABILITY. BY ACCESSING OUR SITES OR SERVICES, YOU AGREE TO BE BOUND BY
      THESE TERMS, INCLUDING THE WARRANTY DISCLAIMERS, LIMITATIONS OF LIABILITY
      AND TERMINATION PROVISIONS. IF YOU DO NOT AGREE WITH THESE TERMS, PLEASE
      DO NOT ACCESS THIS SITE. WHEN REGISTERING FOR AN ACCOUNT AND LOGIN
      CREDENTIALS, YOU WILL NEED TO CONFIRM YOUR AGREEMENT TO THESE TERMS TO
      COMPLETE THE PROCESS. IF YOU DO NOT SO CONFIRM YOUR AGREEMENT, YOUR
      ACCOUNT WILL NOT BE CREATED.
    </p>
    <p>
      We may change the terms of this Agreement from time to time. By continuing
      to use any of the Sites after we post any such changes, you accept and
      agree to be bound by the terms of the Agreement, as modified. YOU
      ACKNOWLEDGE AND AGREE THAT LEAF TRADE SHALL NOT BE LIABLE TO YOU OR TO ANY
      THIRD PARTY FOR ANY MODIFICATION, SUSPENSION OR DISCONTINUANCE OF THE
      SITES OR SERVICES, OR INFORMATION PROVIDED ON THIS SITES OR ACCESSED BY
      YOU THROUGH THE SITES.
    </p>

    <h2>2. SERVICES</h2>
    <p>
      Leaf Trade provides a digital marketplace for duly licensed wholesale and
      retail cannabis-related businesses to connect and transact. By using the
      Sites, you certify that you have a valid and duly issued license to
      operate a cannabis-related business in your jurisdiction and that your
      license is in good standing. You acknowledge that Leaf Trade merely offers
      a communication platform to facilitate orders for cannabis-related
      products, but does not facilitate the actual purchase or sale of such
      products; the purchase and sale must only be conducted between duly
      licensed businesses.
    </p>
    <p>
      You acknowledge and agree that Leaf Trade is not responsible for the
      content, features, and functionality of the Sites and that not all
      Services offered on the Sites are guaranteed to function or perform. You
      further acknowledge that Leaf Trade may alter, expand, or reduce the
      features and functionality of the Sites from time to time without notice.
    </p>
    <p>
      By communicating with other users of the Sites that are sellers regarding
      an order for products shown on the Sites, you acknowledge that all such
      orders will be fulfilled by the users that are third-party sellers and not
      Leaf Trade. The seller/user will be responsible for all processing,
      shipping, returns, and customer service related to your order. Products
      purchased from a seller can only be returned to that seller/user in
      accordance with such seller&apos;s/user&apos;s return policy and cannot be
      returned to Leaf Trade. Users must contact the individual seller to obtain
      the seller&apos;s shipping information, return policy, customer service
      information, and other pertinent policies.
    </p>
    <p>
      Visiting the Sites, using the Services, or sending emails to us
      constitutes electronic communications. You consent to receive electronic
      communications and you agree that all agreements, notices, disclosures,
      and other communications that we provide to you electronically, via email,
      and on the Sites, satisfy any legal requirement that such communications
      be in writing. To opt out of receiving marketing emails from us, follow
      the “unsubscribe” link on the email to change your user preferences or
      follow the procedures set forth in our Privacy Policy. There are certain
      system-generated and legal notices that you cannot opt out of without
      deactivating your account.
    </p>

    <h2>3. USE OF THE SITES</h2>
    <p>
      In order to access certain portions of the Sites, you will be required to
      register by providing information in a form requested by Leaf Trade. Any
      contact information you provide may be used by us in accordance with our
      Privacy Policy. Leaf Trade may accept or reject any application for
      registration in its sole discretion.
    </p>
    <p>
      You certify that the information you provide on the Sites is accurate and
      complete. You acknowledge that you are solely responsible for the
      confidentiality of your login credentials, including the password and for
      all statements made and acts or omissions that occur on or associated with
      your account. If you believe someone has used your password or account
      without your authorization, you must notify Leaf Trade immediately. Your
      account may also be restricted or terminated for any reason, at our sole
      discretion. We may also change, restrict access to, suspend, or
      discontinue the Sites, or any portion of the Sites, at any time without
      notice.
      <b>
        IF YOU CHOOSE TO MAKE ANY OF YOUR PERSONALLY IDENTIFIABLE OR OTHER
        INFORMATION PUBLICLY AVAILABLE IN A FORUM OR OTHERWISE ON THIS SITE, YOU
        DO SO AT YOUR OWN RISK.
      </b>
    </p>
    <p>
      Leaf Trade and its affiliates have no liability to you for content on the
      Sites that you find offensive, indecent, or objectionable. The Sites are
      designed for mature audiences only. By using the Sites, you certify that
      you satisfy the applicable Legal Age requirements.
    </p>
    <p>
      You are prohibited from: (1) violating or attempting to violate the
      security of the Sites; (2) using any device, software, or routine to
      interfere or attempt to interfere with the proper working of the Sites;
      (3) using or attempting to use any engine, software, tool, agent, or other
      device or mechanism (except the search mechanisms provided by Leaf Trade
      or other third-party web browsers) to navigate or search the Sites; (4)
      licensing, distributing, making derivative works, displaying, selling, or
      “framing” content from the Sites, excluding Content you create (as defined
      below); and/or (5) reverse engineering or attempting to reverse engineer
      any portion of the Sites or associated Services.
    </p>
    <p>
      Leaf Trade may alter, suspend, or discontinue the Sites and/or Services,
      or your access thereto or use thereof at any time and for any reason or no
      reason, without notice, but will attempt to provide notice of the same.
      The Sites and/or Services may be unavailable from time to time due to
      maintenance or malfunction of computer or network equipment or other
      reasons. Leaf Trade may periodically add or update the information and
      materials on the Sites without notice. You may need to update third-party
      software from time to time in order to use the Sites and/or Services.
    </p>

    <h2>4. CONTENT SUBMISSION</h2>
    <p>
      Leaf Trade allows and/or may allow users to submit and/or share content
      including, but not limited to, product information including descriptions,
      pricing, lab results, and photographs, comments, feedback, submissions,
      suggestions, questions, reviews, communications, materials and other
      content through the Sites (“Content”). Content should be written in
      English.
    </p>
    <p>
      By submitting Content, you warrant that (1) you are an author and owner of
      the Content or duly licensed by the owner of the Content to use in
      connection with the Sites and the Services without restrictions; (2) you
      are at least of Legal Age as defined in this Agreement; and (3) use of any
      Content you supply will not violate any term of this Agreement/any license
      agreement and will not cause injury to any person or entity.
    </p>

    <h3>A. License Grant for Submitted Content</h3>
    <p>
      If you provide any Content to Leaf Trade (either through the Sites or to
      any Leaf Trade employee or personnel) or make any submission to the Sites,
      you hereby automatically grant, or warrant that the owner of such Content
      has hereby expressly granted Leaf Trade or authorized you to hereby grant
      to Leaf Trade, a royalty-free, perpetual, irrevocable, worldwide,
      unlimited, nonexclusive, fully paid/sublicensable license to use, copy,
      reproduce, create derivative works from, modify, publish, distribute,
      edit, translate, distribute, perform, and display the Content,
      communication, or material in any media or medium, or any form, format, or
      forum now known or hereafter developed. Leaf Trade may sublicense its
      rights through multiple tiers of sublicenses. You should not submit any
      Content, communication or material to us that you do not wish to license
      to us. Leaf Trade is and shall be under no obligation: (1) to maintain any
      Content in confidence; (2) to pay any compensation for any Content; or (3)
      to respond to any Content. You grant Leaf Trade the right to use your name
      in connection with any Content.
    </p>

    <h3>B. Prohibited Content</h3>
    <p>
      You represent and warrant that you will not submit the following Content:
    </p>
    <p>
      <ol type='i'>
        <li>Content that is false, inaccurate, or misleading;</li>
        <li>
          Content that contains an individual&apos;s full name(s), sensitive
          data, or any other confidential identifiable information of an
          individual;
        </li>
        <li>
          Content that violates any local, state, or federal laws; provided,
          however, that Content that violates the Federal Controlled Substances
          Act that otherwise does not violate federal law and complies with
          state law in the jurisdiction in which the Services are used is
          permitted;
        </li>
        <li>
          Content that infringes on the rights of others, including patents,
          copyrights, trademarks, trade secrets, publicity, or privacy rights;
        </li>
        <li>
          Content that is obscene, derogatory, defamatory, threatening,
          harassing, abusive, slanderous, hateful, unlawful as defined in
          4(B)(iii), or embarrassing to any other person or entity, as
          determined by Leaf Trade in its sole discretion;
        </li>
        <li>
          Advertisements, solicitations, or spam links to other web sites or
          individuals, without prior written permission from Leaf Trade;
        </li>
        <li>Chain letters or pyramid schemes;</li>
        <li>
          Content that impersonates another business, person, or entity,
          including Leaf Trade, its related entities, employees, and agents;
        </li>
        <li>
          Content that contains viruses, malware, malicious content, or other
          harmful computer code;
        </li>
        <li>
          Content that victimizes, harasses, degrades, or intimidates an
          individual or group of individuals based on religion, gender, sexual
          orientation, race, ethnicity, age, or disability;
        </li>
        <li>
          Content that you were compensated or granted any compensation by any
          third party to provide unless otherwise authorized by Leaf Trade in
          writing; or
        </li>
        <li>
          Content that violates any policy posted on the Sites, or interferes
          with the use of the Sites by others.
        </li>
      </ol>
    </p>
    <p>
      Although Leaf Trade cannot and does not attempt to monitor all Content,
      you understand that Leaf Trade shall have the right, but not the
      obligation, to monitor the Content of the Sites to determine compliance
      with this Agreement and any other operating rules that may be established
      by Leaf Trade from time to time. Leaf Trade shall have the right, in its
      sole discretion, to edit, move, delete, or refuse to post any Content
      submitted to, or posted on, the Sites for any reason, including violation
      of this Agreement, whether for legal or other reasons. Notwithstanding
      this right of ours, you are solely responsible for any Content you submit
      and you agree to indemnify Leaf Trade and its affiliates for all claims
      resulting from any Content you submit.
    </p>
    <p>
      <b>
        UNDER NO CIRCUMSTANCES SHALL LEAF TRADE OR ITS AFFILIATES BE LIABLE IN
        ANY WAY FOR ANY USER CONTENT POSTED ON OR MADE AVAILABLE THROUGH THE
        SITES OR SERVICES.
      </b>
      We do not control, have no obligation to monitor, and are not responsible
      for what users post, and are not responsible for any offensive,
      inappropriate, obscene, unlawful, infringing, or otherwise objectionable
      or illegal Content on the Sites. Leaf Trade claims no ownership,
      affiliation with or endorsement of any photos that are submitted by users
      through our Sites.
    </p>
    <p>
      Information sent or received over the Internet is generally unsecured and
      Leaf Trade cannot and does not make any representation or warranty
      concerning security of any communication to or from the Sites or any
      representation or warranty regarding the interception by third parties of
      personal or other information. You understand that the technical
      processing and transmission of the Services, including your Content, may
      be transferred unencrypted and involve: (a) transmissions over various
      networks; and (b) changes to conform and adapt to technical requirements
      of connecting networks or devices. Log-ins are intended for one User only
      and are not to be shared by multiple Users. Leaf Trade will not be liable
      for any loss or damage arising from your failure to comply with these
      requirements.
    </p>

    <h2>5. THIRD PARTY REFERENCE AND SITES</h2>
    <p>
      References on the Sites to any names, marks, products, or services of
      third parties, or links to third-party sites or information are not an
      endorsement, sponsorship, or recommendation of the third party, its
      information, products, or services. Leaf Trade is not responsible for the
      content of any third-party linked site or any link contained in a linked
      site. Your use of a third-party site linked from the Sites is at your own
      risk and will be governed by such third party&apos;s terms and policies.
    </p>

    <h2>6. ORDERING AND BILLING</h2>
    <p>
      All Included Entity information provided by you regarding yourself and any
      Included Entities, including, but not limited to, any billing information,
      must be truthful and accurate. Providing any untruthful or inaccurate
      information constitutes a breach of this Agreement and may result in order
      revocation and/or cancellation, as well as account cancellation, at the
      discretion of Leaf Trade.
    </p>
    <p>
      Ordering, processing of orders, and delivery of products are solely and
      exclusively the responsibility of each user. You hereby acknowledge and
      agree that Leaf Trade is not responsible for the ordering process
      including, but not limited to, the placing of orders, the accepting of
      orders, the fulfillment of orders, payment for orders and the delivery of
      order contents. While Leaf Trade is not responsible for the ordering
      process, we reserve the right to refuse or cancel an order for any reason
      including, but not limited to, limitations on quantities available for
      purchase, inaccuracies, errors in product or pricing information, or other
      problems in the product listing or ordering process. Leaf Trade also
      reserves the right, at our sole discretion, to prohibit sales to
      resellers. For purposes of this Agreement, ”resellers” shall be defined as
      users that purchase or intend to purchase any product(s) via Leaf Trade
      and then engage or intend to engage in a commercial sale of that same
      product(s), without alteration, via Leaf Trade.
    </p>
    <p>
      If applicable, you agree that you will pay the fees identified by Leaf
      Trade at the time of registration for use of the Sites and Services and
      for all subscriptions, Content, and transactions in which you participate
      through the Sites, and that Leaf Trade may charge/debit your credit card
      or other designated payment method for any such fees, and for any
      additional amounts (including any taxes, fees, or other charges) that may
      be accrued by or in connection with your use of the Services. The total
      fees to you will include applicable taxes, if any, based on the bill-to
      address provided by you. Fees may change at any time, and the fees set
      forth on the Sites or related agreements do not provide for protection
      against adjustment or for refunds in the event of any subsequent price
      reduction or promotional offering. Leaf Trade reserves the right, with or
      without prior notice, to limit or discontinue the availability of any
      aspect of the Services, and to not honor or otherwise impose additional
      conditions on any promotional code or limited promotions.
    </p>
    <p>
      The monthly or annual, as applicable, fees will be the amount agreed to at
      the time of registration. Thereafter, Leaf Trade has the right to increase
      or decrease any such fee by providing you notice of such increase or
      decrease at least 60 days prior to the effective date of such change. If
      you elect for automatic renewal as provided above, you hereby expressly
      authorize Leaf Trade to charge the applicable fees to the credit/debit
      card or other designated payment method you used to pay for your initial
      fees.
    </p>
    <p>
      <b>
        AS APPLICABLE, YOU ARE RESPONSIBLE FOR THE TIMELY PAYMENT OF ALL FEES
        AND FOR PROVIDING LEAF TRADE WITH A VALID PAYMENT METHOD FOR PAYMENT.
      </b>
      You agree to accept responsibility for all activities that occur under
      your account or password. On the registration payment page, you will be
      given the option to make a one­ time payment or to have a set number of
      consecutive monthly charges/debits posted to your credit card or other
      designated payment method for your fees, or other costs related to the
      Sites and Services. If you elect to terminate your authorization for
      extended payments all unpaid amounts will become immediately due and
      payable and will be immediately charged/debited to your credit card or
      other designated payment method. Notwithstanding any early termination of
      authorization, you remain liable for all charges due for fees, or other
      costs related to the Sites and Services.
    </p>

    <h2>7. PRICING INFORMATION</h2>
    <p>
      The User acknowledges that pricing errors may occur on the Sites and that
      Leaf Trade is not responsible for any pricing errors whether through human
      or computer error. Leaf Trade reserves the right to cancel any orders
      containing pricing errors, with no further obligations to any user, even
      after the receipt of an order confirmation or shipping notice has been
      sent/received. Leaf Trade may, at its discretion, either contact you for
      instructions or cancel your order and notify you of such cancellation.
    </p>

    <h2>8. PROMOTIONAL CODES AND DISCOUNTS</h2>
    <p>
      The User acknowledges that Leaf Trade is not responsible for creating or
      distributing promotional codes. Promotional codes are limited in nature
      and may expire or discontinue with or without notice. Promotional codes
      are void where prohibited by law. Promotional codes may not be copied,
      sold, or otherwise offered. They are not redeemable for cash and are
      subject to cancellation or change at any time for any reason.
    </p>

    <h2>9. SHIPPING AND DELIVERY</h2>
    <p>
      The User acknowledges that Leaf Trade is not responsible for shipping and
      delivery of items purchased via the Sites. Shipping and delivery of items
      purchased via the Sites is the sole responsibility of the seller, which is
      not Leaf Trade. The User agrees to comply with all applicable state laws
      regarding shipping and delivery. The User agrees to comply with all
      jurisdictional laws with respect to shipments of orders, including any
      restrictions on shipping a product produced in one jurisdiction to another
      jurisdiction if prohibited by law.
    </p>

    <h2>10. INFORMATION PROVIDED ON THE SITES</h2>
    <p>
      Leaf Trade and the users post a variety of material on the Sites
      including, but not limited to, product information, product descriptions,
      reviews, comments, health and prescription information, and photographs
      (collectively, the “Materials”). The Materials that appear on the Sites
      are for educational and informational purposes only. Despite our efforts
      to facilitate provision of useful and accurate information, errors may
      appear from time to time. Before you act on information you have found on
      the Sites, you should independently confirm any facts that are important
      to your decision. Leaf Trade and the providers of Content and Materials
      make no warranty as to the reliability, accuracy, timeliness, usefulness,
      or completeness of the information on the Sites. Leaf Trade is not
      responsible for, and cannot guarantee the performance of, goods and
      services provided by users, third parties, advertisers, or others to whose
      sites we link. Product information contained on the Sites may be different
      from information contained on the product materials. If you find a product
      is not as described, your sole remedy is to request a refund from the
      Seller, subject to any Seller terms and conditions, which may require a
      return of the product in unused condition. Leaf Trade is not responsible
      for any products.
    </p>
    <p>
      No health, wellness, prescription, or pharmaceutical information is
      intended to substitute for the diagnosis, treatment, and advice of a
      medical professional. This information does not and is not intended to
      cover all possible uses, precautions, side effects, and interactions and
      should not be construed to indicate that any product is safe for you.
      Consult the product information (including package inserts) regarding
      dosage, precautions, warnings, and interactions and your medical
      professional for guidance before using any prescription, over-the-counter
      drug and/or supplement or products available via the Leaf Trade Sites or
      Services.
    </p>
    <p>
      LEAF TRADE, ITS AFFILIATES, AND AGENTS ASSUME NO RESPONSIBILITY FOR ANY
      CONSEQUENCE RELATING DIRECTLY OR INDIRECTLY TO ANY ACTION OR INACTION YOU
      TAKE BASED ON THE CONTENT, MATERIALS OR PRODUCTS LOCATED ON ANY OF THE
      SITES.
    </p>

    <h2>11. CANNABIS LAWS</h2>
    <p>
      You acknowledge that the products listed on the Sites are subject to state
      and federal regulations. You further acknowledge that marijuana is listed
      as a Schedule I substance under the Controlled Substances Act of 1970, the
      highest classification under the legislation indicating that the federal
      government considers marijuana to have both high abuse potential and no
      established safe medical use. You further acknowledge that violations of
      federal law for the use, possession, sale, cultivation, and transportation
      of marijuana are punishable by severe fines and prison sentences. AS SUCH,
      THE USER ACKNOWLEDGES, ACCEPTS AND ASSUMES ALL LEGAL RISK, ASSOCIATED WITH
      THE PURCHASE AND/OR SALE OF CANNABIS AND CANNABIS-RELATED PRODUCTS VIA THE
      SITE; AND ACKNOWLEDGES THAT LEAF TRADE DOES NOT SELL OR OFFER FOR SALE
      CANNABIS AND CANNABIS-RELATED PRODUCTS, BUT RATHER OFFERS A PLATFORM FOR
      USERS TO COMMUNICATE REGARDING SAME.
    </p>

    <h2>12. INTELLECTUAL PROPERTY</h2>
    <p>
      All copyrights, trademarks, trade dress, other intellectual property and
      materials, including page headers, images, text, illustrations, formats,
      logos, designs, icons, photographs, programs, music clips or downloads,
      video clips, and written and other materials (collectively, the “IP”) are
      owned, controlled or licensed by Leaf Trade, one of its affiliates, or by
      third parties who have licensed their materials to Leaf Trade and are
      protected by U.S. and international patent, trademark, copyright, and
      other intellectual property laws. You may access, view, download, and
      print the IP and all other materials displayed on the Sites for your
      personal, non-commercial use only; provided, however, that you (1) retain
      all copyright, trademark or other proprietary designations contained on
      all IP; (2) do not modify or alter the IP in any way; and (3) do not
      provide or make available the IP to any third party in a commercial
      manner. No license, right, title, or interest in any materials or software
      is transferred to you as a result of your use of the Sites or your
      accessing, viewing, downloading, or printing of the IP. You may not
      reproduce (except as noted above), publish, transmit, distribute, display,
      modify, create derivative works from, sell or participate in any sale of,
      or exploit in any way, in whole or in part, any of the IP, the Sites, or
      any related software. All software used on the Sites is the property of
      Leaf Trade or its suppliers and protected by U.S. and international
      copyright laws. The IP and software on the Sites may be used only as a
      communication resource. Any other use, including the reproduction,
      modification, distribution, transmission, republication, display, or
      performance, of the IP on this Site is strictly prohibited. The
      compilation (meaning the collection, arrangement, and assembly) of all IP
      on the Sites is the exclusive property of Leaf Trade and is also protected
      by U.S. and international copyright laws.
    </p>
    <p>
      Leaf Trade LLC, Leaf Trade, Leaf.Trade, LeafTrade.com, the Leaf Trade,
      Leaf.Trade and LeafTrade.com names and logos, and all other marks, trade
      names, graphics, logos, page headers, button icons, scripts, and service
      names included in or made available through any of the Sites are
      trademarks and/or trade dress of Leaf Trade in the U.S. and other
      countries. All other marks are the property of their respective companies.
      All rights are reserved in all intellectual property owned or licensed by
      Leaf Trade.
    </p>

    <h2>13. PHOTOGRAPHS AND COLORS</h2>
    <p>
      We have endeavored to accurately display the product photographs provided
      by users that appear on the Sites, including, but not limited to, the
      colors of products. However, individual products actually received by
      users may vary dramatically from those pictured and, as the actual colors
      you see will depend on your monitor, software used to render the image,
      and/or other factors beyond our control. We cannot guarantee that your
      monitor&apos;s display of any color will accurately reflect actual product
      color or finish.
    </p>

    <h2>14. PRIVACY</h2>
    <p>
      You acknowledge that any information that you provide through the Sites
      will be used by Leaf Trade in accordance with Leaf Trade&apos;s Privacy
      Policy, the terms of which are incorporated in this Agreement by this
      reference. If you order an item via the Sites, Leaf Trade shares certain
      information with that seller to permit the seller to approve, fulfill and
      ship your order, process returns, and provide customer service. Leaf Trade
      requires sellers to keep your information secure and use it only in
      accordance with the seller&apos;s privacy policy, which can be obtained by
      contacting the individual seller.
    </p>

    <h2>15. INDEMNIFICATION</h2>
    <p>
      You agree to defend, indemnify, and hold harmless Leaf Trade and its
      owners, members, shareholders, directors, officers, agents, employees, and
      affiliates from and against any and all claims, actions, proceedings,
      demands, damages, losses, costs, and expenses, including attorneys&apos;
      fees, arising from or related to your use of the Sites, any Materials or
      Content submitted, posted, transmitted or made available by you through
      the Sites or the Services, any breach or violation by you of the terms of
      this Agreement, the rights of any third party, or any applicable law or
      regulation.
    </p>

    <h2>16. TERMINATION</h2>
    <p>
      This Agreement is effective unless and until terminated by either you or
      Leaf Trade. You may terminate this Agreement at any time; provided that
      you discontinue any further use of the Sites including any orders that are
      in progress. Leaf Trade also may suspend your access to the Sites and/or
      terminate this Agreement at any time and may do so immediately without
      notice, and accordingly deny you access to the Sites, if in Leaf
      Trade&apos;s sole discretion you fail to comply with any term or provision
      of this Agreement.
    </p>
    <p>
      Upon any termination of this Agreement by either you or Leaf Trade, you
      must promptly destroy all materials downloaded or otherwise obtained from
      the Sites, as well as all copies of such materials, whether made under
      this Agreement or otherwise. The following sections shall survive any
      termination of this Agreement: Services, Use of the Sites, Content
      Submission, Ordering and Billing, Pricing Information, Information
      Provided on the Sites, Cannabis Laws, Intellectual Property, Privacy,
      Indemnification, Termination, Disclaimer, Limitation of Liability, and
      General.
    </p>

    <h2>17. DISCLAIMER</h2>
    <p>
      THIS SITE IS PROVIDED BY LEAF TRADE ON AN “AS IS” AND “AS AVAILABLE” BASIS
      AND LEAF TRADE DOES NOT WARRANT THAT THE SITES OR SERVICES WILL MEET YOUR
      SPECIFIC REQUIREMENTS, THAT THE SITES OR SERVICES WILL BE UNINTERRUPTED,
      TIMELY, SECURE OR ERROR-FREE, THAT RESULTS OBTAINED FROM USE OF THE SITES
      OR SERVICES WILL BE ACCURATE OR RELIABLE OR THAT ANY ERRORS IN THE SITES
      OR SERVICES WILL BE CORRECTED. LEAF TRADE MAKES NO REPRESENTATIONS OR
      WARRANTIES OF ANY KIND, EXPRESS OR IMPLIED, AS TO THE OPERATION OF THE
      SITES OR THE INFORMATION, CONTENT, MATERIALS, OR PRODUCTS INCLUDED ON THE
      SITES. TO THE FULL EXTENT PERMISSIBLE BY APPLICABLE LAW, LEAF TRADE
      DISCLAIMS ALL REPRESENTATIONS, WARRANTIES AND CONDITIONS, EXPRESS,
      STATUTORY OR IMPLIED, BY USAGE OF TRADE, CORUSE OF DEALING OR OTHERWISE,
      INCLUDING, BUT NOT LIMITED TO, ANY IMPLIED WARRANTIES OF MERCHANTABILITY,
      FITNESS FOR A PARTICULAR PURPOSE, TITLE, SATISFACTORY QUALITY OR
      NON-INFRINGEMENT. LEAF TRADE DOES NOT WARRANT, ENDORSE, GUARANTEE, PROVIDE
      ANY CONDITIONS OR REPRESENTATIONS, OR ASSUME ANY RESPONSIBILITY FOR ANY
      PRODUCT OR SERVICE ADVERTISED OR OFFERED BY ANY THIRD PARTY USER OR SELLER
      VIA THE SITES OR IN RESPECT TO ANY WEBSITE THAT CAN BE REACHED FROM A LINK
      ON THE SITES OR FEATURED IN A BANNER OR OTHER ADVERTISING ON THE SITES AND
      LEAF TRADE SHALL NOT BE A PARTY TO ANY TRANSACTION THAT YOU MAY ENTER INTO
      WITH ANY SUCH THIRD PARTY. WITHOUT LIMITING THE FOREGOING, LEAF TRADE
      DISCLAIMS ANY AND ALL WARRANTIES, EXPRESS OR IMPLIED, FOR ANY MERCHANDISE
      OFFERED VIA THE SITES OR BY PARTIES USING THE SITES. YOU ACKNOWLEDGE, BY
      YOUR USE OF THE SITES, THAT YOUR USE OF THE SITES IS AT YOUR SOLE RISK.
      THIS DISCLAIMER DOES NOT APPLY TO ANY PRODUCT WARRANTY OFFERED BY THE
      MANUFACTURER OR SELLER OF ANY PRODUCT. THIS DISCLAIMER CONSTITUTES AN
      ESSENTIAL PART OF THIS AGREEMENT. SOME STATES DO NOT ALLOW LIMITATIONS ON
      HOW LONG AN IMPLIED WARRANTY LASTS, SO THE FOREGOING LIMITATIONS MAY NOT
      APPLY TO YOU. LEAF TRADE WILL NOT BE LIABLE FOR ANY TYPE OF CONTENT OR
      MATERIALS EXCHANGED BY MEANS OF THE SITES OR SERVICES.
    </p>
    <p>
      ALL INFORMATION CONTAINED ON THE SITES IS FOR INFORMATIONAL PURPOSES ONLY.
      CONTENT POSTED BY LEAF TRADE ON ANY WEBSITE, MOBILE APPLICATION, SOCIAL
      MEDIA CHANNEL, THIRD-PARTY CONTENT SERVICE, OR ADVERTISEMENT IS FOR
      INFORMATIONAL PURPOSES ONLY. LEAF TRADE DOES NOT ENDORSE, AND IS NOT
      RESPONSIBLE FOR THE ACCURACY OR RELIABILITY OF, ANY OPINION, ADVICE,
      STATEMENT, OR OTHER INFORMATION MADE ON THE SITES, INCLUDING CONTENT AND
      MATERIALS (AS DEFINED HEREIN). LEAF TRADE IS NOT RESPONSIBLE FOR YOUR
      RELATIONSHIPS WITH PATIENTS, DISPENSARIES, PROCESSORS OR CULTIVATORS (AS
      THOSE TERMS ARE USED IN THE CANNABIS INDUSTRY), HEALTH CARE PROVIDER, OR
      OTHER USERS OF THE SITES. LEAF TRADE REQUIRES ITS USERS TO ESTABLISH
      WHETHER THEY ARE QUALIFIED OR AUTHORIZED BY LAW TO PROVIDE THEIR SERVICES
      OR TO DETERMINE THE ACCURACY OF THEIR PRODUCT DESCRIPTIONS OR OTHER
      INFORMATION THEY PROVIDE.
    </p>
    <p>
      LEAF TRADE DOES NOT OFFER MEDICAL ADVICE. ANY INFORMATION ACCESSED THROUGH
      THE SITES IS FOR INFORMATIONAL AND EDUCATIONAL PURPOSES ONLY, IS NOT
      INTENDED TO BE A SUBSTITUTE FOR MEDICAL ADVICE, DIAGNOSIS, OR TREATMENT,
      AND IS NOT INTENDED TO COVER ALL POSSIBLE USES, DIRECTIONS, PRECAUTIONS,
      OR ADVERSE EFFECTS. SUCH INFORMATION INCLUDES WITHOUT LIMITATION,
      MATERIALS, CONTENT, AND LEAF TRADE GENERATED CONTENT DERIVED FROM USER.
      THE INFORMATION ON THE SITES AND THE SERVICES PROVIDED BY LEAF TRADE
      SHOULD NOT BE USED FOR THE DIAGNOSIS OR TREATMENT OF ANY MEDICAL
      CONDITION. ALWAYS CONSULT A DOCTOR OR OTHER QUALIFIED HEALTH CARE PROVIDER
      IF YOU HAVE ANY QUESTIONS ABOUT A MEDICAL CONDITION. NEVER DISREGARD
      PROFESSIONAL MEDICAL ADVICE OR DELAY IN SEEKING IT BECAUSE OF SOMETHING
      YOU HAVE READ ON THE SITES.
    </p>

    <h2>18. LIMITATION OF LIABILITY</h2>
    <p>
      UNDER NO CIRCUMSTANCES AND UNDER NO LEGAL OR EQUITABLE THEORY, WHETHER IN
      TORT, CONTRACT, STRICT LIABILITY, OR OTHERWISE, SHALL LEAF TRADE OR ANY OF
      ITS OWNERS, MEMBERS, SHAREHOLDERS, DIRECTORS, OFFICERS, AGENTS, EMPLOYEES,
      AFFILIATES, VENDORS, AND SUPPLIERS BE LIABLE TO YOU OR TO ANY OTHER PERSON
      FOR ANY DIRECT, INDIRECT, SPECIAL, INCIDENTAL, CONSEQUENTIAL LOSSES OR
      OTHER DAMAGES OF ANY NATURE WHATSOEVER, INCLUDING, WITHOUT LIMITATION, ANY
      DAMAGES ARISING OUT OF OR IN CONNECTION WITH THE USE OF OR INABILITY TO
      USE THE SITES, PERFORMANCE OF THE SITES OR SERVICES, DELAY OR INABILITY TO
      USE THE SITES OR SERVICES, PROVISION OR FAILURE TO PROVIDE SERVICES, ANY
      CONTENT OR MATERIALS OBTAINED THROUGH THE SITES, THE COST OF PROCUREMENT
      OF SUBSTITUTE PRODUCTS, ERRORS, MISTAKES OR INACCURACIES ON THE SITES, OR
      OTHERWISE ARISING OUT OF USE OF THE SITES OR SERVICES, INCLUDING, BUT NOT
      LIMITED TO, DAMAGES FOR LOST PROFITS, LOSS OF GOODWILL, LOSS OF DATA, WORK
      STOPPAGE, ACCURACY OF RESULTS, OR COMPUTER FAILURE OR MALFUNCTION, EVEN IF
      AN AUTHORIZED REPRESENTATIVE OF LEAF TRADE HAS BEEN ADVISED OF OR SHOULD
      HAVE KNOWN OF THE POSSIBILITY OF SUCH DAMAGES. NOTWITHSTANDING ANYTHING TO
      THE CONTRARY CONTAINED HEREIN, IN NO EVENT WILL LEAF TRADE BE LIABLE TO
      YOU FOR ANY DAMAGES ARISING FROM OR RELATED TO THIS AGREEMENT (FOR ANY
      CAUSE WHATSOEVER AND REGARDLESS OF THE FORM OF THE ACTION) IN THE
      AGGREGATE IN EXCESS OF THE FEES PAID BY YOU IN CONNECTION WITH YOUR USE OF
      THE SITES DURING THE SIX-MONTH PERIOD PRECEDING THE DATE ON WHICH THE
      CLAIM AROSE. THE FOREGOING LIMITATIONS SHALL APPLY TO THE FULLEST EXTENT
      PERMITTED BY LAW IN THE APPLICABLE JURISDICTION.
    </p>

    <h2>19. CONSTRUCTION AND SEVERABLE PROVISIONS</h2>
    <p>
      The provisions contained herein shall be presumed to be enforceable, and
      any reading causing unenforceability shall yield to a construction
      permitting enforcement. The provisions of this Agreement are severable,
      and if any one or more provisions may be determined to be illegal or
      otherwise unenforceable, in whole or in part, the remaining provisions and
      any partially unenforceable provisions to the extent enforceable shall
      nevertheless be binding and enforceable. In the event a provision should
      be determined to be unenforceable as written due to overbreadth, you
      specifically agree that said provision shall be modified and enforced to
      the extent reasonable, whether said modifications are in time, territory,
      or scope of prohibited activities.
    </p>

    <h2>20. GENERAL</h2>
    <p>
      This Agreement represents the complete agreement between the parties and
      supersedes all prior agreements and representations between them. Headings
      used in this Agreement are for reference purposes only and in no way
      define or limit the scope of the section. If any provision of this
      Agreement is held to be unenforceable for any reason, such provision shall
      be reformed only to the extent necessary to make it enforceable and the
      other terms of this Agreement shall remain in full force and effect. The
      failure of Leaf Trade to act with respect to a breach of this Agreement by
      you or others does not constitute a waiver and shall not limit Leaf
      Trade&apos;s rights with respect to such breach or any subsequent
      breaches. This Agreement shall be governed by and construed under Illinois
      law without regard to conflicts of law provisions. Any action or
      proceeding arising out of or related to this Agreement or your use of the
      Sites must be brought in the Circuit Court of Cook County, Illinois and
      that any cause of action arising out of this Agreement shall be deemed to
      have arisen from a transaction of business in the State of Illinois and
      you consent to the exclusive personal jurisdiction of such courts.
    </p>
    <p>
      Leaf Trade controls its Sites (excluding linked sites) from the United
      States of America, and makes no representation that any content contained
      in this Site is appropriate or available for use in other locations.
      Accessing this Site in locations where the use of such content is illegal
      is prohibited.
    </p>
    <p>
      Regardless of any statute or law to the contrary, any claim or cause of
      action arising out of or related to use of the Sites or these Terms must
      be filed within one (1) year after such claim or cause of action arose or
      be forever barred.
    </p>
    <p>
      YOU HEREBY ACKNOWLEDGE AND AGREE THAT ANY CONTROVERSY WHICH MAY ARISE
      UNDER THIS AGREEMENT IS LIKELY TO INVOLVE COMPLICATED AND DIFFICULT ISSUES
      AND, THEREFORE, YOU IRREVOCABLY AND UNCONDITIONALLY WAIVE ANY RIGHT YOU
      MAY HAVE TO A TRIAL BY JURY IN RESPECT OF ANY LEGAL ACTION ARISING OUT OF
      OR RELATING TO THIS AGREEMENT OR THE TRANSACTIONS CONTEMPLATED HEREBY. YOU
      AGREE THAT YOU HAVE HAD AN OPPORTUNITY TO HAVE YOUR LEGAL COUNSEL REVIEW
      THIS WAIVER. IN THE EVENT OF LITIGATION, THIS AGREEMENT MAY BE FILED AS
      WRITTEN CONSENT TO A BENCH TRIAL WITHOUT A JURY.
    </p>
    <p>
      Leaf Trade will respond to all valid, legal requests to the extent
      permitted by this Agreement, the Privacy Policy and applicable law. All
      requests for disclosure of User information and account information should
      be directed to Leaf Trade and marked “Legal Department” to our offices at
      412 South Wells Street, Floor 6, Chicago, IL 60607 with a copy to{' '}
      <a href='mailto:legal@leaftrade.com'>legal@leaftrade.com</a>. A valid
      legal request will take the form of: (a) a valid police request in the
      case of disclosure of basic User information in relation to alleged crimes
      such as fraud by such User; (b) a binding court order compelling
      disclosure of certain records such as User records and account details but
      not contents of communications; and/or (c) a search warrant where contents
      of communications are sought. Unless prohibited by applicable law or where
      the court order, subpoena or warrant requires no notification or delayed
      notification, Leaf Trade may notify affected users when we receive legal
      process from a third party requesting that User&apos;s data. Leaf Trade
      may choose not to notify a User if Leaf Trade deems, in its sole
      discretion that providing notice would be ineffective, prejudicial or
      might create a risk of injury or bodily harm to an individual or group, or
      to our property.
    </p>
    <p>
      FOR NEW JERSEY RESIDENTS, TO THE EXTENT ANY TERM HEREOF (OR OF ANY RELATED
      AGREEMENT INCORPORATED HEREIN) IS SUBJECT TO THE NEW JERSEY
      TRUTH-IN-CONSUMER CONTRACT, WARRANTY AND NOTICE ACT (&quot;TCCWNA&quot;)
      AND SUCH TERM, IF ENFORCED AS PROVIDED HEREIN, IS CONTRARY TO OR VIOLATIVE
      OF ANY CLEARLY ESTABLISHED RIGHT YOU HAVE UNDER STATE OR FEDERAL LAW, SUCH
      OFFENDING TERM(S) SHALL BE DEEMED OMITTED FROM THESE TERMS, AND YOUR
      AGREEMENT TO THESE TERMS DOES NOT AND WILL NOT IN ANY WAY CONSTITUTE A
      WAIVER OF YOUR RIGHTS UNDER THE TCCWNA.
    </p>
    <p>
      Pursuant to 47 U.S.C. Section 230(d) (as amended from time to time),
      please be notified that parental control protections (such as computer
      hardware, software or filtering services) are commercially available to
      you that may assist you in limiting access to material that is harmful to
      minors. Information identifying current providers of such protections is
      available on the following website:
      <ul>
        <li>
          On Guard Online (
          <a href='http://onguardonline.gov/' target='_blank' rel='noreferrer'>
            http://onguardonline.gov/
          </a>
          )
        </li>
      </ul>
    </p>
    <p>
      Please be advised that we do not endorse either of the above websites or
      any of the products or services available at such websites.
    </p>
    <p>
      Under California Civil Code Section 1789.3, California users are entitled
      to the following consumer rights notice: If you have a question or
      complaint regarding the Site, please contact us at:
    </p>
    <p>
      Leaf Trade Inc.
      <br />
      225 W Hubbard Street
      <br />
      Floor 3<br />
      Chicago, IL 60654
      <br />
      Attn: Legal Department
      <br />
    </p>
    <p>
      California residents may contact the Complaint Assistant Unit of the
      Division of Consumer Services of the California Department of Consumer
      Affairs by mail at:
    </p>
    <p>
      1625 North Market Blvd.
      <br />
      Sacramento, CA 95834
      <br />
      (916) 445-1254 or (800) 952-5210
      <br />
    </p>

    <h2>21. PROCEDURE FOR MAKING A CLAIM OF COPYRIGHT INFRINGEMENT</h2>
    <p>
      We respect the intellectual property of others. If you believe that your
      intellectual property has been copied and is accessible on the Sites in a
      way that constitutes copyright infringement or other intellectual property
      infringement, please contact us to file a report.
    </p>
    <h3>DMCA Requests</h3>
    <p>
      If you believe rights in one or more of your copyrighted works are being
      violated, you can ask us to remove all copies of the works. Please note
      that while we will attempt to remove any similar copies of the works from
      our Sites, we may be unable to find, locate, and remove versions of an
      image file or other work that has been resized or altered unless
      specifically identified in the DMCA takedown notice. please provide us the
      following information in your Notice (to be effective, the notification
      must include ALL of the following):
    </p>
    <p>
      <ol type='a'>
        <li>
          A physical or electronic signature of the person authorized to act on
          behalf of the owner of an exclusive copyright that is allegedly
          infringed;
        </li>
        <li>
          A description of the copyrighted work that you claim has been
          infringed;
        </li>
        <li>
          A description of where the material that you claim is infringing is
          located on the Sites;
        </li>
        <li>
          Your address, telephone number, and email address and all other
          information reasonably sufficient to permit us to contact you;
        </li>
        <li>
          A statement by you that you have a good faith belief that the disputed
          use is not authorized by the copyright owner, its agent, or the law;
          and
        </li>
        <li>
          A statement by you, made under penalty of perjury, that the above
          information in your Notice is accurate and that you are the copyright
          owner or authorized to act on behalf of the owner of an exclusive
          right that is allegedly infringed.
        </li>
      </ol>
    </p>

    <p>Send your completed DMCA notice to:</p>
    <p>
      Leaf Trade Copyright Agent
      <br />
      225 W Hubbard Street
      <br />
      Floor 3<br />
      Chicago, IL 60654
      <br />
      <br />
      Email: <a href='mailto:Legal@LeafTrade.com'>Legal@LeafTrade.com</a>
    </p>
    <p>
      <b>
        THE PRECEDING INFORMATION IS PROVIDED EXCLUSIVELY FOR NOTIFYING LEAF
        TRADE THAT YOUR COPYRIGHTED MATERIAL MAY HAVE BEEN INFRINGED. ALL OTHER
        INQUIRIES, SUCH AS SITES OR SERVICES RELATED QUESTIONS AND REQUESTS, OR
        QUESTIONS ON OUR PRIVACY PRACTICES, WILL NOT RECEIVE A RESPONSE THROUGH
        THIS PROCESS.
      </b>
    </p>

    <h3>Repeat Offenders</h3>
    <p>
      It is our policy, in appropriate circumstances, to terminate the accounts
      of members or users who are repeat infringers or are repeatedly charged
      with infringement.
    </p>

    <p>Last updated on March 6, 2023.</p>

    <p>© {new Date().getFullYear()} Leaf Trade Inc.. All Rights Reserved</p>
  </div>
);

export default TermsOfService;
