import {
  bodySmallTypography,
  boldBodyLargeTypography,
  Button,
  IconLoadingSpinner,
  Modal,
  Typography,
} from '@leaftrade/leaftrade-component-library';
import React from 'react';
import { useDispatch } from 'react-redux';
import { useStorefrontState } from '../../../../hooks/useReduxState';
import {
  deleteQuickOrderItem,
  getQuickOrderNotices,
  toggleQuickOrderDeleteModal,
} from '../../../../store/actions/storefrontActions';

const DeleteQuickOrderItemModal = () => {
  const dispatch = useDispatch();
  const { quickOrderData, vendor } = useStorefrontState();
  const { deleteItemModal, selectedLocations } = quickOrderData;
  const { cartItemId, productName } = deleteItemModal?.item || {};
  const { settings, slug } = vendor;
  const { batchQueuesEnabled } = settings;

  const selectedLocationsIds = selectedLocations.map((location) =>
    Number(location.id)
  );

  const deleteQuickOrderItemModalJsx = {
    actions: !deleteItemModal.isRemovingItem ? (
      <>
        <Button
          onClick={() => {
            dispatch(
              toggleQuickOrderDeleteModal({
                isOpen: false,
                item: undefined,
              })
            );
          }}
          variant='secondary'
        >
          Cancel
        </Button>
        <Button
          onClick={() =>
            dispatch(
              deleteQuickOrderItem({
                batchQueuesEnabled,
                cartItemId,
                locationsIds: selectedLocationsIds,
                onSuccess: () => {
                  dispatch(
                    getQuickOrderNotices({
                      locationIds: selectedLocationsIds,
                      slug,
                    })
                  );
                },
                slug,
              })
            )
          }
          variant='destructive'
        >
          Remove From All Carts
        </Button>
      </>
    ) : (
      <Button variant='destructive'>
        <IconLoadingSpinner />
      </Button>
    ),
    header: (
      <Typography bodyStyle={boldBodyLargeTypography} gutterBottom>
        {`Remove ${productName} From All Carts?`}
      </Typography>
    ),
    body: (
      <Typography bodyStyle={bodySmallTypography} gutterBottom>
        Are you sure you want to remove this product from all carts?
      </Typography>
    ),
  };

  return (
    <Modal
      actions={deleteQuickOrderItemModalJsx.actions}
      header={deleteQuickOrderItemModalJsx.header}
      hideClose={deleteItemModal.isRemovingItem}
      onClose={() =>
        dispatch(
          toggleQuickOrderDeleteModal({
            isOpen: false,
            item: undefined,
          })
        )
      }
      open={deleteItemModal.isOpen}
      testingId='delete-quick-order-item-modal--component--div'
    >
      {deleteQuickOrderItemModalJsx.body}
    </Modal>
  );
};

export default DeleteQuickOrderItemModal;
