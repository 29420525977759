import PropTypes from 'prop-types';
import React from 'react';
import { findCannabinoidsTooltipDisplayValue } from '../../../utils';
import CannabinoidsTooltip from '../CannabinoidsTooltip';
import FeaturedCannabinoids from '../FeaturedCannabinoids';
import { VariantCannabinoids, VariantSize } from './VariantInfo.styles';
import { useStorefrontState } from '../../../../hooks/useReduxState';

const VariantInfo = ({ productId, selectedVariant, slug }) => {
  const { vendor } = useStorefrontState();
  const { isGridView, settingsDeprecated } = vendor.settings;
  const { primary_lab_values: primaryLabValues } = settingsDeprecated;

  return (
    <>
      <VariantCannabinoids
        data-testid='variant-info--cannabinoids--div'
        isGridView={isGridView}
      >
        <FeaturedCannabinoids
          cannabinoids={selectedVariant.cannabinoids}
          labResultsUnit={selectedVariant.cannabinoids.lab_results_unit}
          primaryLabValues={primaryLabValues}
        />
        {Boolean(
          findCannabinoidsTooltipDisplayValue(primaryLabValues, selectedVariant)
        ) && (
          <CannabinoidsTooltip
            primaryLabValues={primaryLabValues}
            productId={productId}
            url={`/storefront/sellers/${slug}/${productId}?variant=${selectedVariant.id}#cannabinoids`}
          />
        )}
      </VariantCannabinoids>
      <VariantSize
        data-testid='variant-info--size--div'
        isGridView={isGridView}
      >
        {isGridView && 'Size: '}
        {`${selectedVariant.size} ${selectedVariant.size_unit}`}
      </VariantSize>
    </>
  );
};

VariantInfo.propTypes = {
  productId: PropTypes.number.isRequired,
  selectedVariant: PropTypes.object.isRequired,
  slug: PropTypes.string.isRequired,
};

export default VariantInfo;
