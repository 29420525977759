import React from 'react';
import { ScrollSyncPane } from 'react-scroll-sync';
import { useStorefrontState } from '../../../../hooks/useReduxState';
import QuickOrderTableAllInput from '../QuickOrderTableAllInput';
import {
  LocationColumnScrollGradient,
  QuickOrderTableItemWrapper,
  QuickOrderTableLocationColumnWrapper,
  QuickOrderTableLocationColumns,
  QuickOrderTableLocationColumnsWrapper,
} from '../QuickOrderTableItem/QuickOrderTableItem.styles';
import QuickOrderTableItemSummary from '../QuickOrderTableItemSummary';
import QuickOrderTableLocationInput from '../QuickOrderTableLocationInput';
import QuickOrderTableItemInfoBatchQueues from './QuickOrderTableItemInfoBatchQueues';

const QuickOrderTableItemBatchQueues = ({ item, showOverflowButtons }) => {
  const { quickOrderData } = useStorefrontState();
  const { quickOrderItemAlerts, selectedLocations } = quickOrderData;

  return (
    <QuickOrderTableItemWrapper
      data-testid='quick-order-table-item-batch-queues--component--div'
      key={item.cartItemId}
    >
      <QuickOrderTableItemInfoBatchQueues item={item} />
      {selectedLocations.length > 0 && (
        <>
          {selectedLocations.length > 1 && (
            <QuickOrderTableAllInput item={item} />
          )}
          <QuickOrderTableLocationColumnsWrapper>
            {showOverflowButtons?.left && (
              <LocationColumnScrollGradient isLeft />
            )}
            <ScrollSyncPane>
              <QuickOrderTableLocationColumns>
                {[...selectedLocations]
                  .sort((a, b) => a.name.localeCompare(b.name))
                  .map((selectedLocation) => (
                    <QuickOrderTableLocationColumnWrapper
                      key={`${selectedLocation.id}-${item.cartItemId}`}
                    >
                      <QuickOrderTableLocationInput
                        item={item}
                        itemAlert={quickOrderItemAlerts.find(
                          (itemAlert) =>
                            Number(itemAlert.cartItemId) ===
                              Number(item.cartItemId) &&
                            Number(itemAlert.locationId) ===
                              Number(selectedLocation.id)
                        )}
                        location={selectedLocation}
                      />
                    </QuickOrderTableLocationColumnWrapper>
                  ))}
              </QuickOrderTableLocationColumns>
            </ScrollSyncPane>
            {showOverflowButtons?.right && <LocationColumnScrollGradient />}
          </QuickOrderTableLocationColumnsWrapper>
        </>
      )}
      <QuickOrderTableItemSummary item={item} />
    </QuickOrderTableItemWrapper>
  );
};

export default QuickOrderTableItemBatchQueues;
