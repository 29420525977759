import { spacing6 } from '@leaftrade/leaftrade-component-library';
import styled from 'styled-components';

export const QuickOrderTableItemSummaryWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const QuickOrderTableItemSummaryDelete = styled.button`
  background-color: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${spacing6};
  height: ${spacing6};
  & svg {
    width: ${spacing6};
    height: ${spacing6};
  }
`;
