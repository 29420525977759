import { pxToRem, spacing6 } from '@leaftrade/leaftrade-component-library';
import styled from 'styled-components';

export const ExploreCard = styled.div`
  border-radius: ${pxToRem(8)};
  padding: ${spacing6};
  flex: 1 1 ${pxToRem(240)};
  min-width: ${pxToRem(240)};
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-end;
  & h5 {
    margin-bottom: ${pxToRem(8)};
  }
  & p {
    margin-bottom: ${pxToRem(16)};
  }
`;
