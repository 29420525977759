import {
  Input,
  pxToRem,
  IconLoadingSpinner,
  AlertTooltip,
  Typography,
  bodySmallTypography,
  neutral20,
  IconButton,
  IconRefresh,
  interaction60,
} from '@leaftrade/leaftrade-component-library';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { useStorefrontState } from '../../../../hooks/useReduxState';
import {
  editCartItemQuantity,
  getCartDataSummary,
  toggleCartDeleteModal,
} from '../../../../store/actions/storefrontActions';
import { CartLineItemColumnQuantityWrapper } from './CartLineItemColumnQuantity.styles';

const CartLineItemColumnQuantity = ({ item }) => {
  const dispatch = useDispatch();
  const { cart, shopper, vendor } = useStorefrontState();
  const { cartItemAlerts, selectedCart } = cart;
  const { currentLocation } = shopper;
  const { settings, slug } = vendor;
  const { batchQueuesEnabled } = settings;

  const [isUpdating, setIsUpdating] = useState(false);
  const [quantity, setQuantity] = useState(item.totalQuantity);
  const [alert, setAlert] = useState();
  const [tooltipOpen, setTooltipOpen] = useState(false);

  const alertIsErrorOrWarning =
    alert?.level === 'error' || alert?.level === 'warning';

  const alertIsStockIssue =
    alert?.message?.toLowerCase().includes('out of stock') ||
    alert?.message?.toLowerCase().includes('low stock');

  const handleLineItemQuantityChange = (event) => {
    setQuantity(event.target.value);
  };

  const editLineItemQuantity = (newQuantity) => {
    dispatch(
      editCartItemQuantity({
        batchQueuesEnabled,
        item,
        locationId: currentLocation.id,
        onSuccess: (responseData) => {
          const returnedQuantity =
            responseData?.carts
              ?.find((returnedCart) => returnedCart?.id === selectedCart?.id)
              ?.cartItems.find((returnedItem) => returnedItem?.id === item?.id)
              ?.totalQuantity || 0;
          const itemAlert = responseData.cart_item_notices.find(
            (cartItem) => cartItem.cartItemId === item.cartItemId
          );
          setQuantity(returnedQuantity);
          setAlert(itemAlert);
          setTooltipOpen(true);
          dispatch(getCartDataSummary({ slug }));
          setIsUpdating(false);
        },
        quantity: newQuantity,
        slug,
      })
    );
  };

  const handleLineItemQuantityBlur = (event) => {
    const newQuantity = Number(event.target.value);
    if (newQuantity === item.totalQuantity) return;
    setIsUpdating(true);
    if (newQuantity === 0) {
      dispatch(
        toggleCartDeleteModal({
          isOpen: true,
          item,
        })
      );
    } else {
      editLineItemQuantity(newQuantity);
    }
  };

  const handleLineItemQuantityRefresh = () => {
    setIsUpdating(true);
    editLineItemQuantity(item.totalQuantity);
  };

  useEffect(() => {
    const itemAlert = cartItemAlerts.find(
      (cartItem) => cartItem.cartItemId === item.cartItemId
    );
    setAlert(itemAlert);
    setTooltipOpen(true);
  }, [cartItemAlerts]);

  return (
    <CartLineItemColumnQuantityWrapper data-testid='cart-line-item-column-quantity--component--div'>
      {isUpdating ? (
        <IconLoadingSpinner data-testid='cart-line-item-column-quantity--loading--svg' />
      ) : (
        <div style={{ position: 'relative' }}>
          <Input
            onBlur={handleLineItemQuantityBlur}
            onChange={handleLineItemQuantityChange}
            onKeyUp={(e) => {
              if (e.key === 'Enter') {
                handleLineItemQuantityBlur(e);
              }
            }}
            placeholder={item.totalQuantity.toString()}
            size='large'
            startAdornment={
              alert && (
                <AlertTooltip
                  alertType={alert.level}
                  clickable
                  id={item.cartItemId}
                  isOpen={tooltipOpen}
                  setIsOpen={setTooltipOpen}
                  tooltipPlacement='left'
                >
                  <>
                    {typeof alert === 'string' ? (
                      <Typography bodyStyle={bodySmallTypography} gutterBottom>
                        {alert}
                      </Typography>
                    ) : (
                      <>
                        <Typography
                          bodyStyle={bodySmallTypography}
                          gutterBottom
                        >
                          {alert.title || alert.message}
                        </Typography>
                        <Typography
                          bodyStyle={bodySmallTypography}
                          sx={{ color: neutral20.hex }}
                        >
                          {alert.subtitle || alert.sub_message}
                        </Typography>
                      </>
                    )}
                  </>
                </AlertTooltip>
              )
            }
            sx={{
              width: pxToRem(80),
              '& input': {
                textAlign: 'center',
              },
            }}
            testingId='cart-line-item-column-quantity--input'
            type='number'
            value={quantity}
          />
          {batchQueuesEnabled && alertIsErrorOrWarning && alertIsStockIssue && (
            <IconButton
              icon={<IconRefresh />}
              onClick={handleLineItemQuantityRefresh}
              variant='transparent'
              sx={{
                position: 'absolute',
                right: pxToRem(-42),
                top: pxToRem(5),
                '& svg': {
                  fill: interaction60.hex,
                },
              }}
            />
          )}
        </div>
      )}
    </CartLineItemColumnQuantityWrapper>
  );
};

CartLineItemColumnQuantity.propTypes = {
  item: PropTypes.shape({
    cartItemId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    totalQuantity: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }).isRequired,
};

export default CartLineItemColumnQuantity;
