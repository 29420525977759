const vendorBrandsState = {
  brandNames: [],
  brands: [],
  filters: {
    pagination: { pageIndex: 0, pageSize: 10, pageCount: 1 },
    searchTerm: '',
    sort: 'id',
    status: 'active',
    pageLength: 10,
  },
  hasEditPermission: false,
  isCallPending: false,
  isLoading: false,
};

export default vendorBrandsState;
