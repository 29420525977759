import {
  ActionReducerMapBuilder,
  AnyAction,
  createSlice,
  Dispatch,
  Middleware,
  MiddlewareAPI,
  PayloadAction,
  Reducer,
  SliceCaseReducers,
  ValidateSliceCaseReducers,
} from '@reduxjs/toolkit';

type UndoableFields<T> = Record<keyof T, boolean>;

const undoableSymbol = Symbol('undoable');

interface UndoableMetadata<T> {
  past: Partial<T>[];
  future: Partial<T>[];
  buffer: Partial<T>[];
}

type UndoableState<T> = T & {
  [undoableSymbol]: UndoableMetadata<T>;
};

const UNDO = 'UNDO';
const REDO = 'REDO';
const FLUSH_UNDO_BUFFER = 'FLUSH_UNDO_BUFFER';
const RESET_UNDO_STACK = 'RESET_UNDO_STACK';

function getUndoableSubset<T>(
  state: T,
  undoableFields: UndoableFields<T>
): Partial<T> {
  return Object.keys(undoableFields).reduce((acc, key) => {
    if (undoableFields[key]) {
      acc[key] = state[key];
    }
    return acc;
  }, {} as Partial<T>);
}

function haveUndoableFieldsChanged<T>(
  oldState: T,
  newState: T,
  undoableFields: UndoableFields<T>
): boolean {
  return Object.keys(undoableFields).some((key) => {
    return (
      undoableFields[key] &&
      newState[key] !== undefined &&
      newState[key] !== oldState[key]
    );
  });
}

function undoable<T>(
  reducer: Reducer<T>,
  undoableFields: UndoableFields<T>,
  name: string
): Reducer<UndoableState<T>> {
  const initialState: UndoableState<T> = {
    ...reducer(undefined, { type: `${name}/@@INIT` }),
    [undoableSymbol]: {
      past: [],
      future: [],
      buffer: [],
    },
  };

  return ((state = initialState, action: PayloadAction<any>) => {
    const {
      [undoableSymbol]: { past, future, buffer },
      ...present
    } = state;

    switch (action.type) {
      case `${name}/${UNDO}`: {
        if (past.length === 0) return state;
        const previous = past[past.length - 1];
        return {
          ...present,
          ...previous,
          [undoableSymbol]: {
            past: past.slice(0, past.length - 1),
            future: [getUndoableSubset(present, undoableFields), ...future],
            buffer: [],
          },
        };
      }

      case `${name}/${REDO}`: {
        if (future.length === 0) return state;
        const next = future[0];
        return {
          ...present,
          ...next,
          [undoableSymbol]: {
            past: [...past, getUndoableSubset(present, undoableFields)],
            future: future.slice(1),
            buffer: [],
          },
        };
      }

      case `${name}/${FLUSH_UNDO_BUFFER}`:
        if (buffer.length > 0) {
          return {
            ...present,
            [undoableSymbol]: {
              past: [...past, buffer[0]],
              future: [],
              buffer: [],
            },
          };
        }
        return state;

      case `${name}/${RESET_UNDO_STACK}`:
        return {
          ...present,
          [undoableSymbol]: {
            past: [],
            future: [],
            buffer,
          },
        };

      default: {
        const newState = reducer(present as T, action);
        if (present === newState) {
          return state;
        }

        if (
          haveUndoableFieldsChanged(
            present,
            newState as Omit<UndoableState<T>, typeof undoableSymbol>,
            undoableFields
          )
        ) {
          return {
            ...newState,
            [undoableSymbol]: {
              past,
              future: [],
              buffer: [...buffer, getUndoableSubset(present, undoableFields)],
            },
          };
        } else {
          return {
            ...newState,
            [undoableSymbol]: state[undoableSymbol],
          };
        }
      }
    }
  }) as Reducer<UndoableState<T>>;
}

const createDebouncedUndoMiddleware = (
  sliceNames: string[],
  debounceMs: number = 500
): Middleware => {
  const timers: { [key: string]: NodeJS.Timeout | null } = {};

  return (store: MiddlewareAPI) => (next: Dispatch) => (action: AnyAction) => {
    const result = next(action);

    const state = store.getState();
    sliceNames.forEach((sliceName) => {
      if (state?.[sliceName][undoableSymbol].buffer?.length > 0) {
        if (timers[sliceName]) clearTimeout(timers[sliceName]!);
        timers[sliceName] = setTimeout(() => {
          store.dispatch({ type: `${sliceName}/${FLUSH_UNDO_BUFFER}` });
          timers[sliceName] = null;
        }, debounceMs);
      }
    });

    return result;
  };
};

function createUndoableSlice<
  T,
  CaseReducers extends SliceCaseReducers<T>,
  Name extends string = string,
>({
  name,
  initialState,
  reducers,
  extraReducers,
  undoableFields,
}: {
  name: Name;
  initialState: T;
  reducers: ValidateSliceCaseReducers<T, CaseReducers>;
  extraReducers?: (builder: ActionReducerMapBuilder<T>) => void;
  undoableFields: UndoableFields<T>;
}) {
  const slice = createSlice({
    name,
    initialState,
    reducers: {
      ...reducers,
      undo: () => {},
      redo: () => {},
      reset: () => {},
    },
    extraReducers: (builder) => {
      if (extraReducers) {
        extraReducers(builder);
      }
    },
  });

  const enhancedReducer = undoable(slice.reducer, undoableFields, name);

  return {
    ...slice,
    reducer: enhancedReducer,
    actions: {
      ...slice.actions,
      undo: () => ({ type: `${name}/${UNDO}` }),
      redo: () => ({ type: `${name}/${REDO}` }),
      reset: () => ({ type: `${name}/${RESET_UNDO_STACK}` }),
    },
  };
}

export { createDebouncedUndoMiddleware, createUndoableSlice, undoableSymbol };
