import styled from 'styled-components';
import {
  spacing2,
  spacing5,
  neutral10,
  interaction40,
} from '@leaftrade/leaftrade-component-library';

export const AddBankAccountForm = styled.div`
  display: flex;
  flex-direction: column;
`;

export const BankAccountSection = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: ${spacing5};
  margin: ${spacing5} 0 ${spacing2};
  & > div {
    flex: 1 1 45%;
  }
`;
export const AddressSection = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: ${spacing5};
  border-bottom: 1px solid ${neutral10.hex};
  border-top: 1px solid ${neutral10.hex};
  padding: ${spacing5} 0;
  margin: ${spacing5} 0;
  & > div {
    flex: 1 1 45%;
  }
`;

export const PrivacyTermsCta = styled.button`
  background: none;
  border: none;
  color: ${interaction40.hex};
  cursor: pointer;
  padding: 0;
  text-decoration: none;
  font-size: 0.9rem;
  &:hover {
    text-decoration: underline;
  }
`;
