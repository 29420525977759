import { pxToRem, spacing3 } from '@leaftrade/leaftrade-component-library';
import styled from 'styled-components';

export const NoResultsWrapper = styled.div`
  display: flex;
  height: 100%;
  margin: 0 auto;
`;

export const NoResultsIconWrapper = styled.div`
  height: ${pxToRem(72)};
  width: ${pxToRem(72)};
  background: linear-gradient(90deg, #38fa7a 0%, #62ffee 100%);
  box-shadow: inset 2px 2px 4px 0 rgba(0, 0, 0, 0.2);
  border-radius: 100%;
  & svg {
    height: ${pxToRem(64)};
    margin: -${spacing3};
  }
`;
