import styled from 'styled-components';
import { spacing2, spacing6 } from '@leaftrade/leaftrade-component-library';

export const CheckoutShippingLocation = styled.div`
  display: flex;
  align-items: center;
  & p {
    margin-right: ${spacing2};
    margin-bottom: ${spacing6};
  }
`;
