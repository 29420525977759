import { logoDefaultColorOutlineWhite } from '@leaftrade/leaftrade-component-library';
import PropTypes from 'prop-types';
import React from 'react';
import {
  HeaderLogo,
  HeaderWrapper,
} from '../../../shared/styles/Header.styles';
import HeaderActions from './HeaderActions';
import HeaderNav from './HeaderNav';
import HeaderVendorPreview from './HeaderVendorPreview';

const Header = ({ hasLimitedAccess, loggedOutAccess }) => (
  <HeaderWrapper data-testid='header--component--header'>
    <HeaderLogo href='/'>
      <img src={logoDefaultColorOutlineWhite} alt='Leaf Trade' />
    </HeaderLogo>
    {!hasLimitedAccess && <HeaderNav />}
    {hasLimitedAccess && <HeaderVendorPreview />}
    <HeaderActions
      hasLimitedAccess={hasLimitedAccess}
      loggedOutAccess={loggedOutAccess}
    />
  </HeaderWrapper>
);

Header.propTypes = {
  hasLimitedAccess: PropTypes.bool,
  loggedOutAccess: PropTypes.bool,
};

Header.defaultProps = {
  hasLimitedAccess: false,
  loggedOutAccess: false,
};

export default Header;
